export default {
    total: {
        certified: 'Certifié',
        UnCertified: 'Non certifié',
        login: 'Connexion',
        register: "S'inscrire",
        registerNow: 'Inscrivez-vous maintenant',
        loginNow: 'Connectez-vous maintenant',
        buyNow: 'Acheter maintenant',
        Currency: 'Devise',
        chang24: 'Changer',
        NowPrice: 'Dernier prix',
        Leverage: 'Effet de levier',
        Favorites: 'Favoris',
        Announcement: 'Annonce',
        dynamic: 'Dynamique',
        VerificationCode: 'Code de vérification',
        enteremailcode: 'Veuillez entrer le code de vérification par e-mail',
        GetCode: 'Envoyer',
        add: 'Ajouter',
        help: 'Centre d\'aide',
        xszy: 'Guide du débutant',
        jyzn: 'Guide de trading',
        aboutUs: 'À propos de aaaa'
    },
    login: {
        loginTitle: 'Bienvenue chez aaaa',
        email: 'Email',
        enailTest: 'Veuillez saisir votre adresse e-mail',
        password: 'Mot de passe',
        passwordTest: 'Veuillez saisir votre mot de passe',
        unpassword: 'Mot de passe oublié?',
        noassets: 'Pas encore de compte',
        Addassets: 'Créer un compte',
        password2: 'Re-saisir le mot de passe',
        password2test: 'Veuillez ressaisir votre mot de passe',
        assets: 'Compte existant'
    },
    topHeader: {
        nav01: 'Contrat',
        nav02: 'Trading Spot',
        nav03: 'Secondes',
        nav04: 'Copier le commerce',
        nav05: 'Actualités',
        nav06: 'Changement rapide',
        APPStore: 'APP Store',
        GooglePlay: 'Google Play',
        Android: 'Android',
        IOSaAndroid: 'IOS et Android Scan pour télécharger',
        cn: '简体中文',
        en: 'English',
        assetsHome: 'Actifs',
        assets01: 'Détails des actifs',
        assets02: 'Détails des fonds',
        assets03: 'Détails de la commande',
        assetsHome02: 'Commandes',
        assets0201: 'Contrats',
        assets0202: 'Spot',
        assets0203: 'Secondes',
        account01: "Vue d'ensemble",
        account02: 'Compte et sécurité',
        account03: 'Gérer les copies de trades',
        account04: 'Gérer les trades leaders',
        loginOut: 'Se déconnecter'
    },
    foote: {
        test: "aaaa is a leading global digital financial service platform that provides a secure, intelligent, and fair environment for digital asset investment through its one-stop digital investment services, diversified asset allocation tools, innovative investment advisory services, and industry-leading liquidity. The core team of aaaa comes from traditional financial securities industries, top-tier internet companies like Microsoft, and other cryptocurrency enterprises, bringing extensive experience in traditional financial trading, top-tier cryptocurrency exchange platform development, and global market operations. Since its establishment six months ago, the global user base has surpassed 100,000 and covers more than 80 countries and regions, including North America, Europe, and the Asia-Pacific region.",
        tab1Title1: 'Aide',
        tab1Test1: 'FAQ',
        tab1Test2: "Centre d\'annonces",
        tab2Title1: 'Conditions',
        tab2Test1: "Conditions d\'utilisation",
        tab2Test2: 'Politique de confidentialité',
        tab2Test3: 'Divulgation des risques',
        tab3Title1: 'Rejoignez-nous',
        msg: "Avertissement sur les risques : Le trading d'actifs numériques est une industrie émergente avec de grandes perspectives, mais elle comporte également des risques importants en raison de sa nouveauté. En particulier, dans le trading de contrats, l'effet de levier amplifie à la fois les profits et les risques simultanément. Il est crucial que vous ayez une compréhension approfondie de l'industrie, des modèles de trading de contrats, des règles de trading et d'autres connaissances connexes avant de participer. Nous vous recommandons vivement de trader dans les limites de risque que vous pouvez supporter. Le trading comporte des risques, et la prudence est de mise lors de l'entrée sur le marché !",
        banquan: "Droits d\'auteur",
        banquan2: 'Tous droits réservés',
        aboutUs: 'À propos de nous',
        Platform: 'Introduction à la plateforme'
    },
    home: {
        bannerTitle: 'Achetez des crypto-monnaies en aaaa',
        bannerTest: 'La principale plateforme de trading de crypto-monnaies sociale au monde',
        changUpRank: 'Classement des Gagnants',
        changDownRank: 'Classement des Perdants',
        whyTitle: 'Pourquoi nous choisir',
        indexTitle1: 'Garantie de Sécurité',
        indexTest1: 'Déploiement décentralisé mondial sans risque systémique',
        indexTitle2: 'Mission et Vision',
        indexTest2: 'Permettre aux investisseurs de réaliser des profits sur les crypto-monnaies',
        indexTitle3: 'Contrats Intelligents',
        indexTest3: "Trading long et court, placement rapide d'ordres, jusqu'à un effet de levier de 100x",
        indexTitle4: 'Trading Copié',
        indexTest4: 'Un clic pour suivre les trades des meilleurs traders avec un taux de rendement élevé',
        indexTitle5: 'Discussion en Temps Réel',
        indexTest5: 'Chat en temps réel',
        indexTitle6: 'Binaire',
        indexTest6: 'Le premier indice de crypto-monnaies binaire au monde',
        indexTitle7: 'Multi-devises',
        indexTest7: 'Saisissez en toute confiance la clé du succès sur plus de 300 projets diversifiés',
        indexTitle8: 'Écosystème',
        indexTest8: "Créez un service tout-en-un pour les échanges de crypto-monnaies, les portefeuilles et l'écosystème on-chain",
        downLoadTitle: "Trading à Tout Moment, N'importe où",
        downLoadTest: "Disponible sur les appareils iOS, Android et PC. Tradez à tout moment, n'importe où.",
        downLoadCode: 'Scanner pour Télécharger',
        downLoadIosAnd: 'iOS et Android'
    },
    contract: {
        Change: 'Changement 24H',
        High: 'Plus Haut 24H',
        Low: 'Plus Bas 24H',
        Volume24: 'Volume 24H',
        Market: 'Marché',
        LimitPrice: 'Limite',
        BestPrice: 'Trade au Meilleur Prix Disponible',
        EnterPrice: 'Veuillez Entrer le Prix',
        Amount: 'Montant',
        Margin: 'Marge',
        Available: 'Solde Disponible',
        Leverage: 'Effet de Levier',
        Fee: 'Frais de Transaction',
        Buy: 'Acheter',
        Long: 'haussier',
        Sell: 'Vendre',
        Short: 'baissier',
        Assets: 'Actifs',
        ContractAssets: 'Actifs du Contrat',
        Deposit: 'Déposer Maintenant',
        Login: 'Se Connecter au Compte de Trading',
        Register: 'Créer un Compte de Trading',
        Instrument: 'Instrument de Trading',
        Minimum: 'Taille de Lot Minimum',
        Maximum: 'Taille de Lot Maximum',
        TradingTime: 'Horaire de Trading',
        TradingLeverage: 'Effet de Levier de Trading',
        FeeRate: 'Taux de Frais',
        Introduction: 'Introduction',
        IssuanceDate: "Date d'Émission",
        TotalIssuance: 'Émission Totale',
        CirculatingSupply: 'Offre en Circulation',
        Crowdfunding: 'Prix de Financement Participatif',
        Whitepaper: 'Livre Blanc',
        Official: 'Site Web Officiel',
        Blockchain: 'Explorateur de Blockchain',
        Positions: 'Positions',
        OpenOrder: 'Ordres Ouverts',
        TradeHistory: 'Historique des Transactions',
        ProfitLoss: 'Profit/Perte',
        CloseAll: 'Tout Fermer',
        Contract: 'Contrat',
        LotSize: 'Taille du Lot',
        OpeningPrice: "Prix d'Ouverture",
        CurrentPrice: 'Prix Actuel',
        TakeProfi: 'Prix de Take Profit',
        StopLoss: 'Prix de Stop Loss',
        OrderPL: "Profit/Perte de l'Ordre",
        OpeningTime: "Heure d'Ouverture",
        OrderID: "ID de l'Ordre",
        Action: 'Action',
        Settings: 'Paramètres',
        ClosePosition: 'Fermer',
        Delete: 'Supprimer',
        Status: 'Statut',
        EnterCurrency: 'Veuillez Entrer la Devise',
        saveStopLoss: 'Définir T/P & S/L',
        live:'En direct',
        demo:'Démonstration'
    },
    SpotGoods: {
        pricelimit: 'Limite',
        marketprice: 'Marché',
        Price: 'Prix',
        MarketPrice: 'Marché',
        PleaseEnter: 'Veuillez Entrer',
        Quantity: 'Quantité',
        Total: 'Total',
        Or: 'Ou',
        OpenOrders: 'Ordres Ouverts',
        Fee: 'Frais',
        QuantityAcc: 'Chiffre d\'Affaires',
        LatestTrade: 'Dernier Échange',
        Time: 'Temps',
        NowOrders: 'Ordres Ouverts',
        AllOrders: 'Tous les Ordres',
        OrderHistory: 'Historique des Ordres',
        Date: 'Date',
        TradingPair: 'Paire de Trading',
        OrderType: 'Type d\'Ordre',
        MarketOrder: 'Ordre au Marché',
        LimitOrder: 'Ordre à Cours Limité',
        Direction: 'Direction',
        OrderPrice: 'Prix de l\'Ordre',
        OrderQuantity: 'Quantité de l\'Ordre',
        OrderAmoun: 'Montant de l\'Ordre'
    },
    Binary: {
        GameRules: 'Règles du Jeu',
        Trading: 'Trading en Cours',
        PositionClosed: 'Position Fermée',
        BuyLong: 'Acheter à la Hausse',
        BuyShort: 'Acheter à la Baisse',
        OrderPeriod: 'Période de Commande',
        TradingAmount: 'Montant du Trading',
        Estimated: 'Heure de Clôture Estimée',
        ClosingTime: 'Heure de Clôture',
        PlaceBet: 'Placer un Pari',
        Amount: 'Montant',
        Balance: 'Solde du Compte',
        Odds: 'Cotes',
        Profit: 'Profit Estimé',
        Bullish: 'haussier',
        Bearish: 'baissier',
        countTime: 'Compte à Rebours',
        customInp: 'Montant Personnalisé'
    },
    follow: {
        Ranking: 'Classement des Traders',
        Updates: 'Mises à Jour des Données Toutes les Deux Heures',
        SearchKey: 'Rechercher Tous les Traders',
        Search: 'Rechercher',
        Manage: 'Gérer le suiveur',
        Details: 'Détails',
        test1: 'Formule = Ordre Copié [Profit/Perte] / [Marge Totale] * 100%',
        title1: '30J ROR',
        test2: 'Formule = Ordre Auto-Placé [Nombre d\'Ordres Rentables / Nombre Total d\'Ordres] * 100%',
        title2: 'Taux de Réussite sur 30J',
        title3: 'Suiveurs actuels',
        title4: 'Commission sur 30J',
        test5: 'Nombre Total d\'Ordres (Auto-Placés) dans les 30 Derniers Jours',
        title5: 'Lots de trading sur 30J',
        test6: 'Calculé à partir de la Date d\'Enregistrement',
        title6: 'Jours de Trading',
        Unfollow: 'Ne Plus Suivre',
        Followed: 'Suivi',
        EditCopying: 'Modifier la Copie',
        CopyingCurrency: 'Devise de Copie',
        AmountCopying: 'Montant de la Copie',
        CopyingAmount: 'Montant Copié',
        ContractTrading: 'Paire de Trading sur Contrat',
        LotSizeRatio: 'Ratio de Taille de Lot',
        StartCopying: 'Commencer la Copie',
        CancelCopying: 'Annuler la Copie',
        title7: 'Drawdown sur 30J',
        test7: 'Ratio de Perte d\'Actifs dans les 30 Derniers Jours (Pourcentage de Perte d\'Actifs Quotidienne par Rapport aux Actifs du Jour Précédent, Basé sur la Perte Maximale)',
        BackLobby: 'Retour au Lobby',
        TradeHistory: 'Historique de Trading',
        ClosingPrice: 'Prix de Clôture',
        info: 'Profil Personnel',
        CumulativeCopying: 'Montant Cumulatif de Copie',
        TotalCopying: 'Profit Total de Copie',
        Discover: 'Découvrir les Experts',
        CopyOrders: 'Copier les Ordres',
        CurrentFollowers: 'Suiveurs Actuels',
        Avatar: 'Avatar',
        TraderName: 'Nom du Trader',
        MaximumPosition: 'Montant Maximum de Position',
        ProfitLoss: 'Profit/Perte de Copie',
        Edit: 'Éditer',
        TodayProfit: 'Profit d\'Aujourd\'hui',
        Past7Days: 'Profit Total des 7 Derniers Jours',
        Accumulated: 'Profit Total Accumulé',
        ProfitDetails: 'Détails du Profit',
        Followers: 'Suiveurs',
        Profit: 'Profit',
        FollowerAvatar: 'Avatar du Suiveur',
        FollowerNickname: 'Surnom du Suiveur',
        CopyingTime: 'Temps de Copie',
        Notice: 'Avis',
        NoticeTest1: 'Vous n\'êtes pas encore trader, donc vous ne pouvez pas effectuer cette opération !',
        NoticeTest2: 'En cours de révision, veuillez patienter !',
        Cancel: 'Annuler',
        ApplyNow: 'Postuler Maintenant',
        ApplyTrader: 'Devenir Trader',
        Nickname: 'Surnom (Obligatoire)',
        Phone: 'Numéro de Téléphone (Obligatoire)',
        Email: 'Email (Facultatif)',
        test1: 'Après avoir soumis la demande, notre personnel vous contactera dès que possible. Veuillez fournir les informations demandées pour la demande.',
        test2: 'Durant la période d\'audit, veuillez vous assurer que les conditions suivantes ne sont pas remplies, sinon l\'audit échouera : positions en cours, ordres en cours, plans en cours, et suivi de traders.',
        Apply: 'Postuler'
    },
    account: {
        Overview: 'Aperçu',
        Security: 'Sécurité',
        UserID: 'ID Utilisateur',
        Welcome: 'Bienvenue chez aaaa!',
        Welcometest: 'Encore quelques étapes et vous êtes prêt à commencer',
        Registration: 'Inscription au Compte',
        SecurityVerification: 'Vérification de Sécurité',
        GotoBind: 'Aller à Liaison',
        QuickBuy: 'Achat Rapide de Coins',
        AssetDetails: 'Détails des Actifs',
        Deposit: 'Dépôt',
        Withdraw: 'Retrait',
        Transfer: 'Transfert',
        TransferList: 'Historique des Transferts',
        type: 'Type',
        SpotAccount: 'Compte Spot',
        FuturesAccount: 'Compte Futures',
        HideAsset: 'Cacher l\'Évaluation des Actifs',
        Available: 'Solde Disponible (USDT)',
        checkdetails: 'Pour des quantités d\'actifs détaillées, veuillez consulter les détails des actifs',
        Recommendations: 'Recommandations de Sécurité',
        PasswordSet: 'Paramètres du Mot de Passe',
        Change: 'Changer',
        FundPassword: 'Mot de Passe des Fonds',
        GotoSet: 'Aller à Définir',
        AddressManagement: 'Gestion des Adresses',
        GotoAdd: 'Aller à Ajouter',
        Verification: 'Vérification d\'Identité',
        GotoVerify: 'Aller à Vérifier',
        LatestNews: 'Dernières Nouvelles',
        SafetyTitle: 'Authentification à Deux Facteurs',
        SafetyTest: 'Pour protéger votre compte, il est recommandé de compléter au moins une vérification d\'identité en réel',
        SafetyText1: 'Utilisé pour gérer votre mot de passe de connexion au compte',
        SafetyText2: 'Utilisé pour gérer votre mot de passe de fonds du compte',
        NotSet: 'Non Défini',
        Set: 'Définir',
        SafetyText3: 'Protéger vos échanges et la sécurité de votre compte',
        SafetyText4: 'Assurer l\'exactitude des adresses de retrait',
        Manage: 'Gérer',
        InvitationLink: 'Lien d\'Invitation',
        copyInvitationLink: 'Copier',
        InvitationCode: 'Code d\'Invitation',
        copyInvitationCode: 'Copier',
        enterInvitationCode: 'Veuillez entrer le code d\'invitation'
    },
    finance: {
        Protocol: 'Réseau Principal (Protocole)',
        DepositAddress: 'Adresse de Dépôt',
        QRCode: 'Code QR de Dépôt',
        DepositTest1: 'Le montant minimum de dépôt par transaction ne doit pas être inférieur à',
        DepositTest2: ', tout montant inférieur à cela ne sera pas réussi',
        DepositTest3: 'Avant chaque dépôt, vous devez aller sur cette page pour copier l\'adresse afin d\'en assurer l\'exactitude. L\'utilisation d\'une adresse incorrecte entraînera un échec du dépôt.',
        DepositTest4: 'Ne déposez aucun actif non-',
        DepositTest5: 'à l\'adresse ci-dessus, sinon les actifs ne seront pas récupérables.',
        WithdrawalAddress: 'Adresse de Retrait',
        AddressManagement: 'Gestion des Adresses',
        Memo: 'Mémo',
        WithdrawalAmount: 'Montant du Retrait',
        AvailableBalance: 'Solde Disponible',
        Withdraw: 'Retirer',
        WithdrawTest1: 'Veuillez confirmer que l\'adresse de retrait cible existe et est activée, sinon cela entraînera un retrait échoué et les actifs ne seront pas récupérables.',
        WithdrawTest2: 'S\'il y a une étiquette d\'adresse, veuillez vérifier soigneusement l\'étiquette d\'adresse pour éviter la perte d\'actifs qui ne peut pas être récupérée.',
        WithdrawTest3: 'Pour assurer la sécurité des fonds, lorsque votre stratégie de sécurité de compte change ou que les mots de passe sont modifiés, nous examinerons manuellement le retrait. Veuillez attendre patiemment que notre personnel vous contacte par téléphone ou par e-mail.',
        WithdrawTest4: 'Veuillez assurer la sécurité de votre ordinateur et de votre navigateur pour prévenir la manipulation ou la fuite d\'informations.',
        WithdrawTest5: 'Heure de Retrait (UTC+8) : du lundi au dimanche, de 9h00 à 22h00.',
        AvailableAssets: 'Actifs Disponibles',
        GamblingAccount: 'Compte à la Seconde',
        notpassword: 'Vous n\'avez pas encore défini de mot de passe pour les fonds, donc vous ne pouvez pas effectuer cette opération !',
        Back: 'Retour',
        AddAddress: 'Ajouter une Nouvelle Adresse',
        SelectCurrency: 'Sélectionner la Devise',
        Confirm: 'Confirmer',
        from: 'De',
        to: 'À'
    },
    assets: {
        ContractPositions: 'Positions sur Contrat',
        DepositDetails: 'Détails du Dépôt',
        WithdrawalHistory: 'Historique des Retraits',
        WithdrawalRecords: 'Registres de Dépôt et de Retrait',
        TotalEquivalent: 'Valeur Équivalente Totale de Tous les Actifs Spot (en USDT)',
        AvailableBalance: 'Solde Disponible en Spot (USDT)',
        HideSmall: 'Cacher les Petits Actifs',
        Equivalent: 'Équivalent',
        FrozenAmount: 'Montant Gelé',
        DepositType: 'Type de Dépôt',
        DepositAmount: 'Montant du Dépôt',
        DepositCurrency: 'Devise de Dépôt',
        DepositTime: 'Heure du Dépôt',
        WithdrawalType: 'Type de Retrait',
        WithdrawalAmount: 'Montant du Retrait',
        WithdrawalCurrency: 'Devise de Retrait',
        WithdrawalTime: 'Heure du Retrait'
    },
    fastChange: {
        Sell: 'Vendre',
        ReferencePrice: 'Prix de Référence',
        Buy: 'Acheter',
        Quantity: 'Quantité Échangeable',
        PleaseEnter: 'Veuillez entrer le montant souhaité pour l\'échange',
        Exchange: 'Échanger',
        Trading: 'Échange de Petits Montants ● Trading en Gros',
        TradingTest: 'Échange Rapide pour BTC, ETH, et Monnaies Numériques',
        Step1: 'Étape 1',
        Step1Test: 'Sélectionnez la devise à échanger',
        Step2: 'Étape 2',
        Step2Test: 'Entrez le montant à échanger',
        Step3: 'Étape 3',
        Step3Test: 'Commencer l\'échange'
    },
    Password: {
        ChangePassword: 'Changer le Mot de Passe de Connexion',
        OldPassword: 'Ancien Mot de Passe de Connexion',
        enterOldPassword: 'Veuillez entrer votre ancien mot de passe de connexion',
        NewLogin: 'Nouveau Mot de Passe de Connexion',
        enterNewLogin: 'Veuillez entrer votre nouveau mot de passe de connexion',
        againenterNewLogin: 'Veuillez réentrer votre mot de passe de connexion',
        FundPassword: 'Définir le Mot de Passe des Fonds',
        fundpassword: 'Veuillez entrer votre mot de passe des fonds',
        againfundpassword: 'Veuillez réentrer votre mot de passe des fonds',
        Name: 'Nom',
        Number: 'Numéro d\'Identité',
        photos: 'Veuillez télécharger les photos de votre carte d\'identité, la première est le recto, et la seconde est le verso',
        photos2: 'Veuillez télécharger votre photo d\'identité',
        Upload: 'Télécharger',
        wait: 'En cours de révision, veuillez attendre !',
        categoryType: 'Type de Document',
        category1: 'Carte d\'Identité',
        category2: 'Passeport',
        category3: 'Permis de Conduire'
    },
    add: {
        Copied: "Copié !",
        test29: "La simulation de trading est terminée, le trading réel vous attend !",
        test30: "Rechargez maintenant et profitez des récompenses du fonds de trading",
        test31: "Obtenez plus de support et d'outils immédiatement",
        test32: "Rechargez pour commencer votre voyage rentable",
        DemoTrade: "Commerce démo",
        Progress: "En cours",
        Requested: "Demande de remboursement",
        Refunded: "Remboursé",
        Completed: "Terminé",
        test1: "Aperçu du produit",
        test2: "Le Service de Gestion Financière (FMS) de (aaaa) est un produit conçu par (aaaa) pour aider les utilisateurs à tirer profit de leurs actifs numériques inactifs à des seuils bas. Il est facile de commencer. Le Service de Gestion Financière (FMS) de (aaaa) propose différentes options de durée, permettant aux utilisateurs de réaliser des bénéfices en prêtant à des utilisateurs de trading à effet de levier sur le marché du prêt ; Gagner des pièces simples à terme fixe implique de bloquer des pièces pour obtenir des récompenses PoS ou des incitations de projet.",
        test3: "*Veuillez noter : Les gains passés ne représentent pas les gains futurs. Nous ne promettons pas de rembourser le capital et les intérêts dans une certaine période en monnaie, biens physiques, actions ou toute autre forme de revenu de propriété, ni ne promettons tout autre forme de revenu de propriété.",
        test4: "Sécurisé et fiable",
        test5: "(aaaa) dispose de systèmes de contrôle des risques stricts, de mesures de sécurité de premier plan et d'une infrastructure avancée pour garantir pleinement la sécurité des actifs des utilisateurs.",
        test6: "Abonnement et Rédemption Flexibles",
        test7: "Support 24 heures sur 24 pour l'abonnement et la rédemption à tout moment. La rédemption pour les gains actuels de pièces simples est immédiate, tandis que la rédemption pour les gains de pièces simples à terme fixe prend jusqu'à 30 minutes.",
        test8: "Service de Gestion Financière (FMS) à Terme Fixe de (aaaa)",
        test9: "Le Service de Gestion Financière (FMS) à Terme Fixe de (aaaa) dispose de plusieurs sources de revenus, tels que des récompenses obtenues grâce à la mise en garantie et des récompenses basées sur le mécanisme de Proof of Stake (PoS), ainsi que des récompenses de plateforme.",
        test10: "Qu'est-ce que le Rendement de Référence Annuel?",
        test11: "Le Rendement de Référence Annuel est le rendement annuel prévu du produit auquel vous vous abonnez, à titre indicatif seulement.",
        test12: "Comment sont calculés les bénéfices attendus?",
        test13: "Les gains attendus sont calculés en fonction de la quantité de votre abonnement et du rendement de référence annuel du produit, fournissant une estimation des gains attendus quotidiens, à titre indicatif uniquement.",
        test14: "Quand les intérêts commencent-ils à s'accumuler ?",
        test15: "Les gains commencent à s'accumuler à partir de 12h00 (UTC+8) le jour suivant l'abonnement.",
        test16: "Quand les gains sont-ils distribués ?",
        test17: "Les gains sont distribués à partir de 12h00 (UTC+8) le jour suivant le début de l'accumulation des intérêts.",
        test18: "Pourquoi différents produits ont-ils des périodes de remboursement différentes ?",
        test19: "La période de remboursement fait référence au temps nécessaire entre la confirmation du remboursement et le crédit effectif du remboursement. Les actifs pour les gains de pièces simples à terme fixe sont garantis sur la chaîne, ce qui nécessite un certain temps pour le remboursement à partir de la chaîne. Comme chaque chaîne publique fonctionne différemment, les périodes de remboursement varient.",
        test20: "Dois-je rembourser manuellement les produits à terme fixe à maturité ?",
        test21: "Cela dépend de la situation. Tous les produits à terme fixe seront renouvelés automatiquement par défaut. À maturité, le même terme sera automatiquement renouvelé. Après avoir activé le renouvellement automatique, le remboursement doit être effectué manuellement. La fonction de renouvellement automatique est activée par défaut. Pour la désactiver, veuillez vous rendre sur la page des détails de la commande.",
        test22: "Puis-je encore gagner des revenus en attendant le remboursement ?",
        test23: "Une fois que le remboursement est confirmé, les intérêts cessent de s'accumuler. Les revenus ne peuvent pas être gagnés en attendant le remboursement.",
        test24: "Comment choisir le meilleur produit de Service de Gestion Financière (FMS) à Terme Fixe de (aaaa) ?",
        test25: "Vous pouvez comparer différents actifs numériques, termes, rendements historiques, etc., parmi tous les produits de gains de pièces simples que nous proposons, pour choisir le produit qui vous convient. Nous ne fournissons que des services de mise en garantie d'actifs, de collecte et de distribution de récompenses, et ne fournissons aucun conseil en investissement. Avant de prendre une décision d'investissement, vous devez comprendre pleinement la nature et les risques de ce produit, ainsi que d'autres circonstances pertinentes, et prendre des décisions indépendantes en fonction de votre expérience en matière d'investissement, de votre situation financière, de vos objectifs d'investissement, de votre période d'investissement, de votre volonté et de votre capacité à supporter les risques d'investissement, ainsi que d'autres circonstances pertinentes, et être responsable de vos propres décisions.",
        test26: "Revenu",
        test27: "Avantage",
        test28: "FAQ",
        assets: "Financiers",
        Redemption: "Rachat",
        Subscribe: "S'abonner",
        SubscribeType: "Type de Souscription",
        SubscribeNum: "Montant de Souscription",
        Historical: "Intérêts Cumulés",
        SubscribeTime: "Heure de Souscription",
        RedemptionTime: "Heure de Rachat",
        RedemptionConfirm: "Confirmation de Rachat",
        LockedSavings: "Investissement à Terme Fixe",
        test33: "Gestion Financière",
        test34: "Comprenez les concepts fondamentaux et les stratégies de gestion des risques de l'investissement dans les crypto-monnaies, faites face aux fluctuations du marché avec calme et atteignez vos objectifs financiers. Rejoignez-nous dès maintenant et lancez-vous dans l'investissement dans les crypto-monnaies!",
        test35: "Maîtrisez l'essence de l'investissement dans les crypto-monnaies, interprétez les tendances du marché, explorez les opportunités potentielles et appréciez la richesse. Pas de seuil, faible risque, rendements élevés. Commencez votre parcours de gestion de patrimoine en crypto-monnaie et avancez vers de nouveaux sommets de richesse!",
        test36: "Liste des Produits",
        test37: "Liste de Souscription",
        test38: "Taux d'Intérêt Annuel :",
        test39: "Jours：",
        test40: "Montant Minimum d'Investissement :",
        test41: "Montant Maximum :",
        test42: "Date de Début des Intérêts :",
        test43: "Date de Fin de Calcul des Intérêts :",
        test44: "Maximum",
        test45: "Actifs Disponibles :",
        test46: "Bénéfice Estimé :",
        test47: "J'ai lu et j'accepte",
        test48: "Aperçu du Produit",
        test49:"jour",
        Agreement: "Accord d'achat financier",
        test50: "Montant de la recharge",
        test51: "Télécharger le bon de transfert",
        EnterPrice: "Veuillez entrer le prix",
    }    
}