import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/contract',
    name: 'contract',
    component: () => import('../views/contract.vue')
  },
  {
    path: '/SpotGoods',
    name: 'SpotGoods',
    component: () => import('../views/SpotGoods.vue')
  },
  {
    path: '/Binary',
    name: 'Binary',
    component: () => import('../views/Binary.vue')
  },
  {
    path: '/follow',
    name: 'follow',
    component: () => import('../views/follow/follow.vue')
  },
  {
    path: '/follow/detail',
    name: 'followDetail',
    component: () => import('../views/follow/detail.vue')
  },
  {
    path: '/follow/manage',
    name: 'followManage',
    component: () => import('../views/follow/manage.vue')
  },
  {
    path: '/new/newList',
    name: 'newList',
    component: () => import('../views/new/newList')
  },
  {
    path: '/fastChange',
    name: 'fastChange',
    component: () => import('../views/fastChange')
  },
  {
    path: '/protocol',
    name: 'protocol',
    component: () => import('../views/protocol')
  },
  {
    path: '/guide',
    name: 'guide',
    component: () => import('../views/guide')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/help')
  },
  {
    path: '/financialList',
    name: 'financialList',
    component: () => import('../views/financial/financialList')
  },
  {
    path: '/financialDet',
    name: 'financialDet',
    component: () => import('../views/financial/financialDet')
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/account/account'),
    children: [
      {
        path: 'accountInfo',
        name: 'accountInfo',
        component: () => import('../views/account/accountInfo'),
      },
      {
        path: 'followManagement',
        name: 'followManagement',
        component: () => import('../views/account/followManagement'),
      },
      {
        path: 'bringManagement',
        name: 'bringManagement',
        component: () => import('../views/account/bringManagement'),
      },
      {
        path: 'Safety',
        name: 'Safety',
        component: () => import('../views/account/Safety'),
      },
      {
        path: 'loginPassword',
        name: 'accountLoginPassword',
        component: () => import('../views/account/loginPassword'),
      },
      {
        path: 'WithdrawalPassword',
        name: 'WithdrawalPassword',
        component: () => import('../views/account/WithdrawalPassword'),
      },
      {
        path: 'Authentication',
        name: 'Authentication',
        component: () => import('../views/account/Authentication'),
      }
    ]
  },
  {
    path: '/assets',
    name: 'assets',
    component: () => import('../views/assets/assets'),
    children: [
      {
        path: 'account',
        name: 'assetsAccount',
        component: () => import('../views/assets/account'),
      },
      {
        path: 'position',
        name: 'assetsPosition',
        component: () => import('../views/assets/position'),
      },
      {
        path: 'Binary',
        name: 'assetsBinary',
        component: () => import('../views/assets/Binary'),
      },
      {
        path: 'recharge',
        name: 'assetsRecharge',
        component: () => import('../views/assets/recharge'),
      },
      {
        path: 'withdraw',
        name: 'assetsWithdraw',
        component: () => import('../views/assets/withdraw'),
      },
      {
        path: 'Transfer',
        name: 'assetsTransfer',
        component: () => import('../views/assets/Transfer'),
      },
      {
        path: 'Spot',
        name: 'assetsSpot',
        component: () => import('../views/assets/Spot'),
      },
      {
        path: 'contract',
        name: 'assetsContract',
        component: () => import('../views/assets/contract'),
      },
      {
        path: 'micro',
        name: 'assetsMicro',
        component: () => import('../views/assets/micro'),
      },
      {
        path: 'financialOrder',
        name: 'financialOrder',
        component: () => import('../views/assets/financialOrder'),
      }
    ]
  },
  {
    path: '/finance/recharge',
    name: 'recharge',
    component: () => import('../views/finance/recharge')
  },
  {
    path: '/finance/withdraw',
    name: 'withdraw',
    component: () => import('../views/finance/withdraw')
  },
  {
    path: '/finance/transfer',
    name: 'transfer',
    component: () => import('../views/finance/transfer')
  },
  {
    path: '/finance/addressList',
    name: 'addressList',
    component: () => import('../views/finance/addressList')
  },
  {
    path: '/finance/address',
    name: 'address',
    component: () => import('../views/finance/address')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register')
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import('../views/forget')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/** 解决跳转重复路由报错问题 */ 
const routerPush = router.push;
router.push = path => {
  // 判断下当前路由是否就是要跳转的路由
  //if (router.currentRoute.fullPath.includes(path)) return;
  return routerPush.call(router, path);
}

export default router
