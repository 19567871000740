export default {
    total:{
        certified:'已认证',
        UnCertified:'未认证',
        login:'登录',
        register:'注册',
        registerNow:'立即注册',
        loginNow:'立即登录',
        buyNow:'立即买币',
        Currency:'币种',
        chang24:'涨跌幅',
        NowPrice:'最新价',
        Leverage:'数币',
        Favorites:'自选',
        Announcement:'平台公告',
        dynamic:'动态',
        VerificationCode:'验证码',
        enteremailcode:'请输入邮箱验证码',
        GetCode:'获取验证码',
        add:'添加自选',
        help:'帮助中心',
        xszy:'新手引导',
        jyzn:'交易指南',
        aboutUs:'关于aaaa'
    },
    login:{
        loginTitle:'欢迎登录aaaa',
        email:'电子邮箱',
        enailTest:'请输入电子邮箱',
        password:'登录密码',
        passwordTest:'请输入登录密码',
        unpassword:'忘记密码？',
        noassets:'还没有账户',
        Addassets:'创建账户',
        password2:'再次输入登录密码',
        password2test:'请再次输入登录密码',
        assets:'已有账户'
    },
    topHeader: {
        nav01: '合约交易',
        nav02: '现货交易',
        nav03: '秒合约交易',
        nav04: '跟单交易',
        nav05: '最新资讯',
        nav06:'闪兑',
        APPStore:'APP Store',
        GooglePlay:'Google Play',
        Android:'Android',
        IOSaAndroid:'IOS& Android 扫码下载',
        cn:'简体中文',
        en:'English',
        assetsHome:'资产',
        assets01:'资产明细',
        assets02:'资金明细',
        assets03:'订单明细',
        assetsHome02:'订单',
        assets0201:'合约',
        assets0202:'现货',
        assets0203:'秒合约',
        account01:'总览',
        account02:'账户与安全',
        account03:'跟单管理',
        account04:'带单管理',
        loginOut:'登出'
    },
    foote:{
        test:'aaaa是全球领先的数字金融服务平台，通过一站式数字投资服务、多样化资产配置工具、创新型投顾及全网领先的流动性，为全球用户构建安全、智能、公平的数字资产投资环境。aaaa核心团队来自传统金融证券行业、微软等顶级互联网公司等加密资产企业，具备丰富的传统金融交易、顶级加密资产交易平台研发及全球市场运营经验。成立半年以来，全球用户数已突破10万，遍布80多个国家和地区，覆盖北美、欧洲、亚太等主流区域。',
        tab1Title1:'帮助',
        tab1Test1:'常见问题',
        tab1Test2:'公告中心',
        tab2Title1:'条款',
        tab2Test1:'新手帮助',
        tab2Test2:'隐私条款',
        tab2Test3:'风险提示',
        tab3Title1:'加入我们',
        msg:'风险提示：数字资产交易是一个新兴产业，存在光明前景，但正因为属于新事物，也蕴含巨大风险。特别是在合约交易中，杠杆倍数既同步扩大了盈利也同步放大了风险，请您务必对此行业、合约交易模式、交易规则等相关知识有充分了解后再参与。并且我们强烈建议您，在自身能够承担的风险范围内进行交易。交易有风险，入市须谨慎！',
        banquan:'版权',
        banquan2:'保留所有权利',
        aboutUs:'关于我们',
        Platform:'平台简介'
    },
    home:{
        bannerTitle:'买币交易上aaaa',
        bannerTest:'全球领先社交型数字货币交易所',
        changUpRank:'涨幅榜',
        changDownRank:'跌幅榜',
        whyTitle:'为什么选择我们',
        indexTitle1:'安全保障',
        indexTest1:'全球去中心化部署，无系统风险',
        indexTitle2:'使命愿景',
        indexTest2:'让用户无忧便捷享受加密货币项目增值红利',
        indexTitle3:'智能合约',
        indexTest3:'多空双向，快捷下单，百倍杠杆',
        indexTitle4:'达人跟随',
        indexTest4:'高成功率带单达人一键跟随',
        indexTitle5:'实时讨论',
        indexTest5:'支持即时聊天交流共同成长',
        indexTitle6:'趣味交易',
        indexTest6:'全球首款数字货币指数趣味竞猜',
        indexTitle7:'币币多样',
        indexTest7:'300+项目快速上币把握造富神话',
        indexTitle8:'生态系统',
        indexTest8:'打造交易所、钱包、链上生态一站式服务',
        downLoadTitle:'随时随地开启交易',
        downLoadTest:'覆盖iOS、Android、Google Play多个平台，随时随地掌上交易',
        downLoadCode:'扫码下载',
        downLoadIosAnd:'IOS & Android',
        // :'监管许可',
        // :'aaaa 是在美国FinCEN（美国财政部下设机构金融犯罪执法局），加拿大FINTRAC（加拿大金融交易和报告分析中心）以及美国SEC(美国证券交易委员会)监管下，依法合规注册经营的金融及货币服务企业。aaaa将根据拥有金融许可证，遵守其运营所在的每个司法管辖区的所有适用法律和法规。'，
        // :'美国',
        // :'加拿大',
        // :'监管中',
        // :'监管证号',
        // :'发证日期',
        // :'美国 验真网址',
        // :'加拿大 验真网址'
    },
    contract:{
        Change:'24H涨跌',
        High:'24H高',
        Low:'24H低',
        Volume24:'24H成交量',
        Market:'市价交易',
        LimitPrice:'限价交易',
        BestPrice:'以当前最优价格交易',
        EnterPrice:'请输入价格',
        Amount:'币数',
        Margin:'保证金',
        Available:'可用余额',
        Leverage:'杠杆',
        Fee:'手续费',
        Buy:'买入',
        Long:'做多',
        Sell:'卖出',
        Short:'做空',
        Assets:'资产',
        ContractAssets:'合约资产',
        Deposit:'立即充币',
        Login:'登录交易账户',
        Register:'注册交易账户',
        Instrument:'交易品种',
        Minimum:'最低手数',
        Maximum:'最高手数',
        TradingTime:'交易时间',
        TradingLeverage:'交易杠杆',
        FeeRate:'费率',
        Introduction:'介绍',
        IssuanceDate:'发行时间',
        TotalIssuance:'发行总量',
        CirculatingSupply:'流通总量',
        Crowdfunding:'众筹价格',
        Whitepaper:'白皮书',
        Official:'官网',
        Blockchain:'区块链查询',
        Positions:'持仓',
        OpenOrder:'挂单委托',
        TradeHistory:'历史成交',
        ProfitLoss:'盈亏',
        CloseAll:'一键平仓',
        Contract:'合约',
        LotSize:'手数',
        OpeningPrice:'开仓价',
        CurrentPrice:'现价',
        TakeProfi:'止盈价',
        StopLoss:'止损价',
        OrderPL:'订单盈亏',
        OpeningTime:'开仓时间',
        OrderID:'订单号',
        Action:'操作',
        Settings:'设置',
        ClosePosition:'平仓',
        Delete:'删除',
        Status:'状态',
        EnterCurrency:'请输入币种名称',
        saveStopLoss:'设置止盈止损',
        live:'实盘',
        demo:'模拟盘'
    },
    SpotGoods:{
        pricelimit:'限价',
        marketprice:'市价',
        Price:'价格',
        MarketPrice:'市场价格',
        PleaseEnter:'请输入',
        Quantity:'数量',
        Total:'总额',
        Or:'或',
        OpenOrders:'委托订单',
        Fee:'费用',
        QuantityAcc:'数量累计',
        LatestTrade:'最新成交',
        Time:'时间',
        NowOrders:'当前委托',
        AllOrders:'全部委托',
        OrderHistory:'历史委托',
        Date:'日期',
        TradingPair:'交易对',
        OrderType:'委托类型',
        MarketOrder:'市价委托',
        LimitOrder:'限价委托',
        Direction:'方向',
        OrderPrice:'委托价',
        OrderQuantity:'委托量',
        OrderAmoun:'委托金额'
    },
    Binary:{
        GameRules:'玩法规则',
        Trading:'交易中',
        PositionClosed:'已平仓',
        BuyLong:'买涨',
        BuyShort:'买跌',
        OrderPeriod:'委托周期',
        TradingAmount:'交易金额',
        Estimated:'预计平仓时间',
        ClosingTime:'平仓时间',
        PlaceBet:'下单',
        Amount:'金额',
        Balance:'账户余额',
        Odds:'赔率',
        Profit:'预计盈利',
        Bullish:'看涨',
        Bearish:'看跌',
        countTime: '倒计时',
		customInp:'自定义金额',
    },
    follow:{
        Ranking:'交易员榜单',
        Updates:'数据每两小时更新一次',
        SearchKey:'搜索所有交易者',
        Search:'搜索',
        Manage:'管理当前跟随',
        Details:'详情',
        test1:'公式 = 带单订单【盈亏/总保证金】* 100%',
        title1:'近30天带单收益率',
        test2:'公式 = 自主下单订单【盈利订单笔数/总订单笔数】 * 100%',
        title2:'近30天交易胜率',
        title3:'当前跟随人数',
        title4:'近30天带单收益',
        test5:'近30天总 (自主下单) 订单的笔数',
        title5:'近30天交易笔数',
        test6:'从注册日起算',
        title6:'交易天数',
        Unfollow:'去跟随',
        Followed:'已跟随',
        EditCopying:'编辑跟单',
        CopyingCurrency:'跟单币种',
        AmountCopying:'金额跟单',
        CopyingAmount:'跟单金额',
        ContractTrading:'合约交易对',
        LotSizeRatio:'手数比例',
        StartCopying:'立即跟随',
        CancelCopying:'取消跟单',
        title7:'近30天资金回撤率',
        test7:'近30天内资产亏损比例(当日亏损资产与前一日资产的占比,出现过最大亏损的比例)',
        BackLobby:'返回大厅',
        TradeHistory:'历史持仓',
        ClosingPrice:'平仓价',
        info:'个人简介',
        CumulativeCopying:'累计跟单金额',
        TotalCopying:'跟单总收益',
        Discover:'发现高手',
        CopyOrders:'我要带单',
        CurrentFollowers:'当前跟随',
        Avatar:'头像',
        TraderName:'交易员名称',
        MaximumPosition:'最大持仓金额',
        ProfitLoss:'跟单盈亏',
        Edit:'编辑',
        TodayProfit:'今日收益',
        Past7Days:'近7天总收益',
        Accumulated:'累计总收益',
        ProfitDetails:'收益明细',
        Followers:'跟随者',
        Profit:'收益',
        FollowerAvatar:'跟随者头像',
        FollowerNickname:'跟随者昵称',
        CopyingTime:'跟随时间',
        Notice:'提示',
        NoticeTest1:'您还没有成为交易员,暂时无法操作!',
        NoticeTest2:'审核中，请耐心等待！',
        Cancel:'取消',
        ApplyNow:'去申请',
        ApplyTrader:'申请交易员',
        Nickname:'昵稱 (必填項)',
        Phone:'手机号 (必填項)',
        Email:'邮箱 (选填项)',
        test1:'提交申請後我們的工作人員會儘快與您取得聯系，請按照要求提供相應的申請資料',
        test2:'注意稽核期間請確保不存在以下情况，否則將導致稽核失敗：持有倉位、當前委託、當前計畫、有跟隨交易員',
        Apply:'申请'
    },
    account:{
        Overview:'总览',
        Security:'安全',
        UserID:'用户ID',
        Welcome:'欢迎来到aaaa!',
        Welcometest:'只需再执行， 您就可以开始了',
        Registration:'账户注册',
        SecurityVerification:'安全认证',
        GotoBind:'去绑定',
        QuickBuy:'快捷买币',
        AssetDetails:'资产详情',
        Deposit:'充币',
        Withdraw:'提币',
        Transfer:'划转',
        TransferList:'划转历史',
        type:'类型',
        SpotAccount:'现货账户',
        FuturesAccount:'合约账户',
        HideAsset:'隐藏资产估值',
        Available:'可用(USDT)余额',
        checkdetails:'详细的资产数量请去资产明细中查看',
        Recommendations:'安全推荐',
        PasswordSet:'密码设置',
        Change:'更改',
        FundPassword:'资金密码',
        GotoSet:'去设置',
        AddressManagement:'提币地址管理',
        GotoAdd:'去添加',
        Verification:'身份认证',
        GotoVerify:'去认证',
        LatestNews:'最新资讯',
        SafetyTitle:'双重身份验证',
        SafetyTest:'为了保护您的账户，建议至少完成一个实名身份验证',
        SafetyText1:'用于管理您的账户登录密码',
        SafetyText2:'用于管理您的账户资金密码',
        NotSet:'未设置',
        Set:'已设置',
        SafetyText3:'保护您的交易和账户安全',
        SafetyText4:'确保提币地址正确无误',
        Manage:'管理',
        InvitationLink:'邀请链接',
        copyInvitationLink:'复制邀请链接',
        InvitationCode:'邀请码',
        copyInvitationCode:'复制邀请码',
        enterInvitationCode:'请输入邀请码'
    },
    finance:{
        Protocol:'主网络(协议)',
        DepositAddress:'充币地址',
        QRCode:'充币二维码',
        DepositTest1:'单笔充值不得低于',
        DepositTest2:'，小于此金额将不会成功',
        DepositTest3:'每次充值前需要前往此页面复制地址，确保地址准确无误，错误地址会导致充币失败。',
        DepositTest4:'请勿向上述地址充值任何非',
        DepositTest5:'资产，否则资产将不可找回。',
        WithdrawalAddress:'提币地址',
        AddressManagement:'地址管理',
        Memo:'备注',
        WithdrawalAmount:'提币数量',
        AvailableBalance:'可用额度',
        Withdraw:'提现',
        WithdrawTest1:'请确认目标提币地址是否存在且被激活，否则将会导致提币失败，且资产不可找回。',
        WithdrawTest2:'如有地址标签，请务必仔细核对地址标签，否则将造成资产损失并不可找回。',
        WithdrawTest3:'为保障资金安全，当您账户安全策略变更、密码修改、我们会对提币进行人工审核，请耐心等待工作人员电话或邮件联系。',
        WithdrawTest4:'请务必确认电脑及浏览器安全，防止信息被篡改或泄露。',
        WithdrawTest5:'提币时间(UTC+8)：周一到周日的9:00-22:00。',
        AvailableAssets:'可用资产',
        GamblingAccount:'秒合约账户',
        notpassword:'您还没有设置资金密码,暂时无法操作！',
        Back:'返回',
        AddAddress:'新增地址',
        SelectCurrency:'选择币种',
        Confirm:'确定',
        from:'从',
        to:'到'
    },
    assets:{
        ContractPositions:'合约持仓',
        DepositDetails:'充值明细',
        WithdrawalHistory:'提币历史',
        WithdrawalRecords:'充值提现记录',
        TotalEquivalent:'所有现货换算(USDT)总额',
        AvailableBalance:'现货可用(USDT)',
        HideSmall:'隐藏小额资产',
        Equivalent:'折合',
        FrozenAmount:'冻结金额',
        DepositType:'充值类型',
        DepositAmount:'充值金额',
        DepositCurrency:'充值币种',
        DepositTime:'充值时间',
        WithdrawalType:'提币类型',
        WithdrawalAmount:'提币金额',
        WithdrawalCurrency:'提币币种',
        WithdrawalTime:'提币时间'
    },
    fastChange:{
        Sell:'卖出',
        ReferencePrice:'参考价格',
        Buy:'买入',
        Quantity:'可兑换数量',
        PleaseEnter:'请输入欲兑换数量',
        Exchange:'兑换',
        Trading:'小额兑换 ● 大宗交易',
        TradingTest:'BTC, ETH, 数字货币快捷兑换',
        Step1:'第一步',
        Step1Test:'选择需要兑换的币种',
        Step2:'第二步',
        Step2Test:'输入需要兑换的数量',
        Step3:'第三步',
        Step3Test:'开始兑换'
    },
    Password:{
        ChangePassword:'修改登录密码',
        OldPassword:'旧登录密码',
        enterOldPassword:'请输入旧的登录密码',
        NewLogin:'新的登录密码',
        enterNewLogin:'请输入登录密码',
        againenterNewLogin:'请再次输入登录密码',
        FundPassword:'设置资金密码',
        fundpassword:'请输入资金密码',
        againfundpassword:'请再次输入资金密码',
        Name:'姓名',
        Number:'身份证号',
        photos:'请上传证件照,第一张为正面,第二张为反面',
        photos2:'请上传证件照',
        Upload:'上传',
        wait:'审核中，请等待！',
        categoryType:'证件类型',
        category1:'身份证',
        category2:'护照',
        category3:'驾驶证'
    },
    add:{
        Copied:"复制成功!",
        test29:"模拟交易结束，实盘交易等你开启！",
        test30:"充值现享交易资金奖励",
        test31:"立即获得更多支持与工具",
        test32:"充值开启您的盈利之旅",
        DemoTrade:"模拟交易",
        Progress:"进行中",
        Requested:"申请退款中",
        Refunded:"已退款",
        Completed:"已结束",
        test1:"产品概述",
        test2:"（aaaa）财务管理服务（FMS）是（aaaa）打造的一款帮助有空闲数字资产用户进行低门槛赚取收益的产品，入门简单。（aaaa）财务管理服务（FMS）有不同期限可选，通过在借贷市场出借给杠杆交易用户获取收益；定期简单赚币通过锁定币种获取PoS 收益或项目方奖励。",
        test3:"*请注意：历史收益不能代表未来收益。我们不承诺在一定期限内以货币、实物、股权等方式还本付息或者给付任何其他形式的财产性收益回报。",
        test4:"安全可靠",
        test5:"**（aaaa）**拥有严格的风控体系、领先的安全措施和先进的基础设施，能够充分保障用户资产安全。",
        test6:"灵活申赎",
        test7:"24 小时支持随时申购和赎回，活期简单赚币赎回立即到账，定期简单赚币赎回 30 分钟内到账。",
        test8:"定期**（aaaa）**财务管理服务（FMS）",
        test9:"定期**（aaaa）**财务管理服务（FMS）有多种收益来源，例如通过质押获得基于权益证明 (PoS) 机制获得的奖励以及平台奖励。",
        test10:"什么是参考年化？",
        test11:"参考年化是您所申购的产品的预计年化收益率，仅供参考",
        test12:"预期收益如何计算？",
        test13:"预期收益是根据您的申购数量和产品参考年化算出的每日预计可获得的收益，仅供参考。",
        test14:"什么时候开始计息？",
        test15:"收益自申购次日 12:00 (UTC+8) 起开始计算。",
        test16:"什么时候发放收益？",
        test17:"收益自起息次日 12:00 (UTC+8) 起发放。",
        test18:"为什么不同产品赎回天数不同？",
        test19:"赎回天数是指从您确认赎回，到实际赎回到账需要等待的时间。定期简单赚币的资产质押在链上，从链上赎回需要一定的时间，每一条公链的运行机制不同，所以赎回天数不同。",
        test20:"定期产品到期后，我需要手动赎回吗？",
        test21:"这将视情况而定。所有定期产品将默认启用自动续投，产品到期后将会自动续投相同期限。 自动续投启用后，赎回需要手动进行。自动续投功能默认启用，如需关闭，请前往订单详情页面。",
        test22:"在赎回等待的过程中我还能获得收益吗？",
        test23:"订单一旦确认赎回则停止计息，在赎回等待的过程中无法获得收益。",
        test24:"如何选择最佳的定期**（aaaa）**财务管理服务（FMS）产品？",
        test25:"您可以通过比较不同数字资产，不同产品的期限、历史收益等，在我们所提供的所有简单赚币产品中，选择适合您的产品。我们仅提供资产质押、奖励领取和发放服务，不为您提供任何投资建议。在做出投资决定前，您应该充分了解本产品的性质和风险以及其他相关情况，并根据您的投资经验、财务状况、投资目标、投资期限、承担投资风险的意愿和能力以及其他相关情况进行独立地判断慎重考虑是否购买本产品，并为您自己的决策负责。",
        test26:"如何赚取收益",
        test27:"产品优势",
        test28:"常见问题",
        assets:"理财资产",
        Redemption:"赎回",
        Subscribe:"申购",
        SubscribeType:"申购类型",
        SubscribeNum:"申购金额",
        Historical:"累计利息",
        SubscribeTime:"申购时间",
        RedemptionTime:"赎回时间",
        RedemptionConfirm:"确认是否赎回?",
        LockedSavings:"定期投资",
        test33:"理财",
        test34:"了解数字货币投资的核心概念和风险管理策略，从容面对市场波动，实现财务目标。立即加入我们，开启数字货币投资之旅！",
        test35:"掌握数字货币投资的精髓，解读市场趋势，挖掘潜在机会，实现财富增值。无需门槛，低风险，高回报。开始您的数字货币理财之旅，迈向财富的新高度！",
        test36:"产品列表",
        test37:"申购列表",
        test38:"年利率：",
        test39:"天数：",
        test40:"起购金额：",
        test41:"最大金额：",
        test42:"起息日：",
        test43:"计息结束：",
        test44:"最大",
        test45:"可用资产：",
        test46:"预估盈利：",
        test47:"我已阅读并且同意",
        test48:"产品概述",
        test49:"天",
        Agreement:"理财购买协议",
        test50:"充值金额",
	    test51:"上传转账凭证",
        EnterPrice:"请输入金额",
    }
}