<template>
  <div :id="uid" class="showorders"></div>
  
</template>

<script>
import { defineComponent, watch, ref } from 'vue';
import * as echarts from 'echarts';
export default defineComponent({
  name: 'echarts',
  props: {
    chartData: {
          type: Array,
          default: false
      }
  },
  setup(props) {
    const option = { }
    const uid = `echarts-uid-${parseInt((Math.random() * 1000000).toString())}`;
    return {
      option,
      uid
    }
  },
  
  mounted() {
      this.onSlideChange()
  },
  methods: {
    onSlideChange(){
      var chartDom = document.getElementById(this.uid);
        var myChart = echarts.init(chartDom);

        this.option = {
          xAxis: {
            show: false,
            type: 'category',
          },
          yAxis: {
            show: false,
            type: 'value'
          },
          series: [
            {
              name: 'a',
              type: 'line',
              showSymbol: false,
              data: this.chartData
            }
          ],
          color: '#ffd140'
        };
        this.option && myChart.setOption(this.option);
    }
  }
})
</script>

<style lang="scss" scoped>
 .showorders{
  width: 158px;
  height: 85px;
 }
</style>
 