<template>
  <router-view v-if="isRouter" />
</template>

<script setup>
import { nextTick, provide, ref, onMounted, onBeforeMount } from "vue";
import { config, downloadUrl } from "@/api/index";
const isRouter = ref(true);
const reload = () => {
  isRouter.value = false;
  nextTick(() => {
    isRouter.value = true;
  });
};
provide("reload", reload);

const configGet = () => {
  config({}).then((response) => {
    localStorage.setItem('configInfo', JSON.stringify(response.data))

    let title = response.data.site_name
		let icon = response.data.favicon_icon

    var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
    var apple_icon = document.querySelector("link[rel*='apple-touch-icon']") || document.createElement("link");
    var appTitle = document.querySelector("meta[name*='apple-mobile-web-app-title']")
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = icon
    
    apple_icon.href = icon
    appTitle.content = title
    document.getElementsByTagName("head")[0].appendChild(link);
    document.title = title

    //link(response.data.h5_domain)
  });
}

const downloadUrlGet = () => {
  downloadUrl({}).then((response) => {
    localStorage.setItem('downloadUrl', JSON.stringify(response.data))
  });
}

const isMobile = () => {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}

const GetQueryString = (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]); return null;
}

const link = (h5_domain) => {
  if (GetQueryString('code')) {
    localStorage.setItem('invite_code', GetQueryString('code'))
    if (isMobile()) {
      location.href = h5_domain + '/#/pages/login/register?code=' + GetQueryString('code')
    }
  } else {
    if (isMobile()) {
      location.href = h5_domain
    }
  } 
  
}

onMounted(() => {
})

onBeforeMount(()=>{
  configGet()
  downloadUrlGet()
})
</script>

<style lang="scss">
body,
html {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background: #fff;
}

button {
  all: unset
}

button,
label {
  cursor: pointer
}

label {
  padding: 0;
  margin-right: 3px
}

a {
  color: inherit;
  text-decoration: none
}

* {
  box-sizing: border-box;
}

.ant-table table {
    border-collapse: separate !important;
    border-spacing: 0 !important;
}

@font-face {
  font-family: 'DIN-Medium';
  src: url('utils/DIN-Medium.otf');
}

.fontFamily {
  font-family: 'DIN-Medium' !important;
}

.navLink {
  position: relative;
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: 8px;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  font-size: inherit;
  font-weight: inherit;
  justify-content: center;
  line-height: 20px;
  min-height: 3rem;
  min-width: 0;
  overflow: hidden;
  padding: 0 2rem;
  text-align: center;
  touch-action: manipulation;
  transition: background-color .167s cubic-bezier(.4, 0, .2, 1) 0s, box-shadow .167s cubic-bezier(.4, 0, .2, 1) 0s, color .167s cubic-bezier(.4, 0, .2, 1) 0s;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    transition: background-color .3s;
  }

  &:active {
    &:after {
      background-color: rgba(255, 209, 64, .25);
    }
  }

  &:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
  }
}

.navLink:focus:after,
.navLink:hover:after {
  background-color: rgba(246, 249, 254, .1);
}


p {
  margin: 0;
  padding: 0;
  // cursor: pointer;
  margin-bottom: 0em !important;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

ul,
ol,
dl {
  margin-bottom: 0;
}

ul,
li {
  list-style: none;
}

i,
em,
b {
  font-style: normal;
}

input {
  outline: none;
}

/*控制整个滚动条*/
::-webkit-scrollbar {
  background-color: #181a20;
  width: 5px;
  height: 5px;
  background-clip: padding-box;
}

/*滚动条两端方向按钮*/
::-webkit-scrollbar-button {
  background-color: white;
}

/*滚动条中间滑动部分*/
::-webkit-scrollbar-thumb {
  background-color: #353945;
  border-radius: 5px;
}

/*滚动条右下角区域*/
::-webkit-scrollbar-corner {
  background-color: white;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.up {
  color: #02c076 !important;
}

.down {
  color: #f84960 !important;
}


// .tip-class {
//   background-color: #f3ad00 !important;
// }

// .tip-class .el-popper__arrow::before {
//   border: 1px solid #f3ad00 !important;
//   background: #f3ad00 !important;
// }


// .tip-headerMsg {
//   background-color: #fff !important;
//   border: none !important;
//   color: #000 !important;
//   background-clip: padding-box;
//   border-radius: 4px;
//   -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
//   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
//   -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
//   box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
// }

// .tip-headerMsg .el-popper__arrow::before {
//   border: 1px solid #fff !important;
//   background: #fff !important;
// }

.ant-spin-nested-loading>div>.ant-spin {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100vh !important;
  max-height: 100vh !important;
  box-shadow: none;
}

.overlayClass .ant-tooltip-inner {
  color: #000 !important;
  font-size: 8px;
  width: max-content;
  padding: 5px 6px;
  min-height: auto;
  border-radius: 6px;
}
</style>
