export default {
    total:{
        certified: 'Certificato',
        UnCertified: 'Non Certificato',
        login: 'Accedi',
        register: 'Registrati',
        registerNow: 'Registrati Ora',
        loginNow: 'Accedi Ora',
        buyNow: 'Compra Ora',
        Currency: 'Valuta',
        chang24: 'Variazione',
        NowPrice: 'Prezzo Attuale',
        Leverage: 'Leva',
        Favorites: 'Preferiti',
        Announcement: 'Annuncio',
        dynamic: 'Dinamico',
        VerificationCode: 'Codice di Verifica',
        enteremailcode: 'Inserisci il codice di verifica email',
        GetCode: 'Invia',
        add: 'Aggiungi',
        help: 'Centro Assistenza',
        xszy: 'Guida per Principianti',
        jyzn: 'Guida al Trading',
        aboutUs: 'Informazioni su aaaa'
    },
    login:{
        loginTitle: 'Benvenuto in aaaa',
        email: 'Email',
        enailTest: 'Inserisci la tua email',
        password: 'Password',
        passwordTest: 'Inserisci la tua password',
        unpassword: 'Password dimenticata?',
        noassets: 'Ancora nessun account',
        Addassets: 'Crea un account',
        password2: 'Reinserisci la password',
        password2test: 'Reinserisci la tua password',
        assets: 'Account Esistente'
    },
    topHeader: {
        nav01: 'Contratto',
        nav02: 'Trading Spot',
        nav03: 'Secondi',
        nav04: 'Copia Trading',
        nav05: 'Notizie',
        nav06: 'Cambio Veloce',
        APPStore: 'App Store',
        GooglePlay: 'Google Play',
        Android: 'Android',
        IOSaAndroid: 'IOS e Android Scansiona per Scaricare',
        cn: '简体中文',
        en: 'English',
        assetsHome: 'Bene',
        assets01: 'Dettagli Bene',
        assets02: 'Dettagli dei Fondi',
        assets03: 'Dettagli Ordine',
        assetsHome02: 'Ordini',
        assets0201: 'Contratti',
        assets0202: 'Spot',
        assets0203: 'Secondi',
        account01: 'Panoramica',
        account02: 'Account e Sicurezza',
        account03: 'Gestisci Copia Trading',
        account04: 'Gestisci Commercio Leader',
        loginOut: 'Esci'
    },
    foote: {
        test: 'aaaa è una piattaforma di servizi finanziari digitali leader a livello globale che fornisce un ambiente sicuro, intelligente ed equo per gli investimenti in asset digitali attraverso i suoi servizi di investimento digitali one-stop, strumenti di allocazione di asset diversificati, servizi di consulenza per gli investimenti innovativi e liquidità leader nel settore. Il team principale di aaaa proviene dalle industrie tradizionali dei titoli finanziari, dalle migliori aziende internet come Microsoft e da altre imprese di criptovaluta, portando con sé una vasta esperienza nel trading finanziario tradizionale, nello sviluppo delle piattaforme di scambio di criptovalute di alto livello e nelle operazioni di mercato globale. Dal suo fondamento sei mesi fa, la base di utenti globale ha superato 100.000 e copre più di 80 paesi e regioni, tra cui Nord America, Europa e regione Asia-Pacifico.',
        tab1Title1: 'Aiuto',
        tab1Test1: 'Domande frequenti',
        tab1Test2: 'Centro annunci',
        tab2Title1: 'Termini',
        tab2Test1: 'Condizioni di servizio',
        tab2Test2: 'Informativa sulla privacy',
        tab2Test3: 'Divulgazione dei rischi',
        tab3Title1: 'Unisciti a noi',
        msg: "Avvertenza di rischio: il trading di asset digitali è un settore emergente con prospettive luminose, ma comporta anche rischi significativi a causa della sua nuova natura. In particolare, nel trading di contratti, la leva amplifica contemporaneamente profitti e rischi. È fondamentale che tu abbia una comprensione completa del settore, dei modelli di trading di contratti, delle regole di trading e di altre conoscenze correlate prima di partecipare. Ti raccomandiamo vivamente di operare all'interno dei limiti di rischio che puoi sopportare. Il trading comporta rischi e si consiglia cautela nell'entrare nel mercato!",
        banquan: 'Copyright',
        banquan2: 'Tutti i diritti riservati',
        aboutUs: 'Chi siamo',
        Platform: 'Introduzione alla piattaforma'
    },
    home: {
        bannerTitle: 'Acquista Criptovalute in aaaa',
        bannerTest: 'La principale piattaforma di trading di criptovalute sociali al mondo',
        changUpRank: 'Classifica dei Guadagni',
        changDownRank: 'Classifica delle Perdite',
        whyTitle: 'Perché Sceglierci',
        indexTitle1: 'Garanzia di Sicurezza',
        indexTest1: 'Implementazione decentralizzata globale senza rischio sistemico',
        indexTitle2: 'Missione e Visione',
        indexTest2: 'Permettere agli investitori di godere dei profitti delle criptovalute',
        indexTitle3: 'Contratti Intelligenti',
        indexTest3: 'Trading long e short, inserimento rapido degli ordini, fino a 100x di leva',
        indexTitle4: 'Copy Trading',
        indexTest4: 'Un clic per seguire le operazioni dei migliori trader con un alto tasso di rendimento',
        indexTitle5: 'Discussione in Tempo Reale',
        indexTest5: 'Chat in tempo reale',
        indexTitle6: 'Binario',
        indexTest6: 'Il primo indice di criptovalute al mondo in binario',
        indexTitle7: 'Multivaluta',
        indexTest7: 'Afferra con fiducia la chiave del successo su oltre 300 progetti diversificati',
        indexTitle8: 'Ecosistema',
        indexTest8: "Crea un servizio completo per gli scambi di criptovalute, il portafoglio e l'ecologia on-chain",
        downLoadTitle: 'Trading in Qualsiasi Momento e Ovunque',
        downLoadTest: 'Disponibile su dispositivi iOS, Android e PC. Fai trading in qualsiasi momento e ovunque.',
        downLoadCode: 'Scansiona per Scaricare',
        downLoadIosAnd: 'iOS e Android'
    },
    contract: {
        Change: 'Variazione 24H',
        High: 'Massimo 24H',
        Low: 'Minimo 24H',
        Volume24: 'Volume 24H',
        Market: 'Mercato',
        LimitPrice: 'Limite',
        BestPrice: 'Fai trading al miglior prezzo disponibile',
        EnterPrice: 'Inserisci il prezzo',
        Amount: 'Quantità',
        Margin: 'Margine',
        Available: 'Saldo Disponibile',
        Leverage: 'Leverage',
        Fee: 'Commissione di transazione',
        Buy: 'Compra',
        Long: 'Lungo',
        Sell: 'Vendi',
        Short: 'Corto',
        Benes: 'Bene',
        ContractBenes: 'Bene Contrattuale',
        Deposit: 'Deposita ora',
        Login: 'Accedi all\'account di trading',
        Register: 'Registra un account di trading',
        Instrument: 'Strumento di Trading',
        Minimum: 'Dimensione Minima del Lotto',
        Maximum: 'Dimensione Massima del Lotto',
        TradingTime: 'Orario di Trading',
        TradingLeverage: 'Leverage di Trading',
        FeeRate: 'Tasso di Commissione',
        Introduction: 'Introduzione',
        IssuanceDate: 'Data di Emissione',
        TotalIssuance: 'Emissione Totale',
        CirculatingSupply: 'Offerta Circolante',
        Crowdfunding: 'Prezzo di Crowdfunding',
        Whitepaper: 'Whitepaper',
        Official: 'Sito Web Ufficiale',
        Blockchain: 'Esploratore Blockchain',
        Positions: 'Posizioni',
        OpenOrder: 'Ordine Aperto',
        TradeHistory: 'Cronologia dei Trade',
        ProfitLoss: 'Profitto/Perdita',
        CloseAll: 'Chiudi Tutto',
        Contract: 'Contratto',
        LotSize: 'Dimensione del Lotto',
        OpeningPrice: 'Prezzo di Apertura',
        CurrentPrice: 'Prezzo Attuale',
        TakeProfi: 'Prezzo Take Profit',
        StopLoss: 'Prezzo Stop Loss',
        OrderPL: 'Profitto/Perdita dell\'Ordine',
        OpeningTime: 'Orario di Apertura',
        OrderID: 'ID dell\'Ordine',
        Action: 'Azione',
        Settings: 'Impostazioni',
        ClosePosition: 'Chiudi Posizione',
        Delete: 'Elimina',
        Status: 'Stato',
        EnterCurrency: 'Inserisci la Valuta',
        saveStopLoss: 'Imposta T/P e S/L',
        live:'In diretta',
        demo:'Demo'
    },
    SpotGoods: {
        pricelimit: 'Limite',
        marketprice: 'Mercato',
        Price: 'Prezzo',
        MarketPrice: 'Mercato',
        PleaseEnter: 'Inserisci',
        Quantity: 'Quantità',
        Total: 'Totale',
        Or: 'O',
        OpenOrders: 'Ordini Aperti',
        Fee: 'Commissione',
        QuantityAcc: 'Fatturato',
        LatestTrade: 'Ultimo Trade',
        Time: 'Tempo',
        NowOrders: 'Ordini Aperti',
        AllOrders: 'Tutti gli Ordini',
        OrderHistory: 'Storico degli Ordini',
        Date: 'Data',
        TradingPair: 'Coppia di Trading',
        OrderType: 'Tipo di Ordine',
        MarketOrder: 'Ordine di Mercato',
        LimitOrder: 'Ordine a Limite',
        Direction: 'Direzione',
        OrderPrice: 'Prezzo dell\'Ordine',
        OrderQuantity: 'Quantità dell\'Ordine',
        OrderAmount: 'Importo dell\'Ordine'
    },
    Binary: {
        GameRules: 'Regole del Gioco',
        Trading: 'Trading in Corso',
        PositionClosed: 'Posizione Chiusa',
        BuyLong: 'Acquista Long',
        BuyShort: 'Acquista Short',
        OrderPeriod: 'Periodo dell\'Ordine',
        TradingAmount: 'Importo di Trading',
        Estimated: 'Tempo di Chiusura Stimato',
        ClosingTime: 'Ora di Chiusura',
        PlaceBet: 'Piazza Scommessa',
        Amount: 'Importo',
        Balance: 'Saldo dell\'Account',
        Odds: 'Quote',
        Profit: 'Profitto Stimato',
        Bullish: 'Lungo',
        Bearish: 'Corto',
        countTime: 'Conto alla rovescia',
        customInp: 'Importo Personalizzato'
    },
    follow: {
        Ranking: 'Classifica Trader',
        Updates: 'Aggiornamenti Dati ogni due ore',
        SearchKey: 'Cerca Tutti i Trader',
        Search: 'Cerca',
        Manage: 'Gestisci Follower',
        Details: 'Dettagli',
        test1: 'Formula = Copia Ordine [Profitto/Perdita] / [Margine Totale] * 100%',
        title1: 'Rendimento 30 Giorni',
        test2: 'Formula = Ordine Auto-Posizionato [Numero di Ordini Profittevoli / Numero Totale di Ordini] * 100%',
        title2: 'Tasso di Vittoria 30 Giorni',
        title3: 'Follower Attuali',
        title4: 'Commissioni 30 Giorni',
        test5: 'Numero Totale di Ordini (Auto-Posizionati) nei 30 Giorni Precedenti',
        title5: 'Lotti di Trading 30 Giorni',
        test6: 'Calcolato dalla Data di Registrazione',
        title6: 'Giorni di Trading',
        Unfollow: 'Non Seguire',
        Followed: 'Seguito',
        EditCopying: 'Modifica Copia',
        CopyingCurrency: 'Valuta di Copia',
        AmountCopying: 'Importo di Copia',
        CopyingAmount: 'Importo di Copia',
        ContractTrading: 'Coppia di Trading Contrattuale',
        LotSizeRatio: 'Rapporto Dimensione Lotto',
        StartCopying: 'Inizia a Copiare',
        CancelCopying: 'Annulla Copia',
        title7: 'Drawdown 30 Giorni',
        test7: 'Rapporto di Perdita degli Bene nei 30 Giorni Precedenti (Percentuale delle Perdite Giornaliere sugli Bene rispetto agli Bene del Giorno Precedente, Basato sulla Perdita Massima)',
        BackLobby: 'Torna alla Lobby',
        TradeHistory: 'Cronologia dei Trade',
        ClosingPrice: 'Prezzo di Chiusura',
        info: 'Profilo Personale',
        CumulativeCopying: 'Importo di Copia Cumulativo',
        TotalCopying: 'Profitto Totale di Copia',
        Discover: 'Scopri Esperti',
        CopyOrders: 'Ordini di Copia',
        CurrentFollowers: 'Follower Attuali',
        Avatar: 'Avatar',
        TraderName: 'Nome del Trader',
        MaximumPosition: 'Importo Massimo della Posizione',
        ProfitLoss: 'Profitto/Perdita di Copia',
        Edit: 'Modifica',
        TodayProfit: 'Profitto di Oggi',
        Past7Days: 'Profitto Totale negli Ultimi 7 Giorni',
        Accumulated: 'Profitto Totale Cumulativo',
        ProfitDetails: 'Dettagli del Profitto',
        Followers: 'Follower',
        Profit: 'Profitto',
        FollowerAvatar: 'Avatar del Follower',
        FollowerNickname: 'Soprannome del Follower',
        CopyingTime: 'Tempo di Copia',
        Notice: 'Avviso',
        NoticeTest1: 'Non sei ancora un trader, quindi non puoi eseguire questa operazione!',
        NoticeTest2: 'In fase di revisione, attendere pazientemente!',
        Cancel: 'Annulla',
        ApplyNow: 'Presenta Ora',
        ApplyTrader: 'Candidati come Trader',
        Nickname: 'Soprannome (Obbligatorio)',
        Phone: 'Numero di Telefono (Obbligatorio)',
        Email: 'Email (Facoltativa)',
        test1: 'Dopo aver inviato la richiesta, il nostro staff ti contatterà il prima possibile. Fornisci le informazioni richieste per la candidatura.',
        test2: 'Durante il periodo di revisione, assicurati che non siano soddisfatte le seguenti condizioni, altrimenti la revisione non avrà successo: posizioni aperte, ordini attuali, piani attuali e trader seguiti.',
        Apply: 'Candidati'
    },
    account: {
        Overview: 'Panoramica',
        Security: 'Sicurezza',
        UserID: 'ID Utente',
        Welcome: 'Benvenuto su aaaa!',
        Welcometest: 'Manca solo qualche passo e sei pronto per iniziare',
        Registration: 'Registrazione dell\'Account',
        SecurityVerification: 'Verifica di Sicurezza',
        GotoBind: 'Vai alla Bind',
        QuickBuy: 'Acquisto Rapido di Criptovalute',
        BeneDetails: 'Dettagli degli Bene',
        Deposit: 'Deposito',
        Withdraw: 'Prelievo',
        Transfer: 'Trasferimento',
        TransferList: 'Cronologia dei Trasferimenti',
        type: 'Tipo',
        SpotAccount: 'Account Spot',
        FuturesAccount: 'Account Futures',
        HideBene: 'Nascondi la Valutazione degli Bene',
        Available: 'Saldo Disponibile (USDT)',
        checkdetails: 'Per quantità di asset dettagliate, controlla i dettagli degli asset',
        Recommendations: 'Raccomandazioni di Sicurezza',
        PasswordSet: 'Impostazioni Password',
        Change: 'Cambia',
        FundPassword: 'Password dei Fondi',
        GotoSet: 'Vai a Impostare',
        AddressManagement: 'Gestione degli Indirizzi',
        GotoAdd: 'Vai a Aggiungere',
        Verification: 'Verifica dell\'Identità',
        GotoVerify: 'Vai a Verificare',
        LatestNews: 'Ultime Notizie',
        SafetyTitle: 'Autenticazione a Due Fattori',
        SafetyTest: 'Per proteggere il tuo account, è consigliato completare almeno una verifica dell\'identità a nome reale',
        SafetyText1: 'Utilizzato per gestire la password di accesso del tuo account',
        SafetyText2: 'Utilizzato per gestire la password dei fondi del tuo account',
        NotSet: 'Non Impostato',
        Set: 'Imposta',
        SafetyText3: 'Proteggi la sicurezza dei tuoi scambi e del tuo account',
        SafetyText4: 'Assicurati dell\'accuratezza degli indirizzi di prelievo',
        Manage: 'Gestisci',
        InvitationLink: 'Link di Invito',
        copyInvitationLink: 'Copia',
        InvitationCode: 'Codice di Invito',
        copyInvitationCode: 'Copia',
        enterInvitationCode: 'Inserisci il codice di invito'
    },
    finance: {
        Protocol: 'Rete Principale (Protocollo)',
        DepositAddress: 'Indirizzo di Deposito',
        QRCode: 'QR Code di Deposito',
        DepositTest1: 'L\'importo minimo di deposito per transazione non deve essere inferiore a',
        DepositTest2: ', qualsiasi importo inferiore a questo non avrà successo',
        DepositTest3: 'Prima di ogni deposito, è necessario visitare questa pagina per copiare l\'indirizzo al fine di garantirne l\'accuratezza. L\'utilizzo di un indirizzo errato comporterà un deposito fallito.',
        DepositTest4: 'Non depositare alcun asset non-',
        DepositTest5: 'all\'indirizzo sopra, in caso contrario gli asset non saranno recuperabili.',
        WithdrawalAddress: 'Indirizzo di Prelievo',
        AddressManagement: 'Gestione degli Indirizzi',
        Memo: 'Memo',
        WithdrawalAmount: 'Importo di Prelievo',
        AvailableBalance: 'Saldo Disponibile',
        Withdraw: 'Prelievo',
        WithdrawTest1: 'Si prega di confermare che l\'indirizzo di prelievo di destinazione esista e sia attivato, in caso contrario si avrà un prelievo fallito e gli asset non saranno recuperabili.',
        WithdrawTest2: 'Se è presente un tag di indirizzo, verificare attentamente il tag dell\'indirizzo per evitare la perdita di asset non recuperabile.',
        WithdrawTest3: 'Per garantire la sicurezza dei fondi, quando cambia la strategia di sicurezza del tuo account o le password vengono modificate, effettueremo una revisione manuale del prelievo. Si prega di attendere pazientemente che il nostro staff ti contatti per telefono o via email.',
        WithdrawTest4: 'Si prega di garantire la sicurezza del tuo computer e del tuo browser per evitare manipolazioni o perdite di informazioni.',
        WithdrawTest5: 'Orario di Prelievo (UTC+8): Lunedì - Domenica, 9:00-22:00.',
        AvailableBenes: 'Bene Disponibili',
        GamblingAccount: 'Account Seconds',
        notpassword: 'Non hai ancora impostato una password dei fondi, quindi non puoi eseguire questa operazione!',
        Back: 'Indietro',
        AddAddress: 'Aggiungi Nuovo Indirizzo',
        SelectCurrency: 'Seleziona Valuta',
        Confirm: 'Conferma',
        from: 'Da',
        to: 'A'
    },
    assets: {
        ContractPositions: 'Posizioni Contrattuali',
        DepositDetails: 'Dettagli del Deposito',
        WithdrawalHistory: 'Storico dei Prelievi',
        WithdrawalRecords: 'Registrazioni di Deposito e Prelievo',
        TotalEquivalent: 'Valore Equivalente Totale di Tutti gli Bene Spot (in USDT)',
        AvailableBalance: 'Saldo Disponibile in Spot (USDT)',
        HideSmall: 'Nascondi Piccoli Bene',
        Equivalent: 'Equivalente',
        FrozenAmount: 'Importo Congelato',
        DepositType: 'Tipo di Deposito',
        DepositAmount: 'Importo di Deposito',
        DepositCurrency: 'Valuta di Deposito',
        DepositTime: 'Orario di Deposito',
        WithdrawalType: 'Tipo di Prelievo',
        WithdrawalAmount: 'Importo di Prelievo',
        WithdrawalCurrency: 'Valuta di Prelievo',
        WithdrawalTime: 'Orario di Prelievo'
    },
    fastChange: {
        Sell: 'Vendi',
        ReferencePrice: 'Prezzo di Riferimento',
        Buy: 'Compra',
        Quantity: 'Quantità Scambiabile',
        PleaseEnter: 'Inserisci l\'importo di scambio desiderato',
        Exchange: 'Scambia',
        Trading: 'Scambio di Piccole Quantità ● Trading di Massa',
        TradingTest: 'Scambio Veloce per BTC, ETH e Valute Digitali',
        Step1: 'Passo 1',
        Step1Test: 'Seleziona la valuta da scambiare',
        Step2: 'Passo 2',
        Step2Test: 'Inserisci l\'importo da scambiare',
        Step3: 'Passo 3',
        Step3Test: 'Inizia lo scambio'
    },
    Password: {
        ChangePassword: 'Cambia Password di Accesso',
        OldPassword: 'Vecchia Password di Accesso',
        enterOldPassword: 'Inserisci la tua vecchia password di accesso',
        NewLogin: 'Nuova Password di Accesso',
        enterNewLogin: 'Inserisci la tua nuova password di accesso',
        againenterNewLogin: 'Inserisci nuovamente la tua nuova password di accesso',
        FundPassword: 'Imposta Password dei Fondi',
        fundpassword: 'Inserisci la tua password dei fondi',
        againfundpassword: 'Inserisci nuovamente la tua password dei fondi',
        Name: 'Nome',
        Number: 'Numero di Identificazione',
        photos: 'Carica le foto del documento di identità, la prima è il fronte e la seconda è il retro',
        photos2: 'Carica la tua foto del documento di identità',
        Upload: 'Carica',
        wait: 'In fase di revisione, attendere prego!',
        categoryType: 'Tipo di Documento',
        category1: 'Carta d\'Identità',
        category2: 'Passaporto',
        category3: 'Patente di Guida'
    },
    add: {
        Copied: 'Copiato!',
        test29: "La simulazione di trading termina, il vero trading ti aspetta!",
        test30: "Ricarica ora e goditi i premi del fondo di trading",
        test31: "Ottieni subito più supporto e strumenti",
        test32: "Ricarica per iniziare il tuo viaggio redditizio",
        DemoTrade: "Commercio demo",
        Progress: "In Corso",
        Requested: "Richiesta di Rimborso",
        Refunded: "Rimborsato",
        Completed: "Completato",
        test1: "Panoramica del Prodotto",
        test2: "Il Servizio di Gestione Finanziaria (FMS) di (aaaa) è un prodotto progettato da (aaaa) per assistere gli utenti nell'ottenere profitti con asset digitali inattivi a bassi livelli. È facile iniziare. Il Servizio di Gestione Finanziaria (FMS) di (aaaa) offre diverse opzioni di durata, permettendo agli utenti di ottenere profitti prestati agli utenti di trading su margine nel mercato del prestito; Guadagnare monete semplici a termine fisso implica bloccare le monete per ottenere ricompense PoS o incentivi di progetto.",
        test3: "*Si prega di notare: I guadagni passati non rappresentano guadagni futuri. Non promettiamo di rimborsare il capitale e gli interessi entro un certo periodo in valuta, beni fisici, azioni o qualsiasi altra forma di reddito di proprietà, né promettiamo altre forme di reddito di proprietà.",
        test4: "Sicuro e Affidabile",
        test5: "(aaaa) dispone di sistemi di controllo dei rischi rigorosi, misure di sicurezza avanzate e infrastrutture avanzate per garantire completamente la sicurezza degli asset degli utenti.",
        test6: "Abbonamento e Riscatto Flessibili",
        test7: "Supporto 24 ore su 24 per abbonamento e riscatto in qualsiasi momento. Il riscatto per i guadagni attuali di monete semplici è immediato, mentre il riscatto per i guadagni di monete semplici a termine fisso richiede fino a 30 minuti.",
        test8: "Servizio di Gestione Finanziaria (FMS) a Termine Fisso di (aaaa)",
        test9: "Il Servizio di Gestione Finanziaria (FMS) a Termine Fisso di (aaaa) ha diverse fonti di reddito, come le ricompense ottenute attraverso la garanzia e le ricompense basate sul meccanismo Proof of Stake (PoS), così come le ricompense di piattaforma.",
        test10: "Cos'è il Rendimento di Riferimento Annualizzato?",
        test11: "Il Rendimento di Riferimento Annualizzato è il rendimento annualizzato previsto del prodotto a cui ti iscrivi, solo a titolo di riferimento.",
        test12: "Come vengono calcolati i Guadagni Attesi?",
        test13: "I guadagni attesi sono calcolati in base alla quantità sottoscritta e al rendimento di riferimento annuale del prodotto, fornendo una stima dei guadagni attesi giornalieri, solo a titolo di riferimento.",
        test14: "Quando si Accumulano gli Interessi?",
        test15: "I guadagni cominciano ad accumularsi dalle 12:00 (UTC+8) del giorno successivo alla sottoscrizione.",
        test16: "Quando Vengono Distribuiti i Guadagni?",
        test17: "I guadagni vengono distribuiti dalle 12:00 (UTC+8) del giorno successivo all'inizio dell'accumulo degli interessi.",
        test18: "Perché i Diversi Prodotti Hanno Diversi Periodi di Riscatto?",
        test19: "Il periodo di riscatto si riferisce al tempo necessario dalla conferma del riscatto all'effettivo accredito del riscatto. Gli asset per i guadagni di monete semplici a termine fisso sono garantiti sulla catena, richiedendo del tempo per il riscatto dalla catena. Poiché ogni catena pubblica opera in modo diverso, i periodi di riscatto variano.",
        test20: "Devo Riscattare Manualmente i Prodotti a Termine Fisso alla Scadenza?",
        test21: "Questo dipende dalla situazione. Tutti i prodotti a termine fisso verranno automaticamente rinnovati. Alla scadenza, lo stesso termine verrà rinnovato automaticamente. Dopo aver abilitato il rinnovo automatico, il riscatto deve essere effettuato manualmente. La funzione di rinnovo automatico è abilitata per impostazione predefinita. Per disabilitarla, si prega di andare alla pagina dei dettagli dell'ordine.",
        test22: "Posso Ancora Guadagnare Entrate Mentre Attendo il Riscatto?",
        test23: "Una volta confermato il riscatto, gli interessi smettono di accumularsi. Non è possibile guadagnare entrate mentre si attende il riscatto.",
        test24: "Come Scegliere il Miglior Prodotto di Gestione Finanziaria a Termine Fisso (aaaa) (FMS)?",
        test25: "Puoi confrontare diversi asset digitali, termini, rendimenti storici, ecc., tra tutti i prodotti di guadagno di monete semplici che offriamo, per scegliere il prodotto che fa al caso tuo. Forniamo solo servizi di garanzia di asset, raccolta di premi e distribuzione e non forniamo alcun consiglio di investimento. Prima di prendere una decisione di investimento, dovresti comprendere appieno la natura e i rischi di questo prodotto, nonché altre circostanze rilevanti, e fare giudizi indipendenti basati sulla tua esperienza di investimento, situazione finanziaria, obiettivi di investimento, periodo di investimento, volontà e capacità di sopportare i rischi di investimento e altre circostanze rilevanti, e essere responsabile delle tue decisioni.",
        test26: "Entrate",
        test27: "Vantaggio",
        test28: "Domande Frequenti",
        assets: "Finanziario",
        Redemption: "Riscatto",
        Subscribe: "Sottoscrivi",
        SubscribeType: "Tipo di Sottoscrizione",
        SubscribeNum: "Importo della Sottoscrizione",
        Historical: "Interessi Accumulati",
        SubscribeTime: "Orario di Sottoscrizione",
        RedemptionTime: "Orario di Riscatto",
        RedemptionConfirm: "Conferma del Riscatto",
        LockedSavings: "Investimento a Termine Fisso",
        test33: "Gestione Finanziaria",
        test34: "Comprendere i concetti fondamentali e le strategie di gestione del rischio dell'investimento in criptovalute, affrontare con calma le fluttuazioni del mercato e raggiungere gli obiettivi finanziari. Unisciti a noi ora e inizia il tuo viaggio nell'investimento in criptovalute!",
        test35: "Padroneggiare l'essenza dell'investimento in criptovalute, interpretare le tendenze di mercato, esplorare opportunità potenziali e apprezzamento della ricchezza. Nessuna soglia, basso rischio, alti rendimenti. Inizia il tuo percorso di gestione della ricchezza delle criptovalute e muoviti verso nuove vette di ricchezza!",
        test36: "Elenco Prodotti",
        test37: "Elenco Sottoscrizioni",
        test38: "Tasso di Interesse Annuo:",
        test39: "Giorni：",
        test40: "Importo Minimo di Investimento:",
        test41: "Importo Massimo:",
        test42: "Data di Inizio degli Interessi:",
        test43: "Data di Fine del Calcolo degli Interessi:",
        test44: "Massimo",
        test45: "Asset Disponibili:",
        test46: "Profitto Stimato:",
        test47: "Ho letto e accetto",
        test48: "Panoramica del Prodotto",
        test49:"giorno",
        Agreement: "Contratto di Acquisto Finanziario",
        test50: "Importo di ricarica",
	    test51: "Carica voucher di trasferimento",
        EnterPrice: "Inserisci il Prezzo",
    }    
}