import request from '@/utils/request'

// 登录
export const login = (data) => {
  return request({
    url: 'user/login',
    method: 'post',
    data:data
  })
}
// 注册
export const register = (data) => {
  return request({
    url: 'user/register',
    method: 'post',
    data:data
  })
}
// 发送验证码
export const sendEmail = (data) => {
  return request({
    url: 'notify/send_email',
    method: 'post',
    data:data
  })
}
// 登出
export const loginOut = (data) => {
  return request({
    url: 'user/logout',
    method: 'post'
  })
}
// 忘记密码
export const forgetPassword = (data) => {
  return request({
    url: 'user/forget_password',
    method: 'post',
    data:data
  })
}
// 重置密码
export const amendPassword = (data) => {
  return request({
    url: 'user/amend_password',
    method: 'post',
    data:data
  })
}

