import request from '@/utils/request'

// 新闻
export const newList = (data) => {
  return request({
    url: 'news/list',
    method: 'get',
    params:data
  })
}

//获取交易对
export const currencyMatches = (data) => {
  return request({
    url: 'market/currency_matches',
    method: 'get',
    params:data
  })
}
//客服链接交易对
export const kefuSetting = (data) => {
  return request({
    url: '/default/setting',
    method: 'get',
    params:data
  })
}
//语言列表
export const langList = (data) => {
  return request({
    url: '/default/lang_list',
    method: 'get',
    params:data
  })
}

//理财商品列表
export const FundList = (data) => {
  return request({
    url: '/fund/list',
    method: 'get',
    params:data
  })
}

//理财商品详情
export const FundInfoDet = (data) => {
  return request({
    url: '/fund/info',
    method: 'get',
    params:data
  })
}

//购买理财商品
export const Fundbuy = (data) => {
  return request({
    url: '/fund/buy',
    method: 'get',
    params:data
  })
}

//已购买理财商品列表
export const FundbuyList = (data) => {
  return request({
    url: '/fund/subList',
    method: 'get',
    params:data
  })
}

//违约赎回理财商品
export const applyRefund = (data) => {
  return request({
    url: '/fund/applyRefund',
    method: 'get',
    params:data
  })
}
