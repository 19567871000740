<template>
  <footer class="wrap">
    <footer class="Footer_wrap">
      <article class="Footer_Box">
        <section class="Footer_Top">
          <div class="Footer_Top_left">
            <img :src="configInfo?.logo" style="height: 42px;margin-bottom: 5px;">
            <p>
              {{ $testChang(t('foote.test')) }}
            </p>
          </div>
          <div class="Footer_Top_In">
            <p class="Footer_title">{{ t('foote.tab1Title1') }}</p>
            <p><router-link class="link" to="/guide">{{ t('foote.tab1Test1') }}</router-link></p>
            <p><router-link class="link" to="/protocol?cid=59">{{t('foote.Platform')}}</router-link></p>
          </div>
          <div class="Footer_Top_Right">
            <p class="Footer_title">{{ t('foote.tab2Title1') }}</p>
            <!-- <p><router-link class="link" to="/protocol?cid=30">{{ t('foote.tab2Test1') }}</router-link></p> -->
            <p><router-link class="link" to="/protocol?cid=28">{{ t('foote.tab2Test2') }}</router-link></p>
            <p><router-link class="link" to="/protocol?cid=58">{{ t('foote.tab2Test3') }}</router-link></p>
          </div>
          <!-- <div class="Footer_Top_Icon">
            <p class="Footer_title">{{ t('foote.tab3Title1') }}</p>
            <div class="Footer_Icon_Box">
              <a href="javascript:;" type="button" aria-label="twitter link"></a>
              <a href="javascript:;" type="button" aria-label="telegram link"></a>
              <a href="javascript:;" type="button" aria-label="facebook link"></a>
              <a href="javascript:;" type="button" aria-label="linkedin link"></a>
              <a href="javascript:;" type="button" aria-label="instagram link"></a>
              <a href="javascript:;" type="button" aria-label="skype link"></a>
            </div>
          </div> -->
        </section>
        <hr class="MainFooter_divider__R9DTg">
        <section>
          {{ t('foote.msg') }}
        </section>
        <hr class="MainFooter_divider__R9DTg">
        <section class="MainFooter_copyright__Gy_gl">{{t('foote.banquan')}} ©2018，{{t('foote.banquan2')}}。
        </section>
      </article>
    </footer>
  </footer>
</template>

<script>
import { ref, inject, onMounted } from 'vue';
import { useI18n } from "vue-i18n";
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  setup() {
    const { t } = useI18n()
    const { locale: i18nLanguage } = useI18n()
    const configInfo = ref({})

    onMounted(() => {
      setTimeout(()=>{
        configInfo.value = JSON.parse(localStorage.getItem('configInfo'))
      },1000)
    })

    return {
      t,
      configInfo
    }
  }
}
</script>

<style scoped lang="scss">
.Footer_wrap {
  background-color: #181a20;
  font-size: .875rem;

  .Footer_Box {
    max-width: calc(1200px + 2rem);
    margin: auto;
    padding: 2rem;
    line-height: 1.5;
    color: #777e90;

    .MainFooter_divider__R9DTg {
      border: none;
      border-top: 1px solid #2d313a;
      margin: 1rem 0;
    }

    .Footer_Top {
      display: flex;
      width: 100%;

      .MainFooter_linkButton__8UDzC {
        display: block;
      }

      .Footer_title {
        display: block;
        font-size: 14px;
        color: #fff;
      }

      .Footer_Top_left {
        flex: 1 1;

        &>figure {
          width: 110px;
          margin: 0;
        }

        &>p {
          width: 80%;
          color: #777e90;
        }
      }

      .Footer_Top_Icon {
        width: 20%;
      }
    }

    .Footer_Icon_Box {
      display: flex;
      gap: .5rem;
      flex-wrap: wrap;

      a{
        width: 18px;
        height: 18px;
        display: block;
      }
    }
  }
}

.Footer_wrap .Footer_Box .Footer_Top .Footer_Top_In,
.Footer_wrap .Footer_Box .Footer_Top .Footer_Top_Right {
  width: 15%;
}

.link{
  display: block;
  font-size: 14px;
  color: #777e90;
  line-height: 22px;
  padding: 4px 0;
  text-align: left;
  margin: 8px 0 !important;
}
</style>
