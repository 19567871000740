import request from '@/utils/request'

//获取交易对
export const CurrencyGet = (data) => {
  return request({
    url: 'market/currency_matches',
    method: 'get',
    params:data
  })
}
//获取交易对详情
export const CurrencyDealGet = (data) => {
  return request({
    url: 'lever/deal',
    method: 'get',
    params:data
  })
}
//获取持仓详情
export const leverDealall = (data) => {
  return request({
    url: 'lever/dealall',
    method: 'post',
    data:data
  })
}
//获取持仓列表
export const myTradeList = (data) => {
  return request({
    url: 'lever/my_trade',
    method: 'post',
    data:data
  })
}
//开仓
export const leverSubmit = (data) => {
  return request({
    url: 'lever/submit',
    method: 'post',
    data:data
  })
}
//撤单
export const leverCancel = (data) => {
  return request({
    url: 'lever/cancel',
    method: 'post',
    data:data
  })
}
//平仓
export const leverClose = (data) => {
  return request({
    url: 'lever/close',
    method: 'post',
    data:data
  })
}
//一键平仓
export const leverCloseTotal = (data) => {
  return request({
    url: 'lever/batch_close',
    method: 'post',
    data:data
  })
}
//设置止盈止损
export const leversetstop = (data) => {
  return request({
    url: 'lever/setstop',
    method: 'post',
    data:data
  })
}


//现货委托列表
export const txOrderList = (data) => {
  return request({
    url: 'tx-order',
    method: 'get',
    params:data
  })
}
//现货成交列表
export const txOrderHistoryList = (data) => {
  return request({
    url: 'tx_history/list',
    method: 'get',
    params:data
  })
}
//现货买入撤单
export const txInCancelList = (data) => {
  return request({
    url: 'tx_in/cancel',
    method: 'get',
    params:data
  })
}
//现货卖出撤单
export const txOutCancelList = (data) => {
  return request({
    url: 'tx_out/cancel',
    method: 'get',
    params:data
  })
}
//现货全站交易记录
export const globalTxList = (data) => {
  return request({
    url: 'market/global_tx',
    method: 'get',
    params:data
  })
}
//竞猜杠杆列表
export const secondsList = (data) => {
  return request({
    url: 'microtrade/seconds',
    method: 'get',
    params:data
  })
}
//竞猜开仓时间列表
export const payableCurrenciesList = (data) => {
  return request({
    url: 'microtrade/payable_currencies',
    method: 'get',
    params:data
  })
}
//竞猜开仓
export const microtradeSubmit = (data) => {
  return request({
    url: 'microtrade/submit',
    method: 'post',
    data:data
  })
}
//竞猜列表
export const microtradeLists = (data) => {
  return request({
    url: 'microtrade/lists',
    method: 'get',
    params:data
  })
}

//自选交易对列表
export const optionalitem = (data) => {
  return request({
    url: 'optional/item',
    method: 'get',
    params:data
  })
}

//添加自选交易
export const AddOptional = (data) => {
  return request({
    url: 'optional/create',
    method: 'post',
    data:data
  })
}

//全局
export const config = (data) => {
  return request({
    url: 'config',
    method: 'get',
    params:data
  })
}
//下载
export const downloadUrl = (data) => {
  return request({
    url: 'app_version/download_url',
    method: 'post',
    data:data
  })
}
