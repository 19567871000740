export default {
    total:{
        certified: 'Проверено',
        UnCertified: 'Непроверено',
        login: 'Войти',
        register: 'Регистрация',
        registerNow: 'Зарегистрироваться сейчас',
        loginNow: 'Войти сейчас',
        buyNow: 'Купить сейчас',
        Currency: 'Валюта',
        chang24: 'Изменение',
        NowPrice: 'Последняя цена',
        Leverage: 'Плечо',
        Favorites: 'Избранное',
        Announcement:'Объявление',
        dynamic:'Динамика',
        VerificationCode:'Код верификации',
        enteremailcode:'Пожалуйста, введите код верификации по электронной почте',
        GetCode:'Отправить код',
        add:'добавлять',
        help: 'Центр помощи',
        xszy: 'Руководство для начинающих',
        jyzn: 'Руководство по торговле',
        aboutUs: 'О aaaa'
    },
    login:{
        loginTitle: 'Добро пожаловать в aaaa',
        email: 'Электронная почта',
        enailTest: 'Пожалуйста, введите вашу электронную почту',
        password: 'Пароль',
        passwordTest: 'Пожалуйста, введите ваш пароль',
        unpassword: 'Забыли пароль?',
        noassets: 'Еще нет аккаунта',
        Addassets: 'Создать аккаунт',
        password2: 'Повторите пароль',
        password2test: 'Пожалуйста, повторно введите ваш пароль',
        assets: 'Существующий аккаунт'
    },
    topHeader: {
        nav01: 'Контракты',
        nav02: 'Спот-трейдинг',
        nav03: 'Бинарные опционы',
        nav04: 'Копирование сделок',
        nav05: 'Новости',
        nav06: 'Флеш-обмен',
        APPStore: 'APP Store',
        GooglePlay: 'Google Play',
        Android: 'Android',
        IOSaAndroid: 'IOS и Android, нажмите для скачивания',
        cn: '简体中文',
        en: 'English',
        assetsHome: 'Активы',
        assets01: 'Детали актива',
        assets02: 'Детали средств',
        assets03: 'Детали ордера',
        assetsHome02: 'Ордера',
        assets0201: 'Контракты',
        assets0202: 'Спот',
        assets0203:'Секунды',
        account01: 'Обзор',
        account02: 'Аккаунт и безопасность',
        account03: 'Управление копированием сделок',
        account04: 'Управление ведущими сделками',
        loginOut: 'Выйти'
    },
    foote:{
        test: 'aaaa - ведущая мировая платформа цифровых финансовых услуг, которая предоставляет безопасную, интеллектуальную и честную среду для инвестиций в цифровые активы через свои комплексные услуги цифровых инвестиций, инструменты диверсификации активов, инновационные консультационные услуги по инвестициям и ведущую отраслевую ликвидность. Основной командой aaaa являются эксперты из традиционной финансовой индустрии ценных бумаг, ведущих интернет-компаний, таких как Microsoft, и других криптовалютных предприятий, имеющих обширный опыт в традиционной финансовой торговле, разработке платформ для криптовалютных бирж высшего уровня и глобальных операциях на рынке. За полгода с момента основания глобальная база пользователей превысила 100 000 человек и охватывает более 80 стран и регионов, включая Северную Америку, Европу и регион Азиатско-Тихоокеанского региона.',
        tab1Title1: 'Помощь',
        tab1Test1: 'Часто задаваемые вопросы',
        tab1Test2: 'Центр объявлений',
        tab2Title1: 'Условия',
        tab2Test1: 'Условия использования',
        tab2Test2: 'Политика конфиденциальности',
        tab2Test3: 'Раскрытие рисков',
        tab3Title1: 'Присоединяйтесь к нам',
        msg: 'Предупреждение о рисках: Торговля цифровыми активами - это новая отрасль с большим потенциалом, но она также несет значительные риски из-за своей новизны. В частности, в контрактной торговле механизм плеча усиливает как прибыль, так и риски одновременно. Очень важно иметь всестороннее понимание отрасли, моделей контрактной торговли, правил торговли и других связанных знаний перед участием в ней. Мы настоятельно рекомендуем вам торговать в пределах допустимых рисков. Торговля сопряжена с рисками, почему рекомендуется проявлять осторожность при входе на рынок!',
        banquan: 'Авторское право',
        banquan2: 'Все права защищены',
        aboutUs: 'О нас',
        Platform:'Введение платформы'
    },
    home:{
        bannerTitle: 'Купите криптовалюту на aaaa',
        bannerTest: 'Ведущая социальная криптовалютная торговая платформа в мире',
        changUpRank: 'Рейтинг прибыльных',
        changDownRank: 'Рейтинг убыточных',
        whyTitle: 'Почему выбирают нас',
        indexTitle1: 'Гарантия безопасности',
        indexTest1: 'Глобальное децентрализованное развертывание без системных рисков',
        indexTitle2: 'Миссия и видение',
        indexTest2: 'Позволять инвесторам получать прибыль от криптовалюты',
        indexTitle3: 'Умные контракты',
        indexTest3: 'Торговля на покупку и продажу, быстрая размещение ордеров, максимальное плечо до 100x',
        indexTitle4: 'Копирование сделок',
        indexTest4: 'Один клик для подписки на сделки лучших трейдеров с высокой доходностью',
        indexTitle5: 'Обсуждение в режиме реального времени',
        indexTest5: 'Чат в режиме реального времени',
        indexTitle6: 'Бинарные опционы',
        indexTest6: 'Первый в мире бинарный индекс криптовалюты',
        indexTitle7: 'Мультивалютность',
        indexTest7: 'Уверенно владейте ключом к успеху на 300+ разнообразных проектах',
        indexTitle8: 'Экосистема',
        indexTest8: 'Создание комплексного сервиса для криптобирж, кошельков и экологии блокчейна',
        downLoadTitle: 'Торгуйте в любое время и в любом месте',
        downLoadTest: 'Доступно на устройствах iOS, Android и ПК. Торгуйте в любое время и в любом месте.',
        downLoadCode: 'Сканируйте для загрузки',
        downLoadIosAnd: 'iOS и Android'
    },
    contract:{
        Change: 'Изменение за 24 часа',
        High: 'Наивысшая за 24 часа',
        Low: 'Наименьшая за 24 часа',
        Volume24: 'Объем за 24 часа',
        Market: 'Рынок',
        LimitPrice: 'Лимитная',
        BestPrice: 'Торгуйте по лучшей доступной цене',
        EnterPrice: 'Введите цену',
        Amount: 'Количество',
        Margin: 'Маржа',
        Available: 'Доступный баланс',
        Leverage: 'Плечо',
        Fee: 'Комиссия за транзакцию',
        Buy: 'Купить',
        Long: 'Покупка',
        Sell: 'Продать',
        Short: 'Продажа',
        Assets: 'Активы',
        ContractAssets: 'Активы по контрактам',
        Deposit: 'Внести депозит',
        Login: 'Войти в торговый аккаунт',
        Register: 'Зарегистрировать торговый аккаунт',
        Instrument: 'Торговый инструмент',
        Minimum: 'Минимальный размер лота',
        Maximum: 'Максимальный размер лота',
        TradingTime: 'Время торговли',
        TradingLeverage: 'Плечо торговли',
        FeeRate: 'Ставка комиссии',
        Introduction: 'Введение',
        IssuanceDate: 'Дата выпуска',
        TotalIssuance: 'Общий выпуск',
        CirculatingSupply: 'Оборотное предложение',
        Crowdfunding: 'Цена на краудфандинге',
        Whitepaper: 'Белая бумага',
        Official: 'Официальный веб-сайт',
        Blockchain: 'Блокчейн-эксплорер',
        Positions: 'Позиции',
        OpenOrder: 'Открытый ордер',
        TradeHistory: 'История сделок',
        ProfitLoss: 'Прибыль/Убыток',
        CloseAll: 'Закрыть все',
        Contract: 'Контракт',
        LotSize: 'Размер лота',
        OpeningPrice: 'Цена открытия',
        CurrentPrice: 'Текущая цена',
        TakeProfi: 'Цена тейк-профита',
        StopLoss: 'Цена стоп-лосса',
        OrderPL: 'Прибыль/Убыток по ордеру',
        OpeningTime: 'Время открытия',
        OrderID: 'ID ордера',
        Action: 'Действие',
        Settings: 'Настройки',
        ClosePosition: 'Закрыть',
        Delete: 'Удалить',
        Status: 'Статус',
        EnterCurrency: 'Введите валюту',
        saveStopLoss: 'Установить Тейк-профит и Стоп-лосс',
        live:'жить',
        demo:'Демо'
    },
    SpotGoods:{
        pricelimit: 'Лимитная',
        marketprice: 'Рыночная',
        Price: 'Цена',
        MarketPrice: 'Рыночная',
        PleaseEnter: 'Пожалуйста, введите',
        Quantity: 'Количество',
        Total: 'Всего',
        Or: 'Или',
        OpenOrders: 'Открытые ордеры',
        Fee: 'Комиссия',
        QuantityAcc: 'торговля',
        LatestTrade: 'Последняя сделка',
        Time: 'Время',
        NowOrders: 'Открытые ордеры',
        AllOrders: 'Все ордеры',
        OrderHistory: 'История ордеров',
        Date: 'Дата',
        TradingPair: 'Торговая пара',
        OrderType: 'Тип ордера',
        MarketOrder: 'Ордер по рыночной цене',
        LimitOrder: 'Ордер с лимитной ценой',
        Direction: 'Направление',
        OrderPrice: 'Цена ордера',
        OrderQuantity: 'Количество ордера',
        OrderAmoun: 'Сумма ордера'
    },
    Binary:{
        GameRules: 'Правила игры',
        Trading: 'Торговля в процессе',
        PositionClosed: 'Позиция закрыта',
        BuyLong: 'Покупка в длинную позицию',
        BuyShort: 'Покупка в короткую позицию',
        OrderPeriod: 'Период ордера',
        TradingAmount: 'Сумма торговли',
        Estimated: 'Примерное время закрытия',
        ClosingTime: 'Время закрытия',
        PlaceBet: 'Разместить ставку',
        Amount: 'Сумма',
        Balance: 'Баланс аккаунта',
        Odds: 'Коэффициенты',
        Profit: 'Примерная прибыль',
        Bullish: 'Бычий',
        Bearish: 'Медвежий',
        countTime: 'Обратный отсчет',
        customInp: 'Пользовательская сумма'
    },
    follow:{
        Ranking: 'Рейтинг трейдеров',
        Updates: 'Обновление данных каждые два часа',
        SearchKey: 'Поиск всех трейдеров',
        Search: 'Поиск',
        Manage: 'Управление подписчиками',
        Details: 'Детали',
        test1: 'Формула = Копирование ордеров [Прибыль/Убыток] / [Общая маржа] * 100%',
        title1: '30 (ROR)',
        test2: 'Формула = Самостоятельно размещенные ордеры [Количество прибыльных ордеров / Общее количество ордеров] * 100%',
        title2: '30 (Win Rate)',
        title3: 'число людей',
        title4: '30-дневная комиссия',
        test5: 'Общее количество ордеров (самостоятельно размещенных) за последние 30 дней',
        title5: 'лот 30 дней',
        test6: 'Рассчитывается от даты регистрации',
        title6: 'Дни торговли',
        Unfollow: 'Отписаться',
        Followed: 'Подписан',
        EditCopying: 'Редактировать копирование',
        CopyingCurrency: 'Валюта копирования',
        AmountCopying: 'Сумма копирования',
        CopyingAmount: 'Сумма копирования',
        ContractTrading: 'Торговая пара контракта',
        LotSizeRatio: 'Соотношение размера лота',
        StartCopying: 'Начать копирование',
        CancelCopying: 'Отменить копирование',
        title7: '30(drawdown)',
        test7: 'Процент потерь активов за последние 30 дней (процент ежедневных потерь активов относительно активов предыдущего дня, исходя из максимальных потерь)',
        BackLobby: 'Вернуться в Лобби',
        TradeHistory: 'История сделок',
        ClosingPrice: 'Цена закрытия',
        info: 'Личный профиль',
        CumulativeCopying: 'Общая сумма копирования',
        TotalCopying: 'Общая прибыль от копирования',
        Discover: 'Открыть для себя экспертов',
        CopyOrders: 'Копировать ордера',
        CurrentFollowers: 'Текущие подписчики',
        Avatar: 'Аватар',
        TraderName: 'Имя трейдера',
        MaximumPosition: 'Максимальная сумма позиции',
        ProfitLoss: 'Прибыль/Убыток от копирования',
        Edit: 'Редактировать',
        TodayProfit: 'Прибыль сегодня',
        Past7Days: 'Общая прибыль за последние 7 дней',
        Accumulated: 'Накопленная общая прибыль',
        ProfitDetails: 'Детали прибыли',
        Followers: 'Подписчики',
        Profit: 'Прибыль',
        FollowerAvatar: 'Аватар подписчика',
        FollowerNickname: 'Никнейм подписчика',
        CopyingTime: 'Время копирования',
        Notice: 'Уведомление',
        NoticeTest1: 'Вы еще не трейдер, поэтому не можете выполнять данную операцию!',
        NoticeTest2: 'Находится на рассмотрении, пожалуйста, будьте терпеливы!',
        Cancel: 'Отменить',
        ApplyNow: 'Подать заявку сейчас',
        ApplyTrader: 'Подать заявку в качестве трейдера',
        Nickname: 'Никнейм (Обязательно)',
        Phone: 'Номер телефона (Обязательно)',
        Email: 'Email (Опционально)',
        test1: 'После подачи заявки наш сотрудник свяжется с вами в кратчайшие сроки. Пожалуйста, предоставьте требуемую информацию для заявки.',
        test2: 'В период рассмотрения заявки, пожалуйста, убедитесь, что не выполняются следующие условия, иначе заявка будет отклонена: открытые позиции, текущие ордера, текущие планы и подписка на трейдеров.',
        Apply: 'Подать заявку'
    },
    account:{
        Overview: 'Обзор',
        Security: 'Безопасность',
        UserID: 'Идентификатор пользователя',
        Welcome: 'Добро пожаловать в aaaa!',
        Welcometest: 'Осталось всего несколько шагов, и вы готовы к работе',
        Registration: 'Регистрация аккаунта',
        SecurityVerification: 'Проверка безопасности',
        GotoBind: 'Перейти к привязке',
        QuickBuy: 'Быстрая покупка криптовалюты',
        AssetDetails: 'Детали активов',
        Deposit: 'Пополнение',
        Withdraw: 'Вывод',
        Transfer: 'Перевод',
        TransferList: 'История переводов',
        type: 'Тип',
        SpotAccount: 'Счет на бирже',
        FuturesAccount: 'Фьючерсный счет',
        HideAsset: 'Скрыть оценку активов',
        Available: 'Доступный баланс (USDT)',
        checkdetails: 'Для подробной информации о количестве активов, пожалуйста, проверьте детали активов',
        Recommendations: 'Рекомендации по безопасности',
        PasswordSet: 'Настройка пароля',
        Change: 'Изменить',
        FundPassword: 'Пароль для средств',
        GotoSet: 'Перейти к установке',
        AddressManagement: 'Управление адресами',
        GotoAdd: 'Перейти к добавлению',
        Verification: 'Проверка личности',
        GotoVerify: 'Перейти к проверке',
        LatestNews: 'Последние новости',
        SafetyTitle: 'Двухфакторная аутентификация',
        SafetyTest: 'Для защиты вашего аккаунта рекомендуется завершить хотя бы одну проверку личности',
        SafetyText1: 'Используется для управления паролем вашего аккаунта',
        SafetyText2: 'Используется для управления паролем для средств на вашем аккаунте',
        NotSet: 'Не установлено',
        Set: 'Установить',
        SafetyText3: 'Защитите ваши торговые и аккаунтовые данные',
        SafetyText4: 'Обеспечьте точность адресов для вывода средств',
        Manage: 'Управление',
        InvitationLink: 'Пригласительная ссылка',
        copyInvitationLink: 'Копировать',
        InvitationCode: 'Пригласительный код',
        copyInvitationCode: 'Копировать',
        enterInvitationCode: 'Пожалуйста, введите пригласительный код'
    },
    finance:{
        Protocol: 'Основная сеть (протокол)',
        DepositAddress: 'Адрес для пополнения',
        QRCode: 'QR-код для пополнения',
        DepositTest1: 'Минимальная сумма пополнения за одну транзакцию не должна быть меньше',
        DepositTest2: ', иначе пополнение не будет успешным',
        DepositTest3: 'Перед каждым пополнением вам нужно перейти на эту страницу, чтобы скопировать адрес и обеспечить его точность. Использование неправильного адреса может привести к неудачному пополнению.',
        DepositTest4: 'Не пополняйте на адрес',
        DepositTest5: 'активы, иначе средства не будут восстановлены.',
        WithdrawalAddress: 'Адрес для вывода средств',
        AddressManagement: 'Управление адресами',
        Memo: 'Примечание',
        WithdrawalAmount: 'Сумма вывода',
        AvailableBalance: 'Доступный баланс',
        Withdraw: 'Вывести',
        WithdrawTest1: 'Пожалуйста, убедитесь, что целевой адрес для вывода существует и активирован, иначе вывод средств будет неудачным, и средства не будут восстановлены.',
        WithdrawTest2: 'Если есть тег адреса, пожалуйста, тщательно проверьте тег адреса, чтобы избежать потери средств, которые невозможно будет восстановить.',
        WithdrawTest3: 'Для обеспечения безопасности средств, когда изменяется ваша стратегия безопасности аккаунта или изменяются пароли, мы будем выполнять ручную проверку вывода. Пожалуйста, терпеливо дождитесь нашего контакта по телефону или по электронной почте.',
        WithdrawTest4: 'Пожалуйста, обеспечьте безопасность вашего компьютера и браузера, чтобы предотвратить подделку или утечку информации.',
        WithdrawTest5: 'Время вывода (UTC+8): с понедельника по воскресенье, с 9:00 до 22:00.',
        AvailableAssets: 'Доступные активы',
        GamblingAccount: 'Счет секунд',
        notpassword: 'Вы еще не установили пароль для средств, поэтому вы не можете выполнить эту операцию!',
        Back: 'Назад',
        AddAddress: 'Добавить новый адрес',
        SelectCurrency: 'Выберите валюту',
        Confirm: 'Подтвердить',
        from: 'От',
        to: 'До'
    },
    assets:{
        ContractPositions: 'Позиции по контрактам',
        DepositDetails: 'Детали пополнения',
        WithdrawalHistory: 'История вывода средств',
        WithdrawalRecords: 'Записи о пополнении и выводе средств',
        TotalEquivalent: 'Общая эквивалентная стоимость всех активов на счету (в USDT)',
        AvailableBalance: 'Доступный баланс на счету (в USDT)',
        HideSmall: 'Скрыть маленькие активы',
        Equivalent: 'Эквивалент',
        FrozenAmount: 'Замороженная сумма',
        DepositType: 'Тип пополнения',
        DepositAmount: 'Сумма пополнения',
        DepositCurrency: 'Валюта пополнения',
        DepositTime: 'Время пополнения',
        WithdrawalType: 'Тип вывода средств',
        WithdrawalAmount: 'Сумма вывода',
        WithdrawalCurrency: 'Валюта вывода средств',
        WithdrawalTime: 'Время вывода средств'
    },
    fastChange:{
        Sell: 'Продать',
        ReferencePrice: 'Справочная цена',
        Buy: 'Купить',
        Quantity: 'Количество для обмена',
        PleaseEnter: 'Пожалуйста, введите желаемое количество для обмена',
        Exchange: 'Обмен',
        Trading: 'Обмен малых сумм ● Оптовая торговля',
        TradingTest: 'Быстрый обмен BTC, ETH и цифровых валют',
        Step1: 'Шаг 1',
        Step1Test: 'Выберите валюту для обмена',
        Step2: 'Шаг 2',
        Step2Test: 'Введите сумму для обмена',
        Step3: 'Шаг 3',
        Step3Test: 'Начать обмен'
    },
    Password:{
        ChangePassword: 'Изменить пароль для входа',
        OldPassword: 'Старый пароль для входа',
        enterOldPassword: 'Пожалуйста, введите ваш старый пароль для входа',
        NewLogin: 'Новый пароль для входа',
        enterNewLogin: 'Пожалуйста, введите ваш пароль для входа',
        againenterNewLogin: 'Пожалуйста, введите ваш пароль для входа еще раз',
        FundPassword: 'Установить пароль для фонда',
        fundpassword: 'Пожалуйста, введите ваш пароль для фонда',
        againfundpassword: 'Пожалуйста, введите ваш пароль для фонда еще раз',
        Name: 'Имя',
        Number: 'Номер ID',
        photos: 'Пожалуйста, загрузите фотографии удостоверения личности, первая - передняя сторона, вторая - задняя сторона',
        photos2: 'Пожалуйста, загрузите свою фотографию, удостоверяющую личность',
        Upload: 'Загрузить',
        wait: 'На рассмотрении, пожалуйста, подождите!',
        categoryType:'Тип документа',
        category1: "Удостоверение личности",
        category2: "Паспорт",
        category3:"Водительские права"
    },
    add:{
        Copied:"Скопировано!",
        test29: "Симуляционная торговля завершена, настоящая торговля ждет вас!",
        test30: "Пополните сейчас и наслаждайтесь наградами за торговый фонд",
        test31: "Получите больше поддержки и инструментов немедленно",
        test32: "Пополните, чтобы начать вашу прибыльную путешествие",
        DemoTrade:"Демо-трейд",
        Progress:"В процессе",
        Requested:"Запрос на возврат",
        Refunded:"Возвращено",
        Completed:"Завершено",
        test1: "Обзор продукта",
        test2: "Услуга финансового управления (FMS) от (aaaa) - это продукт, разработанный (aaaa) для помощи пользователям с бездействующими цифровыми активами в получении прибыли при низких порогах. Начать работу с ним легко. Услуга финансового управления (FMS) предлагает различные варианты сроков, позволяя пользователям получать прибыль от предоставления займов пользователям с маржинальной торговлей на рынке займов; Фиксированная доходность простой монеты предполагает блокирование монет для получения наград PoS или поощрений за проект.",
        test3: "* Обратите внимание: Прошлые доходы не являются гарантией будущих доходов. Мы не обещаем выплатить основной долг и проценты в определенный период в виде валюты, материальных товаров, акций или любой другой форме дохода от собственности, также мы не обещаем какой-либо другой формы дохода от собственности.",
        test4: "Надежно и безопасно",
        test5: "(aaaa) имеет строгие системы контроля рисков, ведущие меры безопасности и современную инфраструктуру для полной защиты активов пользователей.",
        test6: "Гибкая подписка и выкуп",
        test7: "Круглосуточная поддержка для подписки и выкупа в любое время. Выкуп для текущих доходов от простой монеты осуществляется немедленно, в то время как выкуп для фиксированных доходов от простой монеты занимает до 30 минут.",
        test8: "Фиксированный срок (aaaa) Услуга финансового управления (FMS)",
        test9: "Фиксированный срок (aaaa) Услуга финансового управления (FMS) имеет несколько источников дохода, таких как вознаграждения, полученные через залог и вознаграждения на основе механизма Proof of Stake (PoS), а также вознаграждения от платформы.",
        test10: "Что такое годовая эталонная доходность?",
        test11: "Годовая эталонная доходность - это ожидаемая годовая доходность продукта, на который вы подписываетесь, исключительно для ознакомления.",
        test12: "Как рассчитывается ожидаемая прибыль?",
        test13: "Ожидаемая прибыль рассчитывается на основе количества вашей подписки и годовой эталонной доходности продукта, предоставляя оценку ежедневной ожидаемой прибыли, исключительно для справки.",
        test14: "Когда начисляются проценты?",
        test15: "Начисление дохода начинается с 12:00 (UTC+8) следующего дня после подписки.",
        test16: "Когда начисляется прибыль?",
        test17: "Прибыль начисляется с 12:00 (UTC+8) следующего дня после начала начисления процентов.",
        test18: "Почему у разных продуктов разные периоды погашения?",
        test19: "Период погашения - это время, необходимое для подтверждения погашения и зачисления фактического погашения. Активы для фиксированных доходов от простой монеты обеспечиваются на цепи, что требует времени для погашения с цепи. Поскольку каждая общедоступная цепь работает по-разному, периоды погашения различаются.",
        test20: "Нужно ли мне вручную погашать фиксированные продукты по истечении срока?",
        test21: "Это зависит от ситуации. Все фиксированные продукты по умолчанию автоматически продлятся. По истечении срока тот же срок будет автоматически продлен. После включения автоматического продления погашение должно быть сделано вручную. Функция автоматического продления включена по умолчанию. Чтобы отключить ее, перейдите на страницу с подробностями заказа.",
        test22: "Могу ли я всё ещё зарабатывать доход, ожидая погашения?",
        test23: "Как только погашение подтверждено, начисление процентов прекращается. Доход не может быть получен во время ожидания погашения.",
        test24: "Как выбрать лучший продукт Финансового Управления на Фиксированный Срок (aaaa) (FMS)?",
        test25: "Вы можете сравнить различные цифровые активы, условия, исторические доходы и т. д. среди всех продуктов по простому заработку монет, которые мы предоставляем, чтобы выбрать подходящий продукт для вас. Мы предоставляем только услуги залога активов, сбора наград и их распределения, и не предоставляем никаких инвестиционных советов. Прежде чем принимать решение об инвестициях, вы должны полностью понимать характер и риски этого продукта, а также другие существенные обстоятельства, и делать независимые суждения на основе вашего опыта инвестирования, финансового положения, инвестиционных целей, срока инвестирования, готовности и способности к риску инвестиций и других существенных обстоятельств, и нести ответственность за собственные решения.",
        test26: "Доход",
        test27: "Преимущество",
        test28: "FAQ",
        assets:"Финансы",
        Redemption:"Возврат",
        Subscribe:"Подписка",
        SubscribeType: "Тип подписки",
        SubscribeNum: "Сумма подписки",
        Historical: "Накопленные проценты",
        SubscribeTime: "Время подписки",
        RedemptionTime: "Время погашения",
        RedemptionConfirm: "Подтверждение погашения",
        LockedSavings: "Фиксированные инвестиции",
        test33: "Финансовое управление",
        test34: "Понимайте основные концепции и стратегии управления рисками инвестирования в криптовалюты, спокойно относитесь к колебаниям рынка и достигайте финансовых целей. Присоединяйтесь к нам сейчас и начните свое путешествие в мир инвестирования в криптовалюты!",
        test35: "Освойте суть инвестирования в криптовалюты, интерпретируйте тенденции рынка, ищите потенциальные возможности и достигайте роста богатства. Без порога вхождения, низкий риск, высокая доходность. Начните свое путешествие по управлению криптовалютным богатством и двигайтесь к новым высотам богатства!",
        test36: "Список продуктов",
        test37: "Список подписок",
        test38: "Годовая процентная ставка:",
        test39: "Дни：",
        test40: "Минимальная сумма инвестиций:",
        test41: "Максимальная сумма:",
        test42: "Дата начала начисления процентов:",
        test43: "Дата окончания расчета процентов:",
        test44: "Максимальная",
        test45: "Доступные активы:",
        test46: "Ожидаемая прибыль:",
        test47: "Я прочитал и согласен с",
        test48: "Обзор продукта",
        test49:"день",
        Agreement:"Соглашение о финансовой покупке",
        test50:"Сумма пополнения",
        test51:"Загрузить ваучер на трансфер",
        EnterPrice:"Введите цену",
    }
}