export default {
    total:{
        certified: 'Certificado',
        UnCertified: 'No Certificado',
        login: 'Iniciar sesión',
        register: 'Registrarse',
        registerNow: 'Registrarse ahora',
        loginNow: 'Iniciar sesión ahora',
        buyNow: 'Comprar ahora',
        Currency: 'Moneda',
        chang24: 'Cambiar',
        NowPrice: 'Precio actual',
        Leverage: 'Apalancamiento',
        Favorites: 'Favoritos',
        Announcement: 'Anuncio',
        dynamic: 'Dinámico',
        VerificationCode: 'Código de verificación',
        enteremailcode: 'Por favor ingrese el código de verificación de correo electrónico',
        GetCode: 'Enviar',
        add:'agregar',
        help: 'Centro de ayuda',
        xszy: 'Guía para principiantes',
        jyzn: 'Guía de trading',
        aboutUs: 'Acerca de aaaa'
    },
    login:{
        loginTitle: 'Bienvenido a aaaa',
        email: 'Email',
        enailTest: 'Por favor ingrese su correo electrónico',
        password: 'Contraseña',
        passwordTest: 'Por favor ingrese su contraseña',
        unpassword: '¿Olvidó su contraseña?',
        noassets: 'Aún no tiene una cuenta',
        Addassets: 'Crear una cuenta',
        password2: 'Reingrese la contraseña',
        password2test: 'Por favor vuelva a ingresar su contraseña',
        assets: 'Cuenta existente'
    },
    topHeader: {
        nav01: 'Contrato',
        nav02: 'Trading al contado',
        nav03: 'Segundos',
        nav04: 'Copy Trade',
        nav05: 'Noticias',
        nav06: 'Cambio rápido',
        APPStore: 'APP Store',
        GooglePlay: 'Google Play',
        Android: 'Android',
        IOSaAndroid: 'IOS y Android Escanea para descargar',
        cn: '简体中文',
        en: 'English',
        assetsHome: 'Activos',
        assets01: 'Detalles de los activos',
        assets02: 'Detalles de los fondos',
        assets03: 'Detalles de la orden',
        assetsHome02: 'Órdenes',
        assets0201: 'Contratos',
        assets0202: 'Al contado',
        assets0203:'Segundos',
        account01: 'Resumen',
        account02: 'Cuenta y seguridad',
        account03: 'Gestionar operaciones de copia',
        account04: 'Gestionar operaciones líder',
        loginOut: 'Cerrar sesión'
    },
    foote:{
        test: 'aaaa es una plataforma líder de servicios financieros digitales a nivel global que proporciona un entorno seguro, inteligente y justo para la inversión en activos digitales a través de sus servicios de inversión digital integrales, herramientas de asignación de activos diversificadas, servicios de asesoramiento de inversión innovadores y liquidez líder en la industria. El equipo principal de aaaa proviene de la industria de valores financieros tradicionales, empresas de internet de primer nivel como Microsoft y otras empresas de criptomonedas, aportando una amplia experiencia en negociación financiera tradicional, desarrollo de plataformas de intercambio de criptomonedas de primer nivel y operaciones en el mercado global. Desde su establecimiento hace seis meses, la base de usuarios globales ha superado los 100.000 y abarca más de 80 países y regiones, incluyendo América del Norte, Europa y la región de Asia Pacífico.',
        tab1Title1: 'Ayuda',
        tab1Test1: 'Preguntas frecuentes',
        tab1Test2: 'Centro de anuncios',
        tab2Title1: 'Términos',
        tab2Test1: 'Términos de servicio',
        tab2Test2: 'Política de privacidad',
        tab2Test3: 'Divulgación de riesgos',
        tab3Title1: 'Únete a nosotros',
        msg: 'Advertencia de riesgo: La negociación de activos digitales es una industria incipiente con perspectivas prometedoras, pero también conlleva riesgos significativos debido a su nueva naturaleza. En particular, en la negociación de contratos, el apalancamiento amplifica tanto las ganancias como los riesgos simultáneamente. Es crucial que tengas una comprensión integral de la industria, los modelos de negociación de contratos, las reglas de negociación y otros conocimientos relacionados antes de participar. Te recomendamos encarecidamente que operes dentro de los límites de riesgo que puedas soportar. ¡La negociación conlleva riesgos y se aconseja precaución al ingresar al mercado!',
        banquan: 'Derechos de autor',
        banquan2: 'Todos los derechos reservados',
        aboutUs: 'Sobre nosotros',
        Platform:'Plataforma Introducción'
    },
    home:{
        bannerTitle: 'Compra criptomonedas en aaaa',
        bannerTest: 'El intercambio de criptomonedas social líder en el mundo',
        changUpRank: 'Ranking de ganadores',
        changDownRank: 'Ranking de perdedores',
        whyTitle: '¿Por qué elegirnos?',
        indexTitle1: 'Garantía de seguridad',
        indexTest1: 'Implementación descentralizada global sin riesgo sistémico',
        indexTitle2: 'Misión y visión',
        indexTest2: 'Permitir que los inversores disfruten de obtener ganancias en criptomonedas',
        indexTitle3: 'Contratos inteligentes',
        indexTest3: 'Operaciones largas y cortas, colocación rápida de órdenes, apalancamiento de hasta 100x',
        indexTitle4: 'Copy Trading',
        indexTest4: 'Un clic para seguir las operaciones de los mejores traders con alta tasa de retorno',
        indexTitle5: 'Discusión en tiempo real',
        indexTest5: 'Chat en tiempo real',
        indexTitle6: 'Binary',
        indexTest6: 'El primer índice de criptomonedas binario del mundo',
        indexTitle7: 'Multidivisas',
        indexTest7: 'Agarre con confianza la clave del éxito en más de 300 proyectos diversificados',
        indexTitle8: 'Ecosistema',
        indexTest8: 'Crear un servicio integral para intercambios de criptomonedas, billetera y ecología en cadena',
        downLoadTitle: 'Operar en cualquier momento y lugar',
        downLoadTest: 'Disponible en dispositivos iOS, Android y PC. Opera en cualquier momento y lugar.',
        downLoadCode: 'Escanear para descargar',
        downLoadIosAnd: 'iOS y Android'
    },
    contract:{
        Change: 'Cambio 24H',
        High: 'Máximo 24H',
        Low: 'Mínimo 24H',
        Volume24: 'Volumen 24H',
        Market: 'Mercado',
        LimitPrice: 'Límite',
        BestPrice: 'Operar al Mejor Precio Disponible',
        EnterPrice: 'Por favor ingrese el precio',
        Amount: 'Cantidad',
        Margin: 'Margen',
        Available: 'Saldo Disponible',
        Leverage: 'Apalancamiento',
        Fee: 'Comisión de Transacción',
        Buy: 'Comprar',
        Long: 'Largo',
        Sell: 'Vender',
        Short: 'Corto',
        Assets: 'Activos',
        ContractAssets: 'Activos del Contrato',
        Deposit: 'Depositar ahora',
        Login: 'Iniciar sesión en la cuenta de trading',
        Register: 'Registrar cuenta de trading',
        Instrument: 'Instrumento de Trading',
        Minimum: 'Tamaño Mínimo de Lote',
        Maximum: 'Tamaño Máximo de Lote',
        TradingTime: 'Horario de Trading',
        TradingLeverage: 'Apalancamiento de Trading',
        FeeRate: 'Tasa de Comisión',
        Introduction: 'Introducción',
        IssuanceDate: 'Fecha de Emisión',
        TotalIssuance: 'Emisión Total',
        CirculatingSupply: 'Suministro Circulante',
        Crowdfunding: 'Precio de Crowdfunding',
        Whitepaper: 'Whitepaper',
        Official: 'Sitio Web Oficial',
        Blockchain: 'Explorador de Blockchain',
        Positions: 'Posiciones',
        OpenOrder: 'Orden Abierta',
        TradeHistory: 'Historial de Operaciones',
        ProfitLoss: 'Ganancia/Pérdida',
        CloseAll: 'Cerrar Todo',
        Contract: 'Contrato',
        LotSize: 'Tamaño del Lote',
        OpeningPrice: 'Precio de Apertura',
        CurrentPrice: 'Precio Actual',
        TakeProfi: 'Precio de Toma de Ganancias',
        StopLoss: 'Precio de Detención de Pérdidas',
        OrderPL: 'Ganancia/Pérdida de la Orden',
        OpeningTime: 'Hora de Apertura',
        OrderID: 'ID de Orden',
        Action: 'Acción',
        Settings: 'Configuración',
        ClosePosition: 'Cerrar',
        Delete: 'Eliminar',
        Status: 'Estado',
        EnterCurrency: 'Por favor ingrese la moneda',
        saveStopLoss: 'Establecer T/P y S/L',
        live:'En directo',
        demo:'Demo'
    },
    SpotGoods:{
        pricelimit: 'Límite',
        marketprice: 'Mercado',
        Price: 'Precio',
        MarketPrice: 'Precio de Mercado',
        PleaseEnter: 'Por favor ingrese',
        Quantity: 'Cantidad',
        Total: 'Total',
        Or: 'O',
        OpenOrders: 'Órdenes Abiertas',
        Fee: 'Comisión',
        QuantityAcc: 'Volumen',
        LatestTrade: 'Última Operación',
        Time: 'Hora',
        NowOrders: 'Órdenes Abiertas',
        AllOrders: 'Todas las Órdenes',
        OrderHistory: 'Historial de Órdenes',
        Date: 'Fecha',
        TradingPair: 'Par de Trading',
        OrderType: 'Tipo de Orden',
        MarketOrder: 'Orden de Mercado',
        LimitOrder: 'Orden de Límite',
        Direction: 'Dirección',
        OrderPrice: 'Precio de la Orden',
        OrderQuantity: 'Cantidad de la Orden',
        OrderAmoun: 'Monto de la Orden'
    },
    Binary:{
        GameRules: 'Reglas del Juego',
        Trading: 'Trading en Progreso',
        PositionClosed: 'Posición Cerrada',
        BuyLong: 'Comprar Largo',
        BuyShort: 'Comprar Corto',
        OrderPeriod: 'Período de Orden',
        TradingAmount: 'Monto de Trading',
        Estimated: 'Tiempo de Cierre Estimado',
        ClosingTime: 'Hora de Cierre',
        PlaceBet: 'Realizar Apuesta',
        Amount: 'Cantidad',
        Balance: 'Saldo de la Cuenta',
        Odds: 'Probabilidades',
        Profit: 'Beneficio Estimado',
        Bullish: 'Largo',
        Bearish: 'Corto',
        countTime: 'Cuenta regresiva',
        customInp: 'Cantidad personalizada'
    },
    follow:{
        Ranking: 'Ranking de Traders',
        Updates: 'Datos Actualizados Cada Dos Horas',
        SearchKey: 'Buscar Todos los Traders',
        Search: 'Buscar',
        Manage: 'Gestionar seguidores',
        Details: 'Detalles',
        test1: 'Fórmula = Copia de Orden [Beneficio/Pérdida] / [Margen Total] * 100%',
        title1: 'ROR a 30 días',
        test2: 'Fórmula = Auto-Orden Colocada [Cantidad de Órdenes Rentables / Cantidad Total de Órdenes] * 100%',
        title2: '30 días para ganar',
        title3: 'Seguidores actuales',
        title4: 'Comisión a 30 días',
        test5: 'Cantidad Total (Auto-Colocada) de Órdenes en los Últimos 30 días',
        title5: 'oferta de 30 días',
        test6: 'Calculado desde la Fecha de Registro',
        title6: 'Días de Trading',
        Unfollow: 'Dejar de seguir',
        Followed: 'Seguido',
        EditCopying: 'Editar Copiado',
        CopyingCurrency: 'Moneda a Copiar',
        AmountCopying: 'Cantidad a Copiar',
        CopyingAmount: 'Cantidad Copiada',
        ContractTrading: 'Par de Trading de Contrato',
        LotSizeRatio: 'Relación de Tamaño de Lote',
        StartCopying: 'Comenzar a Copiar',
        CancelCopying: 'Cancelar Copiado',
        title7: 'Drawdown a 30 días',
        test7: 'Ratio de Pérdida de Activos en los Últimos 30 días (Porcentaje de Pérdida Diaria de Activos respecto al Día Anterior, Basado en la Pérdida Máxima)',
        BackLobby: 'Volver al Lobby',
        TradeHistory: 'Historial de Trading',
        ClosingPrice: 'Precio de Cierre',
        info: 'Perfil Personal',
        CumulativeCopying: 'Cantidad Total Copiada',
        TotalCopying: 'Ganancia Total Copiada',
        Discover: 'Descubrir Expertos',
        CopyOrders: 'Órdenes Copiadas',
        CurrentFollowers: 'Seguidores Actuales',
        Avatar: 'Avatar',
        TraderName: 'Nombre del Trader',
        MaximumPosition: 'Monto Máximo de Posición',
        ProfitLoss: 'Ganancia/Pérdida de Copiado',
        Edit: 'Editar',
        TodayProfit: 'Ganancia de Hoy',
        Past7Days: 'Ganancia Total en los Últimos 7 Días',
        Accumulated: 'Ganancia Total Acumulada',
        ProfitDetails: 'Detalles de Ganancia',
        Followers: 'Seguidores',
        Profit: 'Ganancia',
        FollowerAvatar: 'Avatar del Seguidor',
        FollowerNickname: 'Apodo del Seguidor',
        CopyingTime: 'Tiempo de Copiado',
        Notice: 'Aviso',
        NoticeTest1: '¡Todavía no eres un trader, por lo que no puedes realizar esta operación!',
        NoticeTest2: '¡En revisión, por favor espera pacientemente!',
        Cancel: 'Cancelar',
        ApplyNow: 'Aplicar Ahora',
        ApplyTrader: 'Aplicar como Trader',
        Nickname: 'Apodo (Requerido)',
        Phone: 'Número de Teléfono (Requerido)',
        Email: 'Correo Electrónico (Opcional)',
        test1: 'Después de enviar la solicitud, nuestro personal se pondrá en contacto contigo lo antes posible. Por favor, proporciona la información de solicitud requerida según lo solicitado.',
        test2: 'Durante el período de revisión, asegúrate de que no se cumplan las siguientes condiciones, de lo contrario, la revisión fallará: mantener posiciones abiertas, órdenes actuales, planes actuales y seguir a traders.',
        Apply: 'Aplicar'
    },
    account:{
        Overview: 'Resumen',
        Security: 'Seguridad',
        UserID: 'ID de Usuario',
        Welcome: '¡Bienvenido a aaaa!',
        Welcometest: 'Solo faltan algunos pasos más y estarás listo para empezar',
        Registration: 'Registro de Cuenta',
        SecurityVerification: 'Verificación de Seguridad',
        GotoBind: 'Ir a Vincular',
        QuickBuy: 'Compra Rápida de Criptomonedas',
        AssetDetails: 'Detalles de los Activos',
        Deposit: 'Depositar',
        Withdraw: 'Retirar',
        Transfer: 'Transferir',
        TransferList: 'Historial de transferencias',
        type: 'Tipo',
        SpotAccount: 'Cuenta Spot',
        FuturesAccount: 'Cuenta de Futuros',
        HideAsset: 'Ocultar Valoración de Activos',
        Available: 'Saldo Disponible (USDT)',
        checkdetails: 'Para obtener cantidades detalladas de activos, consulta los detalles de los activos',
        Recommendations: 'Recomendaciones de Seguridad',
        PasswordSet: 'Configuración de Contraseña',
        Change: 'Cambiar',
        FundPassword: 'Contraseña de Fondos',
        GotoSet: 'Ir a Configurar',
        AddressManagement: 'Gestionar Direcciones',
        GotoAdd: 'Ir a Agregar',
        Verification: 'Verificación de Identidad',
        GotoVerify: 'Ir a Verificar',
        LatestNews: 'Últimas Noticias',
        SafetyTitle: 'Autenticación de Dos Factores',
        SafetyTest: 'Para proteger tu cuenta, se recomienda completar al menos una verificación de identidad con nombre real',
        SafetyText1: 'Se utiliza para gestionar la contraseña de inicio de sesión de tu cuenta',
        SafetyText2: 'Se utiliza para gestionar la contraseña de fondos de tu cuenta',
        NotSet: 'No Configurado',
        Set: 'Configurar',
        SafetyText3: 'Protege tu trading y la seguridad de tu cuenta',
        SafetyText4: 'Asegura la precisión de las direcciones de retiro',
        Manage: 'Gestionar',
        InvitationLink: 'Enlace de Invitación',
        copyInvitationLink: 'Copiar',
        InvitationCode: 'Código de Invitación',
        copyInvitationCode: 'Copiar',
        enterInvitationCode: 'Por favor ingresa el código de invitación'
    },
    finance:{
        Protocol: 'Red Principal (Protocolo)',
        DepositAddress: 'Dirección de Depósito',
        QRCode: 'Código QR de Depósito',
        DepositTest1: 'El monto mínimo de depósito por transacción no debe ser inferior a',
        DepositTest2: ', cualquier monto menor a esto no será exitoso',
        DepositTest3: 'Antes de cada depósito, debes ir a esta página para copiar la dirección y asegurarte de su precisión. El uso de una dirección incorrecta resultará en un depósito fallido.',
        DepositTest4: 'No deposites ningún activo que no sea',
        DepositTest5: 'en la dirección mencionada anteriormente, de lo contrario, los activos no serán recuperables.',
        WithdrawalAddress: 'Dirección de Retiro',
        AddressManagement: 'Gestión de Direcciones',
        Memo: 'Memo',
        WithdrawalAmount: 'Monto de Retiro',
        AvailableBalance: 'Saldo Disponible',
        Withdraw: 'Retirar',
        WithdrawTest1: 'Por favor, confirma que la dirección de retiro objetivo existe y está activada, de lo contrario resultará en un retiro fallido y los activos no serán recuperables.',
        WithdrawTest2: 'Si hay una etiqueta de dirección, verifica cuidadosamente la etiqueta de dirección para evitar pérdidas de activos que no se pueden recuperar.',
        WithdrawTest3: 'Para garantizar la seguridad de los fondos, cuando cambie la estrategia de seguridad de su cuenta o modifique las contraseñas, revisaremos manualmente el retiro. Por favor, espera pacientemente a que nuestro personal se comunique contigo por teléfono o correo electrónico.',
        WithdrawTest4: 'Por favor, asegúrate de la seguridad de tu computadora y navegador para prevenir la manipulación o divulgación de información.',
        WithdrawTest5: 'Hora de Retiro (UTC+8): lunes a domingo, 9:00-22:00.',
        AvailableAssets: 'Activos Disponibles',
        GamblingAccount: 'Cuenta de Segundos',
        notpassword: '¡Aún no has configurado una contraseña de fondos, por lo que no puedes realizar esta operación!',
        Back: 'Volver',
        AddAddress: 'Agregar Nueva Dirección',
        SelectCurrency: 'Seleccionar Moneda',
        Confirm: 'Confirmar',
        from: 'De',
        to: 'A'
    },
    assets:{
        ContractPositions: 'Posiciones de Contrato',
        DepositDetails: 'Detalles de Depósito',
        WithdrawalHistory: 'Historial de Retiros',
        WithdrawalRecords: 'Registros de Depósito y Retiro',
        TotalEquivalent: 'Valor Total Equivalente de Todos los Activos Spot (en USDT)',
        AvailableBalance: 'Saldo Disponible en Spot (USDT)',
        HideSmall: 'Ocultar Activos Pequeños',
        Equivalent: 'Equivalente',
        FrozenAmount: 'Cantidad Congelada',
        DepositType: 'Tipo de Depósito',
        DepositAmount: 'Monto de Depósito',
        DepositCurrency: 'Moneda de Depósito',
        DepositTime: 'Hora de Depósito',
        WithdrawalType: 'Tipo de Retiro',
        WithdrawalAmount: 'Monto de Retiro',
        WithdrawalCurrency: 'Moneda de Retiro',
        WithdrawalTime: 'Hora de Retiro'
    },
    fastChange:{
        Sell: 'Vender',
        ReferencePrice: 'Precio de Referencia',
        Buy: 'Comprar',
        Quantity: 'Cantidad Intercambiable',
        PleaseEnter: 'Por favor ingresa la cantidad de intercambio deseada',
        Exchange: 'Intercambiar',
        Trading: 'Intercambio de Pequeñas Cantidades ● Trading a Granel',
        TradingTest: 'Intercambio Rápido para BTC, ETH y Criptomonedas',
        Step1: 'Paso 1',
        Step1Test: 'Selecciona la moneda a intercambiar',
        Step2: 'Paso 2',
        Step2Test: 'Ingresa la cantidad a intercambiar',
        Step3: 'Paso 3',
        Step3Test: 'Comienza el intercambio'
    },
    Password:{
        ChangePassword: 'Cambiar Contraseña de Inicio de Sesión',
        OldPassword: 'Contraseña Antigua de Inicio de Sesión',
        enterOldPassword: 'Por favor ingresa tu antigua contraseña de inicio de sesión',
        NewLogin: 'Nueva Contraseña de Inicio de Sesión',
        enterNewLogin: 'Por favor ingresa tu nueva contraseña de inicio de sesión',
        againenterNewLogin: 'Por favor ingresa tu nueva contraseña de inicio de sesión nuevamente',
        FundPassword: 'Configurar Contraseña de Fondos',
        fundpassword: 'Por favor ingresa tu contraseña de fondos',
        againfundpassword: 'Por favor ingresa tu contraseña de fondos nuevamente',
        Name: 'Nombre',
        Number: 'Número de Identificación',
        photos: 'Por favor carga las fotos de tu documento de identidad, la primera es el lado frontal y la segunda es el lado posterior',
        photos2:'Por favor sube tu foto de identificación',
        Upload: 'Subir',
        wait: 'En revisión, ¡por favor espera!',
        categoryType:'Tipo de documento',
        category1:'tarjeta de identificación',
        category2:'Pasaporte',
        category3:"Licencia de conducir"
    },
    add:{
        Copied:"Copiado!",
        test29: "La simulación de trading ha terminado, ¡el trading real te espera!",
        test30: "Recarga ahora y disfruta de las recompensas del fondo de trading",
        test31: "Obtén más soporte y herramientas de inmediato",
        test32: "Recarga para comenzar tu viaje rentable",
        DemoTrade:"Demo Trade",
        Progress: "In Progress",
        Requested: "Refund Requested",
        Refunded: "Refunded",
        Completed: "Completed",
        test1: "Resumen del producto",
        test2: "El Servicio de Gestión Financiera (FMS) de (aaaa) es un producto diseñado por (aaaa) para ayudar a los usuarios con activos digitales inactivos a obtener ganancias con bajos umbrales. Es fácil comenzar. El Servicio de Gestión Financiera (FMS) de (aaaa) ofrece diferentes opciones de plazo, lo que permite a los usuarios obtener ganancias prestando a usuarios de trading apalancado en el mercado de préstamos; El ganar monedas simples a plazo fijo implica bloquear monedas para obtener recompensas PoS o incentivos de proyectos.",
        test3: "*Tenga en cuenta: Los ingresos pasados no representan ingresos futuros. No prometemos devolver el capital e intereses en un período determinado en moneda, bienes físicos, acciones u cualquier otra forma de retorno de ingresos de propiedad, ni prometemos ninguna otra forma de retorno de ingresos de propiedad.",
        test4: "Seguro y confiable",
        test5: "(aaaa) tiene sistemas de control de riesgos estrictos, medidas de seguridad líderes e infraestructura avanzada para salvaguardar completamente la seguridad de los activos del usuario.",
        test6: "Suscripción y redención flexibles",
        test7: "Soporte las 24 horas para suscripción y redención en cualquier momento. La redención para las ganancias actuales de monedas simples es inmediata, mientras que la redención para las ganancias de monedas simples a plazo fijo tarda hasta 30 minutos.",
        test8: "Servicio de Gestión Financiera (FMS) a Plazo Fijo de (aaaa)",
        test9: "El Servicio de Gestión Financiera (FMS) a Plazo Fijo de (aaaa) tiene múltiples fuentes de ingresos, como recompensas obtenidas a través de la garantía y recompensas basadas en el mecanismo de Proof of Stake (PoS), así como recompensas de la plataforma.",
        test10: "Qué es el Rendimiento Anualizado de Referencia?",
        test11: "El Rendimiento Anualizado de Referencia es el rendimiento anualizado esperado del producto al que se suscribe, solo para referencia.",
        test12: "Cómo se calculan las ganancias esperadas?",
        test13: "Las ganancias esperadas se calculan en función de la cantidad de su suscripción y del rendimiento de referencia anualizado del producto, proporcionando una estimación de las ganancias esperadas diarias, solo para referencia.",
        test14: "Cuándo se acumulan los intereses?",
        test15: "Las ganancias comienzan a acumularse a partir de las 12:00 (UTC+8) del día siguiente al de la suscripción.",
        test16: "Cuándo se distribuyen las ganancias?",
        test17: "Las ganancias se distribuyen a partir de las 12:00 (UTC+8) del día siguiente al inicio de la acumulación de intereses.",
        test18: "Por qué tienen diferentes productos diferentes períodos de redención?",
        test19: "El período de redención se refiere al tiempo que transcurre desde la confirmación de la redención hasta que la redención efectiva se acredita. Los activos para las ganancias de monedas simples a plazo fijo están garantizados en la cadena, lo que requiere algún tiempo para la redención desde la cadena. Dado que cada cadena pública funciona de manera diferente, los períodos de redención varían.",
        test20: "Necesito redimir manualmente los productos a plazo fijo al vencimiento?",
        test21: "Esto depende de la situación. Todos los productos a plazo fijo se renovarán automáticamente de forma predeterminada. Al vencimiento, el mismo plazo se renovará automáticamente. Después de habilitar la renovación automática, la redención debe realizarse manualmente. La función de renovación automática está habilitada de forma predeterminada. Para deshabilitarla, por favor vaya a la página de detalles del pedido.",
        test22: "Puedo seguir obteniendo ingresos mientras espero la redención?",
        test23: "Una vez confirmada la redención, los intereses dejan de acumularse. No se pueden obtener ingresos mientras se espera la redención.",
        test24: "Cómo elegir el mejor producto de Servicio de Gestión Financiera (FMS) a plazo fijo de (aaaa)?",
        test25: "Puede comparar diferentes activos digitales, términos, rendimientos históricos, etc., entre todos los productos de ganancia de monedas simples que ofrecemos, para elegir el producto que mejor se adapte a usted. Solo proporcionamos servicios de garantía de activos, recopilación y distribución de recompensas y no proporcionamos ningún consejo de inversión. Antes de tomar una decisión de inversión, debe comprender completamente la naturaleza y los riesgos de este producto, así como otras circunstancias relevantes, y tomar decisiones independientes basadas en su experiencia de inversión, situación financiera, objetivos de inversión, período de inversión, voluntad y capacidad para soportar riesgos de inversión y otras circunstancias relevantes, y ser responsable de sus propias decisiones.",
        test26: "Ingresos",
        test27: "Ventaja",
        test28: "Preguntas frecuentes",
        assets: "Financiero",
        Redemption: "Redemption",
        Subscribe: "Subscribe",
        SubscribeType: "Tipo de Suscripción",
        SubscribeNum: "Cantidad de Suscripción",
        Historical: "Intereses Acumulados",
        SubscribeTime: "Hora de Suscripción",
        RedemptionTime: "Hora de Redención",
        RedemptionConfirm: "Confirmación de Redención",
        LockedSavings: "Inversión a Plazo Fijo",
        test33: "Gestión Financiera",
        test34: "Comprenda los conceptos básicos y las estrategias de gestión de riesgos de la inversión en criptomonedas, enfrente las fluctuaciones del mercado con calma y logre objetivos financieros. ¡Únase a nosotros ahora y comience su viaje hacia la inversión en criptomonedas!",
        test35: "Domine la esencia de la inversión en criptomonedas, interprete las tendencias del mercado, explore oportunidades potenciales y logre la apreciación de la riqueza. Sin umbral, bajo riesgo, altos retornos. ¡Comience su viaje de gestión de riqueza en criptomonedas y avance hacia nuevas alturas de riqueza!",
        test36: "Lista de Productos",
        test37: "Lista de Suscripciones",
        test38: "Tasa de Interés Anual:",
        test39: "Días：",
        test40: "Cantidad Mínima de Inversión:",
        test41: "Cantidad Máxima:",
        test42: "Fecha de Inicio de Intereses:",
        test43: "Fecha de Finalización del Cálculo de Intereses:",
        test44: "Máximo",
        test45: "Activos Disponibles:",
        test46: "Beneficio Estimado:",
        test47: "He leído y acepto",
        test48: "Descripción del Producto",
        test49:"día",
        Agreement: "Financial Purchase Agreement",
        test50:"Monto de recarga",
	    test51:"Subir comprobante de transferencia",
        EnterPrice: "Please Enter Price",
    }
}