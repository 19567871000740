export default {
    total:{
        certified: '已認證',
        UnCertified: '未認證',
        login: '登錄',
        register: '註冊',
        registerNow: '立即註冊',
        loginNow: '立即登錄',
        buyNow: '立即買幣',
        Currency: '幣種',
        chang24: '漲跌幅',
        NowPrice: '最新價',
        Leverage: '數幣',
        Favorites: '自選',
        Announcement: '平台公告',
        dynamic: '動態',
        VerificationCode: '驗證碼',
        enteremailcode: '請輸入郵箱驗證碼',
        GetCode: '獲取驗證碼',
        add:'添加自選',
        help: '幫助中心',
        xszy: '新手引導',
        jyzn: '交易指南',
        aboutUs: '關於aaaa'
    },
    login:{
        loginTitle: '歡迎登錄aaaa',
        email: '電子郵箱',
        enailTest: '請輸入電子郵箱',
        password: '登錄密碼',
        passwordTest: '請輸入登錄密碼',
        unpassword: '忘記密碼？',
        noassets: '還沒有賬戶',
        Addassets: '創建賬戶',
        password2: '再次輸入登錄密碼',
        password2test: '請再次輸入登錄密碼',
        assets: '已有賬戶'
    },
    topHeader: {
        nav01: '合約交易',
        nav02: '現貨交易',
        nav03: '秒合約交易',
        nav04: '跟單交易',
        nav05: '最新資訊',
        nav06: '閃兌',
        APPStore: 'APP Store',
        GooglePlay: 'Google Play',
        Android: 'Android',
        IOSaAndroid: 'IOS和Android 扫码下载',
        cn: '简体中文',
        en: 'English',
        assetsHome: '資產',
        assets01: '資產明細',
        assets02: '資金明細',
        assets03: '訂單明細',
        assetsHome02: '訂單',
        assets0201: '合約',
        assets0202: '現貨',
        assets0203:'秒合約',
        account01: '總覽',
        account02: '賬戶與安全',
        account03: '跟單管理',
        account04: '帶單管理',
        loginOut: '登出'
    },
    foote:{
        test: 'aaaa是全球領先的數字金融服務平台，通過一站式數字投資服務、多樣化資產配置工具、創新型投顧及全網領先的流動性，為全球用戶構建安全、智能、公平的數字資產投資環境。aaaa核心團隊來自傳統金融證券行業、微軟等頂級互聯網公司等加密資產企業，具備豐富的傳統金融交易、頂級加密資產交易平台研發及全球市場運營經驗。成立半年以來，全球用戶數已突破10萬，遍布80多個國家和地區，覆蓋北美、歐洲、亞太等主流區域。',
        tab1Title1: '幫助',
        tab1Test1: '常見問題',
        tab1Test2: '公告中心',
        tab2Title1: '條款',
        tab2Test1: '新手幫助',
        tab2Test2: '隱私條款',
        tab2Test3: '風險提示',
        tab3Title1: '加入我們',
        msg: '風險提示：數字資產交易是一個新興產業，存在光明前景，但正因為屬於新事物，也蘊含巨大風險。特別是在合約交易中，槓桿倍數既同步擴大了盈利也同步放大了風險，請您務必對此行業、合約交易模式、交易規則等相關知識有充分了解後再參與。並且我們強烈建議您，在自身能夠承擔的風險範圍內進行交易。交易有風險，入市須謹慎！',
        banquan: '版權',
        banquan2: '保留所有權利',
        aboutUs: '關於我們',
        Platform:'平台介紹'
    },
    home:{
        bannerTitle: '買幣交易上aaaa',
        bannerTest: '全球領先社交型數字貨幣交易所',
        changUpRank: '漲幅榜',
        changDownRank: '跌幅榜',
        whyTitle: '為什麼選擇我們',
        indexTitle1: '安全保障',
        indexTest1: '全球去中心化部署，無系統風險',
        indexTitle2: '使命願景',
        indexTest2: '讓用戶無憂便捷享受加密貨幣項目增值紅利',
        indexTitle3: '智能合約',
        indexTest3: '多空雙向，快捷下單，百倍槓桿',
        indexTitle4: '達人跟隨',
        indexTest4: '高成功率帶單達人一鍵跟隨',
        indexTitle5: '實時討論',
        indexTest5: '支持即時聊天交流共同成長',
        indexTitle6: '趣味交易',
        indexTest6: '全球首款數字貨幣指數趣味競猜',
        indexTitle7: '幣幣多樣',
        indexTest7: '300+項目快速上幣把握造富神話',
        indexTitle8: '生態系統',
        indexTest8: '打造交易所、錢包、鏈上生態一站式服務',
        downLoadTitle: '隨時隨地開啟交易',
        downLoadTest: '覆蓋iOS、Android、Google Play多個平台，隨時隨地掌上交易',
        downLoadCode: '掃碼下載',
        downLoadIosAnd: 'IOS和Android',
        // :'监管许可',
        // :'aaaa 是在美国FinCEN（美国财政部下设机构金融犯罪执法局），加拿大FINTRAC（加拿大金融交易和报告分析中心）以及美国SEC(美国证券交易委员会)监管下，依法合规注册经营的金融及货币服务企业。aaaa将根据拥有金融许可证，遵守其运营所在的每个司法管辖区的所有适用法律和法规。'，
        // :'美国',
        // :'加拿大',
        // :'监管中',
        // :'监管证号',
        // :'发证日期',
        // :'美国 验真网址',
        // :'加拿大 验真网址'
    },
    contract:{
        Change: '24H漲跌',
        High: '24H高',
        Low: '24H低',
        Volume24: '24H成交量',
        Market: '市價交易',
        LimitPrice: '限價交易',
        BestPrice: '以當前最優價格交易',
        EnterPrice: '請輸入價格',
        Amount: '幣數',
        Margin: '保證金',
        Available: '可用餘額',
        Leverage: '槓桿',
        Fee: '手續費',
        Buy: '買入',
        Long: '做多',
        Sell: '賣出',
        Short: '做空',
        Assets: '資產',
        ContractAssets: '合約資產',
        Deposit: '立即充幣',
        Login: '登錄交易賬戶',
        Register: '註冊交易賬戶',
        Instrument: '交易品種',
        Minimum: '最低手數',
        Maximum: '最高手數',
        TradingTime: '交易時間',
        TradingLeverage: '交易槓桿',
        FeeRate: '費率',
        Introduction: '介紹',
        IssuanceDate: '發行時間',
        TotalIssuance: '發行總量',
        CirculatingSupply: '流通總量',
        Crowdfunding: '眾籌價格',
        Whitepaper: '白皮書',
        Official: '官網',
        Blockchain: '區塊鏈查詢',
        Positions: '持倉',
        OpenOrder: '掛單委託',
        TradeHistory: '歷史成交',
        ProfitLoss: '盈虧',
        CloseAll: '一鍵平倉',
        Contract: '合約',
        LotSize: '手數',
        OpeningPrice: '開倉價',
        CurrentPrice: '現價',
        TakeProfi: '止盈價',
        StopLoss: '止損價',
        OrderPL: '訂單盈虧',
        OpeningTime: '開倉時間',
        OrderID: '訂單號',
        Action: '操作',
        Settings: '設置',
        ClosePosition: '平倉',
        Delete: '刪除',
        Status: '狀態',
        EnterCurrency: '請輸入幣種名稱',
        saveStopLoss: '設置止盈止損',
        live:'實盤',
        demo:'模擬盤'
    },
    SpotGoods:{
        pricelimit: '限價',
        marketprice: '市價',
        Price: '價格',
        MarketPrice: '市場價格',
        PleaseEnter: '請輸入',
        Quantity: '數量',
        Total: '總額',
        Or: '或',
        OpenOrders: '委託訂單',
        Fee: '費用',
        QuantityAcc: '數量累計',
        LatestTrade: '最新成交',
        Time: '時間',
        NowOrders: '當前委託',
        AllOrders: '全部委託',
        OrderHistory: '歷史委託',
        Date: '日期',
        TradingPair: '交易對',
        OrderType: '委託類型',
        MarketOrder: '市價委託',
        LimitOrder: '限價委託',
        Direction: '方向',
        OrderPrice: '委託價',
        OrderQuantity: '委託量',
        OrderAmoun: '委託金額'
    },
    Binary:{
        GameRules: '玩法规则',
        Trading: '交易中',
        PositionClosed: '已平倉',
        BuyLong: '買漲',
        BuyShort: '買跌',
        OrderPeriod: '委託周期',
        TradingAmount: '交易金額',
        Estimated: '預計平倉時間',
        ClosingTime: '平倉時間',
        PlaceBet: '下單',
        Amount: '金額',
        Balance: '賬戶余額',
        Odds: '賠率',
        Profit: '預計盈利',
        Bullish: '看漲',
        Bearish: '看跌',
        countTime: '倒计时',
        customInp:'自定義金額',
    },
    follow:{
        Ranking: '交易员榜單',
        Updates: '數據每兩小時更新一次',
        SearchKey: '搜索所有交易者',
        Search: '搜索',
        Manage: '管理當前跟隨',
        Details: '詳情',
        test1: '公式 = 帶單訂單【盈虧/總保證金】* 100%',
        title1: '近30天帶單收益率',
        test2: '公式 = 自主下單訂單【盈利訂單筆數/總訂單筆數】 * 100%',
        title2: '近30天交易勝率',
        title3: '當前跟隨人數',
        title4: '近30天帶單收益',
        test5: '近30天總 (自主下單) 訂單的筆數',
        title5: '近30天交易筆數',
        test6: '從註冊日起算',
        title6: '交易天數',
        Unfollow: '去跟隨',
        Followed: '已跟隨',
        EditCopying: '編輯跟單',
        CopyingCurrency: '跟單幣種',
        AmountCopying: '金額跟單',
        CopyingAmount: '跟單金額',
        ContractTrading: '合約交易對',
        LotSizeRatio: '手數比例',
        StartCopying: '立即跟隨',
        CancelCopying: '取消跟單',
        title7: '近30天資金回撤率',
        test7: '近30天內資產虧損比例(當日虧損資產與前一日資產的佔比,出現過最大虧損的比例)',
        BackLobby: '返回大廳',
        TradeHistory: '歷史持倉',
        ClosingPrice: '平倉價',
        info: '個人簡介',
        CumulativeCopying: '累計跟單金額',
        TotalCopying: '跟單總收益',
        Discover: '發現高手',
        CopyOrders: '我要帶單',
        CurrentFollowers: '當前跟隨',
        Avatar: '頭像',
        TraderName: '交易員名稱',
        MaximumPosition: '最大持倉金額',
        ProfitLoss: '跟單盈虧',
        Edit: '編輯',
        TodayProfit: '今日收益',
        Past7Days: '近7天總收益',
        Accumulated: '累計總收益',
        ProfitDetails: '收益明細',
        Followers: '跟隨者',
        Profit: '收益',
        FollowerAvatar: '跟隨者頭像',
        FollowerNickname: '跟隨者昵稱',
        CopyingTime: '跟隨時間',
        Notice: '提示',
        NoticeTest1: '您還沒有成為交易員，暫時無法操作！',
        NoticeTest2: '審核中，請耐心等待！',
        Cancel: '取消',
        ApplyNow: '去申請',
        ApplyTrader: '申請交易員',
        Nickname: '昵稱 (必填項)',
        Phone: '手機號 (必填項)',
        Email: '郵箱 (選填項)',
        test1: '提交申請後我們的工作人員會儘快與您取得聯繫，請按照要求提供相應的申請資料',
        test2: '注意稽核期間請確保不存在以下情況，否則將導致稽核失敗：持有倉位、當前委託、當前計劃、有跟隨交易員',
        Apply: '申請'
    },
    account:{
        Overview: '總覽',
        Security: '安全',
        UserID: '用戶ID',
        Welcome: '歡迎來到aaaa!',
        Welcometest: '只需再執行，您就可以開始了',
        Registration: '賬戶註冊',
        SecurityVerification: '安全認證',
        GotoBind: '去綁定',
        QuickBuy: '快捷買幣',
        AssetDetails: '資產詳情',
        Deposit: '充幣',
        Withdraw: '提幣',
        Transfer: '劃轉',
        TransferList: '劃轉歷史',
        type: '類型',
        SpotAccount: '現貨賬戶',
        FuturesAccount: '合約賬戶',
        HideAsset: '隱藏資產估值',
        Available: '可用(USDT)餘額',
        checkdetails: '詳細的資產數量請去資產明細中查看',
        Recommendations: '安全推薦',
        PasswordSet: '密碼設置',
        Change: '更改',
        FundPassword: '資金密碼',
        GotoSet: '去設置',
        AddressManagement: '提幣地址管理',
        GotoAdd: '去添加',
        Verification: '身份認證',
        GotoVerify: '去認證',
        LatestNews: '最新資訊',
        SafetyTitle: '雙重身份驗證',
        SafetyTest: '為了保護您的賬戶，建議至少完成一個實名身份驗證',
        SafetyText1: '用於管理您的賬戶登錄密碼',
        SafetyText2: '用於管理您的賬戶資金密碼',
        NotSet: '未設置',
        Set: '已設置',
        SafetyText3: '保護您的交易和賬戶安全',
        SafetyText4: '確保提幣地址正確無誤',
        Manage: '管理',
        InvitationLink: '邀請鏈接',
        copyInvitationLink: '複製邀請鏈接',
        InvitationCode: '邀請碼',
        copyInvitationCode: '複製邀請碼',
        enterInvitationCode: '請輸入邀請碼'
    },
    finance:{
        Protocol: '主網絡(協議)',
        DepositAddress: '充幣地址',
        QRCode: '充幣二維碼',
        DepositTest1: '單筆充值不得低於',
        DepositTest2: '，小於此金額將不會成功',
        DepositTest3: '每次充值前需要前往此頁面複製地址，確保地址準確無誤，錯誤地址會導致充幣失敗。',
        DepositTest4: '請勿向上述地址充值任何非',
        DepositTest5: '資產，否則資產將不可找回。',
        WithdrawalAddress: '提幣地址',
        AddressManagement: '地址管理',
        Memo: '備註',
        WithdrawalAmount: '提幣數量',
        AvailableBalance: '可用額度',
        Withdraw: '提現',
        WithdrawTest1: '請確認目標提幣地址是否存在且被激活，否則將會導致提幣失敗，且資產不可找回。',
        WithdrawTest2: '如有地址標籤，請務必仔細核對地址標籤，否則將造成資產損失並不可找回。',
        WithdrawTest3: '為保障資金安全，當您賬戶安全策略變更、密碼修改，我們會對提幣進行人工審核，請耐心等待工作人員電話或郵件聯繫。',
        WithdrawTest4: '請務必確認電腦及瀏覽器安全，防止信息被篡改或洩露。',
        WithdrawTest5: '提幣時間(UTC+8)：週一到週日的9:00-22:00。',
        AvailableAssets: '可用資產',
        GamblingAccount: '秒合約賬戶',
        notpassword: '您還沒有設置資金密碼，暫時無法操作！',
        Back: '返回',
        AddAddress: '新增地址',
        SelectCurrency: '選擇幣種',
        Confirm: '確定',
        from: '從',
        to: '到'
    },
    assets:{
        ContractPositions: '合約持倉',
        DepositDetails: '充值明細',
        WithdrawalHistory: '提幣歷史',
        WithdrawalRecords: '充值提現記錄',
        TotalEquivalent: '所有現貨換算(USDT)總額',
        AvailableBalance: '現貨可用(USDT)',
        HideSmall: '隱藏小額資產',
        Equivalent: '折合',
        FrozenAmount: '凍結金額',
        DepositType: '充值類型',
        DepositAmount: '充值金額',
        DepositCurrency: '充值幣種',
        DepositTime: '充值時間',
        WithdrawalType: '提幣類型',
        WithdrawalAmount: '提幣金額',
        WithdrawalCurrency: '提幣幣種',
        WithdrawalTime: '提幣時間'
    },
    fastChange:{
        Sell: '賣出',
        ReferencePrice: '參考價格',
        Buy: '買入',
        Quantity: '可兌換數量',
        PleaseEnter: '請輸入欲兌換數量',
        Exchange: '兌換',
        Trading: '小額兌換 ● 大宗交易',
        TradingTest: 'BTC、ETH、數字貨幣快捷兌換',
        Step1: '第一步',
        Step1Test: '選擇需要兌換的幣種',
        Step2: '第二步',
        Step2Test: '輸入需要兌換的數量',
        Step3: '第三步',
        Step3Test: '開始兌換'
    },
    Password:{
        ChangePassword: '修改登錄密碼',
        OldPassword: '舊登錄密碼',
        enterOldPassword: '請輸入舊的登錄密碼',
        NewLogin: '新的登錄密碼',
        enterNewLogin: '請輸入登錄密碼',
        againenterNewLogin: '請再次輸入登錄密碼',
        FundPassword: '設置資金密碼',
        fundpassword: '請輸入資金密碼',
        againfundpassword: '請再次輸入資金密碼',
        Name: '姓名',
        Number: '身份證號',
        photos: '請上傳證件照，第一張為正面，第二張為反面',
        photos2:'請上傳您的證件照片',
        Upload: '上傳',
        wait: '審核中，請等待！',
        categoryType:'證件類型',
        category1:'身份證',
        category2:'護照',
        category3:"駕駛執照"
    },
    add:{
        Copied:"複製了！",
        test29: "模擬交易結束，實盤交易等你開啟！",
        test30: "充值現享交易資金獎勵",
        test31: "立即獲得更多支持與工具",
        test32: "充值開啟您的盈利之旅",
        DemoTrade:"模擬交易",
        Progress: "進行中",
        Requested: "申請退款中",
        Refunded: "已退款",
        Completed: "已結束",
        test1: "產品概述",
        test2: "（aaaa）財務管理服務（FMS）是（aaaa）設計的一款產品，旨在協助有閒置數字資產的用戶以低門檻獲利。入門簡單。 （aaaa）財務管理服務（FMS）提供不同期限的選擇，讓用戶通過向槓桿交易用戶在借貸市場出借來獲利；定期簡單賺幣涉及鎖定幣種以獲取PoS收益或項目激勵。",
        test3: "*請注意：過去的收益不代表未來的收益。我們不承諾在一定期限內以貨幣、實物、股權等方式還本付息，也不承諾提供任何其他形式的財產性收益回報。",
        test4: "安全可靠",
        test5: "**（aaaa）擁有嚴格的風險控制系統、領先的安全措施和先進的基礎設施，能夠充分保障用戶資產安全。",
        test6: "靈活申購和贖回",
        test7: "24小時支持隨時申購和贖回。活期簡單賺幣的贖回是即時的，而定期簡單賺幣的贖回需時最多30分鐘。",
        test8: "定期（aaaa）財務管理服務（FMS）",
        test9: "定期（aaaa）**財務管理服務（FMS）有多個收入來源，例如通過抵押獲得的獎勵以及基於權益證明（PoS）機制的獎勵，還有平台獎勵。",
        test10: "什麼是參考年化收益率？",
        test11: "參考年化收益率是您訂閱的產品預期年化收益率，僅供參考。",
        test12: "預期收益如何計算？",
        test13: "預期收益是根據您的訂閱數量和產品的參考年化收益率計算的，提供每日預期收益的估算，僅供參考。",
        test14: "何時開始計息？",
        test15: "收益從申購次日的12:00 (UTC+8) 開始計算。",
        test16: "何時分配收益？",
        test17: "收益從計息次日的12:00 (UTC+8) 開始分配。",
        test18: "為什麼不同產品有不同的贖回期限？",
        test19: "贖回天數是指從您確認贖回，到實際贖回到賬需要等待的時間。定期簡單賺幣的資產抵押在鏈上，從鏈上贖回需要一定的時間，每一條公鏈的運行機制不同，所以贖回天數不同。",
        test20: "定期產品到期後，我需要手動贖回嗎？",
        test21: "這將視情況而定。所有定期產品將默認啟用自動續投，產品到期後將會自動續投相同期限。 自動續投啟用後，贖回需要手動進行。自動續投功能默認啟用，如需關閉，請前往訂單詳情頁面。",
        test22: "在贖回等待的過程中我還能獲得收益嗎？",
        test23: "訂單一旦確認贖回則停止計息，在贖回等待的過程中無法獲得收益。",
        test24: "如何選擇最佳的定期**（aaaa）**財務管理服務（FMS）產品？",
        test25: "您可以通過比較不同數字資產，不同產品的期限、歷史收益等，在我們所提供的所有簡單賺幣產品中，選擇適合您的產品。我們僅提供資產抵押、獎勵領取和發放服務，不為您提供任何投資建議。在做出投資決定前，您應該充分了解本產品的性質和風險以及其他相關情況，並根據您的投資經驗、財務狀況、投資目標、投資期限、承擔投資風險的意願和能力以及其他相關情況進行獨立地判斷慎重考慮是否購買本產品，並為您自己的決策負責。",
        test26: "如何賺取收益",
        test27: "產品優勢",
        test28: "常見問題",
        assets: "理財資產",
        Redemption: "贖回",
        Subscribe: "申購",
        SubscribeType: "申購類型",
        SubscribeNum: "申購金額",
        Historical: "累計利息",
        SubscribeTime: "申購時間",
        RedemptionTime: "贖回時間",
        RedemptionConfirm: "確認是否贖回",
        LockedSavings: "定期投資",
        test33: "理財",
        test34: "了解數字貨幣投資的核心概念和風險管理策略，從容面對市場波動，實現財務目標。立即加入我們，開啟數字貨幣投資之旅！",
        test35: "掌握數字貨幣投資的精髓，解讀市場趨勢，挖掘潛在機會，實現財富增值。無需門檻，低風險，高回報。開始您的數字貨幣理財之旅，邁向財富的新高度！",
        test36: "產品列表",
        test37: "申購列表",
        test38: "年利率：",
        test39: "天數：",
        test40: "起購金額：",
        test41: "最大金額：",
        test42: "起息日：",
        test43: "計息結束：",
        test44: "最大",
        test45: "可用資產：",
        test46: "預估盈利：",
        test47: "我已閱讀並同意",
        test48: "產品概述",
        test49:"天",
        Agreement: "理財購買協議",
        test50:"充值金额",
	    test51:"上传转账凭证",
        EnterPrice: "請輸入金額",
    }
}