<template>
  <header class="MainLayout_header">
    <div class="MainHeader_container">
      <div class="Header_navBox">
        <router-link to="/">
          <img :src="configInfo?.logo" style="height:29px;" />
        </router-link>
        <nav class="Header_nav">
          <router-link class="navLink" to="/contract">{{ t('topHeader.nav01') }}</router-link>
          <router-link class="navLink" to="/SpotGoods">{{ t('topHeader.nav02') }}</router-link>
          <router-link class="navLink" to="/Binary">{{ t('topHeader.nav03') }}</router-link>
          <!-- <router-link class="navLink" to="/follow">{{ t('topHeader.nav04') }}</router-link> -->
          <!-- <router-link class="navLink" to="/new/newList?cid=22">{{ t('topHeader.nav05') }}</router-link> -->
          <router-link class="navLink" to="/fastChange">{{ t('topHeader.nav06') }}</router-link>
          <router-link class="navLink" to="/financialList">{{t('add.test33')}}</router-link>
          <router-link class="navLink" to="/Binary?type=simulate" v-if="IsLogin && userInfoa.is_open_simulate == 1">{{t('add.DemoTrade')}}</router-link>
          <p class="navLink" v-else @click="DepositShowClick()">{{t('add.DemoTrade')}}</p>
        </nav>
      </div>
      <section class="HeaderRightBox">
        <div class="header_account" v-if="IsLogin">
          <p class="test">{{ t('topHeader.assetsHome') }}</p>
          <span class="IconTest"></span>
          <div class="ToolTipBox AssetsToolTipBox">
            <div class="accountList">
              <router-link class="list" to="/assets/account">{{ t('topHeader.assets01') }}</router-link>
              <router-link class="list" to="/assets/recharge">{{ t('topHeader.assets02') }}</router-link>
              <router-link class="list" to="/assets/Spot">{{ t('topHeader.assets03') }}</router-link>
            </div>
          </div>
        </div>
        <div class="header_account" v-if="IsLogin">
          <p class="test">{{ t('topHeader.assetsHome02') }}</p>
          <span class="IconTest"></span>
          <div class="ToolTipBox OrderToolTipBox">
            <div class="accountList">
              <router-link class="list" to="/assets/contract">{{ t('topHeader.assets0201') }}</router-link>
              <router-link class="list" to="/assets/Spot">{{ t('topHeader.assets0202') }}</router-link>
              <router-link class="list" to="/assets/micro">{{ t('topHeader.assets0203') }}</router-link>
            </div>
          </div>
        </div>
        <div class="header_account" v-if="IsLogin">
          <span class="icon"></span>
          <div class="ToolTipBox AccountToolTipBox">
            <div class="accountInfo">
              <p class="name">{{ info?.nickname || info?.email }}</p>
              <div class="infoBox">
                <div class="left">{{ info?.uid }}</div>
                <div class="right" :class="info?.auth_status == 2 ? '' : 'cur'">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10px"
                    height="10px" viewBox="0 0 10 10" version="1.1" class="injected-svg">
                    <title></title>
                    <g id="Icons-/-Systems-/-User-/-High-Verify" stroke="none" stroke-width="1" fill-rule="evenodd">
                      <polygon id="星形"
                        points="5 9.04622396 3.45491503 9.75528258 2.62168923 8.27346395 0.954915028 7.93892626 1.15181234 6.25035197 0 5 1.15181234 3.74964803 0.954915028 2.06107374 2.62168923 1.72653605 3.45491503 0.244717419 5 0.953776042 6.54508497 0.244717419 7.37831077 1.72653605 9.04508497 2.06107374 8.84818766 3.74964803 10 5 8.84818766 6.25035197 9.04508497 7.93892626 7.37831077 8.27346395 6.54508497 9.75528258">
                      </polygon>
                      <path
                        d="M6.77683715,3.47764018 L4.28920896,5.8297765 L3.21661887,4.85040499 C3.01165203,4.663242 2.68624973,4.66670279 2.48577961,4.85674978 L2.4797317,4.86256721 C2.27964502,5.05848106 2.28539664,5.37043037 2.49243755,5.55947207 L3.90590702,6.85011721 C3.97207101,6.91071907 4.05410891,6.95356837 4.14358839,6.97426081 C4.32523502,7.03202309 4.53387942,6.99248765 4.67859367,6.85565448 L7.51400319,4.1746604 C7.71752341,3.98222382 7.71752341,3.67022508 7.51400319,3.47780498 C7.31030868,3.28520359 6.98035737,3.28520359 6.77683715,3.47764018 Z"
                        id="路径" fill="#141416"></path>
                    </g>
                  </svg>
                  <p class="test" v-if="info?.auth_status == 2">{{ t('total.certified') }}</p>
                  <router-link to="/account/Authentication" v-else><p class="test">{{ t('total.UnCertified') }}</p></router-link>
                  
                </div>
              </div>
            </div>
            <div class="accountList">
              <router-link class="list" to="/account/accountInfo">{{ t('topHeader.account01') }}</router-link>
              <router-link class="list" to="/account/Safety">{{ t('topHeader.account02') }}</router-link>
              <!-- <router-link class="list" to="/account/followManagement">{{ t('topHeader.account03') }}</router-link>
              <router-link class="list" to="/account/bringManagement">{{ t('topHeader.account04') }}</router-link> -->
              <p class="list" @click="getLoginOut">{{ t('topHeader.loginOut') }}</p>
            </div>
          </div>
        </div>
        <div class="loginBox" v-else>
          <router-link class="login" to="/login">{{ t('total.login') }}</router-link>
          <router-link class="register" to="/register">{{ t('total.register') }}</router-link>
        </div>
        <!-- <div>
          <div class="Tooltip_container">
            <figure><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48px"
                height="64px" viewbox="0 0 48 64" version="1.1">
                <title></title>
                <g id="Icons-/-Systems-/-Header-/-Download" stroke="none" stroke-width="1" fill="none"
                  fill-rule="evenodd">
                  <path
                    d="M29,23 C30.1045695,23 31,23.8954305 31,25 L31,39 C31,40.1045695 30.1045695,41 29,41 L19,41 C17.8954305,41 17,40.1045695 17,39 L17,25 C17,23.8954305 17.8954305,23 19,23 L29,23 Z M30,37.549 L18,37.549 L18,39 C18,39.5128358 18.3860402,39.9355072 18.8833789,39.9932723 L19,40 L29,40 C29.5128358,40 29.9355072,39.6139598 29.9932723,39.1166211 L30,39 L30,37.549 Z M29,24 L19,24 C18.4871642,24 18.0644928,24.3860402 18.0067277,24.8833789 L18,25 L18,36.549 L30,36.549 L30,25 C30,24.4871642 29.6139598,24.0644928 29.1166211,24.0067277 L29,24 Z M24.0535981,27 C24.2613522,27 24.4341408,27.1518717 24.4699734,27.3521482 L24.4767916,27.4293188 L24.476298,32.7382757 L26.3316378,31.4017873 C26.5221573,31.2644434 26.7863545,31.3097863 26.9217388,31.5030634 C27.0420804,31.6748652 27.0201364,31.9057242 26.8799663,32.0513662 L26.8219077,32.1017056 L24.2987331,33.9206403 C24.1750361,34.0098123 24.0156457,34.0239534 23.8805083,33.9624704 L23.8152382,33.9254241 L21.1848746,32.1064894 C20.9917507,31.9729415 20.9419103,31.7058556 21.0735528,31.5099364 C21.1905684,31.3357861 21.4115901,31.2764859 21.5948984,31.3592531 L21.6615945,31.3970034 L23.6307574,32.7580243 L23.6304046,27.4293188 C23.6304046,27.1922126 23.8198748,27 24.0535981,27 Z"
                    id="Combined-Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
                </g>
              </svg></figure>
            <div class="TooltipHover Header_downLoad">
              <div class="MainHeader_icons">
                <a :href="APPStore" rel="noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="31px"
                    height="30px" viewbox="0 0 31 30" version="1.1" class="injected-svg"
                    data-src="/static/media/ico-ios-white.9d35ce75.svg">
                    <title>Icons / Systems / Other / Download / App Store White</title>
                    <g id="Icons-/-Systems-/-Other-/-Download-/-App-Store-White" stroke="none" stroke-width="1"
                      fill="none" fill-rule="evenodd">
                      <path
                        d="M21.9886464,15.2948452 C22.013692,13.3473639 23.0345368,11.5484355 24.6935992,10.5281398 C23.6419635,9.02631703 21.9437843,8.1084424 20.1112093,8.05120089 C18.1824558,7.84882408 16.3146471,9.20460161 15.3322302,9.20460161 C14.3308211,9.20460161 12.8182589,8.07109258 11.1897853,8.10442174 C9.04656132,8.17381306 7.09696388,9.3629945 6.05444876,11.23694 C3.83409152,15.0810095 5.49008548,20.7303559 7.61636982,23.8374806 C8.68046954,25.3595124 9.92507579,27.0583476 11.5516449,26.9984609 C13.1440383,26.9319539 13.7387787,25.9837518 15.6608664,25.9837518 C17.5658133,25.9837518 18.1235214,26.9984609 19.784266,26.9604551 C21.492518,26.9319518 22.5689971,25.4317574 23.5960645,23.8963938 C24.3603921,22.812085 24.9484879,21.6138222 25.3385979,20.3458326 C23.3091162,19.4873791 21.9897045,17.4986754 21.9875884,15.2950357 L21.9885385,15.2950357 L21.9886464,15.2948452 Z M18.8513668,6.00754113 C19.7831339,4.88905667 20.2421982,3.45145869 20.1312068,2 C18.7076811,2.14958976 17.3928191,2.83010782 16.4485986,3.90589921 C15.5163236,4.96682472 15.0440123,6.35490509 15.1355353,7.76425265 C16.5778946,7.77912698 17.9474587,7.13164393 18.8513668,6.00754113 L18.8513668,6.00754113 Z"
                        id="Shape" fill="#E6E8EC" fill-rule="nonzero"></path>
                    </g>
                  </svg> {{ t('topHeader.APPStore') }}</a>
                <a :href="GooglePlay" rel="noreferrer"><svg xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" width="31px" height="30px" viewbox="0 0 31 30"
                    version="1.1" class="injected-svg" data-src="/static/media/ico-googleplay-white.ab7918be.svg">
                    <title>Icons / Systems / Other / Download / Google Play White</title>
                    <g id="Icons-/-Systems-/-Other-/-Download-/-Google-Play-White" stroke="none" stroke-width="1"
                      fill="none" fill-rule="evenodd">
                      <path
                        d="M24.0301572,12.1227676 L21.4573842,10.6359213 L18.3570206,14.4434285 L21.5164154,18.3296439 L24.0289274,16.8784622 C25.0779596,16.2733932 25.653513,15.4260507 25.653513,14.4987702 C25.653513,13.5714897 25.0791894,12.7290664 24.0301572,12.1227676 Z M7.36124479,26 L16.7705593,14.4446583 L7.48053684,3.01475778 C7.45102128,3.01352796 7.42027591,3 7.39076035,3 C5.96048551,3 5,4.16217517 5,5.89375468 L5,23.1111646 C5.00245963,24.8267565 5.95064699,25.9827826 7.36124479,26 Z M20.3763769,10.0160945 L9.7901294,3.90145439 L17.5650198,13.4681852 L20.3763769,10.0160945 Z M17.56379,15.417442 L9.58352048,25.2202973 L20.4366378,18.9519303 L17.56379,15.417442 Z"
                        id="Shape" fill="#E6E8EC" fill-rule="nonzero"></path>
                    </g>
                  </svg> {{ t('topHeader.GooglePlay') }}</a>
                <a :href="Android" rel="noreferrer"><svg xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" width="31px" height="30px" viewbox="0 0 31 30"
                    version="1.1" class="injected-svg" data-src="/static/media/ico-android-white.75e843e5.svg">
                    <title>Icons / Systems / Other / Download / Android White</title>
                    <g id="Icons-/-Systems-/-Other-/-Download-/-Android-White" stroke="none" stroke-width="1" fill="none"
                      fill-rule="evenodd">
                      <path
                        d="M24.8905496,6.24781485 C25.0185711,6.0155354 25.3095602,5.9316234 25.5404919,6.06039216 C25.7714235,6.18916092 25.8548486,6.48184832 25.7268271,6.71412777 L25.7268271,6.71412777 L23.3543627,11.0192184 C27.9585229,13.3019604 30.9090487,17.9805905 31,23.1428571 L31,23.1428571 L1,23.1428571 C1.09095015,17.9806034 4.04146589,13.3019837 8.64561259,11.0192432 L8.64561259,11.0192432 L6.27307407,6.71412777 C6.14505251,6.48184832 6.22847761,6.18916094 6.45940926,6.06039219 C6.6903409,5.93162343 6.98132998,6.01553542 7.10935157,6.24781485 L7.10935157,6.24781485 L9.50816511,10.6006158 C13.6459744,8.7760711 18.3540008,8.7760711 22.4918102,10.6006158 L22.4918102,10.6006158 Z M9.57142857,16 C8.78245036,16 8.14285714,16.6395932 8.14285714,17.4285714 C8.14285714,18.2175496 8.78245036,18.8571429 9.57142857,18.8571429 C10.3600397,18.8562574 10.9991145,18.2171826 11,17.4285714 C11,16.6395932 10.3604068,16 9.57142857,16 Z M23.8571429,16 C23.0681646,16 22.4285714,16.6395932 22.4285714,17.4285714 C22.4285714,18.2175496 23.0681646,18.8571429 23.8571429,18.8571429 C24.645754,18.8562574 25.2848288,18.2171826 25.2857143,17.4285714 C25.2857143,16.6395932 24.6461211,16 23.8571429,16 Z"
                        id="Combined-Shape" fill="#E6E8EC"></path>
                    </g>
                  </svg> {{ t('topHeader.Android') }}</a>
              </div>
              <div class="MainHeader_qrcode">
                <img :src="configInfo?.app_page_qrcode" style="width: 160px;height:160px;" />
                <p>{{ t('topHeader.IOSaAndroid') }}</p>
              </div>
            </div>
          </div>
        </div> -->
        <div><button aria-label="costome services" type="button">
            <a :href="configInfo?.im_url" target="_blank" rel="noopener noreferrer">
              <figure><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48px"
                  height="64px" viewbox="0 0 48 64" version="1.1" class="injected-svg"
                  data-src="/static/media/Customer.49ca598f.svg">
                  <title></title>
                  <g id="Icons-/-Systems-/-Header-/-Customer" stroke="none" stroke-width="1" fill="none"
                    fill-rule="evenodd">
                    <path
                      d="M24,23.5 C28.5267505,23.5 32.2270195,27.0385877 32.4855686,31.5005248 L32.5052635,31.5 L32.5052635,37.9772949 C32.3512512,39.7059006 31.2583823,40.5968018 29.4052635,40.5968018 C27.5362763,40.5968018 26.4972641,39.6812273 26.5,37.9764909 L26.5,35.2647705 C26.5,32.8367564 27.5247034,31.5 29.5026341,31.5 L31.483625,31.5004142 C31.2265344,27.5912025 27.9742617,24.5 24,24.5 C20.0256948,24.5 16.7733944,27.5912701 16.5163666,31.5005425 L18.5026293,31.5 C20.4805601,31.5 21.5052635,32.8367564 21.5052635,35.2647705 L21.5052635,35.2647705 L21.5052635,37.9764909 C21.5079994,39.6812273 20.4689871,40.5968018 18.6,40.5968018 C16.7468811,40.5968018 15.6540123,39.7059006 15.5,37.9772949 L15.5,37.9772949 L15.5,31.5 L15.5144314,31.5005248 C15.7729805,27.0385877 19.4732495,23.5 24,23.5 Z M18.5026293,32.5 L16.5,32.5 L16.4979751,37.9323415 C16.6010984,39.0746983 17.2415629,39.5968018 18.6,39.5968018 C19.9425688,39.5968018 20.5070624,39.0993717 20.505263,37.9772949 L20.505263,37.9772949 L20.505263,35.2647705 C20.505263,33.3397572 19.8615378,32.5 18.5026293,32.5 L18.5026293,32.5 Z M31.5052635,32.5 L29.5026341,32.5 C28.1437257,32.5 27.5000005,33.3397572 27.5000005,35.2647705 L27.5000005,37.9772949 C27.498201,39.0993717 28.0626946,39.5968018 29.4052635,39.5968018 C30.7637006,39.5968018 31.4041651,39.0746983 31.5072884,37.9323415 L31.5052635,32.5 Z"
                      id="Combined-Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
                  </g>
                </svg></figure>
            </a>
          </button></div>
        <div>
          <div class="Tooltip_container MainHeader_navDropDown">
            <button class="Button_default" type="button">{{ languageKey }}<span
                class="MainHeader_triangle"></span></button>
            <div class="TooltipHover MainHeader_languageSelector">
              <button class="navLink" type="button" @click="toggle(item.code, item.name)" v-for="(item, index) in Listlang"
                :key="index">{{ item.name }}</button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </header>

  <div class="SetTingWrap DepositWrap" v-show="DepositShow">
    <div class="box">
      <div class="setTingBox">
        <div class="popTitle">
          <p class="title">{{ t('follow.Notice') }}</p>
          <img src="@/assets/close.png" class="closed" @click="DepositShow = false" />
        </div>

        <div class="popMain">
          <p class="title">{{ t('add.test29') }}</p>
          <!-- <p class="title">{{ t('add.test30') }}</p> -->
          <p class="title">{{ t('add.test31') }}</p>
          <div class="btnBox">
            <router-link class="btn" to="/finance/recharge">{{ t('add.test32') }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue';
import { useI18n } from "vue-i18n";
import { loginOut } from "@/api/login";
import { message } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { kefuSetting, langList } from "@/api/home";
import { userInfo } from "@/api/account";
import { config } from "@/api/index";
export default {
  name: 'header',
  props: {
    msg: String
  },
  setup() {
    const $router = useRouter();
    const { t } = useI18n()
    const { locale: i18nLanguage } = useI18n()
    const reload = inject("reload");
    const info = ref({})
    const configInfo = ref({})
    const downloadUrl = ref({})
    const Android = ref(null)
    const GooglePlay = ref(null)
    const APPStore = ref(null)
    const kefu = ref(null)
    const IsLogin = ref('')
    const languageKey = ref(null)

    const Listlang = ref([])
    const toggle = (key, name) => {
      console.log(key, name)
      i18nLanguage.value = key  // zh en
      languageKey.value = name
      localStorage.setItem('languageKey', name)
      localStorage.setItem('App-Language', key)
      reload();
    }

    const userInfoa = ref({})
    const userInfoGet = () => {
      userInfo({}).then((res) => {
        if (res.code == 1) {
          userInfoa.value = res.data
        } else {
          message.error(res.msg);
        }
      })
    }

    const DepositShow = ref(false)

    const DepositShowClick = () => {
      if (IsLogin.value) {
        DepositShow.value =true
      }else{
          message.error(t('contract.Login'));
      }
    }

    const getLoginOut = () => {
      loginOut({}).then((response) => {
        if (response.code == 1) {
          IsLogin.value = null
          localStorage.removeItem('token')
          message.success(response.msg);
          $router.push('/login')
        } else {
          message.error(response.msg);
        }
      });
    }

    const langListGet = () => {
      langList({}).then((response) => {
        if (response.code == 1) {
          Listlang.value = response.data
        } else {
          message.error(response.msg);
        }
      });
    }

    const kefuSettingGet = () => {
      kefuSetting({ key: 'contract_us_url' }).then((response) => {
        if (response.code == 1) {
          kefu.value = response.data
        } else {
          message.error(response.msg);
        }
      });
    }

    const assetsClick = (item, itema) => {
      localStorage.setItem('assetsSub', item)
      localStorage.setItem('assetsIndex', itema)
    }

    onMounted(() => {
      configInfo.value = JSON.parse(localStorage.getItem('configInfo'))
      if(!configInfo.value?.logo){
        config({}).then((response) => {
          localStorage.setItem('configInfo', JSON.stringify(response.data))
          configInfo.value = response.data
        });
      }
      setTimeout(() => {
        info.value = JSON.parse(localStorage.getItem('info'))
        downloadUrl.value = JSON.parse(localStorage.getItem('downloadUrl'))
        //kefu.value = JSON.stringify(localStorage.getItem('configInfo')).im_url
        if (downloadUrl.value) {
          downloadUrl.value.forEach(item => {
            if (item.type == 2) {
              APPStore.value = item.download_url
            } else if (item.type == 3) {
              GooglePlay.value = item.download_url
            } else if (item.type == 1) {
              Android.value = item.download_url
            }
          });
        }
      }, 1000)


      IsLogin.value = localStorage.getItem('token')
      let language = localStorage.getItem('App-Language')
      languageKey.value = localStorage.getItem('languageKey')
      if (languageKey.value == null || languageKey.value == '简体') {
        languageKey.value = 'English'
      }
      //kefuSettingGet()
      langListGet()
      if (IsLogin.value) {
        userInfoGet()
      }
    })


    return {
      t,
      toggle,
      IsLogin,
      info,
      languageKey,
      configInfo,
      downloadUrl,
      Android,
      GooglePlay,
      APPStore,
      kefu,
      Listlang,
      userInfoa,
      DepositShow,
      userInfoGet,
      getLoginOut,
      kefuSettingGet,
      assetsClick,
      langListGet,
      DepositShowClick
    }
  }
}
</script>

<style scoped lang="scss">
.Button_default {
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-size: inherit;
  font-weight: inherit;
  min-height: 3rem;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow .28s cubic-bezier(.4, 0, .2, 1), opacity 15ms linear 30ms, -webkit-transform .27s cubic-bezier(0, 0, .2, 1) 0;
  transition: box-shadow .28s cubic-bezier(.4, 0, .2, 1), opacity 15ms linear 30ms, transform .27s cubic-bezier(0, 0, .2, 1) 0;
  transition: box-shadow .28s cubic-bezier(.4, 0, .2, 1), opacity 15ms linear 30ms, transform .27s cubic-bezier(0, 0, .2, 1) 0, -webkit-transform .27s cubic-bezier(0, 0, .2, 1) 0;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform, opacity;
  box-shadow: 0 4px 4px 0 transparent, 0 8px 12px 6px transparent;
  border: 2px solid transparent;
  z-index: 0;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 8px;
    background-color: transparent;
    transition: background-color .3s;
  }

  &:hover {
    &:after {
      background-color: rgba(246, 249, 254, .2);
    }
  }

  &:active {
    box-shadow: 0 4px 4px 0 rgba(60, 64, 67, .3), 0 8px 12px 6px rgba(60, 64, 67, .15);
    outline: none;
  }

  &:focus {
    outline: none;
    border: 2px solid rgba(255, 209, 64, .75);
  }

  &:not(:disabled) {
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, .3), 0 4px 8px 3px rgba(60, 64, 67, .15);
  }

  &:not(:disabled):hover {
    box-shadow: 0 2px 3px 0 rgba(60, 64, 67, .3), 0 6px 10px 4px rgba(60, 64, 67, .15);
  }

  &:not(:disabled):focus {
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, .3), 0 4px 8px 3px rgba(60, 64, 67, .15);
  }

  &:not(:disabled):active {
    box-shadow: 0 4px 4px 0 rgba(60, 64, 67, .3), 0 8px 12px 6px rgba(60, 64, 67, .15);
  }

  &:disabled {
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, .3), 0 4px 8px 3px rgba(60, 64, 67, .15);
  }
}

.MainLayout_header {
  z-index: 100;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}




.MainHeader_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  width: 100%;
  height: 4rem;
  background-color: #181a20;
  color: #fff;
  font-weight: 300;

  .Header_navBox {
    display: inherit;
    justify-content: inherit;
    align-items: inherit;

    figure {
      margin: 0 2rem 0 0;
      cursor: pointer;
      width: 74px;
    }
  }

  .Header_nav {
    display: flex;

    &>.navLink {
      padding: .75rem 1.5rem;
      font-size: .875rem;
    }

    .navLink:hover {
      color: #FFD140;
    }
  }

  .HeaderRightBox {
    display: flex;
    justify-content: center;
    align-items: center;

    .header_account {
      display: flex;
      height: 4rem;
      align-items: center;
      margin-left: 5px;
      cursor: pointer;
      fill: #fff;
      position: relative;
      padding: 0 15px;

      .icon {
        display: block;
        width: 18px;
        height: 18px;
        background: url('../assets/account.png') center center no-repeat;
        background-size: 100%;
      }

      .test {
        font-size: 14px;
        color: #fff;
        margin-right: 5px;
      }

      .IconTest {
        display: block;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #777e90;
      }

      .ToolTipBox {
        position: absolute;
        top: 4rem;
        left: -90px;
        background: #23262f;
        width: max-content;
        display: none;

        .accountInfo {
          padding: 20px 0 0 12px;

          .name {
            font-size: 14px;
            font-weight: 400;
            color: #fff;
            width: 170px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .infoBox {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left {
              font-size: 10px;
              font-family: DIN-Regular;
              font-weight: 400;
              color: #777e90;
              margin-right: 18px;
              display: flex;
              align-items: center;
            }

            .right {
              display: flex;
              align-items: center;
              padding: 4px 7px;
              border-top-left-radius: 18px;
              border-bottom-left-radius: 18px;
              background: rgba(255, 209, 64, .1);
              fill: #FFD140;

              .test {
                font-size: 10px;
                font-weight: 400;
                color: #ffd140;
                margin-left: 4px;
              }
            }

            .cur {
              background-color: #777e90;
              fill: #fff;

              .test {
                color: #000;
              }
            }
          }
        }

        .accountList {
          .list {
            display: block;
            width: 100%;
            margin: 0;
            padding: 5px 12px;
            color: #fff;
            height: 50px;
            line-height: 40px;
            font-size: 14px;
            font-weight: 400;

            &:hover {
              background: #313541;
              color: #ffd140;
            }
          }
        }
      }

      .OrderToolTipBox {
        width: 120px;
        left: -30px;
      }

      .AssetsToolTipBox {
        width: 140px;
        left: -30px;
      }

      &:hover {
        .test {
          color: #ffd140;
        }

        .IconTest {
          border-bottom: 5px solid #ffd140;
          border-top: 0;
        }

        .ToolTipBox {
          display: block;
        }
      }
    }

    .loginBox {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .login {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        color: #fff;
        margin-right: 16px;
      }

      .register {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        color: #000;
        background: #ffd140;
        padding: 8px 16px;
        border-radius: 6px;
      }
    }

    figure {
      margin: 0;
      cursor: pointer;
    }

    .Header_downLoad {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 20rem;
      background-color: #23262f;
      padding: .5rem;

      .MainHeader_icons {
        display: flex;
        height: 12rem;
        flex-direction: column;
        align-items: flex-start;

        &>a {
          flex: 1 1;
          display: flex;
          margin: 0;
          align-items: center;
          color: #fff;
          width: max-content;

          &>svg {
            margin-right: .5rem;
          }
        }
      }

      .MainHeader_qrcode {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &>figure {
          margin: 0;
          position: relative;
          width: 10rem;
          height: 10rem;
        }

        &>p {
          margin: .5rem 0;
        }
      }
    }

    .MainHeader_navDropDown {
      .MainHeader_triangle {
        margin-left: .5rem;
        width: 0;
        height: 0;
        transition: border .3s ease;
        border-color: #777e90 transparent transparent;
        border-style: solid;
        border-width: .375rem .375rem 0;
      }

      &:hover {
        .MainHeader_triangle {
          border-width: 0 .375rem .375rem;
          border-color: transparent transparent #777e90;
        }
      }

      &>button {
        box-shadow: none;
        padding: .75rem;
        font-size: .875rem;
      }
    }

    .MainHeader_languageSelector {
      background-color: #23262f;
      min-width: 8rem;
      height: 400px;
      overflow-y: scroll;

      &>button {
        padding: 0;
        display: block;
        width: 100%;
        font-size: .875rem;
      }
    }
  }
}

.Tooltip_container {
  position: relative;
  display: flex;
  justify-content: center;

  .TooltipHover {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    background-color: #23262f;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    -webkit-transform-origin: top;
    transform-origin: top;
    transition: visibility .3s, opacity .3s ease-out, -webkit-transform .3s;
    transition: visibility .3s, transform .3s, opacity .3s ease-out;
    transition: visibility .3s, transform .3s, opacity .3s ease-out, -webkit-transform .3s;
    position: absolute;
    top: calc(100% + 5px);
    z-index: 999;

    &:after {
      position: absolute;
      content: "";
      bottom: 100%;
      left: calc(50% - 5px);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #23262f;
    }
  }

  &:hover {
    .TooltipHover {
      visibility: visible;
      opacity: 1;
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
    }
  }
}

.SetTingWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;

  .box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .setTingBox {
      width: 600px;
      background: #fff;
      border-radius: 4px;

      .popTitle {
        background-color: #f6f6f6;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 0 25px;

        .title {
          color: #101010;
          font-size: 16px;
          font-weight: 500;
        }

        .closed {
          width: 12px;
          height: 12px;
          cursor: pointer;
        }
      }

      .popMain {
        .ContractBox {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 25px;
          margin: 15px auto 0;

          .name {
            margin-right: 10px;
            font-size: 16px;
            font-weight: 500;
            color: #343434;
          }

          .test {
            margin-right: 10px;
            color: #fff;
            padding: 3px 7px;
            border-radius: 3px;
          }

          .upBox {
            background-color: #02c076;
          }

          .downBox {
            background-color: #f84960;
          }
        }

        .inputWrap {
          margin: 15px auto 0;
          padding: 0 25px;
          width: 100%;
          display: flex;
          flex-direction: column;
          font-size: 14px;
          color: #343434;

          .name {
            color: #333;
          }

          .inputBox {
            margin-top: 10px;
            height: 46px !important;
            border: 1px solid #ced4da !important;
            display: flex;
            align-items: center;
            border-radius: 4px;

            .input {
              height: 100%;
              border: none;
              width: 96%;
              margin: 0 auto;
            }
          }
        }

        .title{
          padding:0 30px 10px;
        }

        .btnBox {
          font-size: 16px;
          padding: 25px;
          width: 100%;

          .btn {
            border: 1px solid #ffd140;
            width: 100%;
            height: 50px;
            justify-content: center;
            display: flex;
            text-align: center;
            align-items: center;
            border-radius: 4px;
            cursor: pointer;
            padding: 20px 0;
            color: #fff;
            background: #ffd140;
          }
        }
      }
    }
  }
}

.DepositWrap{
  .box .popMain{
   padding-top: 30px;
   .btnBox .btn{
    color: #000 !important;
   }
  }
}
</style>
