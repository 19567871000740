<template>
  <div id="Home">
    <div class="MainLayout">
      <header-top></header-top>

      <main class="home_page__93UoA">
        <div class="bannerBoxWrap">
          <div class="bannerContainer bannerIntro">
            <div class="bannerImage">
              <img alt="" src="../assets/bg.webp" />
            </div>
            <div class="bannerContent">
              <h1>{{ $testChang(t('home.bannerTitle')) }}</h1>
              <h2>{{ t('home.bannerTest') }}</h2>
              <router-link v-if="!token" class="singUpButton" to="/register">{{ t('total.registerNow') }}</router-link>
              <router-link v-if="token" class="singUpButton" to="/finance/recharge">{{ t('total.buyNow')
              }}</router-link>

              <div class="bannerSwiper">
                <swiper class="swiper-container" :modules="modules" :slides-per-view="1" :space-between="spaceBetween" direction="vertical" :loop="true" :autoplay="{ delay: 5000, disableOnInteraction: false }">
                  <swiper-slide>
                    <div class="CurrencyBox" v-for="(item, index) in marketlist.slice(0, 5)" :key="index">
                      <p class="CurrencyTitle">
                        <span class="Currency">{{ item.symbol }}</span>
                        <span :class="[item.currency_quotation.change < 0 ? 'down' : 'up']">{{
                          item.currency_quotation.change >= 0 ? '+' : '' }}{{ (item.currency_quotation.change - 0)
  }}%</span>
                      </p>
                      <p class="CurrencyPri">{{ item.currency_quotation.close }}</p>
                      <p class="UsdPri">{{ item.currency_quotation.volume }}</p>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="CurrencyBox" v-for="(item, index) in marketlist.slice(5, 10)" :key="index">
                      <p class="CurrencyTitle">
                        <span class="Currency">{{ item.symbol }}</span>
                        <span :class="[item.currency_quotation.change < 0 ? 'down' : 'up']">{{
                          item.currency_quotation.change >= 0 ? '+' : '' }}{{ (item.currency_quotation.change - 0)
  }}%</span>
                      </p>
                      <p class="CurrencyPri">{{ item.currency_quotation.close }}</p>
                      <p class="UsdPri">{{ item.currency_quotation.volume }}</p>
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
        </div>

        <div class="noticeBox mainBox" v-if="listnew.length != 0">
          <div class="left">
            <div class="icon">
              <figure><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30px"
                  height="28px" viewBox="0 0 30 28" version="1.1">
                  <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="首页-黑" transform="translate(-30.000000, -430.000000)" fill-rule="nonzero">
                      <g id="View-Table-/-Notice-Bar备份" transform="translate(29.087329, 428.000000)">
                        <g id="Marketing" transform="translate(0.912671, 2.000000)">
                          <path
                            d="M24.5454545,18.1737818 L24.5454545,15.377992 C26.0516857,15.377992 27.2727273,14.1262743 27.2727273,12.5822022 C27.2727273,11.0381301 26.0516857,9.78641239 24.5454545,9.78641239 L24.5454545,6.9906226 C27.5579168,6.9906226 30,9.49405805 30,12.5822022 C30,15.6703463 27.5579168,18.1737818 24.5454545,18.1737818 Z"
                            id="Path" fill="#FFD140"></path>
                          <path
                            d="M9.40232224,22.6768961 C9.26358692,21.9180728 9.75119203,21.1876322 10.4914187,21.0454114 C11.2316454,20.9031907 11.9441849,21.4030459 12.0829203,22.1618692 L12.8476454,26.3445916 C13.0049252,27.2048455 12.3611295,28 11.5073464,28 L7.87581432,28 C6.63091321,28 5.54401194,27.1358239 5.23429363,25.8997733 L3.13253112,17.5118778 C2.94516388,16.7641164 3.38459728,16.002229 4.11403307,15.8101545 C4.84346887,15.6180801 5.58668457,16.0685533 5.7740518,16.8163147 L7.87581432,25.2042102 L9.86438988,25.2042102 L9.40232224,22.6768961 Z"
                            id="Path" fill="#777E90"></path>
                          <path
                            d="M23.1818182,3.36890915 L13.2516091,6.92938931 L12.960882,6.99817919 L3.93142974,8.08819752 C3.24475121,8.17109205 2.72727273,8.76770562 2.72727273,9.47649954 L2.72727273,14.293618 C2.72727273,15.0016427 3.2436604,15.597864 3.92947653,15.6816827 L12.9628353,16.785717 L13.2499743,16.8536847 L23.1818182,20.4002478 L23.1818182,3.36890915 Z M12.4927974,19.5438595 L3.60661141,18.4578123 C1.54916303,18.2063561 0,16.4176922 0,14.293618 L0,9.47649954 C0,7.35011779 1.55243543,5.56027707 3.61247104,5.31159349 L12.492867,4.23956896 L24.0952481,0.0795308774 C24.9818014,-0.238343121 25.9090909,0.436230109 25.9090909,1.39904301 L25.9090909,22.3674665 C25.9090909,23.3295299 24.9831388,24.0040368 24.0968829,23.6875636 L12.4927974,19.5438595 Z"
                            id="Path" fill="#777E90"></path>
                          <polygon id="Path" fill="#777E90"
                            points="10.9090909 18.1737818 8.18181818 18.1737818 8.18181818 5.72224637 10.9090909 5.72224637">
                          </polygon>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg></figure>
            </div>
            <swiper class="swiper-container swiper" :modules="modules" :slides-per-view="1" :space-between="spaceBetween" direction="vertical" :pagination="false">
              <swiper-slide class="itemBox" v-for="(item, index) in listnew.slice(0,1)" :key="index">
                <!-- <router-link class="item" :to="`/new/newList?cid=22&id=${item.id}`"> -->
                <div class="item" @click="newClick(item.title,item.content,item.updated_at)">
                  <p>{{ item.title }}</p>
                  <span>{{ item.updated_at }}</span>
                </div>
                <!-- </router-link> -->
              </swiper-slide>
            </swiper>
          </div>

          <div class="right">
            <!-- <router-link class="link" to="/new/newList?cid=22"> -->
            <div class="link">
              <bars-outlined style="color:#777E90;cursor: pointer;" />
            </div>

          </div>
        </div>

        <div class="tabImgBox mainBox">
          <img :src="item.banner" v-for="(item, index) in bannerList" :key="index" />
        </div>

        <div class="rankingWrap mainBox">
          <swiper class="swiper-container swiper" :space-between="spaceBetween" :loop="true">
            <swiper-slide class="item">
              <div class="rankingBox">
                <p class="title">{{ t('home.changUpRank') }}</p>
                <table>
                  <tr>
                    <th>{{ t('total.Currency') }}</th>
                    <!-- <th>行情</th> -->
                    <th>{{ t('total.chang24') }}</th>
                    <th>{{ t('total.NowPrice') }}</th>
                  </tr>

                  <tr v-for="(item, index) in ranklisDownt.slice(0, 10)" :key="index" @click="contractHref(item)">
                    <td>
                      <div class="iconBox">
                        <img :src="item.base_currency.logo" class="icon" />
                        <p>{{ item.base_currency.code }}</p>
                      </div>
                    </td>
                    <!-- <td class="echartsBox">
                        <echarts :chartData="chartData"></echarts>
                      </td> -->
                    <td class="downUpBox">
                      <p :class="[item.currency_quotation.change < 0 ? 'down' : 'up']">{{ item.currency_quotation.change
                        >= 0 ? '+' : '' }}{{ (item.currency_quotation.change - 0) }}%</p>
                    </td>
                    <td class="priBox">
                      <p>{{ item.currency_quotation.close }}</p>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="rankingBox">
                <p class="title">{{ t('home.changDownRank') }}</p>
                <table>
                  <tr>
                    <th>{{ t('total.Currency') }}</th>
                    <!-- <th>行情</th> -->
                    <th>{{ t('total.chang24') }}</th>
                    <th>{{ t('total.NowPrice') }}</th>
                  </tr>

                  <tr v-for="(item, index) in ranklistUp.slice(0, 10)" :key="index" @click="contractHref(item)">
                    <td>
                      <div class="iconBox">
                        <img :src="item.base_currency.logo" class="icon" />
                        <p>{{ item.base_currency.code }}</p>
                      </div>
                    </td>
                    <!-- <td class="echartsBox">
                        <echarts :chartData="chartData"></echarts>
                      </td> -->
                    <td class="downUpBox">
                      <p :class="[item.currency_quotation.change < 0 ? 'down' : 'up']">{{
                        item.currency_quotation.change >= 0 ? '+' : '' }}{{ (item.currency_quotation.change - 0)
  }}%</p>
                    </td>
                    <td class="priBox">
                      <p>{{ item.currency_quotation.close }}</p>
                    </td>
                  </tr>
                </table>
              </div>
            </swiper-slide>
          </swiper>
        </div>

        <div class="featureWrap">
          <div class="bannerContainer">
            <p class="home_title">{{ t('home.whyTitle') }}</p>
            <div class="featureBox">
              <div class="featureCards">
                <div>
                  <div><svg width="64px" height="64px" viewbox="0 0 64 64" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title>Icons / Systems / Other / Safety On Light</title>
                      <g id="Icons-/-Systems-/-Other-/-Safety-On-Light" stroke="none" stroke-width="1" fill="none"
                        fill-rule="evenodd">
                        <g id="Safe" transform="translate(7.500000, 4.500000)" fill-rule="nonzero">
                          <path
                            d="M23.8627845,0.0955218803 L1.81554038,6.37149844 C0.741119284,6.67734354 0,7.65887231 0,8.77597656 L0,21.797168 C0,29.1491497 2.26320206,36.1901262 6.54027028,42.1416693 C10.7239536,47.9633029 16.4972201,52.3036456 23.2256617,54.684358 L23.7137287,54.8569582 C24.2530404,55.0476806 24.8414477,55.0476806 25.3807595,54.8569582 L25.8684328,54.6844973 C32.5972681,52.3036456 38.3705346,47.9633029 42.5542222,42.1416633 C46.8312861,36.1901262 49.0944882,29.1491497 49.0944882,21.797168 L49.0944882,8.77597656 C49.0944882,7.65887231 48.3533689,6.67734354 47.2789478,6.37149844 L25.2317037,0.0955218803 C24.7842845,-0.0318406268 24.3102037,-0.0318406268 23.8627845,0.0955218803 Z"
                            id="Path" fill="#777E90"></path>
                          <path
                            d="M25.3807595,54.8569582 L25.8684328,54.6844973 C32.5972681,52.3036456 38.3705346,47.9633029 42.5542222,42.1416633 C46.8312861,36.1901262 49.0944882,29.1491497 49.0944882,21.797168 L49.0944882,8.77597656 C49.0944882,7.65887231 48.3533689,6.67734354 47.2789478,6.37149844 L25.2317037,0.0955218803 L25.3807595,54.8569582 Z"
                            id="Path" fill="#494E5B"></path>
                          <path
                            d="M32.0652587,19.4167198 C32.9396803,18.3481917 34.5147521,18.1908372 35.5832802,19.0652587 C36.6053506,19.9016619 36.7937573,21.3791138 36.0429549,22.4411336 L35.9347413,22.5832802 L24.9664751,35.9863133 C24.0951072,37.0511098 22.5378089,37.2045212 21.4779624,36.3617068 L21.3426399,36.2461179 L14.310906,29.8018897 C13.2929984,28.8690289 13.2240544,27.2876186 14.1569152,26.2697109 C15.0492169,25.2960602 16.5348984,25.1906483 17.5532273,25.9997195 L17.689094,26.1157201 L22.771,30.773 L32.0652587,19.4167198 Z"
                            id="Path-2" fill="#FFD140"></path>
                        </g>
                      </g>
                    </svg></div>
                  <p>{{ t('home.indexTitle1') }}</p>
                  <p>{{ t('home.indexTest1') }}</p>
                </div>
              </div>
              <div class="featureCards">
                <div>
                  <div><svg width="64px" height="64px" viewbox="0 0 64 64" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title>Icons / Systems / Other / Mission On Light</title>
                      <g id="Icons-/-Systems-/-Other-/-Mission-On-Light" stroke="none" stroke-width="1" fill="none"
                        fill-rule="evenodd">
                        <path
                          d="M30.5,10.5 C43.4786916,10.5 54,21.0213084 54,34 C54,46.9786916 43.4786916,57.5 30.5,57.5 C17.5213084,57.5 7,46.9786916 7,34 C7,21.0213084 17.5213084,10.5 30.5,10.5 Z M30.5,15.5 C20.2827321,15.5 12,23.7827321 12,34 C12,44.2172679 20.2827321,52.5 30.5,52.5 C40.7172679,52.5 49,44.2172679 49,34 C49,23.7827321 40.7172679,15.5 30.5,15.5 Z M30.5,19.5 C38.5081289,19.5 45,25.9918711 45,34 C45,42.0081289 38.5081289,48.5 30.5,48.5 C22.4918711,48.5 16,42.0081289 16,34 C16,25.9918711 22.4918711,19.5 30.5,19.5 Z M30.5,24.5 C25.2532949,24.5 21,28.7532949 21,34 C21,39.2467051 25.2532949,43.5 30.5,43.5 C35.7467051,43.5 40,39.2467051 40,34 C40,28.7532949 35.7467051,24.5 30.5,24.5 Z"
                          id="Combined-Shape" fill="#777E90" fill-rule="nonzero"></path>
                        <path
                          d="M47,1.0300293 L47,11.0300293 L45.156,11.9380293 L45.1568542,39.0459415 C45.1568542,40.150511 44.2614237,41.0459415 43.1568542,41.0459415 C42.0522847,41.0459415 41.1568542,40.150511 41.1568542,39.0459415 L41.156,12.0910293 L39,11.0300293 L39,1.0300293 L43,3 L47,1.0300293 Z"
                          id="Combined-Shape" fill="#FFD140"
                          transform="translate(43.000000, 21.037985) rotate(45.000000) translate(-43.000000, -21.037985) ">
                        </path>
                      </g>
                    </svg></div>
                  <p>{{ t('home.indexTitle2') }}</p>
                  <p>{{ t('home.indexTest2') }}</p>
                </div>
              </div>
              <div class="featureCards">
                <div>
                  <div><svg width="64px" height="64px" viewbox="0 0 64 64" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title>Icons / Systems / Other / Contract B On Light</title>
                      <g id="Icons-/-Systems-/-Other-/-Contract-B-On-Light" stroke="none" stroke-width="1" fill="none"
                        fill-rule="evenodd">
                        <path
                          d="M55.9932074,50.4043429 C56.1392195,54.8014476 53.9312568,57 49.3693192,57 C44.8073817,57 32.7194395,57 13.1054928,57 C10.3684976,56.797954 9,54.5994016 9,50.4043429 C9,46.2092843 9,40.0745033 9,32 L56,32 L55.9932074,50.4043429 Z"
                          id="Path" fill="#494E5B" fill-rule="nonzero"></path>
                        <path
                          d="M18.8607204,8 L55.9931907,8 L55.9931907,50.3974933 C56.1395619,54.7991644 53.926169,57 49.353012,57 C44.7798551,57 32.6621844,57 13,57 C16.907147,55.1673952 18.8607204,52.9665597 18.8607204,50.3974933 C18.8607204,47.828427 18.8607204,33.6959293 18.8607204,8 Z"
                          id="Path-2" fill="#777E90" fill-rule="nonzero"></path>
                        <path
                          d="M49.5,45 L49.5,49.8571429 L25.5,49.8571429 L25.5,45 L49.5,45 Z M49,16 L49,31 L44,31 L43.999,24.325 L29.5355339,38.7898697 L26,35.2543358 L40.253,21 L34,21 L34,16 L49,16 Z"
                          id="Combined-Shape" fill="#FFD140"></path>
                      </g>
                    </svg></div>
                  <p>{{ t('home.indexTitle3') }}</p>
                  <p>{{ t('home.indexTest3') }}</p>
                </div>
              </div>
              <div class="featureCards">
                <div>
                  <div><svg width="64px" height="64px" viewbox="0 0 64 64" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title>Icons / Systems / Other / Copy Trade On Liht</title>
                      <g id="Icons-/-Systems-/-Other-/-Copy-Trade-On-Liht" stroke="none" stroke-width="1" fill="none"
                        fill-rule="evenodd">
                        <path d="M47,30 L47,34 L38,34 L38,30 L47,30 Z M47,21 L47,25 L38,25 L38,21 L47,21 Z"
                          id="Combined-Shape-Copy" fill="#494E5B"></path>
                        <polygon id="Path" fill="#494E5B" fill-rule="nonzero"
                          transform="translate(40.000000, 24.500000) scale(1, -1) translate(-40.000000, -24.500000) "
                          points="56 5 56 44 46.3066667 40.1228695 40.0293351 43.37529 33.7075556 40.1219823 24 43.9983913 24 28.7955852 27.5555556 28.7955852 27.5555556 38.7564971 33.8951994 36.2254797 40.0231111 39.37935 46.1159473 36.224146 52.4444444 38.7547226 52.4444444 8.5490193 38.3237847 8.5490193 38.3237847 5">
                        </polygon>
                        <polygon id="Rectangle-Copy" fill="#777E90" fill-rule="nonzero"
                          points="40 18 40 57 30.987793 53.3894043 24.0300293 57 17.0270996 53.3894043 8 57 8 18">
                        </polygon>
                        <polygon id="Path" fill="#777E90" fill-rule="nonzero"
                          points="36 22 12 22 12 51.091 17.2370991 48.9973185 24.024 52.496 30.7730885 48.9943122 36 51.088">
                        </polygon>
                        <path d="M31,39 L31,43 L17,43 L17,39 L31,39 Z M31,30 L31,34 L17,34 L17,30 L31,30 Z"
                          id="Combined-Shape" fill="#FFD140"></path>
                      </g>
                    </svg></div>
                  <p>{{ t('home.indexTitle4') }}</p>
                  <p>{{ t('home.indexTest4') }}</p>
                </div>
              </div>
              <div class="featureCards">
                <div>
                  <div><svg width="64px" height="64px" viewbox="0 0 64 64" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title>Icons / Systems / Other / Conversation B On Light</title>
                      <g id="Icons-/-Systems-/-Other-/-Conversation-B-On-Light" stroke="none" stroke-width="1" fill="none"
                        fill-rule="evenodd">
                        <polygon id="Path-Copy" fill="#494E5B"
                          transform="translate(24.000000, 25.302490) scale(-1, 1) translate(-24.000000, -25.302490) "
                          points="8 36 8 6 40 6 40 36 32.8398438 36 32.8398438 44.6049805 24 36">
                        </polygon>
                        <polygon id="Path" fill="#777E90"
                          points="24 51 24 21 56 21 56 51 48.8398438 51 48.8398438 59.6049805 40 51">
                        </polygon>
                        <path
                          d="M40,40 L40,43 L30,43 L30,40 L40,40 Z M50,34 L50,37 L30,37 L30,34 L50,34 Z M50,28 L50,31 L30,31 L30,28 L50,28 Z"
                          id="Combined-Shape" fill="#FFD140"></path>
                      </g>
                    </svg></div>
                  <p>{{ t('home.indexTitle5') }}</p>
                  <p>{{ t('home.indexTest5') }}</p>
                </div>
              </div>
              <div class="featureCards">
                <div>
                  <div><svg width="64px" height="64px" viewbox="0 0 64 64" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title>Icons / Systems / Other / Margin On Light</title>
                      <g id="Icons-/-Systems-/-Other-/-Margin-On-Light" stroke="none" stroke-width="1" fill="none"
                        fill-rule="evenodd">
                        <path
                          d="M55.7027958,9 L55.7027958,29 L50.7027958,29 L50.7025924,17.535 L12.7573593,55.4809704 C11.7810486,56.4572811 10.1981361,56.4572811 9.22182541,55.4809704 C8.24551468,54.5046597 8.24551468,52.9217472 9.22182541,51.9454365 L47.1655924,14 L35.7027958,14 L35.7027958,9 L55.7027958,9 Z"
                          id="Combined-Shape" fill="#FFD140"></path>
                        <path
                          d="M46,37 C51.5228475,37 56,41.4771525 56,47 C56,52.5228475 51.5228475,57 46,57 C40.4771525,57 36,52.5228475 36,47 C36,41.4771525 40.4771525,37 46,37 Z M46,43 C43.790861,43 42,44.790861 42,47 C42,49.209139 43.790861,51 46,51 C48.209139,51 50,49.209139 50,47 C50,44.790861 48.209139,43 46,43 Z"
                          id="Combined-Shape" fill="#777E90"></path>
                        <path
                          d="M17,9 C22.5228475,9 27,13.4771525 27,19 C27,24.5228475 22.5228475,29 17,29 C11.4771525,29 7,24.5228475 7,19 C7,13.4771525 11.4771525,9 17,9 Z M17,15 C14.790861,15 13,16.790861 13,19 C13,21.209139 14.790861,23 17,23 C19.209139,23 21,21.209139 21,19 C21,16.790861 19.209139,15 17,15 Z"
                          id="Combined-Shape-Copy" fill="#777E90"></path>
                      </g>
                    </svg></div>
                  <p>{{ t('home.indexTitle6') }}</p>
                  <p>{{ t('home.indexTest6') }}</p>
                </div>
              </div>
              <div class="featureCards">
                <div>
                  <div><svg width="64px" height="64px" viewbox="0 0 64 64" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title>Icons / Systems / Other / Trade B On Light</title>
                      <g id="Icons-/-Systems-/-Other-/-Trade-B-On-Light" stroke="none" stroke-width="1" fill="none"
                        fill-rule="evenodd">
                        <g id="Icon" transform="translate(6.000000, 7.000000)">
                          <path
                            d="M51.25,35 L51.25,50 L36.25,50 L36.25,43.75 L45,43.75 L45,35 L51.25,35 Z M15,0 L15,6.25 L6.249,6.25 L6.25,15 L0,15 L0,0 L15,0 Z"
                            id="Combined-Shape" fill="#777E90" fill-rule="nonzero">
                          </path>
                          <path
                            d="M32.5,1.25 C41.8198052,1.25 49.375,8.80519485 49.375,18.125 C49.375,27.4448052 41.8198052,35 32.5,35 C23.1801948,35 15.625,27.4448052 15.625,18.125 C15.625,8.80519485 23.1801948,1.25 32.5,1.25 Z M32.625,8.25 C27.1021525,8.25 22.625,12.7271525 22.625,18.25 C22.625,23.7728475 27.1021525,28.25 32.625,28.25 C38.1478475,28.25 42.625,23.7728475 42.625,18.25 C42.625,12.7271525 38.1478475,8.25 32.625,8.25 Z"
                            id="Combined-Shape" fill="#FFD140" fill-rule="nonzero">
                          </path>
                          <circle id="Oval" fill="#777E90" fill-rule="nonzero" cx="18.75" cy="31.875" r="16.875">
                          </circle>
                          <circle id="Oval" fill="#1C1E24" cx="19" cy="32" r="10">
                          </circle>
                        </g>
                      </g>
                    </svg></div>
                  <p>{{ t('home.indexTitle7') }}</p>
                  <p>{{ t('home.indexTest7') }}</p>
                </div>
              </div>
              <div class="featureCards">
                <div>
                  <div><svg width="64px" height="64px" viewbox="0 0 64 64" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title>Icons / Systems / Other / Ecosystem On Light</title>
                      <g id="Icons-/-Systems-/-Other-/-Ecosystem-On-Light" stroke="none" stroke-width="1" fill="none"
                        fill-rule="evenodd">
                        <g id="Eco" transform="translate(7.500000, 8.000000)" fill-rule="nonzero">
                          <path
                            d="M24.5,0 C37.8956666,0 48.7803633,10.7507401 48.9967177,24.0948477 L49,24.5 L45,24.5 C45,13.1781626 35.8218374,4 24.5,4 C13.1781626,4 4,13.1781626 4,24.5 C4,35.7063084 13.3377186,44.8153269 24.5,45 L24.5,49 C10.9690236,49 0,38.0309764 0,24.5 C0,10.9690236 10.9690236,0 24.5,0 Z M24.5,10 C32.7842712,10 39.5,16.7157288 39.5,25 C39.5,33.2842712 32.7842712,40 24.5,40 C16.2157288,40 9.5,33.2842712 9.5,25 C9.5,16.7157288 16.2157288,10 24.5,10 Z M27.4962767,27.000254 L22.4612124,27.0002265 C22.7357573,29.9375608 23.5607299,32.9309685 24.9377766,35.9907353 L25.0210684,35.9877279 C26.397298,32.9290166 27.2218193,29.9366172 27.4962767,27.000254 Z M18.4469446,27.0002168 L13.6814845,27.0005894 C14.3615189,30.7016184 16.8939808,33.7544555 20.2820679,35.1622981 C19.2749773,32.4136993 18.6630027,29.6913085 18.4469446,27.0002168 Z M43.8958333,28.2500004 L45.5208333,31.5000004 L45.5208333,34.7500004 L42.2708333,34.7500004 L42.2708333,31.5000004 L43.8958333,28.2500004 Z M50.3958333,28.2500004 L52.0208333,31.5000004 L52.0208333,34.7500004 L48.7708333,34.7500004 L48.7708333,31.5000004 L50.3958333,28.2500004 Z M35.3184454,27.0009899 L31.5103813,27.0004265 C31.3092525,29.5077187 30.7644108,32.0415738 29.8766963,34.598356 C32.6759044,33.027618 34.7182852,30.2667158 35.3184454,27.0009899 Z M20.2521879,14.8507751 L20.155735,14.8911286 C16.8308136,16.3219046 14.3525884,19.3450788 13.6813011,23.0004093 L18.4190569,23.000104 C18.604341,20.2454482 19.2150878,17.5271176 20.2521879,14.8507751 Z M24.9778719,14.0403814 C23.5210356,16.9782112 22.6711236,19.960944 22.4300553,23.0005705 L27.5274039,23.00016 C27.289333,19.9990163 26.4577355,17.0533345 25.0344584,14.1515735 L24.9778719,14.0403814 Z M29.9220157,15.4269174 C30.8275424,17.9183876 31.3658886,20.4441878 31.5379764,23.0003262 L35.3187425,23.0006464 C34.7222494,19.752187 32.6986927,17.002963 29.9220157,15.4269174 Z"
                            id="Combined-Shape" fill="#777E90"></path>
                          <path
                            d="M47.1458333,21.7500004 C44.45765,21.7500004 42.2708333,23.9368171 42.2708333,26.6250004 C42.2708333,27.0958171 42.2708333,32.0803421 42.2708333,31.5000004 L45.5208333,31.5000004 L48.7708333,31.5000004 L52.0208333,31.5000004 C52.0208333,31.0291838 52.0208333,26.0446588 52.0208333,26.6250004 C52.0208333,23.9368171 49.8340166,21.7500004 47.1458333,21.7500004 Z"
                            id="路径" fill="#FFD140"></path>
                        </g>
                      </g>
                    </svg></div>
                  <p>{{ t('home.indexTitle8') }}</p>
                  <p>{{ t('home.indexTest8') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- <div class="home_license">
            <div class="bannerContainer">
              <p class="home_title">监管许可</p>
              <p class="home_content">aaaa 是在美国FinCEN（美国财政部下设机构金融犯罪执法局），加拿大FINTRAC
                （加拿大金融交易和报告分析中心）以及美国SEC(美国证券交易委员会)监管下，依法合规注册经营的金融及货币服务企业。aaaa将根据拥有金融许可证，遵守其运营所在的每个司法管辖区的所有适用法律和法规。
              </p>
              <div class="home_licenseBox">
                <div class="home_licenseCard">
                  <section class="LicenseCard_container">
                    <div class="LicenseCard_licenseFrame" aria-hidden="true">
                      <img src="@/assets/USA-L.webp" class="img" />
                    </div>
                    <p class="LicenseCard_country">美国<span>监管中</span></p>
                    <div class="LicenseCard_licenseInfo">
                      <p><span>监管证号</span><span>31000192121255</span></p>
                      <p><span>发证日期</span><span>2021/06/17</span></p>
                    </div>
                    <router-link class="Button_default LicenseCard_link" to="">美国 验真网址 &gt;</router-link>
                  </section>
                </div>
                <div class="home_licenseCard">
                  <section class="LicenseCard_container">
                    <div class="LicenseCard_licenseFrame" aria-hidden="true">
                      <img src="@/assets/CA-L.webp" class="img" />
                    </div>
                    <p class="LicenseCard_country">加拿大<span>监管中</span></p>
                    <div class="LicenseCard_licenseInfo">
                      <p><span>监管证号</span><span>M21813989</span></p>
                      <p><span>发证日期</span><span>2021/07/14</span></p>
                    </div>
                    <router-link class="Button_default LicenseCard_link" to="">加拿大 验真网址 &gt;</router-link>
                  </section>
                </div>
                <div class="home_licenseCard">
                  <section class="LicenseCard_container">
                    <div class="LicenseCard_licenseFrame" aria-hidden="true">
                      <img src="@/assets/UBS-L.webp" class="img" />
                    </div>
                    <p class="LicenseCard_country">美国<span>监管中</span></p>
                    <div class="LicenseCard_licenseInfo">
                      <p><span>监管证号</span><span>20211057628</span></p>
                      <p><span>发证日期</span><span>2021/05/21</span></p>
                    </div>
                    <router-link class="Button_default LicenseCard_link" to="">美国 验真网址 &gt;</router-link>
                  </section>
                </div>
              </div>
            </div>
          </div> -->

        <div class="home_download">
          <div class="bannerContainer">
            <p class="home_title">{{ t('home.downLoadTitle') }}</p>
            <p class="home_content">{{ t('home.downLoadTest') }}</p>
            <div class="home_downloadInfo">
              <div class="home_appPreview">
                <span
                  style="box-sizing:border-box;display:inline-block;overflow:hidden;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;position:relative;max-width:100%">
                  <span
                    style="box-sizing:border-box;display:block;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;max-width:100%">
                    <img
                      style="display:block;max-width:100%;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0"
                      :src="configInfo.app_preview" />
                  </span>
                </span>
              </div>
              <div class="home_downloadMethod">
                <div class="home_qrCode">
                  <div class="home_qrCodeImg">
                    <span
                      style="box-sizing:border-box;display:inline-block;overflow:hidden;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;position:relative;max-width:100%">
                      <span
                        style="box-sizing:border-box;display:block;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;max-width:100%">
                        <img
                          style="display:block;max-width:100%;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0"
                          :src="configInfo.app_page_qrcode" />
                      </span>
                    </span>
                  </div>
                  <div class="home_qrCodeText"><span>{{ t('home.downLoadCode') }}</span><span>{{
                    t('home.downLoadIosAnd') }}</span></div>
                </div>
                <div class="home_appLinks">
                  <a :href="APPStore" rel="noreferrer">
                    <svg width="31px" height="30px" viewbox="0 0 31 30" version="1.1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title></title>
                      <g id="Icons-/-Systems-/-Other-/-App-Store" stroke="none" stroke-width="1" fill-rule="evenodd">
                        <path fill="currentcolor"
                          d="M21.9886464,15.2948452 C22.013692,13.3473639 23.0345368,11.5484355 24.6935992,10.5281398 C23.6419635,9.02631703 21.9437843,8.1084424 20.1112093,8.05120089 C18.1824558,7.84882408 16.3146471,9.20460161 15.3322302,9.20460161 C14.3308211,9.20460161 12.8182589,8.07109258 11.1897853,8.10442174 C9.04656132,8.17381306 7.09696388,9.3629945 6.05444876,11.23694 C3.83409152,15.0810095 5.49008548,20.7303559 7.61636982,23.8374806 C8.68046954,25.3595124 9.92507579,27.0583476 11.5516449,26.9984609 C13.1440383,26.9319539 13.7387787,25.9837518 15.6608664,25.9837518 C17.5658133,25.9837518 18.1235214,26.9984609 19.784266,26.9604551 C21.492518,26.9319518 22.5689971,25.4317574 23.5960645,23.8963938 C24.3603921,22.812085 24.9484879,21.6138222 25.3385979,20.3458326 C23.3091162,19.4873791 21.9897045,17.4986754 21.9875884,15.2950357 L21.9885385,15.2950357 L21.9886464,15.2948452 Z M18.8513668,6.00754113 C19.7831339,4.88905667 20.2421982,3.45145869 20.1312068,2 C18.7076811,2.14958976 17.3928191,2.83010782 16.4485986,3.90589921 C15.5163236,4.96682472 15.0440123,6.35490509 15.1355353,7.76425265 C16.5778946,7.77912698 17.9474587,7.13164393 18.8513668,6.00754113 L18.8513668,6.00754113 Z"
                          id="Shape" fill-rule="nonzero"></path>
                      </g>
                    </svg>
                    <p>{{ t('topHeader.APPStore') }}</p>
                  </a>
                  <a :href="Android" rel="noreferrer">
                    <svg width="31px" height="30px" viewbox="0 0 31 30" version="1.1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title></title>
                      <g id="Icons-/-Systems-/-Other-/-Android" stroke="none" stroke-width="1" fill-rule="evenodd">
                        <path fill="currentcolor"
                          d="M24.8905496,6.24781485 C25.0185711,6.0155354 25.3095602,5.9316234 25.5404919,6.06039216 C25.7714235,6.18916092 25.8548486,6.48184832 25.7268271,6.71412777 L25.7268271,6.71412777 L23.3543627,11.0192184 C27.9585229,13.3019604 30.9090487,17.9805905 31,23.1428571 L31,23.1428571 L1,23.1428571 C1.09095015,17.9806034 4.04146589,13.3019837 8.64561259,11.0192432 L8.64561259,11.0192432 L6.27307407,6.71412777 C6.14505251,6.48184832 6.22847761,6.18916094 6.45940926,6.06039219 C6.6903409,5.93162343 6.98132998,6.01553542 7.10935157,6.24781485 L7.10935157,6.24781485 L9.50816511,10.6006158 C13.6459744,8.7760711 18.3540008,8.7760711 22.4918102,10.6006158 L22.4918102,10.6006158 Z M9.57142857,16 C8.78245036,16 8.14285714,16.6395932 8.14285714,17.4285714 C8.14285714,18.2175496 8.78245036,18.8571429 9.57142857,18.8571429 C10.3600397,18.8562574 10.9991145,18.2171826 11,17.4285714 C11,16.6395932 10.3604068,16 9.57142857,16 Z M23.8571429,16 C23.0681646,16 22.4285714,16.6395932 22.4285714,17.4285714 C22.4285714,18.2175496 23.0681646,18.8571429 23.8571429,18.8571429 C24.645754,18.8562574 25.2848288,18.2171826 25.2857143,17.4285714 C25.2857143,16.6395932 24.6461211,16 23.8571429,16 Z"
                          id="Combined-Shape"></path>
                      </g>
                    </svg>
                    <p>{{ t('topHeader.Android') }}</p>
                  </a>
                  <a :href="GooglePlay" rel="noreferrer">
                    <svg width="31px" height="30px" viewbox="0 0 31 30" version="1.1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title></title>
                      <g id="Icons-/-Systems-/-Other-/-Google-Play" stroke="none" stroke-width="1" fill-rule="evenodd">
                        <path fill="currentcolor"
                          d="M24.0301572,12.1227676 L21.4573842,10.6359213 L18.3570206,14.4434285 L21.5164154,18.3296439 L24.0289274,16.8784622 C25.0779596,16.2733932 25.653513,15.4260507 25.653513,14.4987702 C25.653513,13.5714897 25.0791894,12.7290664 24.0301572,12.1227676 Z M7.36124479,26 L16.7705593,14.4446583 L7.48053684,3.01475778 C7.45102128,3.01352796 7.42027591,3 7.39076035,3 C5.96048551,3 5,4.16217517 5,5.89375468 L5,23.1111646 C5.00245963,24.8267565 5.95064699,25.9827826 7.36124479,26 Z M20.3763769,10.0160945 L9.7901294,3.90145439 L17.5650198,13.4681852 L20.3763769,10.0160945 Z M17.56379,15.417442 L9.58352048,25.2202973 L20.4366378,18.9519303 L17.56379,15.417442 Z"
                          id="Shape" fill-rule="nonzero"></path>
                      </g>
                    </svg>
                    <p>{{ t('topHeader.GooglePlay') }}</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer></Footer>
    </div>
  </div>
  <loading v-show="spinning"></loading>

  
  <div class="noticePopWrap" @click="open = false" v-if="open">
    <div class="noticePopBox" @click.stop="">
      <p class="title">{{nameVal}}</p>
      <p class="time">{{ updated_atVal }}</p>
      <div class="test" v-html="contentVal"></div>
    </div>
    <div class="closedBox" @click.stop="open = false">
      <img src="../assets/close.png" class="closed" />
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted, onUpdated, onMounted, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import HeaderTop from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { useI18n } from "vue-i18n";
import echarts from '@/components/echarts.vue'
import { newList, currencyMatches } from "@/api/home";
import { BarsOutlined } from '@ant-design/icons-vue'
// import ws from '@/utils/websocket'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper'
import 'swiper/css';

export default {
  name: 'home',
  components: {
    HeaderTop, Footer, echarts, BarsOutlined,Swiper, SwiperSlide
  },
  setup() {
    const modules = [Autoplay]
    const $router = useRouter();
    let { t } = useI18n()
    const { proxy } = getCurrentInstance();

    let bannerList = []
    let spaceBetween = 30;
    let onProgress = (e) => {
      let [swiper, progress] = e.detail;
    };

    let onSlideChange = (e) => {

    }

    let chartData = [7, 6, 5, 4, 4, 3, 1]
    let chartData2 = [11, 8, 25, 4, 15, 6, 17]
    let token = localStorage.getItem('token')

    let marketlist = ref([])
    let ranklistUp = ref([])
    let ranklisDownt = ref([])
    let cny_rate = ref(null)
    let usd_rate = ref(null)
    let listnew = ref([])
    let spinning = ref(false)
    let ws = ref(null)


    const configInfo = ref({})
    const downloadUrl = ref({})
    const Android = ref(null)
    const GooglePlay = ref(null)
    const APPStore = ref(null)


    const receive = (message) => {
      let msg = JSON.parse(message.data)
      marketlist.value.filter(item => {
        if (item.symbol == msg.symbol) {
          item.currency_quotation.volume = msg.quotation.volume
          item.currency_quotation.close = msg.quotation.close
          item.currency_quotation.change = msg.quotation.change
        }
      })
      ranklistUp.value.filter(item => {
        if (item.symbol == msg.symbol) {
          item.currency_quotation.volume = msg.quotation.volume
          item.currency_quotation.close = msg.quotation.close
          item.currency_quotation.change = msg.quotation.change
        }
      })
      ranklisDownt.value.filter(item => {
        if (item.symbol == msg.symbol) {
          item.currency_quotation.volume = msg.quotation.volume
          item.currency_quotation.close = msg.quotation.close
          item.currency_quotation.change = msg.quotation.change
        }
      })
    }

    const getNewList = () => {
      newList({ category_id: 22 }).then((response) => {
        listnew.value = response.data.data
      });
    }

    const getBannerList = () => {
      spinning = true
      currencyMatches({ accounts_display: 3 }).then((response) => {
        spinning = false
        let usdtData = response.data.find(item => item.code == 'USDT');
        marketlist.value = usdtData.matches

        ws = new WebSocket(proxy.$wssLink);

        ws.onopen = function (evt) {
          ws.send(JSON.stringify({
            type: "sub",
            params: "DAY_MARKET"
          }));
        };
        ws.onmessage = function (e) {
          let msg = JSON.parse(e.data)
          if (msg.type == 'DAY_MARKET') {
            receive(e)
          }
        };

        let up = JSON.parse(JSON.stringify(usdtData.matches))
        let down = JSON.parse(JSON.stringify(usdtData.matches))
        ranklistUp.value = up.sort(function (a, b) {
          return a.currency_quotation?.change - b.currency_quotation?.change;
        });
        ranklisDownt.value = down.sort(function (a, b) {
          return b.currency_quotation?.change - a.currency_quotation?.change;
        });


      });
    }

    const contractHref = (item) => {
      $router.push('/contract?id=' + item.id)
    }

    const open = ref(false)
    const contentVal = ref(null)
    const nameVal = ref(null)
    const updated_atVal = ref(null)
    const newClick = (name,val,updated_at) => {

      contentVal.value = proxy.$testChang3(proxy.$testChang2(val))
      nameVal.value = proxy.$testChang3(proxy.$testChang2(name))
      updated_atVal.value = updated_at
      open.value = true
    }

    onUnmounted(() => {
      console.log('关闭')
      ws.close();
    })

    onMounted(() => {
      let time = 0
      if(!localStorage.getItem('configInfo')){
        time = 2000
      }
      setTimeout(()=>{
        configInfo.value = JSON.parse(localStorage.getItem('configInfo'))
        downloadUrl.value = JSON.parse(localStorage.getItem('downloadUrl'))
        downloadUrl.value.forEach(item => {
          if (item.type == 2) {
            APPStore.value = item.download_url
          } else if (item.type == 3) {
            GooglePlay.value = item.download_url
          } else if (item.type == 1) {
            Android.value = item.download_url
          }
        });
      },time)
      
      getBannerList()
      getNewList()
    })

    return {
      t,
      spaceBetween,
      chartData,
      chartData2,
      bannerList,
      token,
      marketlist,
      ranklistUp,
      ranklisDownt,
      cny_rate,
      usd_rate,
      listnew,
      spinning,
      ws,
      configInfo,
      downloadUrl,
      Android,
      GooglePlay,
      APPStore,
      open,
      contentVal,
      nameVal,
      updated_atVal,
      modules,
      onProgress,
      onSlideChange,

      receive,
      getNewList,
      getBannerList,
      contractHref,
      newClick
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'https://cdn.jsdelivr.net/npm/swiper@9/swiper-element-bundle.min.css';

.MainLayout {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.home_page__93UoA {
  width: 100%;
  background: #181a20;
  color: #fff;

  .mainBox {
    width: 1200px;
    margin: 0 auto;
  }

  .noticeBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 35px auto 15px;

    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 55px;

      .icon {
        margin-right: 16px;

        figure {
          margin: 0 !important;
        }
      }

      .swiper {
        height: 55px;

        .itemBox {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .item {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 16px;
          color: #fff;
          cursor: pointer;
          

          p {
            padding: 0 16px;
          }
        }
      }
    }

    .right {
      font-size: 30px;
    }
  }

  .tabImgBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;

    img {
      width: 100%;
      height: 110px;
      display: block;
    }
  }

  .rankingWrap {
    padding-bottom: 25px;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .rankingBox {
        width: 580px;

        margin: 0 15px 20px;
        padding: 15px 0;
        border-radius: 10px;
        background-color: #1e2129;

        .title {
          position: relative;
          width: 100%;
          text-align: center;
          font-size: 1.5rem;
          margin: 0;
          padding-bottom: 1rem;
          border-bottom: 1px solid rgba(119, 126, 144, .533);

          &:after {
            content: "";
            height: 3px;
            width: 24px;
            position: absolute;
            bottom: 0;
            background: #ffd140;
            border-radius: 1px;
            left: calc(50% - 12px);
          }
        }

        table {
          width: 100%;
        }

        tr {
          height: 3rem;

          th {
            font-weight: 400;
            font-size: .875rem;
            color: #777e90;
            height: 3.9rem;
            text-align: right;
          }

          td {
            font-weight: 500;
            font-size: 16px;
            height: 3.9rem;
          }

          th:first-of-type,
          td:first-of-type {
            padding-left: 1rem;
            text-align: left;
          }

          th:nth-of-type(2) {
            text-align: center;
          }

          th:last-of-type {
            padding-right: 1rem;
          }

          td:first-of-type {
            text-align: left;
            // min-width: 10rem;

            .iconBox {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .icon {
                width: 24px;
                height: 24px;
                display: block;
                margin-right: 1rem;
                border-radius: 24px;
              }
            }
          }

          // .echartsBox {
          //   width: 10rem;
          // }

          .downUpBox,
          .priBox {
            min-width: 6rem;
            text-align: right;
          }

          .downUpBox {
            text-align: center;
          }

          .priBox {
            padding-right: 1rem;
          }
        }
      }
    }
  }

  .featureWrap {
    background: #1b1e26;

    .featureBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      gap: 1rem;
      margin-top: 4rem;
    }

    .featureCards {
      width: calc((100% - 3rem)/4);
      width: calc((100% - 3rem)/4);
    }
  }

  .bannerContainer {
    margin: auto;
    text-align: center;
    padding: 4rem 2rem;
    max-width: calc(1200px + 2rem);

    .home_title {
      margin-top: 0;
      margin-bottom: 2rem;
      font-size: 34px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      color: #fff;
      line-height: 48px;
    }

    .home_content {
      line-height: 1.5;
      font-size: 16px;
      font-family: ArialMT, ArialMT-Regular;
      font-weight: 400;
      color: #777e90;
      margin: 2rem 0 !important;
    }
  }

  .home_license {
    background: #181a20;

    .home_licenseBox {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 1rem;
    }

    .home_licenseCard {
      width: calc((100% - 2rem)/3);
      background: #242730;
      border-radius: 1rem;
    }
  }

  .bannerBoxWrap {
    background: #0f1216;
    display: flex;
    justify-content: center;

    .bannerIntro {
      position: relative;
    }

    .bannerContainer {
      padding: 0;
    }

    .bannerImage {
      width: 100%;
    }

    .bannerContent {
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 0;
      font-family: PingFang SC;

      h1,
      h2 {
        color: #fff;
      }

      .singUpButton {
        position: relative;
        background-color: #ffd140;
        color: #0f1216;
        width: 13.75rem;
        padding: .675rem 2rem;
        border-radius: .5rem;

        &:hover {
          &:after {
            opacity: .2;
          }
        }

        &:after {
          content: "";
          position: absolute;
          inset: 0;
          background-color: #fff;
          opacity: 0;
          transition: opacity .3s;
        }
      }

      &>figure {
        width: 110px;
        margin: 0;
      }

      &>h1 {
        margin: 0;
        font-size: 48px;
        font-weight: 600;
      }

      &>h2 {
        margin: .5rem 0 1rem;
        font-weight: 400;
        font-size: 24px;
      }

      .bannerSwiper {
        width: 100%;
        height: 116px;
        padding: 16px 0;

        .swiper-container {
          width: 100%;
          height: 100%;

          .swiper-slide {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .CurrencyBox {
              text-align: left;

              p {
                margin: 0;
              }

              .CurrencyTitle {
                font-size: 16px;
                font-weight: 400;

                .up {
                  color: #02c076;
                }

                .down {
                  color: #f84960;
                }
              }

              .CurrencyPri {
                font-size: 24px;
                font-weight: 500;
              }

              .UsdPri {
                color: #babdc2;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }

  .home_download {
    .home_downloadInfo {
      display: flex;
      width: 100%;
      padding: 1rem 0;

      .home_appPreview {
        flex: 1 1;
      }

      .home_downloadMethod {
        width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .home_qrCode {
          display: flex;
          align-items: center;
          justify-content: center;

          .home_qrCodeImg {
            width: 35%;
            height: auto;
          }

          .home_qrCodeText {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            text-align: start;
            padding: 0 0 0 1rem;
            height: 100%;
            margin: auto 0;
            line-height: 1.25;

            &>span {
              display: block;

              &:first-of-type {
                color: #777e90;
                font-size: 1.25rem;
                font-weight: 300;
                margin-bottom: .75rem;
              }

              &:last-of-type {
                font-size: 1.5rem;
                font-weight: 600;
              }
            }
          }
        }

        .home_appLinks {
          display: flex;
          justify-content: space-evenly;
          width: 100%;
          gap: 1rem;

          a {
            color: #fff;
          }
        }
      }
    }
  }
}

.LicenseCard_container {
  padding: 1rem;

  .LicenseCard_licenseFrame {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #181a20;
    cursor: pointer;
    height: 176px;
    width: 100%;

    .img {
      box-sizing: border-box;
      padding: 0;
      border: none;
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      min-width: 96%;
      max-width: 96%;
      min-height: 84%;
      max-height: 84%;
      object-fit: contain;
    }
  }

  .LicenseCard_country {
    text-align: start;
    font-size: 20px;
    line-height: 1;
    font-weight: 500;
    color: #fff;
    margin: 20px auto !important;
    padding-bottom: 16px;
    border-bottom: 1px solid #494e5b;

    &>span {
      font-size: 14px;
      line-height: 1;
      font-weight: 400;
      color: #777e90;
      padding: 3px 6px;
      border: 1px solid #777e90;
      border-radius: 4px;
      margin-left: 14px;
    }
  }

  .LicenseCard_licenseInfo {
    display: flex;
    justify-content: space-between;
    position: relative;

    &>p {
      margin: 0;
      padding: 0;
      flex: 1 1;
      text-align: start;

      &>span {
        display: block;
        color: #777e90;
        font-size: 16px;

        &:last-of-type {
          color: #fff;
        }
      }

      &:last-of-type {
        padding-left: 1.5rem;
      }
    }

    :after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(50% - .5px);
      width: 1px;
      background-color: #494e5b;
    }
  }

  .LicenseCard_link {
    margin-top: 1rem;
    width: 100%;
    border: 1px solid #ffd140;
    color: #ffd140;
    line-height: 3rem;
    display: block;
    border-radius: 8px;
    font-size: 16px;
  }

  .LicenseCard_imageView {
    display: none;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(.25rem);
    backdrop-filter: blur(.25rem);
    background-color: rgba(24, 26, 32, .2);
    justify-content: center;
    align-items: center;

    &>figure {
      width: 80%;
      height: 90%;
      position: relative;
      cursor: pointer;
    }
  }

  .LicenseCard_modelShow {
    display: flex;

    &>figure {
      -webkit-animation: LicenseCard_zoom__wRqsX .3s;
      animation: LicenseCard_zoom__wRqsX .3s;
    }
  }
}

.noticePopWrap{
  position: fixed;
  top:0;
  left:0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .closedBox{
    position: absolute;
    top:20%;
    right: 25%;
    padding:10px;
    cursor: pointer;
    .closed{
      width: 20px;
      height: 20px;
    }
  }
  .noticePopBox{
    background: #fff;
    border-radius: 10px;
    width: 50%;
    height: 60%;
    text-align: center;
    padding: 20px;
    
    box-sizing: border-box;
      overflow-y: scroll;
    .title{
      font-size: 18px;
      margin-bottom: 10px !important;
    }
    .time{
      font-size: 12px;
    }
    .test{
      margin-top: 20px;
      padding: 0 !important;
      text-align: left;
    }
  }
  
    
    /*控制整个滚动条*/
    ::-webkit-scrollbar {
      background-color: transparent;
      width: 1px;
      height: 5px;
      background-clip: padding-box;
    }

    /*滚动条两端方向按钮*/
    ::-webkit-scrollbar-button {
      background-color: transparent;
    }

    /*滚动条中间滑动部分*/
    ::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    /*滚动条右下角区域*/
    ::-webkit-scrollbar-corner {
      background-color: transparent;
    }
}


@-webkit-keyframes LicenseCard_zoom__wRqsX {
  0% {
    -webkit-transform: scale(.5);
    transform: scale(.5)
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes LicenseCard_zoom__wRqsX {
  0% {
    -webkit-transform: scale(.5);
    transform: scale(.5)
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}</style>
