export default {
    total:{
        certified: 'Certified',
        UnCertified: 'Uncertified',
        login: 'Login',
        register: 'Register',
        registerNow: 'Register Now',
        loginNow: 'Login Now',
        buyNow: 'Buy Now',
        Currency: 'Currency',
        chang24: 'Change',
        NowPrice: 'Latest Price',
        Leverage: 'Leverage',
        Favorites: 'Favorites',
        Announcement:'Announcement',
        dynamic:'Dynamic',
        VerificationCode:'Verification Code',
        enteremailcode:'Please enter the email verification code',
        GetCode:'Send',
        add:'Add',
        help: 'Help Center',
        xszy: "Beginner's Guide",
        jyzn: 'Trading Guide',
        aboutUs: 'About aaaa'
    },
    login:{
        loginTitle: 'Welcome to aaaa',
        email: 'Email',
        enailTest: 'Please enter your email',
        password: 'Password',
        passwordTest: 'Please enter your password',
        unpassword: 'Forgot password?',
        noassets: 'No account yet',
        Addassets: 'Create an account',
        password2: 'Re-enter password',
        password2test: 'Please re-enter your password',
        assets: 'Existing account'
    },
    topHeader: {
        nav01: 'Contract',
        nav02: 'Spot Trading',
        nav03: 'Seconds',
        nav04: 'Copy Trade',
        nav05: 'News',
        nav06:'Convert',
        APPStore: 'APP Store',
        GooglePlay: 'Google Play',
        Android: 'Android',
        IOSaAndroid: 'IOS & Android Scan to Download',
        cn: '简体中文',
        en: 'English',
        assetsHome: 'Assets',
        assets01: 'Asset Details',
        assets02: 'Funds Details',
        assets03: 'Order Details',
        assetsHome02: 'Orders',
        assets0201: 'Contracts',
        assets0202: 'Spot',
        assets0203:'Seconds',
        account01: 'Overview',
        account02: 'Account and Security',
        account03: 'Manage copy trades',
        account04: 'Manage lead trades ',
        loginOut: 'Logout'
    },
    foote:{
        test: 'aaaa is a leading global digital financial service platform that provides a secure, intelligent, and fair environment for digital asset investment through its one-stop digital investment services, diversified asset allocation tools, innovative investment advisory services, and industry-leading liquidity. The core team of aaaa comes from traditional financial securities industries, top-tier internet companies like Microsoft, and other cryptocurrency enterprises, bringing extensive experience in traditional financial trading, top-tier cryptocurrency exchange platform development, and global market operations. Since its establishment six months ago, the global user base has surpassed 100,000 and covers more than 80 countries and regions, including North America, Europe, and the Asia-Pacific region.',
        tab1Title1: 'Help',
        tab1Test1: 'FAQ',
        tab1Test2: 'Announcement Center',
        tab2Title1: 'Terms',
        tab2Test1: 'Terms of Service',
        tab2Test2: 'Privacy Policy',
        tab2Test3: 'Risk Disclosure',
        tab3Title1: 'Join Us',
        msg: 'Risk Warning: Digital asset trading is a nascent industry with bright prospects, but it also carries significant risks due to its new nature. In particular, in contract trading, leverage amplifies both profits and risks simultaneously. It is crucial for you to have a comprehensive understanding of the industry, contract trading models, trading rules, and other related knowledge before participating. We strongly recommend that you trade within the risk limits you can bear. Trading involves risks, and caution is advised when entering the market!',
        banquan: 'Copyright',
        banquan2: 'All rights reserved',
        aboutUs:'About Us',
        Platform:'Platform Introduction'
    },
    home:{
        bannerTitle: 'Buy Crypto in aaaa',
        bannerTest: "The world's leading social cryptocurrency trading exchange",
        changUpRank: 'Gainers Ranking',
        changDownRank: 'Losers Ranking',
        whyTitle: 'Why Choose Us',
        indexTitle1: 'Security Guarantee',
        indexTest1: 'Global decentralized deployment with no systemic risk',
        indexTitle2: 'Mission and Vision',
        indexTest2: 'Let investors enjoy making profits on Cryptocurrency',
        indexTitle3: 'Smart Contracts',
        indexTest3: 'Long and short trading, quick order placement, up to 100x leverage',
        indexTitle4: 'Copy Trading',
        indexTest4: 'One click to follow trades from top traders with high return rate',
        indexTitle5: 'Real-time Discussion',
        indexTest5: 'Real-time Chat',
        indexTitle6: 'Binary',
        indexTest6: "The world's first cryptocurrency index in binary",
        indexTitle7: 'Multi currencies',
        indexTest7: 'Confidently grasp the key to success on 300+ diversified projects',
        indexTitle8: 'Ecosystem',
        indexTest8: 'Create a one-stop service for crypto exchanges, wallet and on-chain ecology',
        downLoadTitle: 'Trading Anytime Anywhere',
        downLoadTest: 'Availble on iOS, Android and PC devices. Trade anytime, anywhere.',
        downLoadCode: 'Scan to Download',
        downLoadIosAnd: 'iOS & Android'
    },
    contract:{
        Change:'24H Change',
        High:'24H High',
        Low:'24H Low',
        Volume24:'24H Volume',
        Market:'Market',
        LimitPrice:'Limit',
        BestPrice:'Trade at the Best Available Price',
        EnterPrice:'Please Enter Price',
        Amount:'Amount',
        Margin:'Margin',
        Available:'Available Balance',
        Leverage:'Leverage',
        Fee:'Transaction Fee',
        Buy:'Buy',
        Long:'Long',
        Sell:'Sell',
        Short:'Short',
        Assets:'Assets',
        ContractAssets:'Contract Assets',
        Deposit:'Deposit now',
        Login:'Log in to Trading Account',
        Register:'Register Trading Account',
        Instrument:'Trading Instrument',
        Minimum:'Minimum Lot Size',
        Maximum:'Maximum Lot Size',
        TradingTime:'Trading Time',
        TradingLeverage:'Trading Leverage',
        FeeRate:'Fee Rate',
        Introduction:'Introduction',
        IssuanceDate:'Issuance Date',
        TotalIssuance:'Total Issuance',
        CirculatingSupply:'Circulating Supply',
        Crowdfunding:'Crowdfunding Price',
        Whitepaper:'Whitepaper',
        Official:'Official Website',
        Blockchain:'Blockchain Explorer',
        Positions:'Positions',
        OpenOrder:'Open Order',
        TradeHistory:'Trade History',
        ProfitLoss:'Profit/Loss',
        CloseAll:'Close All',
        Contract:'Contract',
        LotSize:'Lot Size',
        OpeningPrice:'Opening Price',
        CurrentPrice:'Current Price',
        TakeProfi:'Take Profit Price',
        StopLoss:'Stop Loss Price',
        OrderPL:'Order P/L',
        OpeningTime:'Opening Time',
        OrderID:'Order ID',
        Action:'Action',
        Settings:'Settings',
        ClosePosition:'Close',
        Delete:'Delete',
        Status:'Status',
        EnterCurrency:'Please Enter Currency',
        saveStopLoss:'Set T/P & S/L',
        live:'Live',
        demo:'Demo'
    },
    SpotGoods:{
        pricelimit:'Limit',
        marketprice:'Market',
        Price:'Price',
        MarketPrice:'Market',
        PleaseEnter:'Please Enter',
        Quantity:'Quantity',
        Total:'Total',
        Or:'Or',
        OpenOrders:'Open Orders',
        Fee:'Fee',
        QuantityAcc:'Turnoverl',
        LatestTrade:'Latest Trade',
        Time:'Time',
        NowOrders:'Open Orders',
        AllOrders:'All Orders',
        OrderHistory:'Order History',
        Date:'Date',
        TradingPair:'Trading Pair',
        OrderType:'Order Type',
        MarketOrder:'Market Order',
        LimitOrder:'Limit Order',
        Direction:'Direction',
        OrderPrice:'Order Price',
        OrderQuantity:'Order Quantity',
        OrderAmoun:'Order Amount'
    },
    Binary:{
        GameRules:'Game Rules',
        Trading:'Trading In Progress',
        PositionClosed:'Position Closed',
        BuyLong:'Buy Long',
        BuyShort:'Buy Short',
        OrderPeriod:'Order Period',
        TradingAmount:'Trading Amount',
        Estimated:'Estimated Closing Time',
        ClosingTime:'Closing Time',
        PlaceBet:'Place Bet',
        Amount:'Amount',
        Balance:'Account Balance',
        Odds:'Odds',
        Profit:'Estimated Profit',
        Bullish:'Long',
        Bearish:'Short',
        countTime: 'Count down',
        customInp:'Custom amount'
    },
    follow:{
        Ranking:'Trader Ranking',
        Updates:'Data Updates Every Two Hours',
        SearchKey:'Search All Traders',
        Search:'Search',
        Manage:'Manage follower',
        Details:'Details',
        test1:'Formula = Copy Order [Profit/Loss] / [Total Margin] * 100%',
        title1:'30D ROR',
        test2:'Formula = Self-Placed Order [Profitable Order Count / Total Order Count] * 100%',
        title2:'30D Win Rate',
        title3:'Current followers',
        title4:'30D commission',
        test5:'Total (Self-Placed) Order Count in the Past 30 Days',
        title5:'30D trading lots',
        test6:'Calculated from Registration Date',
        title6:'Trade days',
        Unfollow:'Unfollow',
        Followed:'Followed',
        EditCopying:'Edit Copying',
        CopyingCurrency:'Copying Currency',
        AmountCopying:'Amount Copying',
        CopyingAmount:'Copying Amount',
        ContractTrading:'Contract Trading Pair',
        LotSizeRatio:'Lot Size Ratio',
        StartCopying:'Start Copying',
        CancelCopying:'Cancel Copying',
        title7:'30D drawdown',
        test7:'Asset Loss Ratio in the Past 30 Days (Percentage of Daily Loss Assets to Previous Day Assets, Based on the Maximum Loss)',
        BackLobby:'Back to Lobby',
        TradeHistory:'Trade History',
        ClosingPrice:'Closing Price',
        info:'Personal profile',
        CumulativeCopying:'Cumulative Copying Amount',
        TotalCopying:'Total Copying Profit',
        Discover:'Discover Experts',
        CopyOrders:'Copy Orders',
        CurrentFollowers:'Current Followers',
        Avatar:'Avatar',
        TraderName:'Trader Name',
        MaximumPosition:'Maximum Position Amount',
        ProfitLoss:'Copying Profit/Loss',
        Edit:'Edit',
        TodayProfit:"Today's Profit",
        Past7Days:'Total Profit in the Past 7 Days',
        Accumulated:'Accumulated Total Profit',
        ProfitDetails:'Profit Details',
        Followers:'Followers',
        Profit:'Profit',
        FollowerAvatar:'Follower Avatar',
        FollowerNickname:'Follower Nickname',
        CopyingTime:'Copying Time',
        Notice:'Notice',
        NoticeTest1:'You are not a trader yet, so you cannot perform this operation!',
        NoticeTest2:'Under review, please wait patiently!',
        Cancel:'Cancel',
        ApplyNow:'Apply Now',
        ApplyTrader:'Apply as Trader',
        Nickname:'Nickname (Required)',
        Phone:'Phone Number (Required)',
        Email:'Email (Optional)',
        test1:'After submitting the application, our staff will contact you as soon as possible. Please provide the required application information as requested.',
        test2:'During the audit period, please ensure that the following conditions are not met, otherwise the audit will fail: holding positions, current orders, current plans, and following traders.',
        Apply:'Apply'
    },
    account:{
        Overview:'Overview',
        Security:'Security',
        UserID:'User ID',
        Welcome:'Welcome to aaaa!',
        Welcometest:"Just a few more steps and you're ready to go",
        Registration:'Account Registration',
        SecurityVerification:'Security Verification',
        GotoBind:'Go to Bind',
        QuickBuy:'Quick Buy Coins',
        AssetDetails:'Asset Details',
        Deposit:'Deposit',
        Withdraw:'Withdraw',
        Transfer:'Transfer',
        TransferList: 'Transfer History',
        type: 'Type',
        SpotAccount:'Spot Account',
        FuturesAccount:'Futures Account',
        HideAsset:'Hide Asset Valuation',
        Available:'Available (USDT) Balance',
        checkdetails:'For detailed asset quantities, please check the asset details',
        Recommendations:'Security Recommendations',
        PasswordSet:'Password Settings',
        Change:'Change',
        FundPassword:'Fund Password',
        GotoSet:'Go to Set',
        AddressManagement:'Manage address',
        GotoAdd:'Go to Add',
        Verification:'Identity Verification',
        GotoVerify:'Go to Verify',
        LatestNews:'Latest News',
        SafetyTitle:'Two-Factor Authentication',
        SafetyTest:'To protect your account, it is recommended to complete at least one real-name identity verification',
        SafetyText1:'Used to manage your account login password',
        SafetyText2:'Used to manage your account fund password',
        NotSet:'Not Set',
        Set:'Set',
        SafetyText3:'Protect your trading and account security',
        SafetyText4:'Ensure the accuracy of withdrawal addresses',
        Manage:'Manage',
        InvitationLink:'Invitation Link',
        copyInvitationLink:'Copy',
        InvitationCode:'Invitation Code',
        copyInvitationCode:'Copy',
        enterInvitationCode:'Please enter the invitation code'
    },
    finance:{
        Protocol:'Main Network (Protocol)',
        DepositAddress:'Deposit Address',
        QRCode:'Deposit QR Code',
        DepositTest1:'The minimum deposit amount per transaction must not be less than',
        DepositTest2:', any amount lower than this will not be successful',
        DepositTest3:'Before each deposit, you need to go to this page to copy the address to ensure its accuracy. Using an incorrect address will result in a failed deposit.',
        DepositTest4:'Do not deposit any non-',
        DepositTest5:'assets to the above address, otherwise the assets will not be recoverable.',
        WithdrawalAddress:'Withdrawal Address',
        AddressManagement:'Address Management',
        Memo:'Memo',
        WithdrawalAmount:'Withdrawal Amount',
        AvailableBalance:'Available Balance',
        Withdraw:'Withdraw',
        WithdrawTest1:'Please confirm that the target withdrawal address exists and is activated, otherwise it will result in a failed withdrawal and the assets will not be recoverable.',
        WithdrawTest2:'If there is an address tag, please carefully verify the address tag to prevent asset loss that cannot be recovered.',
        WithdrawTest3:'To ensure the security of funds, when your account security strategy changes or passwords are modified, we will manually review the withdrawal. Please wait patiently for our staff to contact you by phone or email.',
        WithdrawTest4:'Please ensure the security of your computer and browser to prevent information tampering or leakage.',
        WithdrawTest5:'Withdrawal Time (UTC+8): Monday to Sunday, 9:00-22:00.',
        AvailableAssets:'Available Assets',
        GamblingAccount:'Seconds Account',
        notpassword:'You have not set a fund password yet, so you cannot perform this operation!',
        Back:'Back',
        AddAddress:'Add New Address',
        SelectCurrency:'Select Currency',
        Confirm:'Confirm',
        from:'From',
        to:'To'
    },
    assets:{
        ContractPositions:'Contract Positions',
        DepositDetails:'Deposit Details',
        WithdrawalHistory:'Withdrawal History',
        WithdrawalRecords:'Deposit and Withdrawal Records',
        TotalEquivalent:'Total Equivalent Value of All Spot Assets (in USDT)',
        AvailableBalance:'Available Balance in Spot (USDT)',
        HideSmall:'Hide Small Assets',
        Equivalent:'Equivalent',
        FrozenAmount:'Frozen Amount',
        DepositType:'Deposit Type',
        DepositAmount:'Deposit Amount',
        DepositCurrency:'Deposit Currency',
        DepositTime:'Deposit Time',
        WithdrawalType:'Withdrawal Type',
        WithdrawalAmount:'Withdrawal Amount',
        WithdrawalCurrency:'Withdrawal Currency',
        WithdrawalTime:'Withdrawal Time'
    },
    fastChange:{
        Sell:'Sell',
        ReferencePrice:'Reference Price',
        Buy:'Buy',
        Quantity:'Exchangeable Quantity',
        PleaseEnter:'Please enter the desired exchange amount',
        Exchange:'Exchange',
        Trading:'Small Amount Exchange ● Bulk Trading',
        TradingTest:'Quick Exchange for BTC, ETH, and Digital Currencies',
        Step1:'Step 1',
        Step1Test:'Select the currency to exchange',
        Step2:'Step 2',
        Step2Test:'Enter the amount to exchange',
        Step3:'Step 3',
        Step3Test:'Start to exchange'
    },
    Password:{
        ChangePassword:'Change Login Password',
        OldPassword:'Old Login Password',
        enterOldPassword:'Please enter your old login password',
        NewLogin:'New Login Password',
        enterNewLogin:'Please enter your login password',
        againenterNewLogin:'Please enter your login password again',
        FundPassword:'Set Fund Password',
        fundpassword:'Please enter your fund password',
        againfundpassword:'Please enter your fund password again',
        Name:'Name',
        Number:'ID Number',
        photos:'Please upload ID card photos, the first one is the front side, and the second one is the back side',
        photos2:'Please upload your ID photo',
        Upload:'Upload',
        wait:'Under review, please wait!',
        categoryType:'Document type',
        category1:'ID card',
        category2:'Passport',
        category3:"Driver's License"
    },
    add:{
        Copied:"Copied!",
        test29: "Simulation trading ends, real trading awaits you!",
        test30: "Recharge now and enjoy trading fund rewards",
        test31: "Get more support and tools immediately",
        test32: "Recharge to start your profitable journey",
        DemoTrade:"Demo Trade",
        Progress:"In Progress",
        Requested:"Refund Requested",
        Refunded:"Refunded",
        Completed:"Completed",
        test1: "Product Overview",
        test2: "(aaaa) Financial Management Service (FMS) is a product designed by (aaaa) to assist users with idle digital assets in earning profits at low thresholds. It is easy to get started with. (aaaa) Financial Management Service (FMS) offers different term options, allowing users to earn profits by lending to leveraged trading users in the lending market; Fixed-term simple coin earning involves locking in coins to earn PoS rewards or project incentives.",
        test3: "*Please note: Past earnings do not represent future earnings. We do not promise to repay principal and interest within a certain period in currency, physical goods, equity, or any other form of property income return, nor do we promise any other form of property income return.",
        test4: "Secure and Reliable",
        test5: "**(aaaa)** has strict risk control systems, leading security measures, and advanced infrastructure to fully safeguard user asset security.",
        test6: "Flexible Subscription and Redemption",
        test7: "24-hour support for subscription and redemption at any time. Redemption for current simple coin earnings is immediate, while redemption for fixed-term simple coin earnings takes up to 30 minutes.",
        test8: "Fixed-term **(aaaa)** Financial Management Service (FMS)",
        test9: "Fixed-term **(aaaa)** Financial Management Service (FMS) has multiple sources of income, such as rewards obtained through collateralization and rewards based on the Proof of Stake (PoS) mechanism, as well as platform rewards.",
        test10: "What is Annualized Reference Yield?",
        test11: "The Annualized Reference Yield is the expected annualized yield of the product you subscribe to, for reference only.",
        test12: "How is the Expected Earnings Calculated?",
        test13: "Expected earnings are calculated based on your subscription quantity and the product's annualized reference yield, providing an estimate of daily expected earnings, for reference only.",
        test14: "When Does Interest Accrue?",
        test15: "Earnings begin to accrue from 12:00 (UTC+8) the day after subscription.",
        test16: "When Are Earnings Distributed?",
        test17: "Earnings are distributed from 12:00 (UTC+8) the day after interest begins to accrue.",
        test18: "Why Do Different Products Have Different Redemption Periods?",
        test19: "The redemption period refers to the time it takes from confirming redemption to the actual redemption being credited. Assets for fixed-term simple coin earnings are collateralized on the chain, requiring some time for redemption from the chain. Since each public chain operates differently, the redemption periods vary.",
        test20: "Do I Need to Manually Redeem Fixed-Term Products upon Maturity?",
        test21: "This depends on the situation. All fixed-term products will default to automatic renewal. Upon maturity, the same term will be automatically renewed. After enabling automatic renewal, redemption needs to be done manually. The automatic renewal feature is enabled by default. To disable it, please go to the order details page.",
        test22: "Can I Still Earn Income While Waiting for Redemption?",
        test23: "Once redemption is confirmed, interest stops accruing. Income cannot be earned while waiting for redemption.",
        test24: "How to Choose the Best Fixed-term **(aaaa)** Financial Management Service (FMS) Product?",
        test25: "You can compare different digital assets, terms, historical returns, etc., among all simple coin earning products we provide, to choose the product that suits you. We only provide asset collateralization, reward collection, and distribution services and do not provide any investment advice. Before making an investment decision, you should fully understand the nature and risks of this product, as well as other relevant circumstances, and make independent judgments based on your investment experience, financial status, investment goals, investment period, willingness and ability to bear investment risks, and other relevant circumstances, and be responsible for your own decisions.",
        test26: "Income",
        test27: "Advantage",
        test28: "FAQs",
        assets:"Financial Account",
        Redemption:"Redemption",
        Subscribe:"Subscribe",
        SubscribeType: "Subscription Type",
        SubscribeNum: "Subscription Amount",
        Historical: "Accumulated Interest",
        SubscribeTime: "Subscription Time",
        RedemptionTime: "Redemption Time",
        RedemptionConfirm: "Redemption Confirmation",
        LockedSavings: "Fixed-term Investment",
        test33: "Financial Management",
        test34: "Understand the core concepts and risk management strategies of cryptocurrency investment, face market fluctuations calmly, and achieve financial goals. Join us now and start your journey into cryptocurrency investment!",
        test35: "Master the essence of cryptocurrency investment, interpret market trends, explore potential opportunities, and achieve wealth appreciation. No threshold, low risk, high returns. Start your cryptocurrency wealth management journey and move towards new heights of wealth!",
        test36: "Product List",
        test37: "Subscription List",
        test38: "Annual Interest Rate:",
        test39: "Days：",
        test40: "Minimum Investment Amount:",
        test41: "Maximum Amount:",
        test42: "Start Date of Interest:",
        test43: "Interest Calculation End Date:",
        test44: "Maximum",
        test45: "Available Assets:",
        test46: "Estimated Profit:",
        test47: "I have read and agree to",
        test48: "Product Overview",
        test49:" Day",
        Agreement:"Financial Purchase Agreement",
        test50:"Recharge Amount",
        test51:"Upload transfer voucher",
        EnterPrice:"Please Enter Amount",
    }
}