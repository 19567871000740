export default {
    total:{
        certified: 'Certificado',
        UnCertified: 'Não Certificado',
        login: 'Entrar',
        register: 'Registrar',
        registerNow: 'Registrar Agora',
        loginNow: 'Entrar Agora',
        buyNow: 'Comprar Agora',
        Currency: 'Moeda',
        chang24: 'Variação',
        NowPrice: 'Último Preço',
        Leverage: 'Alavancagem',
        Favorites: 'Favoritos',
        Announcement: 'Anúncio',
        dynamic: 'Dinâmico',
        VerificationCode: 'Código de Verificação',
        enteremailcode: 'Por favor, digite o código de verificação por e-mail',
        GetCode: 'Enviar',
        add:'Adicionar',
        help: 'Centro de Ajuda',
        xszy: 'Guia para Iniciantes',
        jyzn: 'Guia de Negociação',
        aboutUs: 'Sobre a aaaa'
    },
    login:{
        loginTitle: 'Bem-vindo ao aaaa',
        email: 'Email',
        enailTest: 'Por favor, insira seu email',
        password: 'Senha',
        passwordTest: 'Por favor, insira sua senha',
        unpassword: 'Esqueceu a senha?',
        noassets: 'Ainda não possui uma conta',
        Addassets: 'Criar uma conta',
        password2: 'Reinsira a senha',
        password2test: 'Por favor, reinsira sua senha',
        assets: 'Conta existente'
    },
    topHeader: {
        nav01: 'Contrato',
        nav02: 'Negociação à vista',
        nav03: 'Segundos',
        nav04: 'Copiar Negociação',
        nav05: 'Notícias',
        nav06:'Troca Rápida',
        APPStore: 'APP Store',
        GooglePlay: 'Google Play',
        Android: 'Android',
        IOSaAndroid: 'IOS e Android Escanear para Baixar',
        cn: '简体中文',
        en: 'English',
        assetsHome: 'Ativos',
        assets01: 'Detalhes do Ativo',
        assets02: 'Detalhes dos Fundos',
        assets03: 'Detalhes do Pedido',
        assetsHome02: 'Pedidos',
        assets0201: 'Contratos',
        assets0202: 'À vista',
        assets0203:'Segundos',
        account01: 'Visão Geral',
        account02: 'Conta e Segurança',
        account03: 'Gerenciar cópias de negociação',
        account04: 'Gerenciar negociações líderes',
        loginOut: 'Sair'
    },
    foote:{
        test: 'A aaaa é uma plataforma líder de serviços financeiros digitais globais que oferece um ambiente seguro, inteligente e justo para investimentos em ativos digitais por meio de seus serviços de investimento digital completos, ferramentas de alocação de ativos diversificados, serviços inovadores de consultoria de investimentos e liquidez de ponta do setor. A equipe principal da aaaa vem das indústrias tradicionais de valores mobiliários financeiros, empresas de internet de primeira linha como a Microsoft e outras empresas de criptomoedas, trazendo uma ampla experiência em negociação financeira tradicional, desenvolvimento de plataformas de câmbio de criptomoedas de primeira linha e operações de mercado global. Desde sua criação há seis meses, a base de usuários globais ultrapassou 100.000 e abrange mais de 80 países e regiões, incluindo América do Norte, Europa e região da Ásia-Pacífico.',
        tab1Title1: 'Ajuda',
        tab1Test1: 'Perguntas Frequentes',
        tab1Test2: 'Centro de Anúncios',
        tab2Title1: 'Termos',
        tab2Test1: 'Termos de Serviço',
        tab2Test2: 'Política de Privacidade',
        tab2Test3: 'Divulgação de Riscos',
        tab3Title1: 'Junte-se a nós',
        msg: 'Aviso de Risco: A negociação de ativos digitais é uma indústria emergente com perspectivas promissoras, mas também apresenta riscos significativos devido à sua natureza nova. Em particular, na negociação de contratos, a alavancagem amplifica tanto os lucros quanto os riscos simultaneamente. É crucial que você tenha uma compreensão abrangente da indústria, dos modelos de negociação de contratos, das regras de negociação e de outros conhecimentos relacionados antes de participar. Recomendamos fortemente que você negocie dentro dos limites de risco que pode suportar. A negociação envolve riscos, e é aconselhável ter cautela ao entrar no mercado!',
        banquan: 'Direitos Autorais',
        banquan2: 'Todos os direitos reservados',
        aboutUs: 'Sobre Nós',
        Platform:'Introdução à plataforma'
    },
    home:{
        bannerTitle: 'Compre Criptomoedas na aaaa',
        bannerTest: 'A principal bolsa de negociação de criptomoedas social do mundo',
        changUpRank: 'Ranking dos Ganadores',
        changDownRank: 'Ranking dos Perdedores',
        whyTitle: 'Por Que Nos Escolher',
        indexTitle1: 'Garantia de Segurança',
        indexTest1: 'Implantação descentralizada global sem risco sistêmico',
        indexTitle2: 'Missão e Visão',
        indexTest2: 'Permitir que os investidores desfrutem de lucros no mercado de criptomoedas',
        indexTitle3: 'Contratos Inteligentes',
        indexTest3: 'Negociação longa e curta, rápida realização de pedidos, alavancagem de até 100x',
        indexTitle4: 'Negociação por Cópia',
        indexTest4: 'Siga os negócios de traders experientes com alta taxa de retorno em apenas um clique',
        indexTitle5: 'Discussão em Tempo Real',
        indexTest5: 'Chat em tempo real',
        indexTitle6: 'Binário',
        indexTest6: 'O primeiro índice de criptomoedas binário do mundo',
        indexTitle7: 'Multi Moedas',
        indexTest7: 'Agarre com confiança a chave do sucesso em mais de 300 projetos diversificados',
        indexTitle8: 'Ecossistema',
        indexTest8: 'Crie um serviço completo para bolsas de criptomoedas, carteiras e ecologia on-chain',
        downLoadTitle: 'Negocie a Qualquer Hora, em Qualquer Lugar',
        downLoadTest: 'Disponível em dispositivos iOS, Android e PC. Negocie a qualquer hora, em qualquer lugar.',
        downLoadCode: 'Escanear para Baixar',
        downLoadIosAnd: 'iOS e Android'
    },
    contract:{
        Change: 'Variação 24H',
        High: 'Máximo 24H',
        Low: 'Mínimo 24H',
        Volume24: 'Volume 24H',
        Market: 'Mercado',
        LimitPrice: 'Limite',
        BestPrice: 'Negocie pelo Melhor Preço Disponível',
        EnterPrice: 'Por favor, insira o preço',
        Amount: 'Quantidade',
        Margin: 'Margem',
        Available: 'Saldo Disponível',
        Leverage: 'Alavancagem',
        Fee: 'Taxa de Transação',
        Buy: 'Comprar',
        Long: 'Longo',
        Sell: 'Vender',
        Short: 'Curto',
        Assets: 'Ativos',
        ContractAssets: 'Ativos do Contrato',
        Deposit: 'Depositar agora',
        Login: 'Acessar Conta de Negociação',
        Register: 'Registrar Conta de Negociação',
        Instrument: 'Instrumento de Negociação',
        Minimum: 'Tamanho Mínimo do Lote',
        Maximum: 'Tamanho Máximo do Lote',
        TradingTime: 'Horário de Negociação',
        TradingLeverage: 'Alavancagem de Negociação',
        FeeRate: 'Taxa de Taxa',
        Introduction: 'Introdução',
        IssuanceDate: 'Data de Emissão',
        TotalIssuance: 'Emissão Total',
        CirculatingSupply: 'Oferta Circulante',
        Crowdfunding: 'Preço de Crowdfunding',
        Whitepaper: 'Whitepaper',
        Official: 'Website Oficial',
        Blockchain: 'Explorador de Blockchain',
        Positions: 'Posições',
        OpenOrder: 'Ordem em Aberto',
        TradeHistory: 'Histórico de Negociações',
        ProfitLoss: 'Lucro/Prejuízo',
        CloseAll: 'Fechar Tudo',
        Contract: 'Contrato',
        LotSize: 'Tamanho do Lote',
        OpeningPrice: 'Preço de Abertura',
        CurrentPrice: 'Preço Atual',
        TakeProfi: 'Preço de Take Profit',
        StopLoss: 'Preço de Stop Loss',
        OrderPL: 'Lucro/Prejuízo da Ordem',
        OpeningTime: 'Horário de Abertura',
        OrderID: 'ID da Ordem',
        Action: 'Ação',
        Settings: 'Configurações',
        ClosePosition: 'Fechar',
        Delete: 'Excluir',
        Status: 'Status',
        EnterCurrency: 'Por favor, insira a moeda',
        saveStopLoss: 'Definir T/P & S/L',
        live:'Em direto',
        demo:'Demonstração'
    },
    SpotGoods:{
        pricelimit: 'Limite',
        marketprice: 'Mercado',
        Price: 'Preço',
        MarketPrice: 'Preço de Mercado',
        PleaseEnter: 'Por favor, insira',
        Quantity: 'Quantidade',
        Total: 'Total',
        Or: 'Ou',
        OpenOrders: 'Ordens em Aberto',
        Fee: 'Taxa',
        QuantityAcc: 'Volume de Negociação',
        LatestTrade: 'Última Negociação',
        Time: 'Tempo',
        NowOrders: 'Ordens em Aberto',
        AllOrders: 'Todas as Ordens',
        OrderHistory: 'Histórico de Ordens',
        Date: 'Data',
        TradingPair: 'Par de Negociação',
        OrderType: 'Tipo de Ordem',
        MarketOrder: 'Ordem de Mercado',
        LimitOrder: 'Ordem de Limite',
        Direction: 'Direção',
        OrderPrice: 'Preço da Ordem',
        OrderQuantity: 'Quantidade da Ordem',
        OrderAmoun: 'Valor da Ordem'
    },
    Binary:{
        GameRules: 'Regras do Jogo',
        Trading: 'Negociação em Andamento',
        PositionClosed: 'Posição Fechada',
        BuyLong: 'Comprar Longo',
        BuyShort: 'Comprar Curto',
        OrderPeriod: 'Período de Ordem',
        TradingAmount: 'Valor de Negociação',
        Estimated: 'Tempo Estimado de Fechamento',
        ClosingTime: 'Tempo de Fechamento',
        PlaceBet: 'Faça sua Aposta',
        Amount: 'Quantidade',
        Balance: 'Saldo da Conta',
        Odds: 'Probabilidades',
        Profit: 'Lucro Estimado',
        Bullish: 'Longo',
        Bearish: 'Curto',
        countTime: 'Contagem regressiva',
        customInp: 'Valor personalizado'
    },
    follow:{
        Ranking: 'Ranking de Traders',
        Updates: 'Atualizações de Dados a Cada Duas Horas',
        SearchKey: 'Buscar Todos os Traders',
        Search: 'Buscar',
        Manage: 'Gerenciar Seguidor',
        Details: 'Detalhes',
        test1: 'Fórmula = Ordem Copiada [Lucro/Prejuízo] / [Margem Total] * 100%',
        title1: '30D ROR',
        test2: 'Fórmula = Ordem Própria [Contagem de Ordens Lucrativas / Contagem Total de Ordens] * 100%',
        title2: 'Taxa de Acertos 30D',
        title3: 'Seguidores Atuais',
        title4: 'Comissão 30D',
        test5: 'Contagem Total (Própria) de Ordens nos Últimos 30 Dias',
        title5: 'transação 30D',
        test6: 'Calculado a partir da Data de Registro',
        title6: 'Dias de Negociação',
        Unfollow: 'Deixar de Seguir',
        Followed: 'Seguindo',
        EditCopying: 'Editar Cópia',
        CopyingCurrency: 'Moeda de Cópia',
        AmountCopying: 'Quantidade de Cópia',
        CopyingAmount: 'Valor de Cópia',
        ContractTrading: 'Par de Negociação de Contrato',
        LotSizeRatio: 'Taxa de Tamanho de Lote',
        StartCopying: 'Iniciar Cópia',
        CancelCopying: 'Cancelar Cópia',
        title7: 'Redução 30D',
        test7: 'Taxa de Perda de Ativos nos Últimos 30 Dias (Porcentagem de Perda Diária de Ativos em Relação aos Ativos do Dia Anterior, com Base na Perda Máxima)',
        BackLobby: 'Voltar para Lobby',
        TradeHistory: 'Histórico de Negociações',
        ClosingPrice: 'Preço de Fechamento',
        info: 'Perfil Pessoal',
        CumulativeCopying: 'Valor de Cópia Cumulativo',
        TotalCopying: 'Lucro Total de Cópia',
        Discover: 'Descubra Especialistas',
        CopyOrders: 'Copiar Ordens',
        CurrentFollowers: 'Seguidores Atuais',
        Avatar: 'Avatar',
        TraderName: 'Nome do Trader',
        MaximumPosition: 'Valor Máximo de Posição',
        ProfitLoss: 'Lucro/Prejuízo de Cópia',
        Edit: 'Editar',
        TodayProfit: 'Lucro de Hoje',
        Past7Days: 'Lucro Total nos Últimos 7 Dias',
        Accumulated: 'Lucro Total Acumulado',
        ProfitDetails: 'Detalhes do Lucro',
        Followers: 'Seguidores',
        Profit: 'Lucro',
        FollowerAvatar: 'Avatar do Seguidor',
        FollowerNickname: 'Apelido do Seguidor',
        CopyingTime: 'Tempo de Cópia',
        Notice: 'Aviso',
        NoticeTest1: 'Você ainda não é um trader, portanto não pode realizar essa operação!',
        NoticeTest2: 'Em análise, por favor, aguarde com paciência!',
        Cancel: 'Cancelar',
        ApplyNow: 'Aplicar Agora',
        ApplyTrader: 'Aplicar como Trader',
        Nickname: 'Apelido (Obrigatório)',
        Phone: 'Número de Telefone (Obrigatório)',
        Email: 'Email (Opcional)',
        test1: 'Após enviar a aplicação, nossa equipe entrará em contato o mais breve possível. Por favor, forneça as informações de aplicação necessárias conforme solicitado.',
        test2: 'Durante o período de análise, certifique-se de que as seguintes condições não sejam atendidas, caso contrário, a análise falhará: manter posições, ordens atuais, planos atuais e seguir traders.',
        Apply: 'Aplicar'
    },
    account:{
        Overview: 'Visão Geral',
        Security: 'Segurança',
        UserID: 'ID do Usuário',
        Welcome: 'Bem-vindo à aaaa!',
        Welcometest: 'Mais alguns passos e você está pronto para começar',
        Registration: 'Registro da Conta',
        SecurityVerification: 'Verificação de Segurança',
        GotoBind: 'Ir para Vinculação',
        QuickBuy: 'Compra Rápida de Criptomoedas',
        AssetDetails: 'Detalhes dos Ativos',
        Deposit: 'Depósito',
        Withdraw: 'Retirada',
        Transfer: 'Transferência',
        TransferList: 'Histórico de Transferências',
        type: 'Tipo',
        SpotAccount: 'Conta Spot',
        FuturesAccount: 'Conta de Futuros',
        HideAsset: 'Ocultar Valor dos Ativos',
        Available: 'Saldo Disponível (USDT)',
        checkdetails: 'Para quantidades detalhadas dos ativos, verifique os detalhes dos ativos',
        Recommendations: 'Recomendações de Segurança',
        PasswordSet: 'Configurações de Senha',
        Change: 'Alterar',
        FundPassword: 'Senha de Fundos',
        GotoSet: 'Ir para Configurar',
        AddressManagement: 'Gerenciar Endereço',
        GotoAdd: 'Ir para Adicionar',
        Verification: 'Verificação de Identidade',
        GotoVerify: 'Ir para Verificar',
        LatestNews: 'Últimas Notícias',
        SafetyTitle: 'Autenticação de Dois Fatores',
        SafetyTest: 'Para proteger sua conta, é recomendado completar pelo menos uma verificação de identidade com nome real',
        SafetyText1: 'Usado para gerenciar a senha de login da sua conta',
        SafetyText2: 'Usado para gerenciar a senha de fundos da sua conta',
        NotSet: 'Não Configurado',
        Set: 'Configurar',
        SafetyText3: 'Proteja a segurança das suas negociações e da sua conta',
        SafetyText4: 'Garanta a precisão dos endereços de retirada',
        Manage: 'Gerenciar',
        InvitationLink: 'Link de Convite',
        copyInvitationLink: 'cópia de',
        InvitationCode: 'Código de Convite',
        copyInvitationCode: 'cópia de',
        enterInvitationCode: 'Por favor, insira o código de convite'
    },
    finance:{
        Protocol: 'Rede Principal (Protocolo)',
        DepositAddress: 'Endereço de Depósito',
        QRCode: 'Código QR de Depósito',
        DepositTest1: 'O valor mínimo de depósito por transação não pode ser inferior a',
        DepositTest2: ', qualquer valor abaixo disso não será bem-sucedido',
        DepositTest3: 'Antes de cada depósito, você precisa acessar esta página para copiar o endereço e garantir sua precisão. Usar um endereço incorreto resultará em falha no depósito.',
        DepositTest4: 'Não deposite nenhum ativo não-',
        DepositTest5: 'no endereço acima, caso contrário, os ativos não serão recuperáveis.',
        WithdrawalAddress: 'Endereço de Retirada',
        AddressManagement: 'Gerenciamento de Endereço',
        Memo: 'Memo',
        WithdrawalAmount: 'Valor de Retirada',
        AvailableBalance: 'Saldo Disponível',
        Withdraw: 'Retirar',
        WithdrawTest1: 'Por favor, confirme que o endereço de retirada de destino existe e está ativado, caso contrário, resultará em falha na retirada e os ativos não serão recuperáveis.',
        WithdrawTest2: 'Se houver uma tag de endereço, verifique cuidadosamente a tag de endereço para evitar perda de ativos que não possam ser recuperados.',
        WithdrawTest3: 'Para garantir a segurança dos fundos, quando a estratégia de segurança da sua conta mudar ou as senhas forem modificadas, faremos uma revisão manual da retirada. Por favor, aguarde pacientemente nosso pessoal entrar em contato com você por telefone ou e-mail.',
        WithdrawTest4: 'Por favor, garanta a segurança do seu computador e navegador para evitar adulteração ou vazamento de informações.',
        WithdrawTest5: 'Horário de Retirada (UTC+8): segunda a domingo, das 9:00 às 22:00.',
        AvailableAssets: 'Ativos Disponíveis',
        GamblingAccount: 'Conta de Seconds',
        notpassword: 'Você ainda não definiu uma senha de fundos, portanto não pode realizar essa operação!',
        Back: 'Voltar',
        AddAddress: 'Adicionar Novo Endereço',
        SelectCurrency: 'Selecionar Moeda',
        Confirm: 'Confirmar',
        from: 'De',
        to: 'Para'
    },
    assets:{
        ContractPositions: 'Posições de Contrato',
        DepositDetails: 'Detalhes de Depósito',
        WithdrawalHistory: 'Histórico de Retiradas',
        WithdrawalRecords: 'Registros de Depósito e Retirada',
        TotalEquivalent: 'Valor Total Equivalente de Todos os Ativos Spot (em USDT)',
        AvailableBalance: 'Saldo Disponível em Spot (USDT)',
        HideSmall: 'Ocultar Pequenos Ativos',
        Equivalent: 'Equivalente',
        FrozenAmount: 'Valor Congelado',
        DepositType: 'Tipo de Depósito',
        DepositAmount: 'Valor de Depósito',
        DepositCurrency: 'Moeda de Depósito',
        DepositTime: 'Hora de Depósito',
        WithdrawalType: 'Tipo de Retirada',
        WithdrawalAmount: 'Valor de Retirada',
        WithdrawalCurrency: 'Moeda de Retirada',
        WithdrawalTime: 'Hora de Retirada'
    },
    fastChange:{
        Sell: 'Vender',
        ReferencePrice: 'Preço de Referência',
        Buy: 'Comprar',
        Quantity: 'Quantidade para Troca',
        PleaseEnter: 'Por favor, insira a quantidade desejada para troca',
        Exchange: 'Trocar',
        Trading: 'Pequena Quantidade de Troca ● Negociação em Volume',
        TradingTest: 'Troca Rápida de BTC, ETH e Moedas Digitais',
        Step1: 'Passo 1',
        Step1Test: 'Selecione a moeda para troca',
        Step2: 'Passo 2',
        Step2Test: 'Insira a quantidade para troca',
        Step3: 'Passo 3',
        Step3Test: 'Iniciar a troca'
    },
    Password:{
        ChangePassword: 'Alterar Senha de Login',
        OldPassword: 'Senha Antiga de Login',
        enterOldPassword: 'Por favor, insira sua senha antiga de login',
        NewLogin: 'Nova Senha de Login',
        enterNewLogin: 'Por favor, insira sua nova senha de login',
        againenterNewLogin: 'Por favor, insira sua nova senha de login novamente',
        FundPassword: 'Definir Senha de Fundos',
        fundpassword: 'Por favor, insira sua senha de fundos',
        againfundpassword: 'Por favor, insira sua senha de fundos novamente',
        Name: 'Nome',
        Number: 'Número de Identificação',
        photos: 'Por favor, faça upload das fotos do seu documento de identidade, a primeira é a frente e a segunda é o verso',
        photos2: 'Por favor, carregue sua foto de identificação',
        Upload: 'Enviar',
        wait: 'Em análise, por favor, aguarde!',
        categoryType:'Tipo de documento',
        category1:'cartão de identificação',
        category2:'Passaporte',
        category3:"Carteira de motorista"
    },
    add:{
        Copied:"Copiado!",
        test29: "A negociação de simulação termina, a negociação real aguarda você!",
        test30: "Recarregue agora e aproveite as recompensas de fundos de negociação",
        test31: "Obtenha mais suporte e ferramentas imediatamente",
        test32: "Recarregue para iniciar sua jornada lucrativa",
        DemoTrade:"Demonstração",
        Progress: "Em Andamento",
        Requested: "Reembolso Solicitado",
        Refunded: "Reembolsado",
        Completed: "Concluído",
        test1: "Visão Geral do Produto",
        test2: "O Serviço de Gestão Financeira (FMS) da (aaaa) é um produto projetado para ajudar os usuários com ativos digitais ociosos a obter lucros em limiares baixos. É fácil começar. O Serviço de Gestão Financeira (FMS) da (aaaa) oferece diferentes opções de prazo, permitindo aos usuários obter lucros emprestando para usuários de negociação alavancada no mercado de empréstimos; O ganho de moedas simples a prazo fixo envolve o bloqueio de moedas para obter recompensas PoS ou incentivos de projeto.",
        test3: "* Por favor, note: Os ganhos passados não representam ganhos futuros. Não prometemos reembolsar principal e juros dentro de um determinado período em moeda, bens físicos, patrimônio líquido, ou qualquer outra forma de retorno de renda de propriedade, nem prometemos qualquer outra forma de retorno de renda de propriedade.",
        test4: "Seguro e Confiável",
        test5: "(aaaa) possui sistemas rigorosos de controle de riscos, medidas de segurança avançadas e infraestrutura avançada para proteger totalmente a segurança dos ativos dos usuários.",
        test6: "Assinatura e Resgate Flexíveis",
        test7: "Suporte 24 horas para assinatura e resgate a qualquer momento. O resgate para ganhos de moedas simples atuais é imediato, enquanto o resgate para ganhos de moedas simples a prazo fixo leva até 30 minutos.",
        test8: "Serviço de Gestão Financeira (FMS) de Prazo Fixo da (aaaa)",
        test9: "O Serviço de Gestão Financeira (FMS) de Prazo Fixo da (aaaa) possui múltiplas fontes de renda, como recompensas obtidas por meio de colateralização e recompensas baseadas no mecanismo de Prova de Participação (PoS), além de recompensas da plataforma.",
        test10: "O que é Rendimento Anualizado de Referência?",
        test11: "O Rendimento Anualizado de Referência é o rendimento anualizado esperado do produto ao qual você se inscreve, apenas para referência.",
        test12: "Como São Calculados os Ganhos Esperados?",
        test13: "Os ganhos esperados são calculados com base na quantidade da sua assinatura e no rendimento anualizado de referência do produto, fornecendo uma estimativa dos ganhos diários esperados, apenas para referência.",
        test14: "Quando os Juros Acumulam?",
        test15: "Os ganhos começam a acumular a partir das 12:00 (UTC+8) do dia seguinte à assinatura.",
        test16: "Quando os Ganhos São Distribuídos?",
        test17: "Os ganhos são distribuídos a partir das 12:00 (UTC+8) do dia seguinte ao início da acumulação de juros.",
        test18: "Por Que os Diferentes Produtos Têm Períodos de Resgate Diferentes?",
        test19: "O período de resgate refere-se ao tempo necessário desde a confirmação do resgate até o resgate real ser creditado. Os ativos para ganhos de moedas simples a prazo fixo são garantidos na cadeia, requerendo algum tempo para o resgate da cadeia. Como cada cadeia pública opera de maneira diferente, os períodos de resgate variam.",
        test20: "Preciso Resgatar Manualmente os Produtos a Prazo Fixo ao Maturarem?",
        test21: "Isso depende da situação. Todos os produtos a prazo fixo serão renovados automaticamente por padrão. Ao vencer, o mesmo prazo será renovado automaticamente. Após habilitar a renovação automática, o resgate precisa ser feito manualmente. A renovação automática é ativada por padrão. Para desativá-la, por favor, vá para a página de detalhes do pedido.",
        test22: "Posso Ainda Ganhar Renda Enquanto Aguardo o Resgate?",
        test23: "Uma vez que o resgate é confirmado, os juros deixam de acumular. Não é possível obter renda enquanto aguarda o resgate.",
        test24: "Como Escolher o Melhor Produto de Serviço de Gestão Financeira de (aaaa) de Prazo Fixo (FMS)?",
        test25: "Você pode comparar diferentes ativos digitais, termos, retornos históricos, etc., entre todos os produtos de ganho de moeda simples que oferecemos, para escolher o produto que mais lhe convém. Nós fornecemos apenas serviços de garantia de ativos, coleta e distribuição de recompensas e não fornecemos nenhum conselho de investimento. Antes de tomar uma decisão de investimento, você deve entender completamente a natureza e os riscos deste produto, bem como outras circunstâncias relevantes, e tomar decisões independentes com base em sua experiência de investimento, situação financeira, objetivos de investimento, período de investimento, disposição e capacidade de suportar riscos de investimento, e outras circunstâncias relevantes, e ser responsável por suas próprias decisões.",
        test26: "Renda",
        test27: "Vantagem",
        test28: "FAQs",
        assets: "Financeiro",
        Redemption: "Resgate",
        Subscribe: "Inscrever-se",
        SubscribeType: "Tipo de Subscrição",
        SubscribeNum: "Montante da Subscrição",
        Historical: "Juros Acumulados",
        SubscribeTime: "Hora da Subscrição",
        RedemptionTime: "Hora de Resgate",
        RedemptionConfirm: "Confirmação de Resgate",
        LockedSavings: "Investimento a Prazo Fixo",
        test33: "Gestão Financeira",
        test34: "Compreenda os conceitos principais e as estratégias de gestão de riscos do investimento em criptomoedas, enfrente as flutuações do mercado com calma e alcance objetivos financeiros. Junte-se a nós agora e inicie sua jornada no investimento em criptomoedas!",
        test35: "Domine a essência do investimento em criptomoedas, interprete as tendências de mercado, explore oportunidades potenciais e obtenha apreciação de riqueza. Sem limite mínimo, baixo risco, altos retornos. Inicie sua jornada de gestão de riqueza em criptomoedas e avance para novas alturas de riqueza!",
        test36: "Lista de Produtos",
        test37: "Lista de Subscrição",
        test38: "Taxa de Juros Anual:",
        test39: "Dias：",
        test40: "Valor Mínimo de Investimento:",
        test41: "Montante Máximo:",
        test42: "Data de Início dos Juros:",
        test43: "Data de Fim de Cálculo dos Juros:",
        test44: "Máximo",
        test45: "Ativos Disponíveis:",
        test46: "Lucro Estimado:",
        test47: "Li e concordo com",
        test48: "Visão Geral do Produto",
        test49:"dia",
        Agreement: "Acordo de Compra Financeira",
        test50:"Quantidade de recarga",
        test51:"Carregar comprovante de transferência",
        EnterPrice: "Por favor, Insira o Preço",
    }
}