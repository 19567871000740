export default {
    total:{
        certified: 'معتمد',
        UnCertified: 'غير معتمد',
        login: 'تسجيل الدخول',
        register: 'تسجيل',
        registerNow: 'سجل الآن',
        loginNow: 'تسجيل الدخول الآن',
        buyNow: 'شراء الآن',
        Currency: 'العملة',
        chang24: 'التغير',
        NowPrice: 'آخر سعر',
        Leverage: 'الرافعة المالية',
        Favorites: 'المفضلة',
        Announcement: 'الإعلانات',
        dynamic: 'ديناميكية',
        VerificationCode: 'رمز التحقق',
        enteremailcode: 'يرجى إدخال رمز التحقق عبر البريد الإلكتروني',
        GetCode: 'إرسال',
        add:'يضيف',
        help: 'مركز المساعدة',
        xszy: 'دليل المبتدئين',
        jyzn: 'دليل التداول',
        aboutUs: 'حول بيتوب'
    },
    login:{
        loginTitle: 'مرحبًا بك في بيتوب',
        email: 'البريد الإلكتروني',
        enailTest: 'يرجى إدخال البريد الإلكتروني الخاص بك',
        password: 'كلمة المرور',
        passwordTest: 'يرجى إدخال كلمة المرور الخاصة بك',
        unpassword: 'نسيت كلمة المرور؟',
        noassets: 'ليس لديك حساب بعد',
        Addassets: 'إنشاء حساب',
        password2: 'إعادة إدخال كلمة المرور',
        password2test: 'يرجى إعادة إدخال كلمة المرور الخاصة بك',
        assets: 'حساب موجود'
    },
    topHeader: {
        nav01: 'العقود',
        nav02: 'تداول الفوركس',
        nav03: 'ثوانٍ',
        nav04: 'تداول المتابعة',
        nav05: 'الأخبار',
        nav06:'سريع التغيير',
        APPStore: 'متجر APP',
        GooglePlay: 'جوجل بلاي',
        Android: 'أندرويد',
        IOSaAndroid: 'IOS و Android امسح للتنزيل',
        cn: 'الصينية المبسطة',
        en: 'الإنجليزية',
        assetsHome: 'الأصول',
        assets01: 'تفاصيل الأصول',
        assets02: 'تفاصيل الأموال',
        assets03: 'تفاصيل الطلب',
        assetsHome02: 'الطلبات',
        assets0201: 'العقود',
        assets0202: 'المنصة',
        assets0203:'العقد الثاني',
        account01: 'نظرة عامة',
        account02: 'الحساب والأمان',
        account03: 'إدارة تداول المتابعة',
        account04: 'إدارة تداول المشاركة ',
        loginOut: 'تسجيل الخروج'
    },
    foote:{
        test: 'aaaa هي منصة خدمات مالية رقمية عالمية رائدة توفر بيئة آمنة وذكية وعادلة للاستثمار في الأصول الرقمية من خلال خدمات الاستثمار الرقمي الشاملة، وأدوات توزيع الأصول المتنوعة، وخدمات الاستشارة الاستثمارية الابتكارية، والسيولة الرائدة في الصناعة. يأتي الفريق الأساسي لشركة aaaa من صناع الأوراق المالية التقليدية وشركات الإنترنت من الدرجة الأولى مثل Microsoft وشركات العملات الرقمية الأخرى، ويجلب خبرة واسعة في التداول المالي التقليدي، وتطوير منصة تداول العملات الرقمية من الدرجة الأولى، وعمليات السوق العالمية. منذ إنشائها قبل ستة أشهر، تجاوزت قاعدة المستخدمين العالمية 100،000 وتغطي أكثر من 80 دولة ومنطقة، بما في ذلك أمريكا الشمالية وأوروبا ومنطقة آسيا والمحيط الهادئ.',
        tab1Title1: 'المساعدة',
        tab1Test1: 'أسئلة وأجوبة شائعة',
        tab1Test2: 'مركز الإعلانات',
        tab2Title1: 'الشروط',
        tab2Test1: 'شروط الخدمة',
        tab2Test2: 'سياسة الخصوصية',
        tab2Test3: 'إفصاح المخاطر',
        tab3Title1: 'انضم إلينا',
        msg: 'تحذير المخاطر: تداول الأصول الرقمية هو صناعة ناشئة ذات آفاق واعدة، ولكنها تحمل أيضًا مخاطر كبيرة نظرًا لطبيعتها الجديدة. وبشكل خاص في تداول العقود، فإن الرافعة المالية تعزز الأرباح والمخاطر في نفس الوقت. من الضروري أن تمتلك فهمًا شاملاً للصناعة ونماذج تداول العقود وقواعد التداول والمعرفة المتعلقة الأخرى قبل المشاركة. نوصي بشدة بالتداول ضمن حدود المخاطر التي يمكنك تحملها. التداول ينطوي على مخاطر، ويُنصح بالحذر عند الدخول إلى السوق!',
        banquan: 'حقوق النشر',
        banquan2: 'كل الحقوق محفوظة',
        aboutUs:'معلومات عنا',
        Platform:'مقدمة المنصة'
    },
    home:{
        bannerTitle: 'شراء العملات الرقمية في aaaa',
        bannerTest: "أفضل منصة تداول للعملات الرقمية الاجتماعية على مستوى العالم",
        changUpRank: 'تصنيف الارتفاعات',
        changDownRank: 'تصنيف الانخفاضات',
        whyTitle: 'لماذا تختارنا',
        indexTitle1: 'ضمان الأمان',
        indexTest1: 'نشر لامركزي عالمي بدون مخاطر نظامية',
        indexTitle2: 'الرسالة والرؤية',
        indexTest2: 'السماح للمستثمرين بتحقيق الأرباح من التداول في العملات الرقمية',
        indexTitle3: 'العقود الذكية',
        indexTest3: 'تداول الشراء والبيع القصير، وتنفيذ الأوامر السريعة، والرافعة المالية حتى 100 مرة',
        indexTitle4: 'نسخ التداول',
        indexTest4: 'نسخ صفقات أفضل المتداولين بنقرة واحدة وبنسبة عائد عالية',
        indexTitle5: 'المناقشة في الوقت الحقيقي',
        indexTest5: 'الدردشة في الوقت الحقيقي',
        indexTitle6: 'ثواني',
        indexTest6: "أول مؤشر للعملات الرقمية بنظام ثنائي في العالم",
        indexTitle7: 'عملات متعددة',
        indexTest7: 'احترس بثقة للنجاح على أكثر من 300 مشروع متنوع',
        indexTitle8: 'البيئة الاقتصادية',
        indexTest8: 'إنشاء خدمة متكاملة لتبادل العملات الرقمية والمحفظة والبيئة على السلسلة',
        downLoadTitle: 'تداول في أي وقت وأي مكان',
        downLoadTest: 'متاح على أجهزة iOS و Android و PC. قم بالتداول في أي وقت وأي مكان.',
        downLoadCode: 'مسح للتحميل',
        downLoadIosAnd: 'iOS و Android'
    },
    contract:{
        Change:'تغير في 24 ساعة',
        High:'أعلى سعر في 24 ساعة',
        Low:'أدنى سعر في 24 ساعة',
        Volume24:'حجم التداول في 24 ساعة',
        Market:'السوق',
        LimitPrice:'سعر الحد',
        BestPrice:'تداول بأفضل سعر متاح',
        EnterPrice:'يرجى إدخال السعر',
        Amount:'الكمية',
        Margin:'الهامش',
        Available:'الرصيد المتاح',
        Leverage:'الرافعة المالية',
        Fee:'رسوم التداول',
        Buy:'شراء',
        Long:'شراء',
        Sell:'بيع',
        Short:'بيع',
        Assets:'الأصول',
        ContractAssets:'أصول العقود',
        Deposit:'ايداع الآن',
        Login:'تسجيل الدخول إلى الحساب التداولي',
        Register:'إنشاء حساب تداول',
        Instrument:'أداة التداول',
        Minimum:'الحد الأدنى لحجم الصفقة',
        Maximum:'الحد الأقصى لحجم الصفقة',
        TradingTime:'أوقات التداول',
        TradingLeverage:'الرافعة المالية للتداول',
        FeeRate:'نسبة الرسوم',
        Introduction:'مقدمة',
        IssuanceDate:'تاريخ الإصدار',
        TotalIssuance:'إجمالي الإصدار',
        CirculatingSupply:'العرض المتداول',
        Crowdfunding:'سعر التمويل الجماعي',
        Whitepaper:'الورقة البيضاء',
        Official:'الموقع الرسمي',
        Blockchain:'مستكشف البلوكشين',
        Positions:'المراكز',
        OpenOrder:'أمر مفتوح',
        TradeHistory:'سجل التداول',
        ProfitLoss:'الربح/الخسارة',
        CloseAll:'إغلاق الكل',
        Contract:'عقد',
        LotSize:'حجم العقد',
        OpeningPrice:'سعر الافتتاح',
        CurrentPrice:'السعر الحالي',
        TakeProfi:'سعر التحقيق في الربح',
        StopLoss:'سعر وقف الخسارة',
        OrderPL:'ربح/خسارة الأمر',
        OpeningTime:'وقت الافتتاح',
        OrderID:'رقم الأمر',
        Action:'إجراء',
        Settings:'الإعدادات',
        ClosePosition:'إغلاق',
        Delete:'حذف',
        Status:'الحالة',
        EnterCurrency:'يرجى إدخال العملة',
        saveStopLoss:'ضبط التحقق من الربح ووقف الخسارة',
        live:'بث مباشر',
        demo:'عرض توضيحي'
    },
    SpotGoods:{
        pricelimit:'حد السعر',
        marketprice:'السعر السوقي',
        Price:'السعر',
        MarketPrice:'السعر السوقي',
        PleaseEnter:'يرجى إدخال',
        Quantity:'الكمية',
        Total:'الإجمالي',
        Or:'أو',
        OpenOrders:'الأوامر المفتوحة',
        Fee:'الرسوم',
        QuantityAcc:'حجم التداول',
        LatestTrade:'أحدث صفقة',
        Time:'الوقت',
        NowOrders:'الأوامر المفتوحة',
        AllOrders:'كل الأوامر',
        OrderHistory:'سجل الأوامر',
        Date:'التاريخ',
        TradingPair:'زوج التداول',
        OrderType:'نوع الأمر',
        MarketOrder:'أمر السوق',
        LimitOrder:'أمر الحد',
        Direction:'الاتجاه',
        OrderPrice:'سعر الأمر',
        OrderQuantity:'كمية الأمر',
        OrderAmount:'قيمة الأمر'
    },
    Binary:{
        GameRules:'قواعد اللعبة',
        Trading:'جاري التداول',
        PositionClosed:'تم إغلاق المركز',
        BuyLong:'شراء طويل',
        BuyShort:'شراء قصير',
        OrderPeriod:'فترة الطلب',
        TradingAmount:'مبلغ التداول',
        Estimated:'وقت الإغلاق المقدر',
        ClosingTime:'وقت الإغلاق',
        PlaceBet:'مكان الرهان',
        Amount:'المبلغ',
        Balance:'رصيد الحساب',
        Odds:'الفرص',
        Profit:'الربح المتوقع',
        Bullish:'صاعد',
        Bearish:'هابط',
        countTime: 'العد التنازلي',
        customInp: 'المبلغ المخصص'
    },
    follow:{
        Ranking:'ترتيب المتداولين',
        Updates:'تحديث البيانات كل ساعتين',
        SearchKey:'البحث عن جميع المتداولين',
        Search:'بحث',
        Manage:'إدارة المتابعين',
        Details:'تفاصيل',
        test1:'الصيغة = نسبة الربح / الخسارة للطلب المنسوخ / إجمالي الهامش * 100٪',
        title1:'معدل العائد لمدة 30 يومًا',
        test2:'الصيغة = نسبة الأوامر الناجحة / إجمالي أوامر الشراء * 100٪',
        title2:'معدل النجاح لمدة 30 يومًا',
        title3:'عدد المتابعين الحالي',
        title4:'عمولة لمدة 30 يومًا',
        test5:'إجمالي (الأوامر الشراء الذاتية) لعدد الطلبات في الـ 30 يومًا الماضية',
        title5:'صفقة 30 يوم',
        test6:'محسوبة من تاريخ التسجيل',
        title6:'أيام التداول',
        Unfollow:'إلغاء المتابعة',
        Followed:'تمت المتابعة',
        EditCopying:'تحرير عملية النسخ',
        CopyingCurrency:'نسخ العملة',
        AmountCopying:'نسخ المبلغ',
        CopyingAmount:'نسخ المبلغ',
        ContractTrading:'زوج تداول العقود',
        LotSizeRatio:'نسبة حجم العقد',
        StartCopying:'بدء النسخ',
        CancelCopying:'إلغاء النسخ',
        title7:'معدل التراجع لمدة 30 يومًا',
        test7:'نسبة الخسارة للأصول في الـ 30 يومًا الماضية (نسبة الخسارة اليومية للأصول إلى الأصول السابقة بناءً على الخسارة القصوى)',
        BackLobby:'العودة إلى اللوبي',
        TradeHistory:'سجل التداول',
        ClosingPrice:'سعر الإغلاق',
        info:'الملف الشخصي',
        CumulativeCopying:'إجمالي المبلغ المنسوخ',
        TotalCopying:'إجمالي الربح المنسوخ',
        Discover:'اكتشاف الخبراء',
        CopyOrders:'طلبات النسخ',
        CurrentFollowers:'المتابعون الحاليون',
        Avatar:'الصورة الرمزية',
        TraderName:'اسم المتداول',
        MaximumPosition:'الحد الأقصى لمبلغ الوضع',
        ProfitLoss:'الربح / الخسارة لعملية النسخ',
        Edit:'تحرير',
        TodayProfit:'الربح اليومي',
        Past7Days:'إجمالي الربح في الـ 7 أيام الماضية',
        Accumulated:'إجمالي الربح المتراكم',
        ProfitDetails:'تفاصيل الربح',
        Followers:'المتابعون',
        Profit:'الربح',
        FollowerAvatar:'صورة المتابع',
        FollowerNickname:'اسم المتابع',
        CopyingTime:'وقت النسخ',
        Notice:'ملاحظة',
        NoticeTest1:'أنت لست متداولًا حتى الآن ، لذلك لا يمكنك إجراء هذه العملية!',
        NoticeTest2:'قيد المراجعة ، يرجى الانتظار بصبر!',
        Cancel:'إلغاء',
        ApplyNow:'قدم الآن',
        ApplyTrader:'قدم كمتداول',
        Nickname:'اللقب (مطلوب)',
        Phone:'رقم الهاتف (مطلوب)',
        Email:'البريد الإلكتروني (اختياري)',
        test1:'بعد تقديم الطلب ، سيتصل بك فريقنا في أقرب وقت ممكن. يرجى تقديم المعلومات المطلوبة في طلبك حسب الطلب.',
        test2:'خلال فترة المراجعة ، يرجى التأكد من عدم توفر الشروط التالية ، وإلا ستفشل المراجعة: امتلاك وضعيات ، وأوامر جارية ، وخطط جارية ، ومتابعة المتداولين.',
        Apply:'قدم'
    },
    account:{
        Overview:'نظرة عامة',
        Security:'الأمان',
        UserID:'معرف المستخدم',
        Welcome:'مرحبًا بك في بيتوب!',
        Welcometest:'مجرد خطوات قليلة أخرى وستكون جاهزًا للبدء',
        Registration:'تسجيل الحساب',
        SecurityVerification:'التحقق من الأمان',
        GotoBind:'الانتقال إلى الربط',
        QuickBuy:'شراء سريع للعملات',
        AssetDetails:'تفاصيل الأصول',
        Deposit:'الإيداع',
        Withdraw:'السحب',
        Transfer:'التحويل',
        TransferList: 'سجل التحويل',
        type: 'نوع',
        SpotAccount:'حساب السبوت',
        FuturesAccount:'حساب العقود الآجلة',
        HideAsset:'إخفاء تقييم الأصول',
        Available:'الرصيد المتاح (USDT)',
        checkdetails:'لمعرفة كمية الأصول التفصيلية ، يرجى التحقق من تفاصيل الأصول',
        Recommendations:'توصيات الأمان',
        PasswordSet:'إعدادات كلمة المرور',
        Change:'تغيير',
        FundPassword:'كلمة مرور الأموال',
        GotoSet:'الانتقال للضبط',
        AddressManagement:'إدارة العناوين',
        GotoAdd:'الانتقال للإضافة',
        Verification:'التحقق من الهوية',
        GotoVerify:'الانتقال للتحقق',
        LatestNews:'أحدث الأخبار',
        SafetyTitle:'المصادقة الثنائية',
        SafetyTest:'لحماية حسابك ، يُنصح بإكمال التحقق من الهوية بالاسم الحقيقي على الأقل',
        SafetyText1:'يُستخدم لإدارة كلمة مرور تسجيل الدخول لحسابك',
        SafetyText2:'يُستخدم لإدارة كلمة مرور الأموال لحسابك',
        NotSet:'لم يتم التعيين',
        Set:'ضبط',
        SafetyText3:'حماية التداول وأمان الحساب',
        SafetyText4:'ضمان دقة عناوين السحب',
        Manage:'إدارة',
        InvitationLink:'رابط الدعوة',
        copyInvitationLink:'ينسخ',
        InvitationCode:'رمز الدعوة',
        copyInvitationCode:'ينسخ',
        enterInvitationCode:'يرجى إدخال رمز الدعوة'
    },
    finance:{
        Protocol:'الشبكة الرئيسية (بروتوكول)',
        DepositAddress:'عنوان الإيداع',
        QRCode:'رمز الاستجابة السريعة للإيداع',
        DepositTest1:'يجب أن لا يقل المبلغ الأدنى لكل عملية إيداع عن',
        DepositTest2:', أي مبلغ أقل من ذلك لن ينجح',
        DepositTest3:'قبل كل إيداع ، يجب عليك الذهاب إلى هذه الصفحة لنسخ العنوان لضمان دقته. باستخدام عنوان غير صحيح سيؤدي إلى فشل الإيداع.',
        DepositTest4:'لا تقم بإيداع أي أصول غير',
        DepositTest5:'إلى العنوان أعلاه ، وإلا فلن يمكن استرداد الأصول.',
        WithdrawalAddress:'عنوان السحب',
        AddressManagement:'إدارة العناوين',
        Memo:'مذكرة',
        WithdrawalAmount:'مبلغ السحب',
        AvailableBalance:'الرصيد المتاح',
        Withdraw:'سحب',
        WithdrawTest1:'يرجى التأكد من وجود عنوان السحب المستهدف وتفعيله ، وإلا سيؤدي ذلك إلى فشل السحب وعدم استرداد الأصول.',
        WithdrawTest2:'إذا كان هناك علامة عنوان ، يرجى التحقق بعناية من علامة العنوان لمنع فقدان الأصول الذي لا يمكن استرداده.',
        WithdrawTest3:'لضمان أمان الأموال ، عند تغيير استراتيجية أمان حسابك أو تعديل كلمات المرور ، سنقوم بمراجعة السحب يدويًا. يرجى الانتظار بصبر حتى يتصل بك موظفونا عبر الهاتف أو البريد الإلكتروني.',
        WithdrawTest4:'يرجى ضمان أمان جهاز الكمبيوتر والمستعرض الخاص بك لمنع تلاعب المعلومات أو التسرب.',
        WithdrawTest5:'وقت السحب (UTC + 8): من الاثنين إلى الأحد ، من الساعة 9:00 حتى الساعة 22:00.',
        AvailableAssets:'الأصول المتاحة',
        GamblingAccount:'حساب الثواني',
        notpassword:'لم تقم بتعيين كلمة مرور الأموال بعد ، لذا لا يمكنك إجراء هذا الإجراء!',
        Back:'العودة',
        AddAddress:'إضافة عنوان جديد',
        SelectCurrency:'اختر العملة',
        Confirm:'تأكيد',
        from:'من',
        to:'إلى'
    },
    assets:{
        ContractPositions:'مراكز العقود',
        DepositDetails:'تفاصيل الإيداع',
        WithdrawalHistory:'سجل السحب',
        WithdrawalRecords:'سجل الإيداع والسحب',
        TotalEquivalent:'قيمة إجمالية معادلة لجميع الأصول السائلة (بالدولار الأمريكي)',
        AvailableBalance:'الرصيد المتاح في السائلة (بالدولار الأمريكي)',
        HideSmall:'إخفاء الأصول الصغيرة',
        Equivalent:'القيمة المعادلة',
        FrozenAmount:'المبلغ المجمد',
        DepositType:'نوع الإيداع',
        DepositAmount:'مبلغ الإيداع',
        DepositCurrency:'عملة الإيداع',
        DepositTime:'وقت الإيداع',
        WithdrawalType:'نوع السحب',
        WithdrawalAmount:'مبلغ السحب',
        WithdrawalCurrency:'عملة السحب',
        WithdrawalTime:'وقت السحب'
    },
    fastChange:{
        Sell:'بيع',
        ReferencePrice:'سعر الإشارة',
        Buy:'شراء',
        Quantity:'كمية قابلة للتبادل',
        PleaseEnter:'يرجى إدخال الكمية المطلوبة للتبادل',
        Exchange:'تبادل',
        Trading:'تبادل الكميات الصغيرة ● التداول الجماعي',
        TradingTest:'تبادل سريع لعملات BTC و ETH والعملات الرقمية',
        Step1:'الخطوة 1',
        Step1Test:'حدد العملة للتبادل',
        Step2:'الخطوة 2',
        Step2Test:'أدخل الكمية المراد تبادلها',
        Step3:'الخطوة 3',
        Step3Test:'ابدأ عملية التبادل'
    },
    Password:{
        ChangePassword:'تغيير كلمة المرور لتسجيل الدخول',
        OldPassword:'كلمة المرور الحالية لتسجيل الدخول',
        enterOldPassword:'يرجى إدخال كلمة المرور الحالية لتسجيل الدخول',
        NewLogin:'كلمة مرور جديدة لتسجيل الدخول',
        enterNewLogin:'يرجى إدخال كلمة مرور جديدة لتسجيل الدخول',
        againenterNewLogin:'يرجى إدخال كلمة مرور جديدة لتسجيل الدخول مرة أخرى',
        FundPassword:'تعيين كلمة مرور للأموال',
        fundpassword:'يرجى إدخال كلمة مرور للأموال',
        againfundpassword:'يرجى إدخال كلمة مرور للأموال مرة أخرى',
        Name:'الاسم',
        Number:'رقم الهوية',
        photos:'يرجى تحميل صور بطاقة الهوية، الأولى هي الجانب الأمامي والثانية هي الجانب الخلفي',
        photos2:'يرجى تحميل صورة الهوية',
        Upload:'تحميل',
        wait:'قيد المراجعة، يرجى الانتظار!',
        categoryType:'نوع الوثيقة',
        category1:'بطاقة التعريف',
        category2:'جواز سفر',
        category3:"رخصة السائق"
    },
    add:{
        Copied:"نسخ!",
        test29: "انتهت المحاكاة التجارية، وينتظرك التداول الحقيقي!",
        test30: "قم بإعادة الشحن الآن واستمتع بمكافآت الصندوق التجاري",
        test31: "احصل على المزيد من الدعم والأدوات على الفور",
        test32: "أعد الشحن لبدء رحلتك الرابحة",
        DemoTrade:"تداول تجريبي",
        Progress: "قيد التنفيذ",
        Requested: "تم طلب الاسترداد",
        Refunded: "تمت استعادة المبلغ",
        Completed: "اكتمل",
        test1: "نظرة عامة على المنتج",
        test2: "خدمة إدارة الأموال المالية (FMS) هي منتج صممته (aaaa) لمساعدة المستخدمين في تحقيق أرباح من الأصول الرقمية الخاملة عند عتبات منخفضة. من السهل البدء بها. تقدم خدمة إدارة الأموال المالية (FMS) خيارات مدد مختلفة، مما يسمح للمستخدمين بتحقيق أرباح من خلال إقراضها لمستخدمي التداول بالرافعة المالية في سوق الإقراض؛ ينطوي الكسب البسيط على المدى الثابت على قفل العملات لكسب مكافآت PoS أو حوافز المشاريع.",
        test3: "*يرجى الملاحظة: الأرباح السابقة لا تمثل الأرباح المستقبلية. لا نعد بسداد رأس المال والفائدة خلال فترة معينة بالعملة أو السلع الفعلية أو الأسهم أو أي شكل آخر من أشكال عائدات العقارات، ولا نعد بأي شكل آخر من أشكال عائدات العقارات.",
        test4: "آمنة وموثوق بها",
        test5: "(aaaa) تتمتع بأنظمة صارمة لمراقبة المخاطر، وتدابير أمنية رائدة، وبنية تحتية متقدمة لحماية أمان الأصول للمستخدم بشكل كامل.",
        test6: "اشتراك واسترداد مرنة",
        test7: "دعم على مدار الساعة للاشتراك والاسترداد في أي وقت. يكون الاسترداد للكسب البسيط الحالي على الفور، بينما يستغرق الاسترداد للكسب البسيط على المدى الثابت ما يصل إلى 30 دقيقة.",
        test8: "خدمة إدارة الأموال المالية (FMS) على المدى الثابت لـ**(aaaa)",
        test9: "تتمتع خدمة إدارة الأموال المالية (FMS) على المدى الثابت لـ(aaaa)** بمصادر دخل متعددة، مثل المكافآت التي تحصل عليها من خلال رهن الضمان والمكافآت القائمة على آلية الحصة النقدية (PoS)، فضلاً عن مكافآت المنصة.",
        test10: "ما هو العائد المرجعي السنوي؟",
        test11: "العائد المرجعي السنوي هو العائد المتوقع بالسنوي على المنتج الذي تشترك فيه، للإشارة فقط.",
        test12: "كيف يتم حساب الأرباح المتوقعة؟",
        test13: "تُحسب الأرباح المتوقعة استنادًا إلى كمية الاشتراك الخاصة بك والعائد المرجعي السنوي للمنتج، لتوفير تقدير للأرباح المتوقعة يوميًا، للإشارة فقط.",
        test14: "متى يتم تراكم الفائدة؟",
        test15: "تبدأ الأرباح في التراكم من الساعة 12:00 (UTC+8) في اليوم التالي للاشتراك.",
        test16: "متى يتم توزيع الأرباح؟",
        test17: "تُوزع الأرباح ابتداءً من الساعة 12:00 (UTC+8) في اليوم التالي لبدء تراكم الفائدة.",
        test18: "لماذا تختلف المنتجات في فترات الاسترداد؟",
        test19: "تشير فترة الاسترداد إلى الوقت الذي يستغرقه تأكيد الاسترداد إلى الاسترداد الفعلي الذي يتم تحقيقه. تتم رهن الأصول للكسب البسيط على المدى الثابت على السلسلة، مما يتطلب بعض الوقت للاسترداد من السلسلة. نظرًا لأن كل سلسلة عمومية تعمل بشكل مختلف، فإن فترات الاسترداد تختلف.",
        test20: "هل يتعين عليّ استرداد المنتجات على المدى الثابت يدويًا عند الانتهاء؟",
        test21: "هذا يعتمد على الحالة. سيتم تجديد جميع المنتجات على المدى الثابت تلقائيًا افتراضيًا. عند الانتهاء، سيتم تجديد نفس المدة تلقائيًا. بعد تمكين التجديد التلقائي، يجب القيام بالاسترداد يدويًا. يتم تمكين ميزة التجديد التلقائي بشكل افتراضي. لتعطيلها، يرجى الانتقال إلى صفحة تفاصيل الطلب.",
        test22: "هل يمكنني الحصول على دخل أثناء انتظار الاسترداد؟",
        test23: "بمجرد تأكيد الاسترداد، يتوقف الفائدة عن التراكم. لا يمكن كسب الدخل أثناء انتظار الاسترداد.",
        test24: "كيفية اختيار أفضل منتج لإدارة الأموال المالية (FMS) على المدى الثابت لـ**(aaaa)**؟",
        test25: "يمكنك مقارنة الأصول الرقمية المختلفة والمدد والعائدات التاريخية، وما إلى ذلك، بين جميع منتجات الكسب البسيط التي نقدمها، لاختيار المنتج الذي يناسبك. نحن نقدم فقط خدمات رهن الأصول، وجمع المكافآت، وتوزيعها، ولا نقدم أي نصيحة استثمارية. قبل اتخاذ قرار استثماري، يجب أن تفهم تمامًا طبيعة ومخاطر هذا المنتج، وكذلك الظروف الأخرى ذات الصلة، واتخاذ قرارات مستقلة بناءً على تجربتك الاستثمارية، ووضعك المالي، وأهدافك الاستثمارية، ومدة الاستثمار، ورغبتك وقدرتك على تحمل مخاطر الاستثمار، والظروف الأخرى ذات الصلة، وتكون مسؤولًا عن قراراتك الخاصة.",
        test26: "الدخل",
        test27: "الميزة",
        test28: "الأسئلة الشائعة",
        assets: "الأصول المالية",
        Redemption: "الاسترداد",
        Subscribe: "اشتراك",
        SubscribeType: "نوع الاشتراك",
        SubscribeNum: "مبلغ الاشتراك",
        Historical: "الفائدة التاريخية",
        SubscribeTime: "وقت الاشتراك",
        RedemptionTime: "وقت الاسترداد",
        RedemptionConfirm: "تأكيد الاسترداد",
        LockedSavings: "استثمار ثابت الأجل",
        test33: "إدارة مالية",
        test34: "فهم المفاهيم الأساسية واستراتيجيات إدارة المخاطر لاستثمار العملات المشفرة، ومواجهة تقلبات السوق بروية، وتحقيق الأهداف المالية. انضم إلينا الآن وابدأ رحلتك في استثمار العملات المشفرة!",
        test35: "احترس من جوهر استثمار العملات المشفرة، وتفسير اتجاهات السوق، واستكشاف الفرص المحتملة، وتحقيق تقدير الثروة. بدون حاجز، منخفض المخاطر، عوائد عالية. ابدأ رحلتك في إدارة الثروة بالعملات المشفرة وانطلق نحو آفاق جديدة من الثروة!",
        test36: "قائمة المنتجات",
        test37: "قائمة الاشتراك",
        test38: "الفائدة السنوية:",
        test39: "أيام：",
        test40: "الحد الأدنى للمبلغ المستثمر:",
        test41: "الحد الأقصى للمبلغ:",
        test42: "تاريخ بدء الفائدة:",
        test43: "تاريخ انتهاء حساب الفائدة:",
        test44: "الحد الأقصى",
        test45: "الأصول المتاحة:",
        test46: "الربح المقدر:",
        test47: "لقد قرأت وأوافق على",
        test48: "نظرة عامة على المنتج",
        test49:"اليوم",
        Agreement: "اتفاقية الشراء المالي",
        test50:"مبلغ إعادة الشحن",
	    test51: "تحميل قسيمة النقل",
        EnterPrice: "يرجى إدخال السعر",
    }
}