export default {
    total:{
        certified: 'Zertifiziert',
        UnCertified: 'Nicht Zertifiziert',
        login: 'Anmelden',
        register: 'Registrieren',
        registerNow: 'Jetzt Registrieren',
        loginNow: 'Jetzt Anmelden',
        buyNow: 'Jetzt Kaufen',
        Currency: 'Währung',
        chang24: 'Veränderung',
        NowPrice: 'Aktueller Preis',
        Leverage: 'Hebel',
        Favorites: 'Favoriten',
        Announcement: 'Ankündigung',
        dynamic: 'Dynamik',
        VerificationCode: 'Verifizierungscode',
        enteremailcode: 'Bitte geben Sie den E-Mail-Verifizierungscode ein',
        GetCode: 'Senden',
        add: 'Hinzufügen',
        help: 'Hilfezentrum',
        xszy: "Anfängerleitfaden",
        jyzn: 'Handelsanleitung',
        aboutUs: 'Über aaaa'
    },
    login:{
        loginTitle: 'Willkommen bei aaaa',
        email: 'E-Mail',
        enailTest: 'Bitte geben Sie Ihre E-Mail ein',
        password: 'Passwort',
        passwordTest: 'Bitte geben Sie Ihr Passwort ein',
        unpassword: 'Passwort vergessen?',
        noassets: 'Noch kein Konto',
        Addassets: 'Konto erstellen',
        password2: 'Passwort erneut eingeben',
        password2test: 'Bitte geben Sie Ihr Passwort erneut ein',
        assets: 'Bestehendes Konto'
    },
    topHeader: {
        nav01: 'Vertrag',
        nav02: 'Spot-Handel',
        nav03: 'Sekunden',
        nav04: 'Kopierhandel',
        nav05: 'Nachrichten',
        nav06: 'Schnellwechsel',
        APPStore: 'APP Store',
        GooglePlay: 'Google Play',
        Android: 'Android',
        IOSaAndroid: 'IOS & Android zum Herunterladen scannen',
        cn: '简体中文',
        en: 'English',
        assetsHome: 'Vermögenswerte',
        assets01: 'Vermögensdetails',
        assets02: 'Fondsdetails',
        assets03: 'Bestelldetails',
        assetsHome02: 'Bestellungen',
        assets0201: 'Verträge',
        assets0202: 'Spot',
        assets0203: 'Sekunden',
        account01: 'Übersicht',
        account02: 'Konto und Sicherheit',
        account03: 'Kopierhandel verwalten',
        account04: 'Leithandel verwalten',
        loginOut: 'Abmelden'
    },
    foote:{
        test: 'aaaa ist eine führende globale digitale Finanzdienstleistungsplattform, die durch ihre One-Stop-Digitalanlage-Dienstleistungen, diversifizierte Asset-Allokationstools, innovative Anlageberatungsdienste und branchenführende Liquidität ein sicheres, intelligentes und faires Umfeld für digitale Vermögensanlagen bietet. Das Kernteam von aaaa stammt aus traditionellen Finanz- und Wertpapierbranchen, erstklassigen Internetunternehmen wie Microsoft und anderen Kryptowährungsunternehmen, und bringt umfangreiche Erfahrung im traditionellen Finanzhandel, der Entwicklung von erstklassigen Kryptowährungsbörsenplattformen und globalen Marktoperationen mit. Seit seiner Gründung vor sechs Monaten hat die globale Nutzerbasis 100.000 überschritten und deckt mehr als 80 Länder und Regionen ab, einschließlich Nordamerika, Europa und der asiatisch-pazifischen Region.',
        tab1Title1: 'Hilfe',
        tab1Test1: 'FAQ',
        tab1Test2: 'Ankündigungszentrum',
        tab2Title1: 'Bedingungen',
        tab2Test1: 'Nutzungsbedingungen',
        tab2Test2: 'Datenschutzrichtlinie',
        tab2Test3: 'Risikoaufklärung',
        tab3Title1: 'Treten Sie uns bei',
        msg: 'Risikowarnung: Der Handel mit digitalen Vermögenswerten ist eine aufstrebende Branche mit großen Aussichten, birgt aber aufgrund ihrer Neuartigkeit auch erhebliche Risiken. Insbesondere im Kontrakthandel verstärkt der Hebel sowohl Gewinne als auch Risiken gleichzeitig. Es ist entscheidend für Sie, ein umfassendes Verständnis der Branche, der Kontrakthandelsmodelle, der Handelsregeln und anderer verwandter Kenntnisse zu haben, bevor Sie teilnehmen. Wir empfehlen dringend, dass Sie innerhalb der Risikogrenzen handeln, die Sie tragen können. Handel birgt Risiken, und Vorsicht ist beim Markteintritt geboten!',
        banquan: 'Urheberrecht',
        banquan2: 'Alle Rechte vorbehalten',
        aboutUs: 'Über Uns',
        Platform: 'Plattformvorstellung'
    },
    home:{
        bannerTitle: 'Kryptowährung kaufen bei aaaa',
        bannerTest: "Die weltweit führende soziale Kryptowährungshandelsbörse",
        changUpRank: 'Gewinner-Rangliste',
        changDownRank: 'Verlierer-Rangliste',
        whyTitle: 'Warum Uns Wählen',
        indexTitle1: 'Sicherheitsgarantie',
        indexTest1: 'Globale dezentrale Bereitstellung ohne systemisches Risiko',
        indexTitle2: 'Mission und Vision',
        indexTest2: 'Investoren sollen Gewinne mit Kryptowährungen genießen',
        indexTitle3: 'Smart Contracts',
        indexTest3: 'Long- und Short-Trading, schnelle Auftragsplatzierung, bis zu 100-facher Hebel',
        indexTitle4: 'Kopierhandel',
        indexTest4: 'Mit einem Klick Trades von Top-Händlern mit hoher Rendite folgen',
        indexTitle5: 'Echtzeit-Diskussion',
        indexTest5: 'Echtzeit-Chat',
        indexTitle6: 'Binär',
        indexTest6: "Der weltweit erste Kryptowährungsindex in binär",
        indexTitle7: 'Multi-Währungen',
        indexTest7: 'Erfolgreich den Schlüssel zum Erfolg bei über 300 diversifizierten Projekten ergreifen',
        indexTitle8: 'Ökosystem',
        indexTest8: 'Erstellen Sie einen One-Stop-Service für Krypto-Börsen, Wallet und On-Chain-Ökologie',
        downLoadTitle: 'Überall und jederzeit handeln',
        downLoadTest: 'Verfügbar auf iOS-, Android- und PC-Geräten. Handeln Sie jederzeit und überall.',
        downLoadCode: 'Zum Herunterladen scannen',
        downLoadIosAnd: 'iOS & Android'
    },
    contract:{
        Change: '24H Veränderung',
        High: '24H Höchst',
        Low: '24H Tiefst',
        Volume24: '24H Volumen',
        Market: 'Markt',
        LimitPrice: 'Limit',
        BestPrice: 'Handeln zum Besten Verfügbaren Preis',
        EnterPrice: 'Bitte Preis eingeben',
        Amount: 'Menge',
        Margin: 'Margin',
        Available: 'Verfügbares Guthaben',
        Leverage: 'Hebel',
        Fee: 'Transaktionsgebühr',
        Buy: 'Kaufen',
        Long: 'Long',
        Sell: 'Verkaufen',
        Short: 'Short',
        Assets: 'Vermögenswerte',
        ContractAssets: 'Kontrakt-Vermögenswerte',
        Deposit: 'Jetzt Einzahlen',
        Login: 'Anmelden zum Handelskonto',
        Register: 'Handelskonto Registrieren',
        Instrument: 'Handelsinstrument',
        Minimum: 'Mindestlosgröße',
        Maximum: 'Maximale Losgröße',
        TradingTime: 'Handelszeit',
        TradingLeverage: 'Handelshebel',
        FeeRate: 'Gebührenrate',
        Introduction: 'Einführung',
        IssuanceDate: 'Ausgabedatum',
        TotalIssuance: 'Gesamtemission',
        CirculatingSupply: 'Umlaufversorgung',
        Crowdfunding: 'Crowdfunding-Preis',
        Whitepaper: 'Weißbuch',
        Official: 'Offizielle Webseite',
        Blockchain: 'Blockchain Explorer',
        Positions: 'Positionen',
        OpenOrder: 'Offene Order',
        TradeHistory: 'Handelshistorie',
        ProfitLoss: 'Gewinn/Verlust',
        CloseAll: 'Alle Schließen',
        Contract: 'Vertrag',
        LotSize: 'Losgröße',
        OpeningPrice: 'Eröffnungspreis',
        CurrentPrice: 'Aktueller Preis',
        TakeProfi: 'Gewinnmitnahme Preis',
        StopLoss: 'Stop-Loss-Preis',
        OrderPL: 'Order P/L',
        OpeningTime: 'Eröffnungszeit',
        OrderID: 'Order-ID',
        Action: 'Aktion',
        Settings: 'Einstellungen',
        ClosePosition: 'Schließen',
        Delete: 'Löschen',
        Status: 'Status',
        EnterCurrency: 'Bitte Währung eingeben',
        saveStopLoss: 'T/P & S/L setzen',
        live:'Live',
        demo:'Demo'
    },
    SpotGoods:{
        pricelimit: 'Limit',
        marketprice: 'Markt',
        Price: 'Preis',
        MarketPrice: 'Markt',
        PleaseEnter: 'Bitte Eingeben',
        Quantity: 'Menge',
        Total: 'Gesamt',
        Or: 'Oder',
        OpenOrders: 'Offene Orders',
        Fee: 'Gebühr',
        QuantityAcc: 'Umsatz',
        LatestTrade: 'Letzter Handel',
        Time: 'Zeit',
        NowOrders: 'Offene Orders',
        AllOrders: 'Alle Orders',
        OrderHistory: 'Orderhistorie',
        Date: 'Datum',
        TradingPair: 'Handelspaar',
        OrderType: 'Ordertyp',
        MarketOrder: 'Marktorder',
        LimitOrder: 'Limitorder',
        Direction: 'Richtung',
        OrderPrice: 'Orderpreis',
        OrderQuantity: 'Ordermenge',
        OrderAmoun: 'Orderbetrag'
    },
    Binary:{
        GameRules: 'Spielregeln',
        Trading: 'Handel in Bearbeitung',
        PositionClosed: 'Position Geschlossen',
        BuyLong: 'Long Kaufen',
        BuyShort: 'Short Kaufen',
        OrderPeriod: 'Orderperiode',
        TradingAmount: 'Handelsbetrag',
        Estimated: 'Geschätzte Schließzeit',
        ClosingTime: 'Schließzeit',
        PlaceBet: 'Wette Platzieren',
        Amount: 'Betrag',
        Balance: 'Kontostand',
        Odds: 'Wettchancen',
        Profit: 'Geschätzter Gewinn',
        Bullish: 'Long',
        Bearish: 'Short',
        countTime: 'Countdown',
        customInp: 'Benutzerdefinierter Betrag'
    },
    follow:{
        Ranking: 'Trader-Rangliste',
        Updates: 'Datenaktualisierung Alle Zwei Stunden',
        SearchKey: 'Alle Trader Durchsuchen',
        Search: 'Suchen',
        Manage: 'Follower Verwalten',
        Details: 'Details',
        test1: 'Formel = Kopierter Order [Gewinn/Verlust] / [Gesamtmarge] * 100%',
        title1: '30T ROR',
        test2: 'Formel = Eigenplatzierter Order [Anzahl Profitabler Orders / Gesamtanzahl Orders] * 100%',
        title2: '30T Gewinnrate',
        title3: 'Aktuelle Follower',
        title4: '30T Provision',
        test5: 'Gesamtanzahl (Eigenplatzierter) Orders in den Letzten 30 Tagen',
        title5: '30T Handelslose',
        test6: 'Berechnet ab Registrierungsdatum',
        title6: 'Handelstage',
        Unfollow: 'Nicht Mehr Folgen',
        Followed: 'Gefolgt',
        EditCopying: 'Kopieren Bearbeiten',
        CopyingCurrency: 'Kopierwährung',
        AmountCopying: 'Kopierbetrag',
        CopyingAmount: 'Kopiertes Volumen',
        ContractTrading: 'Vertrags-Handelspaar',
        LotSizeRatio: 'Losgrößenverhältnis',
        StartCopying: 'Kopieren Starten',
        CancelCopying: 'Kopieren Abbrechen',
        title7: '30T Drawdown',
        test7: 'Vermögensverlustverhältnis in den Letzten 30 Tagen (Prozentsatz des Täglichen Verlusts zu Vermögenswerten des Vortags, Basierend auf dem Maximalen Verlust)',
        BackLobby: 'Zurück zur Lobby',
        TradeHistory: 'Handelshistorie',
        ClosingPrice: 'Schlusspreis',
        info: 'Persönliches Profil',
        CumulativeCopying: 'Kumulierter Kopierbetrag',
        TotalCopying: 'Gesamtgewinn Durch Kopieren',
        Discover: 'Experten Entdecken',
        CopyOrders: 'Orders Kopieren',
        CurrentFollowers: 'Aktuelle Follower',
        Avatar: 'Avatar',
        TraderName: 'Trader-Name',
        MaximumPosition: 'Maximale Positionsgröße',
        ProfitLoss: 'Kopiergewinn/-verlust',
        Edit: 'Bearbeiten',
        TodayProfit: 'Heutiger Gewinn',
        Past7Days: 'Gesamtgewinn der Letzten 7 Tage',
        Accumulated: 'Gesammelter Gesamtgewinn',
        ProfitDetails: 'Gewinndetails',
        Followers: 'Follower',
        Profit: 'Gewinn',
        FollowerAvatar: 'Follower-Avatar',
        FollowerNickname: 'Follower-Spitzname',
        CopyingTime: 'Kopierzeit',
        Notice: 'Hinweis',
        NoticeTest1: 'Sie sind noch kein Trader, daher können Sie diese Operation nicht durchführen!',
        NoticeTest2: 'In Überprüfung, bitte geduldig warten!',
        Cancel: 'Abbrechen',
        ApplyNow: 'Jetzt Bewerben',
        ApplyTrader: 'Als Trader Bewerben',
        Nickname: 'Spitzname (Erforderlich)',
        Phone: 'Telefonnummer (Erforderlich)',
        Email: 'E-Mail (Optional)',
        test1: 'Nach Einreichung der Bewerbung wird unser Personal Sie so bald wie möglich kontaktieren. Bitte stellen Sie die erforderlichen Bewerbungsinformationen wie angefordert bereit.',
        test2: 'Während der Prüfungsphase stellen Sie bitte sicher, dass die folgenden Bedingungen nicht erfüllt sind, sonst wird die Prüfung fehlschlagen: Offene Positionen, Aktuelle Orders, Aktuelle Pläne und Trader Folgen.',
        Apply: 'Bewerben'
    },
    account:{
        Overview: 'Übersicht',
        Security: 'Sicherheit',
        UserID: 'Benutzer-ID',
        Welcome: 'Willkommen bei aaaa!',
        Welcometest: "Nur noch ein paar Schritte und Sie sind bereit",
        Registration: 'Konto Registrierung',
        SecurityVerification: 'Sicherheitsüberprüfung',
        GotoBind: 'Zum Binden gehen',
        QuickBuy: 'Schnell Münzen kaufen',
        AssetDetails: 'Vermögensdetails',
        Deposit: 'Einzahlung',
        Withdraw: 'Auszahlung',
        Transfer: 'Überweisung',
        TransferList: 'Überweisungshistorie',
        type: 'Typ',
        SpotAccount: 'Spot-Konto',
        FuturesAccount: 'Futures-Konto',
        HideAsset: 'Vermögensbewertung verbergen',
        Available: 'Verfügbarer (USDT) Saldo',
        checkdetails: 'Für detaillierte Vermögensmengen, bitte die Vermögensdetails überprüfen',
        Recommendations: 'Sicherheitsempfehlungen',
        PasswordSet: 'Passworteinstellungen',
        Change: 'Ändern',
        FundPassword: 'Fondspasswort',
        GotoSet: 'Zum Einstellen gehen',
        AddressManagement: 'Adresse verwalten',
        GotoAdd: 'Zum Hinzufügen gehen',
        Verification: 'Identitätsüberprüfung',
        GotoVerify: 'Zur Überprüfung gehen',
        LatestNews: 'Neueste Nachrichten',
        SafetyTitle: 'Zwei-Faktor-Authentifizierung',
        SafetyTest: 'Um Ihr Konto zu schützen, wird empfohlen, mindestens eine Echtzeit-Identitätsüberprüfung abzuschließen',
        SafetyText1: 'Verwendet, um Ihr Konto-Login-Passwort zu verwalten',
        SafetyText2: 'Verwendet, um Ihr Kontofondspasswort zu verwalten',
        NotSet: 'Nicht gesetzt',
        Set: 'Setzen',
        SafetyText3: 'Schützen Sie Ihren Handel und die Sicherheit Ihres Kontos',
        SafetyText4: 'Stellen Sie die Genauigkeit der Auszahlungsadressen sicher',
        Manage: 'Verwalten',
        InvitationLink: 'Einladungslink',
        copyInvitationLink: 'Kopieren',
        InvitationCode: 'Einladungscode',
        copyInvitationCode: 'Kopieren',
        enterInvitationCode: 'Bitte geben Sie den Einladungscode ein'
    },
    finance:{
        Protocol: 'Hauptnetzwerk (Protokoll)',
        DepositAddress: 'Einzahlungsadresse',
        QRCode: 'Einzahlungs-QR-Code',
        DepositTest1: 'Der Mindesteinzahlungsbetrag pro Transaktion darf nicht weniger als',
        DepositTest2: 'betragen, jeder Betrag darunter wird nicht erfolgreich sein',
        DepositTest3: 'Vor jeder Einzahlung müssen Sie auf diese Seite gehen, um die Adresse zu kopieren und deren Genauigkeit zu gewährleisten. Die Verwendung einer falschen Adresse führt zu einer fehlgeschlagenen Einzahlung.',
        DepositTest4: 'Hinterlegen Sie keine Nicht-',
        DepositTest5: 'Vermögenswerte an die obige Adresse, sonst sind die Vermögenswerte nicht wiederherstellbar.',
        WithdrawalAddress: 'Auszahlungsadresse',
        AddressManagement: 'Adressverwaltung',
        Memo: 'Memo',
        WithdrawalAmount: 'Auszahlungsbetrag',
        AvailableBalance: 'Verfügbares Guthaben',
        Withdraw: 'Abheben',
        WithdrawTest1: 'Bitte bestätigen Sie, dass die Zielabhebungsadresse existiert und aktiviert ist, sonst wird dies zu einer fehlgeschlagenen Abhebung führen und die Vermögenswerte werden nicht wiederherstellbar sein.',
        WithdrawTest2: 'Wenn es ein Adressetikett gibt, überprüfen Sie bitte sorgfältig das Adressetikett, um einen nicht wiederherstellbaren Verlust von Vermögenswerten zu verhindern.',
        WithdrawTest3: 'Um die Sicherheit der Gelder zu gewährleisten, werden wir die Abhebung manuell überprüfen, wenn Ihre Kontosicherheitsstrategie geändert oder Passwörter modifiziert werden. Bitte warten Sie geduldig, bis unser Personal Sie telefonisch oder per E-Mail kontaktiert.',
        WithdrawTest4: 'Bitte stellen Sie die Sicherheit Ihres Computers und Browsers sicher, um eine Manipulation oder ein Auslaufen von Informationen zu verhindern.',
        WithdrawTest5: 'Abhebungszeit (UTC+8): Montag bis Sonntag, 9:00-22:00.',
        AvailableAssets: 'Verfügbare Vermögenswerte',
        GamblingAccount: 'Sekundenkonto',
        notpassword: 'Sie haben noch kein Fondspasswort festgelegt, daher können Sie diese Operation nicht durchführen!',
        Back: 'Zurück',
        AddAddress: 'Neue Adresse hinzufügen',
        SelectCurrency: 'Währung auswählen',
        Confirm: 'Bestätigen',
        from: 'Von',
        to: 'Zu'
    },
    assets:{
        ContractPositions: 'Kontrakt-Positionen',
        DepositDetails: 'Einzahlungsdetails',
        WithdrawalHistory: 'Auszahlungshistorie',
        WithdrawalRecords: 'Einzahlungs- und Auszahlungsprotokolle',
        TotalEquivalent: 'Gesamtwert aller Spot-Vermögenswerte (in USDT)',
        AvailableBalance: 'Verfügbares Guthaben in Spot (USDT)',
        HideSmall: 'Kleine Vermögenswerte verbergen',
        Equivalent: 'Äquivalent',
        FrozenAmount: 'Gefrorener Betrag',
        DepositType: 'Einzahlungstyp',
        DepositAmount: 'Einzahlungsbetrag',
        DepositCurrency: 'Einzahlungswährung',
        DepositTime: 'Einzahlungszeit',
        WithdrawalType: 'Auszahlungstyp',
        WithdrawalAmount: 'Auszahlungsbetrag',
        WithdrawalCurrency: 'Auszahlungswährung',
        WithdrawalTime: 'Auszahlungszeit'
    },
    fastChange:{
        Sell: 'Verkaufen',
        ReferencePrice: 'Referenzpreis',
        Buy: 'Kaufen',
        Quantity: 'Tauschbare Menge',
        PleaseEnter: 'Bitte geben Sie den gewünschten Tauschbetrag ein',
        Exchange: 'Tauschen',
        Trading: 'Kleiner Betrag Tausch ● Großhandel',
        TradingTest: 'Schneller Tausch für BTC, ETH und digitale Währungen',
        Step1: 'Schritt 1',
        Step1Test: 'Wählen Sie die zu tauschende Währung aus',
        Step2: 'Schritt 2',
        Step2Test: 'Geben Sie den Betrag zum Tauschen ein',
        Step3: 'Schritt 3',
        Step3Test: 'Beginnen Sie mit dem Tausch'
    },
    Password:{
        ChangePassword: 'Anmeldepasswort Ändern',
        OldPassword: 'Altes Anmeldepasswort',
        enterOldPassword: 'Bitte geben Sie Ihr altes Anmeldepasswort ein',
        NewLogin: 'Neues Anmeldepasswort',
        enterNewLogin: 'Bitte geben Sie Ihr Anmeldepasswort ein',
        againenterNewLogin: 'Bitte geben Sie Ihr Anmeldepasswort erneut ein',
        FundPassword: 'Fondspasswort Festlegen',
        fundpassword: 'Bitte geben Sie Ihr Fondspasswort ein',
        againfundpassword: 'Bitte geben Sie Ihr Fondspasswort erneut ein',
        Name: 'Name',
        Number: 'Ausweisnummer',
        photos: 'Bitte laden Sie Fotos Ihrer Ausweiskarte hoch, das erste ist die Vorderseite und das zweite die Rückseite',
        photos2: 'Bitte laden Sie Ihr Ausweisfoto hoch',
        Upload: 'Hochladen',
        wait: 'In Überprüfung, bitte warten!',
        categoryType: 'Dokumenttyp',
        category1: 'Personalausweis',
        category2: 'Reisepass',
        category3: 'Führerschein'
    },
    add:{
        Copied: "Kopiert!",
        test29: "Die Simulation des Handels endet, der echte Handel erwartet Sie!",
        test30: "Laden Sie jetzt auf und genießen Sie die Belohnungen des Handelsfonds",
        test31: "Erhalten Sie sofort mehr Unterstützung und Tools",
        test32: "Laden Sie auf, um Ihre profitable Reise zu beginnen",
        DemoTrade: "Demo-Handel",
        Progress: "In Bearbeitung",
        Requested: "Rückerstattung angefordert",
        Refunded: "Rückerstattet",
        Completed: "Abgeschlossen",
        test1: "Produktübersicht",
        test2: "Der Financial Management Service (FMS) von (aaaa) ist ein Produkt, das von (aaaa) entwickelt wurde, um Benutzern dabei zu helfen, mit brachliegenden digitalen Vermögenswerten Gewinne zu erzielen, ohne hohe Hürden überwinden zu müssen. Es ist einfach, damit zu beginnen. Der Financial Management Service (FMS) von (aaaa) bietet verschiedene Laufzeitoptionen und ermöglicht es Benutzern, durch die Vergabe von Krediten an gehebelte Handelsbenutzer auf dem Kreditmarkt Gewinne zu erzielen. Das Festzins-Simple-Coin-Earning beinhaltet das Sperren von Münzen, um PoS-Belohnungen oder Projektanreize zu verdienen.",
        test3: "*Bitte beachten Sie: Vergangene Erträge stellen keine zukünftigen Erträge dar. Wir versprechen nicht, Kapital und Zinsen innerhalb eines bestimmten Zeitraums in Währung, Sachwerten, Eigenkapital oder in irgendeiner anderen Form von Einkommen aus Eigentum zurückzuzahlen, noch versprechen wir eine andere Form von Einkommen aus Eigentum.",
        test4: "Sicher und zuverlässig",
        test5: "(aaaa) verfügt über strenge Risikokontrollsysteme, führende Sicherheitsmaßnahmen und eine fortschrittliche Infrastruktur, um die Sicherheit der Benutzerassets vollständig zu schützen.",
        test6: "Flexible Abonnements und Rücknahmen",
        test7: "24-Stunden-Unterstützung für Abonnements und Rücknahmen zu jeder Zeit. Die Rücknahme für aktuelle einfache Münzgewinne erfolgt sofort, während die Rücknahme für festverzinsliche einfache Münzgewinne bis zu 30 Minuten dauert.",
        test8: "Festverzinslicher (aaaa) Financial Management Service (FMS)",
        test9: "Der Festzins-(aaaa)-Financial Management Service (FMS) verfügt über mehrere Einnahmequellen, wie z. B. Belohnungen, die durch Verpfändung und Belohnungen basierend auf dem Proof of Stake (PoS)-Mechanismus erzielt werden, sowie Plattformbelohnungen.",
        test10: "Was ist der annualisierte Referenzertrag?",
        test11: "Der annualisierte Referenzertrag ist der erwartete annualisierte Ertrag des von Ihnen abonnierten Produkts, nur zur Information.",
        test12: "Wie werden die erwarteten Erträge berechnet?",
        test13: "Die erwarteten Erträge werden auf der Grundlage Ihrer Abonnementmenge und des annualisierten Referenzertrags des Produkts berechnet und bieten eine Schätzung der täglichen erwarteten Erträge, nur zur Information.",
        test14: "Wann fallen Zinsen an?",
        test15: "Die Erträge beginnen ab 12:00 Uhr (UTC+8) am Tag nach dem Abonnement anzufallen.",
        test16: "Wann werden die Erträge verteilt?",
        test17: "Die Erträge werden ab 12:00 Uhr (UTC+8) am Tag nach Beginn der Zinsen verteilt.",
        test18: "Warum haben verschiedene Produkte unterschiedliche Rückzahlungsfristen?",
        test19: "Die Rückzahlungsfrist bezieht sich auf die Zeit, die benötigt wird, um von der Bestätigung der Rückzahlung bis zur tatsächlichen Gutschrift der Rückzahlung zu gelangen. Vermögenswerte für festverzinsliche einfache Münzgewinne sind auf der Kette verpfändet und erfordern einige Zeit für die Rückzahlung von der Kette. Da jede öffentliche Kette unterschiedlich funktioniert, variieren die Rückzahlungsfristen.",
        test20: "Muss ich festverzinsliche Produkte bei Fälligkeit manuell einlösen?",
        test21: "Das hängt von der Situation ab. Alle festverzinslichen Produkte werden standardmäßig automatisch verlängert. Bei Fälligkeit wird die gleiche Laufzeit automatisch verlängert. Nachdem die automatische Verlängerung aktiviert wurde, muss die Einlösung manuell erfolgen. Die automatische Verlängerungsfunktion ist standardmäßig aktiviert. Um sie zu deaktivieren, gehen Sie bitte zur Seite mit den Bestelldetails.",
        test22: "Kann ich während des Wartens auf die Einlösung noch Einkommen erzielen?",
        test23: "Sobald die Einlösung bestätigt ist, hören die Zinsen auf anzufallen. Es kann kein Einkommen erzielt werden, während auf die Einlösung gewartet wird.",
        test24: "Wie wähle ich das beste festverzinsliche (aaaa)-Finanzmanagement-Service (FMS)-Produkt aus?",
        test25: "Sie können verschiedene digitale Vermögenswerte, Laufzeiten, historische Renditen usw. unter allen von uns angebotenen einfachen Münzgewinnprodukten vergleichen, um das Produkt auszuwählen, das am besten zu Ihnen passt. Wir bieten nur Vermögenswertverpfändung, Belohnungssammlung und -verteilungsdienste an und geben keine Anlageberatung. Bevor Sie eine Anlageentscheidung treffen, sollten Sie die Natur und Risiken dieses Produkts sowie andere relevante Umstände vollständig verstehen und unabhängige Urteile auf der Grundlage Ihrer Anlageerfahrung, finanziellen Situation, Anlageziele, Anlagezeitraum, Bereitschaft und Fähigkeit zur Übernahme von Anlagerisiken und anderen relevanten Umständen treffen und für Ihre eigenen Entscheidungen verantwortlich sein.",
        test26: "Einkommen",
        test27: "Vorteil",
        test28: "FAQs",
        assets: "Finanzvermögen",
        Redemption: "Rücknahme",
        Subscribe: "Abonnieren",
        SubscribeType: "Abonnementtyp",
        SubscribeNum: "Abonnementbetrag",
        Historical: "Kumulierte Zinsen",
        SubscribeTime: "Abonnementzeit",
        RedemptionTime: "Rücknahmezeitpunkt",
        RedemptionConfirm: "Rücknahmebestätigung",
        LockedSavings: "Festzinsanlage",
        test33: "Finanzmanagement",
        test34: "Verstehen Sie die Kernkonzepte und Risikomanagementstrategien der Kryptowährungsanlage, bewältigen Sie Marktschwankungen gelassen und erreichen Sie finanzielle Ziele. Treten Sie uns jetzt bei und starten Sie Ihre Reise in die Kryptowährungsanlage!",
        test35: "Meistern Sie die Essenz der Kryptowährungsanlage, interpretieren Sie Markttrends, erkunden Sie potenzielle Chancen und erreichen Sie eine Wertsteigerung des Vermögens. Keine Schwelle, geringes Risiko, hohe Renditen. Starten Sie Ihre Kryptowährungsvermögensverwaltung und bewegen Sie sich auf neue Höhen des Reichtums zu!",
        test36: "Produktliste",
        test37: "Abonnementliste",
        test38: "Jahreszinssatz:",
        test39: "Tage：",
        test40: "Mindestanlagebetrag:",
        test41: "Höchstbetrag:",
        test42: "Startdatum der Zinsen:",
        test43: "Enddatum der Zinsberechnung:",
        test44: "Maximal",
        test45: "Verfügbare Vermögenswerte:",
        test46: "Geschätzter Gewinn:",
        test47: "Ich habe gelesen und stimme zu",
        test48: "Produktübersicht",
        test49:"Tag",
        Agreement: "Finanzielle Kaufvereinbarung",
        test50: "Aufladebetrag",
	    test51: "Überweisungsbeleg hochladen",
        EnterPrice: "Bitte Preis eingeben",
    }    
}