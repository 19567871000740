import { createI18n } from 'vue-i18n'
import cn from './zh'
import en from './en'
import ja from './ja'
import ko from './ko'
import th from './th'
import ru from './ru'
import fr from './fr'
import es from './es'
import pt from './pt'
import ar from './ar'
import de from './de'
import it from './it'
import hk from './hk'



let lang = navigator.language || navigator.userLanguage;//常规浏览器语言和IE浏览器
console.log('lang:',lang)
let langtest = null
if (!localStorage.getItem('App-Language')) {
    // if (lang == 'zh-CN') {
    //     langtest = 'cn'
    //     localStorage.setItem('App-Language', 'cn')
    //     localStorage.setItem('languageKey', '简体')
    // } else 
    if (lang == 'zh-TW') {
        langtest = 'hk'
        localStorage.setItem('App-Language', 'hk')
        localStorage.setItem('languageKey', '繁体中文')
    } else if (lang == 'ja') {
        langtest = 'ja'
        localStorage.setItem('App-Language', 'ja')
        localStorage.setItem('languageKey', '日本語')
    } else if (lang == 'th') {
        langtest = 'th'
        localStorage.setItem('App-Language', 'th')
        localStorage.setItem('languageKey', 'แบบไทย')
    } else if (lang == 'ko') {
        langtest = 'ko'
        localStorage.setItem('App-Language', 'ko')
        localStorage.setItem('languageKey', '한국인')
    } else if (lang == 'ru') {
        langtest = 'ru'
        localStorage.setItem('App-Language', 'ru')
        localStorage.setItem('languageKey', 'Русский')
    } else if (lang == 'fr') {
        langtest = 'fr'
        localStorage.setItem('App-Language', 'fr')
        localStorage.setItem('languageKey', 'Français')
    } else if (lang == 'es') {
        langtest = 'es'
        localStorage.setItem('App-Language', 'es')
        localStorage.setItem('languageKey', 'español')
    } else if (lang == 'pt') {
        langtest = 'pt'
        localStorage.setItem('App-Language', 'pt')
        localStorage.setItem('languageKey', 'Português')
    } else if (lang == 'ar') {
        langtest = 'ar'
        localStorage.setItem('App-Language', 'ar')
        localStorage.setItem('languageKey', 'عربي')
    } else if (lang == 'de') {
        langtest = 'de'
        localStorage.setItem('App-Language', 'de')
        localStorage.setItem('languageKey', 'Deutsch')
    } else if (lang == 'it-IT') {
        langtest = 'it'
        localStorage.setItem('App-Language', 'it')
        localStorage.setItem('languageKey', 'Italia')
    } else {
        langtest = lang
        localStorage.setItem('App-Language', lang)
    }
} else {
    langtest = localStorage.getItem('App-Language')
}

let langlist = ['en', 'ja', 'es', 'pt', 'th', 'hk', 'ko', 'ru', 'fr', 'ar', 'de', 'it']
if (langlist.includes(langtest)) {
    //console.log("数组中存在该值");
} else {
    langtest = 'en'
    localStorage.setItem('App-Language', langtest)
}
 
const i18n = createI18n({
    legacy: false,  // 没有该参数可能会报错
    locale: localStorage.getItem('App-Language') || 'en',
    messages: {
        cn,
        en,
        it,
        de,
        ar,
        pt,
        es,
        fr,
        ru,
        th,
        ko,
        ja,
        hk
    }
})
 
export default i18n