export default {
    total:{
        certified: '인증됨',
        UnCertified: '인증되지 않음',
        login: '로그인',
        register: '등록',
        registerNow: '지금 등록',
        loginNow: '지금 로그인',
        buyNow: '지금 구매',
        Currency: '통화',
        chang24: '변동',
        NowPrice: '최신 가격',
        Leverage: '레버리지',
        Favorites: '즐겨찾기',
        Announcement:'공지사항',
        dynamic:'동적',
        VerificationCode:'인증 코드',
        enteremailcode:'이메일 인증 코드를 입력하세요',
        GetCode:'발송',
        add:'추가하다',
        help: '도움말 센터',
        xszy: '초보자 가이드',
        jyzn: '거래 가이드',
        aboutUs: 'aaaa 소개'
    },
    login:{
        loginTitle: 'aaaa에 오신 것을 환영합니다',
        email: '이메일',
        enailTest: '이메일을 입력하세요',
        password: '비밀번호',
        passwordTest: '비밀번호를 입력하세요',
        unpassword: '비밀번호를 잊으셨나요?',
        noassets: '아직 계정이 없으신가요?',
        Addassets: '계정 만들기',
        password2: '비밀번호 재입력',
        password2test: '비밀번호를 다시 입력하세요',
        assets: '기존 계정'
    },
    topHeader: {
        nav01: '계약',
        nav02: '스팟 트레이딩',
        nav03: '바이너리',
        nav04: '복사 거래',
        nav05: '뉴스',
        nav06: '빠른 교환',
        APPStore: '앱 스토어',
        GooglePlay: '구글 플레이',
        Android: '안드로이드',
        IOSaAndroid: 'IOS 및 안드로이드 다운로드를 위해 스캔',
        cn: '简体中文',
        en: 'English',
        assetsHome: '자산',
        assets01: '자산 세부 정보',
        assets02: '자금 세부 정보',
        assets03: '주문 세부 정보',
        assetsHome02: '주문',
        assets0201: '계약',
        assets0202: '스팟',
        assets0203:'초',
        account01: '개요',
        account02: '계정 및 보안',
        account03: '복사 거래 관리',
        account04: '리드 거래 관리',
        loginOut: '로그아웃'
    },
    foote:{
        test: 'aaaa은(는) 원스톱 디지털 투자 서비스, 다양한 자산 할당 도구, 혁신적인 투자 자문 서비스 및 최첨단 유동성을 통해 안전하고 지능적이며 공정한 디지털 자산 투자 환경을 제공하는 선도적인 글로벌 디지털 금융 서비스 플랫폼입니다. aaaa의 핵심 팀은 전통적인 금융 증권 산업, Microsoft와 같은 세계적인 인터넷 기업, 그리고 다른 암호화폐 기업에서 온 사람들로 구성되어 있으며, 전통적인 금융 거래, 세계적인 암호화폐 거래 플랫폼 개발 및 글로벌 시장 운영에 관한 폭넓은 경험을 보유하고 있습니다. 설립 이후 6개월 동안 글로벌 사용자 기반은 10만 명을 넘어서며, 북미, 유럽 및 아시아 태평양 지역을 포함한 80개 이상의 국가와 지역을 커버하고 있습니다.',
        tab1Title1: '도움말',
        tab1Test1: '자주 묻는 질문',
        tab1Test2: '공지 사항 센터',
        tab2Title1: '약관',
        tab2Test1: '서비스 약관',
        tab2Test2: '개인 정보 보호 정책',
        tab2Test3: '리스크 공개',
        tab3Title1: '우리와 함께하세요',
        msg: '리스크 경고: 디지털 자산 거래는 새로운 산업으로 밝은 전망을 가지고 있지만, 새로운 성격 때문에 상당한 위험도를 내포하고 있습니다. 특히, 계약 거래에서 레버리지는 이익과 위험을 동시에 증폭시킵니다. 참여하기 전에 업계, 계약 거래 모델, 거래 규칙 및 관련 지식에 대해 포괄적인 이해를 가져야 합니다. 당사는 위험을 감당할 수 있는 한도 내에서 거래하는 것을 강력히 권장합니다. 거래에는 위험이 따르며, 시장 진입 시 조심이 필요합니다!',
        banquan: '저작권',
        banquan2: '모든 권리 보유',
        aboutUs:'About Us',
        Platform:'플랫폼 소개'
    },
    home:{
        bannerTitle: '비톱에서 암호화폐 구매하기',
        bannerTest: '세계 최고의 소셜 암호화폐 거래소',
        changUpRank: '상승 순위',
        changDownRank: '하락 순위',
        whyTitle: '왜 비톱을 선택해야 하는가',
        indexTitle1: '보안 보장',
        indexTest1: '시스템적인 리스크가 없는 글로벌 분산 배치',
        indexTitle2: '미션과 비전',
        indexTest2: '투자자들이 암호화폐로 수익 창출을 즐길 수 있도록',
        indexTitle3: '스마트 계약',
        indexTest3: '롱 및 숏 트레이딩, 신속한 주문 체결, 최대 100배 레버리지',
        indexTitle4: '복사 트레이딩',
        indexTest4: '클릭 한 번으로 수익률이 높은 최고의 트레이더들의 거래를 복사',
        indexTitle5: '실시간 토론',
        indexTest5: '실시간 채팅',
        indexTitle6: '바이너리',
        indexTest6: '세계 최초의 암호화폐 바이너리 인덱스',
        indexTitle7: '다양한 화폐',
        indexTest7: '300개 이상의 다양한 프로젝트에서 성공의 핵심을 확보',
        indexTitle8: '생태계',
        indexTest8: '암호화폐 거래소, 지갑 및 온체인 생태계를 위한 원스톱 서비스 구축',
        downLoadTitle: '언제 어디서나 거래하세요',
        downLoadTest: 'iOS, Android 및 PC 기기에서 사용할 수 있습니다. 언제 어디서나 거래하세요.',
        downLoadCode: '스캔하여 다운로드',
        downLoadIosAnd: 'iOS 및 Android'
    },
    contract:{
        Change:'24H Change',
        High:'24H High',
        Low:'24H Low',
        Volume24:'24H Volume',
        Market:'Market',
        LimitPrice:'지정가',
        BestPrice:'가장 좋은 가격으로 거래',
        EnterPrice:'가격을 입력하세요',
        Amount:'수량',
        Margin:'증거금',
        Available:'사용 가능한 잔액',
        Leverage:'레버리지',
        Fee:'수수료',
        Buy:'매수',
        Long:'롱',
        Sell:'매도',
        Short:'숏',
        Assets:'자산',
        ContractAssets:'계약 자산',
        Deposit:'입금하기',
        Login:'거래 계정으로 로그인',
        Register:'거래 계정 등록',
        Instrument:'거래 상품',
        Minimum:'최소 거래 수량',
        Maximum:'최대 거래 수량',
        TradingTime:'거래 시간',
        TradingLeverage:'거래 레버리지',
        FeeRate:'수수료 비율',
        Introduction:'소개',
        IssuanceDate:'발행 일자',
        TotalIssuance:'총 발행량',
        CirculatingSupply:'유통량',
        Crowdfunding:'크라우드펀딩 가격',
        Whitepaper:'백서',
        Official:'공식 웹사이트',
        Blockchain:'블록체인 탐색기',
        Positions:'포지션',
        OpenOrder:'미체결 주문',
        TradeHistory:'거래 내역',
        ProfitLoss:'손익',
        CloseAll:'전체 청산',
        Contract:'계약',
        LotSize:'로트 크기',
        OpeningPrice:'진입 가격',
        CurrentPrice:'현재 가격',
        TakeProfi:'익절 가격',
        StopLoss:'손절 가격',
        OrderPL:'주문 손익',
        OpeningTime:'진입 시간',
        OrderID:'주문 ID',
        Action:'동작',
        Settings:'설정',
        ClosePosition:'청산',
        Delete:'삭제',
        Status:'상태',
        EnterCurrency:'화폐를 입력하세요',
        saveStopLoss:'익절/손절 설정',
        live:'라이브',
        demo:'데모'
    },
    SpotGoods:{
        pricelimit:'지정가',
        marketprice:'시장가',
        Price:'가격',
        MarketPrice:'시장가',
        PleaseEnter:'입력하세요',
        Quantity:'수량',
        Total:'총액',
        Or:'또는',
        OpenOrders:'미체결 주문',
        Fee:'수수료',
        QuantityAcc:'거래량',
        LatestTrade:'최신 거래',
        Time:'시간',
        NowOrders:'미체결 주문',
        AllOrders:'전체 주문',
        OrderHistory:'주문 내역',
        Date:'날짜',
        TradingPair:'거래 페어',
        OrderType:'주문 유형',
        MarketOrder:'시장가 주문',
        LimitOrder:'지정가 주문',
        Direction:'거래 방향',
        OrderPrice:'주문 가격',
        OrderQuantity:'주문 수량',
        OrderAmount:'주문 금액'
    },
    Binary:{
        GameRules:'게임 규칙',
        Trading:'거래 진행 중',
        PositionClosed:'포지션 종료',
        BuyLong:'매수 (상승)',
        BuyShort:'매수 (하락)',
        OrderPeriod:'주문 기간',
        TradingAmount:'거래 금액',
        Estimated:'예상 종료 시간',
        ClosingTime:'종료 시간',
        PlaceBet:'베팅하기',
        Amount:'금액',
        Balance:'계정 잔액',
        Odds:'배당률',
        Profit:'예상 수익',
        Bullish:'상승',
        Bearish:'하락',
        countTime: '카운트 다운',
        customInp: '사용자 정의 금액'
    },
    follow:{
        Ranking:'트레이더 랭킹',
        Updates:'데이터는 2시간마다 업데이트됩니다',
        SearchKey:'모든 트레이더 검색',
        Search:'검색',
        Manage:'팔로워 관리',
        Details:'상세 정보',
        test1:'수식 = 복사 주문 [수익/손실] / [총 마진] * 100%',
        title1:'30일 수익률',
        test2:'수식 = 자체 주문 [수익 주문 수 / 총 주문 수] * 100%',
        title2:'30일 승률',
        title3:'현재 팔로워',
        title4:'30일 수수료',
        test5:'지난 30일 동안의 총 (자체 주문) 주문 수',
        title5:'30일 거래 로트',
        test6:'등록일 기준으로 계산',
        title6:'거래 일수',
        Unfollow:'언팔로우',
        Followed:'팔로우한',
        EditCopying:'복사 설정 편집',
        CopyingCurrency:'복사 통화',
        AmountCopying:'복사 금액',
        CopyingAmount:'복사 금액',
        ContractTrading:'계약 거래 페어',
        LotSizeRatio:'로트 사이즈 비율',
        StartCopying:'복사 시작',
        CancelCopying:'복사 취소',
        title7:'30일 하락률',
        test7:'지난 30일 동안의 자산 손실 비율 (일일 손실 자산을 이전 날짜 자산으로 나눈 비율, 최대 손실 기준)',
        BackLobby:'로비로 돌아가기',
        TradeHistory:'거래 기록',
        ClosingPrice:'마감 가격',
        info:'개인 프로필',
        CumulativeCopying:'누적 복사 금액',
        TotalCopying:'총 복사 수익',
        Discover:'전문가 찾기',
        CopyOrders:'주문 복사',
        CurrentFollowers:'현재 팔로워',
        Avatar:'아바타',
        TraderName:'트레이더 이름',
        MaximumPosition:'최대 포지션 금액',
        ProfitLoss:'복사 수익/손실',
        Edit:'편집',
        TodayProfit:"오늘의 수익",
        Past7Days:'지난 7일간의 총 수익',
        Accumulated:'누적 총 수익',
        ProfitDetails:'수익 세부 정보',
        Followers:'팔로워',
        Profit:'수익',
        FollowerAvatar:'팔로워 아바타',
        FollowerNickname:'팔로워 닉네임',
        CopyingTime:'복사 시간',
        Notice:'알림',
        NoticeTest1:'아직 트레이더가 아니므로 이 작업을 수행할 수 없습니다!',
        NoticeTest2:'심사 중입니다. 기다려주십시오!',
        Cancel:'취소',
        ApplyNow:'지금 신청하세요',
        ApplyTrader:'트레이더로 신청하기',
        Nickname:'닉네임 (필수)',
        Phone:'전화번호 (필수)',
        Email:'이메일 (선택)',
        test1:'신청서를 제출한 후, 담당자가 가능한 빨리 연락드릴 것입니다. 필요한 신청 정보를 요청대로 제공해주시기 바랍니다.',
        test2:'심사 기간 동안 다음 조건을 충족하지 않도록 주의하십시오. 그렇지 않으면 심사가 실패할 수 있습니다: 포지션 보유, 현재 주문, 현재 플랜, 팔로우 중인 트레이더.',
        Apply:'신청하기'
    },
    account:{
        Overview:'개요',
        Security:'보안',
        UserID:'사용자 ID',
        Welcome:'aaaa에 오신 것을 환영합니다!',
        Welcometest:'마지막 몇 단계만 더 진행하면 준비 완료입니다.',
        Registration:'계정 등록',
        SecurityVerification:'보안 인증',
        GotoBind:'바인딩으로 이동',
        QuickBuy:'빠른 코인 구매',
        AssetDetails:'자산 세부 정보',
        Deposit:'입금',
        Withdraw:'출금',
        Transfer:'이체',
        TransferList: '이체 내역',
        type: '유형',
        SpotAccount:'스팟 계정',
        FuturesAccount:'선물 계정',
        HideAsset:'자산 평가 숨기기',
        Available:'사용 가능한 (USDT) 잔액',
        checkdetails:'자산 수량에 대한 자세한 내용은 자산 세부 정보를 확인하십시오.',
        Recommendations:'보안 권장 사항',
        PasswordSet:'비밀번호 설정',
        Change:'변경',
        FundPassword:'자금 비밀번호',
        GotoSet:'설정으로 이동',
        AddressManagement:'주소 관리',
        GotoAdd:'추가로 이동',
        Verification:'본인 인증',
        GotoVerify:'인증으로 이동',
        LatestNews:'최신 뉴스',
        SafetyTitle:'2단계 인증',
        SafetyTest:'계정 보호를 위해 최소한 한 가지의 실명 인증을 완료하는 것이 좋습니다.',
        SafetyText1:'계정 로그인 비밀번호 관리에 사용됩니다.',
        SafetyText2:'계정 자금 비밀번호 관리에 사용됩니다.',
        NotSet:'설정되지 않음',
        Set:'설정',
        SafetyText3:'거래 및 계정 보안 보호',
        SafetyText4:'출금 주소의 정확성 보장',
        Manage:'관리',
        InvitationLink:'초대 링크',
        copyInvitationLink:'복사',
        InvitationCode:'초대 코드',
        copyInvitationCode:'복사',
        enterInvitationCode:'초대 코드를 입력하세요'
    },
    finance:{
        Protocol:'주요 네트워크 (프로토콜)',
        DepositAddress:'입금 주소',
        QRCode:'입금 QR 코드',
        DepositTest1:'한 거래당 최소 입금 금액은',
        DepositTest2:'보다 작을 수 없으며, 이보다 낮은 금액으로의 입금은 성공하지 않습니다.',
        DepositTest3:'각 입금 전에 이 페이지로 이동하여 주소를 복사하여 정확성을 확인해야 합니다. 잘못된 주소 사용으로 인해 입금에 실패할 수 있습니다.',
        DepositTest4:'상기 주소로',
        DepositTest5:'이외의 자산은 입금하지 마십시오. 그렇지 않을 경우 자산을 복구할 수 없습니다.',
        WithdrawalAddress:'출금 주소',
        AddressManagement:'주소 관리',
        Memo:'메모',
        WithdrawalAmount:'출금 금액',
        AvailableBalance:'사용 가능한 잔액',
        Withdraw:'출금',
        WithdrawTest1:'대상 출금 주소가 존재하고 활성화되어 있는지 확인하십시오. 그렇지 않을 경우 출금에 실패하고 자산을 복구할 수 없습니다.',
        WithdrawTest2:'주소 태그가 있는 경우 주소 태그를 정확하게 확인하여 복구할 수 없는 자산 손실을 방지하십시오.',
        WithdrawTest3:'자금 보안 전략이 변경되거나 비밀번호가 수정될 때 자금 출금은 수동으로 검토됩니다. 저희 직원이 전화나 이메일로 연락을 드릴 때까지 기다려주십시오.',
        WithdrawTest4:'컴퓨터와 브라우저의 보안을 유지하여 정보 조작이나 유출이 발생하지 않도록 주의하십시오.',
        WithdrawTest5:'출금 시간 (UTC+8): 월요일부터 일요일까지, 9:00-22:00.',
        AvailableAssets:'사용 가능한 자산',
        GamblingAccount:'거래 계정',
        notpassword:'아직 자금 비밀번호를 설정하지 않았으므로 이 작업을 수행할 수 없습니다!',
        Back:'뒤로',
        AddAddress:'새 주소 추가',
        SelectCurrency:'통화 선택',
        Confirm:'확인',
        from:'부터',
        to:'까지'
    },
    assets:{
        ContractPositions:'계약 포지션',
        DepositDetails:'입금 내역',
        WithdrawalHistory:'출금 내역',
        WithdrawalRecords:'입출금 내역',
        TotalEquivalent:'스팟 자산의 총 동등 가치 (USDT 기준)',
        AvailableBalance:'스팟 잔액 (USDT)',
        HideSmall:'소액 자산 숨기기',
        Equivalent:'동등 가치',
        FrozenAmount:'동결된 금액',
        DepositType:'입금 유형',
        DepositAmount:'입금 금액',
        DepositCurrency:'입금 통화',
        DepositTime:'입금 시간',
        WithdrawalType:'출금 유형',
        WithdrawalAmount:'출금 금액',
        WithdrawalCurrency:'출금 통화',
        WithdrawalTime:'출금 시간'
    },
    fastChange:{
        Sell:'판매',
        ReferencePrice:'참고 가격',
        Buy:'구매',
        Quantity:'교환 가능 수량',
        PleaseEnter:'원하는 교환 금액을 입력하세요',
        Exchange:'교환',
        Trading:'소액 교환 ● 대량 거래',
        TradingTest:'BTC, ETH 및 디지털 통화에 대한 빠른 교환',
        Step1:'단계 1',
        Step1Test:'교환할 통화 선택',
        Step2:'단계 2',
        Step2Test:'교환할 금액 입력',
        Step3:'단계 3',
        Step3Test:'교환 시작'
    },
    Password:{
        ChangePassword:'비밀번호 변경',
        OldPassword:'기존 비밀번호',
        enterOldPassword:'기존 로그인 비밀번호를 입력하세요',
        NewLogin:'새로운 로그인 비밀번호',
        enterNewLogin:'새로운 로그인 비밀번호를 입력하세요',
        againenterNewLogin:'새로운 로그인 비밀번호를 다시 입력하세요',
        FundPassword:'자금 비밀번호 설정',
        fundpassword:'자금 비밀번호를 입력하세요',
        againfundpassword:'자금 비밀번호를 다시 입력하세요',
        Name:'이름',
        Number:'신분증 번호',
        photos:'신분증 사진을 업로드하세요. 첫 번째 사진은 앞면이고, 두 번째 사진은 뒷면입니다',
        photos2:'신분증 사진을 업로드해주세요',
        Upload:'업로드',
        wait:'심사 중입니다. 기다려주세요!',
        categoryType:'문서 유형',
        category1:'신분증',
        category2:'여권',
        category3:"운전면허증"
    },
    add:{
        Copied:"복사되었습니다!",
        test29: "시뮬레이션 거래가 종료되었습니다. 실제 거래가 기다리고 있습니다!",
        test30: "지금 충전하고 거래 자금 보상을 받으세요",
        test31: "즉시 더 많은 지원과 도구를 받으세요",
        test32: "수익성 있는 여정을 시작하려면 충전하세요",
        DemoTrade:"데모 거래",
        Progress: "진행 중",
        Requested: "환불 요청됨",
        Refunded: "환불됨",
        Completed: "완료됨",
        test1: "제품 개요",
        test2: "(aaaa) 금융 관리 서비스(FMS)는 (aaaa)에서 만든 제품으로, 여유 있는 디지털 자산을 가지고 있는 사용자들이 낮은 임계값에서 수익을 올리도록 돕는 데 사용됩니다. 이 서비스는 시작하기 쉽습니다. (aaaa) 금융 관리 서비스(FMS)는 차등적인 기간 옵션을 제공하여 사용자들이 대출 시장에서 레버리지 트레이딩 사용자들에게 대출하고 수익을 올릴 수 있도록 합니다. 고정 기간 단순 코인 수익은 코인을 잠그고 PoS 보상이나 프로젝트 인센티브를 얻는 것을 포함합니다.",
        test3: "*참고: 과거 수익은 미래의 수익을 대표하지 않습니다. 통화, 실물 재화, 자본, 또는 기타 형태의 재산 소득 반환 내지 이와 유사한 형태의 재산 소득 반환을 특정 기간 내에 상환하는 것을 약속하지 않습니다.",
        test4: "안전하고 신뢰할 수 있음",
        test5: "**(aaaa)**는 엄격한 위험 관리 시스템, 선도적인 보안 조치 및 고급 인프라를 갖추어 사용자 자산 안전을 완벽하게 보호합니다.",
        test6: "유연한 구독 및 환매",
        test7: "24시간 언제든지 구독 및 환매 지원. 현재의 단순 코인 수익의 경우 즉시 환매가 가능하며, 고정 기간 단순 코인 수익의 경우 최대 30분이 소요됩니다.",
        test8: "고정 기간 (aaaa) 금융 관리 서비스 (FMS)",
        test9: "고정 기간 (aaaa) 금융 관리 서비스 (FMS)는 담보화를 통한 보상 및 PoS(PoW) 메커니즘을 기반으로 한 보상과 플랫폼 보상과 같은 다양한 수익원이 있습니다.",
        test10: "연간화된 참고 수익이란?",
        test11: "연간화된 참고 수익은 구독한 제품의 예상 연간 수익률로, 참고 용도로만 사용됩니다.",
        test12: "예상 수익은 어떻게 계산됩니까?",
        test13: "예상 수익은 구독 수량과 제품의 연간화된 참고 수익을 기반으로 계산되어 일일 예상 수익을 제공하며, 참고 용도로만 제공됩니다.",
        test14: "이자가 언제 발생합니까?",
        test15: "수익은 구독 다음 날 12:00 (UTC+8)부터 발생합니다.",
        test16: "수익이 언제 분배됩니까?",
        test17: "수익은 이자 발생 다음 날 12:00 (UTC+8)부터 분배됩니다.",
        test18: "다른 제품이 왜 상환 기간이 다를까요?",
        test19: "상환 기간은 상환 확인부터 실제 상환이 이루어지는 시간을 의미합니다. 고정 기간 단순 코인 수익을 위한 자산은 체인 상에 담보화되어 있으며, 체인에서 상환하는 데 시간이 소요됩니다. 각 공개 체인이 다르게 운영되므로 상환 기간이 다를 수 있습니다.",
        test20: "만기 시 고정 기간 제품을 수동으로 상환해야합니까?",
        test21: "이것은 상황에 따라 다릅니다. 모든 고정 기간 제품은 자동 갱신으로 기본 설정됩니다. 만기 시, 동일한 기간이 자동으로 갱신됩니다. 자동 갱신을 활성화한 후에는 상환을 수동으로 수행해야 합니다. 자동 갱신 기능은 기본적으로 활성화되어 있습니다. 비활성화하려면 주문 상세 페이지로 이동하십시오.",
        test22: "상환을 기다리는 동안에도 수익을 올릴 수 있습니까?",
        test23: "상환이 확인되면 이자가 발생하지 않습니다. 상환을 기다리는 동안 수익을 얻을 수 없습니다.",
        test24: "최적의 고정 기간 (aaaa) 금융 관리 서비스 (FMS) 제품을 선택하는 방법은?",
        test25: "저희가 제공하는 모든 간단한 코인 수익 제품 중에서 다른 디지털 자산, 기간, 과거 수익 등을 비교하여 본인에게 적합한 제품을 선택할 수 있습니다. 저희는 자산 담보화, 보상 수령 및 배포 서비스만을 제공하며 투자 조언은 제공하지 않습니다. 투자 결정을 내리기 전에 이 제품의 본질과 위험, 그리고 다른 관련 사정을 완전히 이해하고 투자 경험, 재정 상태, 투자 목표, 투자 기간, 투자 위험을 감수할 의지와 능력, 그리고 기타 관련 사정을 고려하여 독립적으로 판단하고 본인의 결정에 대한 책임을 져야 합니다.",
        test26: "수익",
        test27: "장점",
        test28: "FAQ",
        assets:"금융",
        Redemption: "환급",
        Subscribe: "구독",
        SubscribeType: "구독 유형",
        SubscribeNum: "구독 금액",
        Historical: "누적 이자",
        SubscribeTime: "구독 시간",
        RedemptionTime: "상환 시간",
        RedemptionConfirm: "상환 확인",
        LockedSavings: "고정 기간 투자",
        test33: "금융 관리",
        test34: "암호화폐 투자의 핵심 개념 및 리스크 관리 전략을 이해하고 시장 변동에 차분히 대응하여 금융 목표를 달성합니다. 지금 바로 참여하여 암호화폐 투자 여행을 시작하세요!",
        test35: "암호화폐 투자의 본질을 습득하고 시장 트렌드를 해석하며 잠재적 기회를 탐색하여 재산을 증가시킵니다. 임계값 없음, 낮은 리스크, 높은 수익. 암호화폐 재산 관리 여정을 시작하고 새로운 재산의 높은 곳으로 나아가세요!",
        test36: "상품 목록",
        test37: "구독 목록",
        test38: "연이율:",
        test39: "일 수：",
        test40: "최소 투자 금액:",
        test41: "최대 금액:",
        test42: "이자 시작일:",
        test43: "이자 계산 종료일:",
        test44: "최대",
        test45: "가용 자산:",
        test46: "예상 수익:",
        test47: "나는 읽었으며 동의합니다",
        test48: "상품 개요",
        test49:"일",
        Agreement: "금융 상품 구매 동의서",
        test50: "충전 금액",
        test51:"이체 바우처 업로드",
        EnterPrice: "가격을 입력하세요",
    }
}