export default {
    total:{
        certified: '認証済み',
        UnCertified: '未認証',
        login: 'ログイン',
        register: '新規登録',
        registerNow: '今すぐ登録',
        loginNow: '今すぐログイン',
        buyNow: '今すぐ購入',
        Currency: '通貨',
        chang24: '変化',
        NowPrice: '最新価格',
        Leverage: 'レバレッジ',
        Favorites: 'お気に入り',
        Announcement:'お知らせ',
        dynamic:'動向',
        VerificationCode:'認証コード',
        enteremailcode:'メール認証コードを入力してください',
        GetCode:'送信',
        add:'追加',
        help: 'ヘルプセンター',
        xszy: "初心者ガイド",
        jyzn: '取引ガイド',
        aboutUs: 'aaaaについて'
    },
    login:{
        loginTitle: 'aaaaへようこそ',
        email: 'メールアドレス',
        enailTest: 'メールアドレスを入力してください',
        password: 'パスワード',
        passwordTest: 'パスワードを入力してください',
        unpassword: 'パスワードをお忘れですか？',
        noassets: 'アカウントをお持ちでない場合',
        Addassets: 'アカウントを作成する',
        password2: 'パスワードを再入力してください',
        password2test: 'パスワードを再入力してください',
        assets: '既存のアカウントがある場合'
    },
    topHeader: {
        nav01: '契約',
        nav02: 'スポット取引',
        nav03: '秒',
        nav04: 'コピー取引',
        nav05: 'ニュース',
        nav06:'高速切り替え',
        APPStore: 'APP Store',
        GooglePlay: 'Google Play',
        Android: 'Android',
        IOSaAndroid: 'IOS＆Android ダウンロード',
        cn: '简体中文',
        en: 'English',
        assetsHome: 'アセット',
        assets01: 'アセット詳細',
        assets02: '資金詳細',
        assets03: '注文詳細',
        assetsHome02: '注文',
        assets0201: '契約',
        assets0202: 'スポット',
        assets0203:'秒',
        account01: '概要',
        account02: 'アカウントとセキュリティ',
        account03: 'コピー取引の管理',
        account04: 'リード取引の管理',
        loginOut: 'ログアウト'
    },
    foote:{
        test: 'aaaaは、セキュアでインテリジェントで公正な環境を提供する、一括型デジタル投資サービス、多様な資産配分ツール、革新的な投資アドバイザリーサービス、業界をリードする流動性を通じて、デジタル資産投資におけるグローバルなデジタル金融サービスプラットフォームです。aaaaのコアチームは、伝統的な金融証券業界、マイクロソフトなどの一流のインターネット企業、その他の仮想通貨企業から来ており、伝統的な金融取引、一流の仮想通貨取引所プラットフォームの開発、グローバル市場運営における幅広い経験を持っています。設立から6か月で、グローバルなユーザーベースは10万人を超え、北米、ヨーロッパ、アジア太平洋地域を含む80以上の国と地域に広がっています。',
        tab1Title1: 'ヘルプ',
        tab1Test1: 'よくある質問',
        tab1Test2: 'お知らせセンター',
        tab2Title1: '利用規約',
        tab2Test1: '利用規約',
        tab2Test2: 'プライバシーポリシー',
        tab2Test3: 'リスク開示',
        tab3Title1: '参加する',
        msg: 'リスク警告: デジタル資産取引は新興業界で将来性が高い一方で、新しさからくる重大なリスクも伴います。特に契約取引では、レバレッジが利益とリスクの両方を同時に拡大します。参加する前に、業界、契約取引モデル、取引ルール、その他関連する知識について包括的な理解を持つことが重要です。リスクを負うことができる範囲内で取引することを強くお勧めします。取引にはリスクが伴いますので、市場参入時には注意が必要です！',
        banquan: '著作権',
        banquan2: '全著作権所有',
        aboutUs:'会社概要',
        Platform:'プラットフォーム紹介'
    },
    home:{
        bannerTitle: 'aaaaで暗号通貨を購入する',
        bannerTest: '世界をリードするソーシャル暗号通貨取引所',
        changUpRank: '上昇ランキング',
        changDownRank: '下降ランキング',
        whyTitle: 'なぜ当社を選ぶのか',
        indexTitle1: 'セキュリティ保証',
        indexTest1: 'システムリスクのないグローバルな分散展開',
        indexTitle2: 'ミッションとビジョン',
        indexTest2: '投資家が暗号通貨で利益を享受できるようにする',
        indexTitle3: 'スマートコントラクト',
        indexTest3: 'ロング・ショート取引、迅速な注文執行、最大100倍のレバレッジ',
        indexTitle4: 'コピートレード',
        indexTest4: '高い利回りを誇るトップトレーダーのトレードをワンクリックでフォロー',
        indexTitle5: 'リアルタイムディスカッション',
        indexTest5: 'リアルタイムチャット',
        indexTitle6: 'バイナリー',
        indexTest6: '世界初のバイナリーオプション暗号通貨指数',
        indexTitle7: 'マルチ通貨',
        indexTest7: '300以上の多様なプロジェクトの鍵を自信を持って掴む',
        indexTitle8: 'エコシステム',
        indexTest8: '暗号通貨取引所、ウォレット、オンチェーンエコロジーのワンストップサービスを提供',
        downLoadTitle: 'いつでもどこでも取引',
        downLoadTest: 'iOS、Android、PCデバイスで利用可能。いつでもどこでも取引できます。',
        downLoadCode: 'ダウンロードするにはスキャン',
        downLoadIosAnd: 'iOS＆Android'
    },
    contract:{
        Change:'24時間変動',
        High:'24時間高値',
        Low:'24時間安値',
        Volume24:'24時間出来高',
        Market:'マーケット',
        LimitPrice:'指値',
        BestPrice:'現在の最良価格で取引',
        EnterPrice:'価格を入力してください',
        Amount:'数量',
        Margin:'証拠金',
        Available:'利用可能な残高',
        Leverage:'レバレッジ',
        Fee:'手数料',
        Buy:'買い',
        Long:'ロング',
        Sell:'売り',
        Short:'ショート',
        Assets:'アセット',
        ContractAssets:'コントラクトアセット',
        Deposit:'今すぐ入金',
        Login:'取引アカウントにログイン',
        Register:'取引アカウントを登録',
        Instrument:'取引インストゥルメント',
        Minimum:'最小取引数量',
        Maximum:'最大取引数量',
        TradingTime:'取引時間',
        TradingLeverage:'取引レバレッジ',
        FeeRate:'手数料率',
        Introduction:'紹介',
        IssuanceDate:'発行日',
        TotalIssuance:'総発行量',
        CirculatingSupply:'流通量',
        Crowdfunding:'クラウドファンディング価格',
        Whitepaper:'ホワイトペーパー',
        Official:'公式ウェブサイト',
        Blockchain:'ブロックチェーンエクスプローラー',
        Positions:'ポジション',
        OpenOrder:'オープンオーダー',
        TradeHistory:'取引履歴',
        ProfitLoss:'利益/損失',
        CloseAll:'全てクローズ',
        Contract:'コントラクト',
        LotSize:'取引単位',
        OpeningPrice:'オープニング価格',
        CurrentPrice:'現在価格',
        TakeProfi:'利益確定価格',
        StopLoss:'損切価格',
        OrderPL:'注文利益/損失',
        OpeningTime:'オープニング時間',
        OrderID:'注文ID',
        Action:'操作',
        Settings:'設定',
        ClosePosition:'クローズ',
        Delete:'削除',
        Status:'ステータス',
        EnterCurrency:'通貨を入力してください',
        saveStopLoss:'利益確定/損切を設定',
        live:'ライブ',
        demo:'デモ'
    },
    SpotGoods:{
        pricelimit:'指値',
        marketprice:'成行',
        Price:'価格',
        MarketPrice:'成行',
        PleaseEnter:'入力してください',
        Quantity:'数量',
        Total:'合計',
        Or:'または',
        OpenOrders:'オープンオーダー',
        Fee:'手数料',
        QuantityAcc:'累計取引数量',
        LatestTrade:'最新取引',
        Time:'時間',
        NowOrders:'オープンオーダー',
        AllOrders:'全てのオーダー',
        OrderHistory:'オーダー履歴',
        Date:'日付',
        TradingPair:'取引ペア',
        OrderType:'オーダータイプ',
        MarketOrder:'成行オーダー',
        LimitOrder:'指値オーダー',
        Direction:'方向',
        OrderPrice:'オーダー価格',
        OrderQuantity:'オーダー数量',
        OrderAmoun:'オーダー金額'
    },
    Binary:{
        GameRules:'ゲームルール',
        Trading:'取引中',
        PositionClosed:'ポジションクローズ',
        BuyLong:'ロング購入',
        BuyShort:'ショート購入',
        OrderPeriod:'オーダーペリオド',
        TradingAmount:'取引量',
        Estimated:'予想クローズ時間',
        ClosingTime:'クローズ時間',
        PlaceBet:'ベットをする',
        Amount:'金額',
        Balance:'口座残高',
        Odds:'オッズ',
        Profit:'予想利益',
        Bullish:'ロング',
        Bearish:'ショート',
        countTime: 'カウントダウン',
        customInp: 'カスタム金額'
    },
    follow:{
        Ranking:'トレーダーランキング',
        Updates:'データは2時間ごとに更新されます',
        SearchKey:'全てのトレーダーを検索',
        Search:'検索',
        Manage:'フォロワーの管理',
        Details:'詳細',
        test1:'式 = コピー注文の [利益/損失] ÷ [総マージン] × 100%',
        title1:'30日間の利益率',
        test2:'式 = 自己注文の [利益注文数/総注文数] × 100%',
        title2:'30日間の勝率',
        title3:'現在のフォロワー',
        title4:'30日間の手数料',
        test5:'過去30日間の（自己注文）の総注文数',
        title5:'30日間の取引ロット数',
        test6:'登録日から計算',
        title6:'取引日数',
        Unfollow:'フォロー解除',
        Followed:'フォロー済み',
        EditCopying:'コピー設定の編集',
        CopyingCurrency:'コピー通貨',
        AmountCopying:'コピー金額',
        CopyingAmount:'コピー金額',
        ContractTrading:'契約取引ペア',
        LotSizeRatio:'ロットサイズの比率',
        StartCopying:'コピーを開始する',
        CancelCopying:'コピーをキャンセルする',
        title7:'30日間の最大ドローダウン',
        test7:'過去30日間の資産損失率（最大損失を基準とした前日の資産損失の割合）',
        BackLobby:'ロビーに戻る',
        TradeHistory:'取引履歴',
        ClosingPrice:'終値',
        info:'個人プロフィール',
        CumulativeCopying:'累計コピー金額',
        TotalCopying:'総コピー利益',
        Discover:'エキスパートを見つける',
        CopyOrders:'コピー注文',
        CurrentFollowers:'現在のフォロワー',
        Avatar:'アバター',
        TraderName:'トレーダー名',
        MaximumPosition:'最大ポジション数量',
        ProfitLoss:'コピー利益/損失',
        Edit:'編集',
        TodayProfit:'今日の利益',
        Past7Days:'過去7日間の総利益',
        Accumulated:'累計総利益',
        ProfitDetails:'利益の詳細',
        Followers:'フォロワー',
        Profit:'利益',
        FollowerAvatar:'フォロワーのアバター',
        FollowerNickname:'フォロワーのニックネーム',
        CopyingTime:'コピー開始日時',
        Notice:'お知らせ',
        NoticeTest1:'あなたはまだトレーダーではないため、この操作は実行できません！',
        NoticeTest2:'審査中ですので、お待ちください！',
        Cancel:'キャンセル',
        ApplyNow:'今すぐ申し込む',
        ApplyTrader:'トレーダーとして申請',
        Nickname:'ニックネーム（必須）',
        Phone:'電話番号（必須）',
        Email:'メールアドレス（任意）',
        test1:'申請を送信した後、弊社スタッフができるだけ早く連絡いたします。必要な申請情報を正確に提供してください。',
        test2:'審査期間中、以下の条件を満たさないようにしてください。そうでない場合、審査に失敗します：ポジション保有、現在の注文、現在のプラン、およびフォローしているトレーダー。',
        Apply:'申請する'
    },
    account:{
        Overview:'概要',
        Security:'セキュリティ',
        UserID:'ユーザーID',
        Welcome:'aaaaへようこそ！',
        Welcometest:'あともう少しで開始できます',
        Registration:'アカウント登録',
        SecurityVerification:'セキュリティ検証',
        GotoBind:'バインドへ進む',
        QuickBuy:'クイック購入',
        AssetDetails:'資産詳細',
        Deposit:'入金',
        Withdraw:'出金',
        Transfer:'送金',
        TransferList: '振込履歴',
        type: 'タイプ',
        SpotAccount:'スポットアカウント',
        FuturesAccount:'先物アカウント',
        HideAsset:'資産評価を非表示にする',
        Available:'利用可能（USDT）残高',
        checkdetails:'詳細な資産数量については、資産詳細を確認してください',
        Recommendations:'セキュリティのおすすめ',
        PasswordSet:'パスワードの設定',
        Change:'変更',
        FundPassword:'資金パスワード',
        GotoSet:'設定へ進む',
        AddressManagement:'アドレスの管理',
        GotoAdd:'追加へ進む',
        Verification:'本人確認',
        GotoVerify:'確認へ進む',
        LatestNews:'最新ニュース',
        SafetyTitle:'2要素認証',
        SafetyTest:'アカウントを保護するために、少なくとも1つの実名認証を完了することをお勧めします',
        SafetyText1:'アカウントのログインパスワードを管理するために使用されます',
        SafetyText2:'アカウントの資金パスワードを管理するために使用されます',
        NotSet:'未設定',
        Set:'設定',
        SafetyText3:'取引とアカウントのセキュリティを保護します',
        SafetyText4:'出金アドレスの正確性を確保します',
        Manage:'管理',
        InvitationLink:'招待リンク',
        copyInvitationLink:'コピー',
        InvitationCode:'招待コード',
        copyInvitationCode:'コピー',
        enterInvitationCode:'招待コードを入力してください'
    },
    finance:{
        Protocol:'メインネットワーク（プロトコル）',
        DepositAddress:'入金アドレス',
        QRCode:'入金QRコード',
        DepositTest1:'取引ごとの最小入金額は',
        DepositTest2:'を下回ることはできません。この金額以下の入金は成功しません。',
        DepositTest3:'各入金前に、アドレスを正確にコピーするためにこのページにアクセスする必要があります。誤ったアドレスを使用すると、入金が失敗します。',
        DepositTest4:'上記のアドレスに',
        DepositTest5:'以外の資産を入金しないでください。そうしないと、資産は回復できません。',
        WithdrawalAddress:'出金アドレス',
        AddressManagement:'アドレス管理',
        Memo:'メモ',
        WithdrawalAmount:'出金金額',
        AvailableBalance:'利用可能残高',
        Withdraw:'出金',
        WithdrawTest1:'出金先のアドレスが存在し、アクティブ化されていることを確認してください。それ以外の場合、出金が失敗し、資産は回復できません。',
        WithdrawTest2:'アドレスタグがある場合は、アドレスタグを正確に確認して、回復できない資産の損失を防止してください。',
        WithdrawTest3:'資金の安全性を確保するために、アカウントのセキュリティ戦略が変更されたり、パスワードが変更されたりした場合、出金は手動で審査されます。弊社のスタッフから電話またはメールで連絡があるまで、お待ちください。',
        WithdrawTest4:'コンピューターとブラウザのセキュリティを確保して、情報の改ざんや漏洩を防止してください。',
        WithdrawTest5:'出金時間（UTC+8）：月曜日から日曜日の9:00-22:00。',
        AvailableAssets:'利用可能な資産',
        GamblingAccount:'Secondsアカウント',
        notpassword:'まだ資金パスワードが設定されていないため、この操作は行えません！',
        Back:'戻る',
        AddAddress:'新しいアドレスを追加',
        SelectCurrency:'通貨を選択',
        Confirm:'確認',
        from:'から',
        to:'へ'
    },
    assets:{
        ContractPositions:'契約ポジション',
        DepositDetails:'入金詳細',
        WithdrawalHistory:'出金履歴',
        WithdrawalRecords:'入出金履歴',
        TotalEquivalent:'すべてのスポットアセットの合計換算価値（USDT）',
        AvailableBalance:'スポットの利用可能残高（USDT）',
        HideSmall:'小口資産を非表示にする',
        Equivalent:'換算価値',
        FrozenAmount:'凍結額',
        DepositType:'入金タイプ',
        DepositAmount:'入金額',
        DepositCurrency:'入金通貨',
        DepositTime:'入金時間',
        WithdrawalType:'出金タイプ',
        WithdrawalAmount:'出金額',
        WithdrawalCurrency:'出金通貨',
        WithdrawalTime:'出金時間'
    },
    fastChange:{
        Sell:'売る',
        ReferencePrice:'参考価格',
        Buy:'買う',
        Quantity:'交換可能な数量',
        PleaseEnter:'交換したい数量を入力してください',
        Exchange:'交換する',
        Trading:'小口取引 ● 大口取引',
        TradingTest:'BTC、ETH、およびデジタル通貨の迅速な交換',
        Step1:'ステップ1',
        Step1Test:'交換する通貨を選択します',
        Step2:'ステップ2',
        Step2Test:'交換する数量を入力します',
        Step3:'ステップ3',
        Step3Test:'交換を開始します'
    },
    Password:{
        ChangePassword:'ログインパスワードの変更',
        OldPassword:'現在のログインパスワード',
        enterOldPassword:'現在のログインパスワードを入力してください',
        NewLogin:'新しいログインパスワード',
        enterNewLogin:'新しいログインパスワードを入力してください',
        againenterNewLogin:'新しいログインパスワードをもう一度入力してください',
        FundPassword:'資金パスワードの設定',
        fundpassword:'資金パスワードを入力してください',
        againfundpassword:'資金パスワードをもう一度入力してください',
        Name:'名前',
        Number:'身分証明書番号',
        photos:'身分証明書の写真をアップロードしてください。1枚目は表面、2枚目は裏面です',
        photos2:'証明写真をアップロードしてください',
        Upload:'アップロード',
        wait:'審査中です。お待ちください！',
        categoryType:'ドキュメント タイプ',
        category1:"IDカード",
        category2:"パスポート",
        category3:"運転免許証"
    },
    add:{
        Copied:"コピーしました！",
        test29: "シミュレーション取引は終了しました、リアル取引が待っています！",
        test30: "今すぐリチャージして取引資金のリワードを楽しむ",
        test31: "すぐにサポートとツールをさらに取得する",
        test32: "収益の旅を始めるためにリチャージ",
        DemoTrade:"デモトレード",
        Progress: "進行中",
        Requested: "払い戻しリクエスト済み",
        Refunded: "払い戻し済み",
        Completed: "完了",
        test1: "製品概要",
        test2: "(aaaa)金融管理サービス（FMS）は、（aaaa）によって設計された製品であり、アイドルのデジタル資産を持つユーザーが低いしきい値で利益を得るのを支援します。初めて利用する方にも簡単です。 （aaaa）金融管理サービス（FMS）は、ユーザーがレバレッジ取引者に貸付を行い、貸付市場で利益を得るための異なる期間オプションを提供します。固定期間のシンプルコインの収益は、コインをロックしてPoSリワードやプロジェクトのインセンティブを得ることを含みます。",
        test3: "＊ご注意：過去の収益は将来の収益を示すものではありません。通貨、物理的な商品、株式、その他の形式の財産収益、またはその他の形式の財産収益の返済を特定の期間内に行うことを約束するものではありません。",
        test4: "安全かつ信頼性があります",
        test5: "**(aaaa)は、厳格なリスク管理システム、先進的なセキュリティ対策、高度なインフラを備えており、ユーザーの資産セキュリティを完全に保護しています。",
        test6: "柔軟な購読と償還",
        test7: "24時間、いつでも購読と償還のサポートが利用できます。現在のシンプルコインの収益の償還は即時であり、固定期間のシンプルコインの収益の償還には最大30分かかります。",
        test8: "固定期間(aaaa)金融管理サービス（FMS）",
        test9: "固定期間(aaaa)**金融管理サービス（FMS）には、担保化によって得られた報酬やProof of Stake（PoS）メカニズムに基づく報酬、およびプラットフォームの報酬など、複数の収益源があります。",
        test10: "年間リファレンス利回りとは何ですか？",
        test11: "年間リファレンス利回りは、購読する製品の期待される年間化された利回りであり、参考のみです。",
        test12: "期待収益はどのように計算されますか？",
        test13: "予想収益は、購読数量と製品の年間リファレンス利回りに基づいて計算され、1日あたりの予想収益の見積もりを提供します。参考のみです。",
        test14: "利子はいつ発生しますか？",
        test15: "利益は、購読の翌日の12:00（UTC+8）から発生します。",
        test16: "収益はいつ配布されますか？",
        test17: "収益は、利息の発生日の翌日の12:00（UTC+8）から配布されます。",
        test18: "なぜ異なる製品には異なる償還期間があるのですか？",
        test19: "償還期間とは、償還を確認して実際の償還が行われるまでの時間を指します。固定期間のシンプルコインの収益のための資産は、チェーン上で担保化されており、チェーンからの償還には時間がかかります。各公共チェーンは異なる方法で運営されているため、償還期間は異なります。",
        test20: "満期時に固定期間製品を手動で償還する必要がありますか？",
        test21: "これは状況によります。すべての固定期間製品は自動更新にデフォルトで設定されます。満期時には、同じ期間が自動的に更新されます。自動更新を有効にした後、償還は手動で行う必要があります。自動更新機能はデフォルトで有効になっています。無効にするには、注文詳細ページに移動してください。",
        test22: "償還を待っている間にまだ収益を得ることはできますか？",
        test23: "償還が確認されると、利息の発生が停止します。償還を待っている間に収益を得ることはできません。",
        test24: "最良の固定期間**(aaaa)**金融管理サービス（FMS）製品を選ぶ方法は？",
        test25: "提供するすべてのシンプルコイン収益製品の中で、さまざまなデジタル資産、条件、過去の収益などを比較して、自分に合った製品を選択できます。当社は資産の担保、報酬の収集、配布のサービスのみを提供し、投資助言は提供していません。投資判断を行う前に、この製品の性質やリスク、その他の関連する事情を十分に理解し、投資経験、財務状況、投資目標、投資期間、投資リスクを負担する意志と能力、その他の関連する事情に基づいて独自の判断を行い、自己の決定に責任を持つべきです。",
        test26: "収益",
        test27: "利点",
        test28: "FAQ",
        assets: "金融資産",
        Redemption: "償還",
        Subscribe: "申し込む",
        SubscribeType: "申し込みタイプ",
        SubscribeNum: "申し込み金額",
        Historical: "累積利息",
        SubscribeTime: "申し込み時間",
        RedemptionTime: "償還時間",
        RedemptionConfirm: "償還確認",
        LockedSavings: "定期投資",
        test33: "ファイナンシャルマネジメント",
        test34: "暗号通貨投資の核心的な概念とリスク管理戦略を理解し、市場の変動に落ち着いて対処し、財務目標を達成します。今すぐ参加して、暗号通貨投資の旅を始めましょう！",
        test35: "暗号通貨投資の本質を掌握し、市場のトレンドを解釈し、潜在的な機会を探り、富を増やします。門構えは必要ありません。低リスク、高リターン。暗号通貨の財務管理の旅を始め、富の新たな高みを目指します！",
        test36: "製品リスト",
        test37: "申し込みリスト",
        test38: "年利率：",
        test39: "日数：",
        test40: "最小投資金額：",
        test41: "最大金額：",
        test42: "利息開始日：",
        test43: "利息計算終了日：",
        test44: "最大",
        test45: "利用可能な資産：",
        test46: "見積利益：",
        test47: "私は読んで同意します",
        test48: "製品概要",
        test49:"日",
        Agreement: "金融取引契約",
        test50:"チャージ金額",
        test51:"転送伝票をアップロード",
        EnterPrice: "価格を入力してください",
    }
}